{
    "addPanelData/hackathon_2022/bootstrap/Alert.png": "/addPanelData/hackathon_2022/bootstrap/Alert.png",
    "addPanelData/hackathon_2022/bootstrap/Button.png": "/addPanelData/hackathon_2022/bootstrap/Button.png",
    "addPanelData/hackathon_2022/bootstrap/ButtonGroup.png": "/addPanelData/hackathon_2022/bootstrap/ButtonGroup.png",
    "addPanelData/hackathon_2022/bootstrap/Card.png": "/addPanelData/hackathon_2022/bootstrap/Card.png",
    "addPanelData/hackathon_2022/bootstrap/Carousel.png": "/addPanelData/hackathon_2022/bootstrap/Carousel.png",
    "addPanelData/hackathon_2022/bootstrap/Dropdown.png": "/addPanelData/hackathon_2022/bootstrap/Dropdown.png",
    "addPanelData/hackathon_2022/bootstrap/ListGroup.png": "/addPanelData/hackathon_2022/bootstrap/ListGroup.png",
    "addPanelData/hackathon_2022/bootstrap/Modal.png": "/addPanelData/hackathon_2022/bootstrap/Modal.png",
    "addPanelData/hackathon_2022/bootstrap/Navs.png": "/addPanelData/hackathon_2022/bootstrap/Navs.png",
    "addPanelData/hackathon_2022/bootstrap/Pagination.png": "/addPanelData/hackathon_2022/bootstrap/Pagination.png",
    "addPanelData/hackathon_2022/bootstrap/Progress.png": "/addPanelData/hackathon_2022/bootstrap/Progress.png",
    "addPanelData/hackathon_2022/bootstrap/Spinner.png": "/addPanelData/hackathon_2022/bootstrap/Spinner.png",
    "addPanelData/hackathon_2022/bootstrap/Tabs.png": "/addPanelData/hackathon_2022/bootstrap/Tabs.png",
    "addPanelData/hackathon_2022/bootstrap/Toast.png": "/addPanelData/hackathon_2022/bootstrap/Toast.png",
    "addPanelData/hackathon_2022/bootstrap/Tooltip.png": "/addPanelData/hackathon_2022/bootstrap/Tooltip.png",
    "addPanelData/sections/3DGalleriesSection_en/3DGalleriesSection_en.png": "/addPanelData/sections/3DGalleriesSection_en/3DGalleriesSection_en.v4.png",
    "addPanelData/sections/accordionContainer_en/accordionConainer_en.png": "/addPanelData/sections/accordionContainer_en/accordionConainer_en.png",
    "addPanelData/sections/accordionContainer_en/accordionConainer_en.v2.retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionConainer_en.v2.retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionConainer_en.v2_hover.retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionConainer_en.v2_hover.retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v6.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.png": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v2.png",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_hover.png": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_hover.png",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_retina_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v2_retina_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_retina_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v3_retina_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_retina_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v4_retina_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_retina_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v5_retina_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_hover.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_retina.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_retina.jpg",
    "addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_retina_hover.jpg": "/addPanelData/sections/accordionContainer_en/accordionContainer_en.v6_retina_hover.jpg",
    "addPanelData/sections/addPagesSection/add_admin_page_circle.png": "/addPanelData/sections/addPagesSection/add_admin_page_circle.v1.png",
    "addPanelData/sections/addPagesSection/addBlankPage.png": "/addPanelData/sections/addPagesSection/addBlankPage.v1.png",
    "addPanelData/sections/addPagesSection/addBlankPageDisabled.png": "/addPanelData/sections/addPagesSection/addBlankPageDisabled.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_ar/addPanelCaptchaSection_ar.png": "/addPanelData/sections/addPanelCaptchaSection_ar/addPanelCaptchaSection_ar.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_cs/addPanelCaptchaSection_cs.png": "/addPanelData/sections/addPanelCaptchaSection_cs/addPanelCaptchaSection_cs.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_da/addPanelCaptchaSection_da.png": "/addPanelData/sections/addPanelCaptchaSection_da/addPanelCaptchaSection_da.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_de/addPanelCaptchaSection_de.png": "/addPanelData/sections/addPanelCaptchaSection_de/addPanelCaptchaSection_de.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_en/addPanelCaptchaSection.png": "/addPanelData/sections/addPanelCaptchaSection_en/addPanelCaptchaSection.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_en/addPanelCaptchaSection_en.png": "/addPanelData/sections/addPanelCaptchaSection_en/addPanelCaptchaSection_en.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_es/addPanelCaptchaSection_es.png": "/addPanelData/sections/addPanelCaptchaSection_es/addPanelCaptchaSection_es.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_fr/addPanelCaptchaSection_fr.png": "/addPanelData/sections/addPanelCaptchaSection_fr/addPanelCaptchaSection_fr.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_hi/addPanelCaptchaSection_hi.png": "/addPanelData/sections/addPanelCaptchaSection_hi/addPanelCaptchaSection_hi.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_it/addPanelCaptchaSection_it.png": "/addPanelData/sections/addPanelCaptchaSection_it/addPanelCaptchaSection_it.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_ja/addPanelCaptchaSection_ja.png": "/addPanelData/sections/addPanelCaptchaSection_ja/addPanelCaptchaSection_ja.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_ko/addPanelCaptchaSection_ko.png": "/addPanelData/sections/addPanelCaptchaSection_ko/addPanelCaptchaSection_ko.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_nl/addPanelCaptchaSection_nl.png": "/addPanelData/sections/addPanelCaptchaSection_nl/addPanelCaptchaSection_nl.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_no/addPanelCaptchaSection_no.png": "/addPanelData/sections/addPanelCaptchaSection_no/addPanelCaptchaSection_no.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_pl/addPanelCaptchaSection_pl.png": "/addPanelData/sections/addPanelCaptchaSection_pl/addPanelCaptchaSection_pl.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_pt/addPanelCaptchaSection_pt.png": "/addPanelData/sections/addPanelCaptchaSection_pt/addPanelCaptchaSection_pt.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_ru/addPanelCaptchaSection_ru.png": "/addPanelData/sections/addPanelCaptchaSection_ru/addPanelCaptchaSection_ru.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_th/addPanelCaptchaSection_th.png": "/addPanelData/sections/addPanelCaptchaSection_th/addPanelCaptchaSection_th.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_tr/addPanelCaptchaSection_tr.png": "/addPanelData/sections/addPanelCaptchaSection_tr/addPanelCaptchaSection_tr.v1.png",
    "addPanelData/sections/addPanelCaptchaSection_zh/addPanelCaptchaSection_zh.png": "/addPanelData/sections/addPanelCaptchaSection_zh/addPanelCaptchaSection_zh.v1.png",
    "addPanelData/sections/anchorsSection_en/anchorsSection_en.png": "/addPanelData/sections/anchorsSection_en/anchorsSection_en.v6.png",
    "addPanelData/sections/anchorsSection_en/anchorsSection_en.v6@2x.png": "/addPanelData/sections/anchorsSection_en/anchorsSection_en.v6@2x.png",
    "addPanelData/sections/animalsNatureSection_en/animalsNatureSection_en.png": "/addPanelData/sections/animalsNatureSection_en/animalsNatureSection_en.v4.png",
    "addPanelData/sections/animationsEmbed/addPanelLottieEmbed.png": "/addPanelData/sections/animationsEmbed/addPanelLottieEmbed.png",
    "addPanelData/sections/arrowsSection_en/arrowsSection_en.png": "/addPanelData/sections/arrowsSection_en/arrowsSection_en.v7.png",
    "addPanelData/sections/arrowsSection_en/arrowsSection_redesign_en.png": "/addPanelData/sections/arrowsSection_en/arrowsSection_redesign_en.png",
    "addPanelData/sections/arrowsSection_en/mobileArrowsSection_en.png": "/addPanelData/sections/arrowsSection_en/mobileArrowsSection_en.v2.png",
    "addPanelData/sections/bannersBadgesSection_en/bannersBadgesSection_en.png": "/addPanelData/sections/bannersBadgesSection_en/bannersBadgesSection_en.v4.png",
    "addPanelData/sections/basicGalleries/grid.png": "/addPanelData/sections/basicGalleries/grid.v1.png",
    "addPanelData/sections/basicGalleries/more.png": "/addPanelData/sections/basicGalleries/more.v1.png",
    "addPanelData/sections/basicGalleries/slider.png": "/addPanelData/sections/basicGalleries/slider.v1.png",
    "addPanelData/sections/basicShapesSection_en/basicShapesSection_en.png": "/addPanelData/sections/basicShapesSection_en/basicShapesSection_en.v5.png",
    "addPanelData/sections/basicShapesSection_en/basicShapesSection_redesign_en.png": "/addPanelData/sections/basicShapesSection_en/basicShapesSection_redesign_en.png",
    "addPanelData/sections/basicShapesSection_en/mobileBasicShapesSection_en.png": "/addPanelData/sections/basicShapesSection_en/mobileBasicShapesSection_en.v2.png",
    "addPanelData/sections/blogArchiveListSection_en/blogArchiveListSection_en.png": "/addPanelData/sections/blogArchiveListSection_en/blogArchiveListSection_en.v1.png",
    "addPanelData/sections/blogArchiveSection_en/blogArchiveSection_en.png": "/addPanelData/sections/blogArchiveSection_en/blogArchiveSection_en.v2.png",
    "addPanelData/sections/blogArchiveSection_en/studioBlogArchiveSection_en@2x.png": "/addPanelData/sections/blogArchiveSection_en/studioBlogArchiveSection_en@2x.png",
    "addPanelData/sections/blogCategoryListSection_en/blogCategoryListSection_en.png": "/addPanelData/sections/blogCategoryListSection_en/blogCategoryListSection_en.v1.png",
    "addPanelData/sections/blogCategoryMenuSection_en/blogCategoryMenuSection_en.png": "/addPanelData/sections/blogCategoryMenuSection_en/blogCategoryMenuSection_en.v2.png",
    "addPanelData/sections/blogCategoryMenuSection_en/studioBlogCategoryMenuSection_en@2x.png": "/addPanelData/sections/blogCategoryMenuSection_en/studioBlogCategoryMenuSection_en@2x.png",
    "addPanelData/sections/blogCustomFeedSection_en/blogCustomFeedSection_en.png": "/addPanelData/sections/blogCustomFeedSection_en/blogCustomFeedSection_en.v1.png",
    "addPanelData/sections/blogFeaturedPostsSection_en/blogFeaturedPostsSection_en.png": "/addPanelData/sections/blogFeaturedPostsSection_en/blogFeaturedPostsSection_en.v1.png",
    "addPanelData/sections/blogPostListEditorialSmallSection_en/blogPostListEditorialSmallSection_en.png": "/addPanelData/sections/blogPostListEditorialSmallSection_en/blogPostListEditorialSmallSection_en.png",
    "addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection.png": "/addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection.png",
    "addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection_en.png": "/addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection_en.v4.png",
    "addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection_en@2x.png": "/addPanelData/sections/blogPostListLargeSection_en/blogPostListLargeSection_en@2x.v2.png",
    "addPanelData/sections/blogPostListLargeSection_en/studioBlogPostListLargeSection_en@2x.png": "/addPanelData/sections/blogPostListLargeSection_en/studioBlogPostListLargeSection_en@2x.png",
    "addPanelData/sections/blogPostListListSmallSection_en/blogPostListListSmallSection_en.jpg": "/addPanelData/sections/blogPostListListSmallSection_en/blogPostListListSmallSection_en.jpg",
    "addPanelData/sections/blogPostListSection_en/blogPostListSection_en.png": "/addPanelData/sections/blogPostListSection_en/blogPostListSection_en.v1.png",
    "addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en.png": "/addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en.v2.png",
    "addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en@2x.png": "/addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en@2x.png",
    "addPanelData/sections/blogPostListSidebarSection_en/studioBlogPostListSidebarSection_en@2x.png": "/addPanelData/sections/blogPostListSidebarSection_en/studioBlogPostListSidebarSection_en@2x.v1.png",
    "addPanelData/sections/blogPostListSliderSmallSection_en/blogPostListSliderSmallSection_en.png": "/addPanelData/sections/blogPostListSliderSmallSection_en/blogPostListSliderSmallSection_en.png",
    "addPanelData/sections/blogPostTickerSection_en/blogPostTickerSection_en.png": "/addPanelData/sections/blogPostTickerSection_en/blogPostTickerSection_en.v1.png",
    "addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en.png": "/addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en.v5.png",
    "addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en@2x.png": "/addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en@2x.v5.png",
    "addPanelData/sections/blogRecentPostsSection_en/studioBlogRecentPostsSection_en@2x.png": "/addPanelData/sections/blogRecentPostsSection_en/studioBlogRecentPostsSection_en@2x.png",
    "addPanelData/sections/blogRssButtonSection_en/blogRssButtonSection_en.png": "/addPanelData/sections/blogRssButtonSection_en/blogRssButtonSection_en.v3.png",
    "addPanelData/sections/blogRssButtonSection_en/studioBlogRssButtonSection_en@2x.png": "/addPanelData/sections/blogRssButtonSection_en/studioBlogRssButtonSection_en@2x.png",
    "addPanelData/sections/blogTagCloudSection_en/blogTagCloudSection_en.png": "/addPanelData/sections/blogTagCloudSection_en/blogTagCloudSection_en.v2.png",
    "addPanelData/sections/blogTagCloudSection_en/studioBlogTagCloudSection_en@2x.png": "/addPanelData/sections/blogTagCloudSection_en/studioBlogTagCloudSection_en@2x.png",
    "addPanelData/sections/blogWelcomeMagicMigration_en/blogWelcomeMagicMigration_en.png": "/addPanelData/sections/blogWelcomeMagicMigration_en/blogWelcomeMagicMigration_en.v2.png",
    "addPanelData/sections/blogWelcomeSection_en/blogWelcomeSection_en.png": "/addPanelData/sections/blogWelcomeSection_en/blogWelcomeSection_en.v5.png",
    "addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en.png": "/addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en.v2.png",
    "addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en.v1@x2.png": "/addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en.v1@x2.png",
    "addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en@x2.png": "/addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en@x2.v1.png",
    "addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en.png": "/addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en.v2.png",
    "addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en@x2.png": "/addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en@x2.v2.png",
    "addPanelData/sections/bookingsAppointmentFormSection_en/bookingsAppointmentFormSection_en.png": "/addPanelData/sections/bookingsAppointmentFormSection_en/bookingsAppointmentFormSection_en.v1.png",
    "addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_editorX_en.png": "/addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_editorX_en.v2.png",
    "addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_wix_studio_en.png": "/addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_wix_studio_en.png",
    "addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_editorX_en.png": "/addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_editorX_en.v2.png",
    "addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_wix_studio_en.png": "/addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_wix_studio_en.png",
    "addPanelData/sections/bookingsNextAvailabilityBlocksSection_en/bookingsNextAvailabilityBlocksWidget1.png": "/addPanelData/sections/bookingsNextAvailabilityBlocksSection_en/bookingsNextAvailabilityBlocksWidget1.v3.png",
    "addPanelData/sections/bookingsNextAvailabilityBlocksSection_en/bookingsNextAvailabilityBlocksWidget2.png": "/addPanelData/sections/bookingsNextAvailabilityBlocksSection_en/bookingsNextAvailabilityBlocksWidget2.png",
    "addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_editorX_en.png": "/addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_editorX_en.png",
    "addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_en.png": "/addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_en.v3.png",
    "addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_wix_studio_en.png": "/addPanelData/sections/bookingsWeeklyTimetableSection_en/bookingsWeeklyTimetableSection_wix_studio_en.png",
    "addPanelData/sections/boxSlideShow980Section_en/boxSlideShow980Section_en.png": "/addPanelData/sections/boxSlideShow980Section_en/boxSlideShow980Section_en.v3.png",
    "addPanelData/sections/boxSlideShow980Section_en/boxSlideShow980Section_redesign_en.png": "/addPanelData/sections/boxSlideShow980Section_en/boxSlideShow980Section_redesign_en.png",
    "addPanelData/sections/boxSlideShowDesignSection_en/boxSlideShowDesignSection_en.png": "/addPanelData/sections/boxSlideShowDesignSection_en/boxSlideShowDesignSection_en.v1.png",
    "addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_en.png": "/addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_en.v7.png",
    "addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_redesign_en.png": "/addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_redesign_en.png",
    "addPanelData/sections/boxSlideShowSlideSection_en/boxSlideShowSlideSection_en.png": "/addPanelData/sections/boxSlideShowSlideSection_en/boxSlideShowSlideSection_en.v1.png",
    "addPanelData/sections/breadcrumbs_en/breadcrumbs_en.png": "/addPanelData/sections/breadcrumbs_en/breadcrumbs_en.png",
    "addPanelData/sections/breadcrumbs_en/breadcrumbs_en_v2.png": "/addPanelData/sections/breadcrumbs_en/breadcrumbs_en_v2.png",
    "addPanelData/sections/breadcrumbsSection_en/breadcrumbsSection_en.png": "/addPanelData/sections/breadcrumbsSection_en/breadcrumbsSection_en.v1.png",
    "addPanelData/sections/challengesSection_en/challengesSection_en.png": "/addPanelData/sections/challengesSection_en/challengesSection_en.v4.png",
    "addPanelData/sections/chatButton_en/chat_button_en.png": "/addPanelData/sections/chatButton_en/chat_button_en.v4.png",
    "addPanelData/sections/chatButton_en/chatButton_en.png": "/addPanelData/sections/chatButton_en/chatButton_en.png",
    "addPanelData/sections/chatButton_en/hover_en/chat_button__hover_en.png": "/addPanelData/sections/chatButton_en/hover_en/chat_button__hover_en.v4.png",
    "addPanelData/sections/chatButton_en/hover_en/chat_button_hover_en.png": "/addPanelData/sections/chatButton_en/hover_en/chat_button_hover_en.v1.png",
    "addPanelData/sections/chatButton_en/hover_en/chatButton_hover_en.png": "/addPanelData/sections/chatButton_en/hover_en/chatButton_hover_en.png",
    "addPanelData/sections/checkboxGroupSection_en/checkboxGroupSection_en.png": "/addPanelData/sections/checkboxGroupSection_en/checkboxGroupSection_en.v1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragpraph) - Hover.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragpraph) - Hover.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragpraph) - Regular.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragpraph) - Regular.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Hover.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Hover.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Hover.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Hover.v1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Regular.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Regular.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Regular.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Paragraph) - Regular.v1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles) - updated.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles) - updated.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles) - updated.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles) - updated.v1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles).jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible text (Titles).jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Paragraph)_Hover.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Paragraph)_Hover.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Paragraph)_Regular.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Paragraph)_Regular.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Titles)_updated.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_(Titles)_updated.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_hover.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_hover.v2.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_hover@2x.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_hover@2x.v2.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Hover.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Hover.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Hover@1.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Hover@1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Regular.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Regular.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Regular@1.png": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Regular@1.png",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_regular.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_regular.v2.jpg",
    "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_regular@2x.jpg": "/addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_regular@2x.v2.jpg",
    "addPanelData/sections/columnsAboutSection_cz/columnsAboutSection_cz.png": "/addPanelData/sections/columnsAboutSection_cz/columnsAboutSection_cz.png",
    "addPanelData/sections/columnsAboutSection_da/columnsAboutSection_da.png": "/addPanelData/sections/columnsAboutSection_da/columnsAboutSection_da.png",
    "addPanelData/sections/columnsAboutSection_de/columnsAboutSection_de.png": "/addPanelData/sections/columnsAboutSection_de/columnsAboutSection_de.png",
    "addPanelData/sections/columnsAboutSection_en/columnsAboutSection_en.png": "/addPanelData/sections/columnsAboutSection_en/columnsAboutSection_en.v2.png",
    "addPanelData/sections/columnsAboutSection_es/columnsAboutSection_es.png": "/addPanelData/sections/columnsAboutSection_es/columnsAboutSection_es.png",
    "addPanelData/sections/columnsAboutSection_fr/columnsAboutSection_fr.png": "/addPanelData/sections/columnsAboutSection_fr/columnsAboutSection_fr.png",
    "addPanelData/sections/columnsAboutSection_hi/columnsAboutSection_hi.png": "/addPanelData/sections/columnsAboutSection_hi/columnsAboutSection_hi.png",
    "addPanelData/sections/columnsAboutSection_it/columnsAboutSection_it.png": "/addPanelData/sections/columnsAboutSection_it/columnsAboutSection_it.png",
    "addPanelData/sections/columnsAboutSection_jp/columnsAboutSection_jp.png": "/addPanelData/sections/columnsAboutSection_jp/columnsAboutSection_jp.png",
    "addPanelData/sections/columnsAboutSection_nl/columnsAboutSection_nl.png": "/addPanelData/sections/columnsAboutSection_nl/columnsAboutSection_nl.png",
    "addPanelData/sections/columnsAboutSection_no/columnsAboutSection_no.png": "/addPanelData/sections/columnsAboutSection_no/columnsAboutSection_no.png",
    "addPanelData/sections/columnsAboutSection_pl/columnsAboutSection_pl.png": "/addPanelData/sections/columnsAboutSection_pl/columnsAboutSection_pl.png",
    "addPanelData/sections/columnsAboutSection_pt/columnsAboutSection_pt.png": "/addPanelData/sections/columnsAboutSection_pt/columnsAboutSection_pt.png",
    "addPanelData/sections/columnsAboutSection_ru/columnsAboutSection_ru.png": "/addPanelData/sections/columnsAboutSection_ru/columnsAboutSection_ru.png",
    "addPanelData/sections/columnsAboutSection_sv/columnsAboutSection_sv.png": "/addPanelData/sections/columnsAboutSection_sv/columnsAboutSection_sv.png",
    "addPanelData/sections/columnsAboutSection_tr/columnsAboutSection_tr.png": "/addPanelData/sections/columnsAboutSection_tr/columnsAboutSection_tr.png",
    "addPanelData/sections/columnsClientsSection_en/columnsClientsSection_en.png": "/addPanelData/sections/columnsClientsSection_en/columnsClientsSection_en.png",
    "addPanelData/sections/columnsContactSection_da/columnsContactSection_da.png": "/addPanelData/sections/columnsContactSection_da/columnsContactSection_da.png",
    "addPanelData/sections/columnsContactSection_de/columnsContactSection_de.png": "/addPanelData/sections/columnsContactSection_de/columnsContactSection_de.png",
    "addPanelData/sections/columnsContactSection_en/columnsContactSection_en.png": "/addPanelData/sections/columnsContactSection_en/columnsContactSection_en.v2.png",
    "addPanelData/sections/columnsContactSection_es/columnsContactSection_es.png": "/addPanelData/sections/columnsContactSection_es/columnsContactSection_es.png",
    "addPanelData/sections/columnsContactSection_fr/columnsContactSection_fr.png": "/addPanelData/sections/columnsContactSection_fr/columnsContactSection_fr.png",
    "addPanelData/sections/columnsContactSection_hi/columnsContactSection_hi.png": "/addPanelData/sections/columnsContactSection_hi/columnsContactSection_hi.png",
    "addPanelData/sections/columnsContactSection_it/columnsContactSection_it.png": "/addPanelData/sections/columnsContactSection_it/columnsContactSection_it.png",
    "addPanelData/sections/columnsContactSection_jp/columnsContactSection_jp.png": "/addPanelData/sections/columnsContactSection_jp/columnsContactSection_jp.png",
    "addPanelData/sections/columnsContactSection_nl/columnsContactSection_nl.png": "/addPanelData/sections/columnsContactSection_nl/columnsContactSection_nl.png",
    "addPanelData/sections/columnsContactSection_no/columnsContactSection_no.png": "/addPanelData/sections/columnsContactSection_no/columnsContactSection_no.png",
    "addPanelData/sections/columnsContactSection_pl/columnsContactSection_pl.png": "/addPanelData/sections/columnsContactSection_pl/columnsContactSection_pl.png",
    "addPanelData/sections/columnsContactSection_pt/columnsContactSection_pt.png": "/addPanelData/sections/columnsContactSection_pt/columnsContactSection_pt.png",
    "addPanelData/sections/columnsContactSection_ru/columnsContactSection_ru.png": "/addPanelData/sections/columnsContactSection_ru/columnsContactSection_ru.png",
    "addPanelData/sections/columnsContactSection_sv/columnsContactSection_sv.png": "/addPanelData/sections/columnsContactSection_sv/columnsContactSection_sv.png",
    "addPanelData/sections/columnsContactSection_tr/columnsContactSection_tr.png": "/addPanelData/sections/columnsContactSection_tr/columnsContactSection_tr.png",
    "addPanelData/sections/columnsImpressionsSection/columnsImpressionsSection_redesign_en.png": "/addPanelData/sections/columnsImpressionsSection/columnsImpressionsSection_redesign_en.v2.png",
    "addPanelData/sections/columnsParallaxSection_en/columnsParallaxSection_en.png": "/addPanelData/sections/columnsParallaxSection_en/columnsParallaxSection_en.v2.png",
    "addPanelData/sections/columnsServicesSection_cz/columnsServicesSection_cz.png": "/addPanelData/sections/columnsServicesSection_cz/columnsServicesSection_cz.png",
    "addPanelData/sections/columnsServicesSection_da/columnsServicesSection_da.png": "/addPanelData/sections/columnsServicesSection_da/columnsServicesSection_da.png",
    "addPanelData/sections/columnsServicesSection_de/columnsServicesSection_de.png": "/addPanelData/sections/columnsServicesSection_de/columnsServicesSection_de.png",
    "addPanelData/sections/columnsServicesSection_en/columnsServicesSection_en.png": "/addPanelData/sections/columnsServicesSection_en/columnsServicesSection_en.png",
    "addPanelData/sections/columnsServicesSection_es/columnsServicesSection_es.png": "/addPanelData/sections/columnsServicesSection_es/columnsServicesSection_es.png",
    "addPanelData/sections/columnsServicesSection_fr/columnsServicesSection_fr.png": "/addPanelData/sections/columnsServicesSection_fr/columnsServicesSection_fr.png",
    "addPanelData/sections/columnsServicesSection_hi/columnsServicesSection_hi.png": "/addPanelData/sections/columnsServicesSection_hi/columnsServicesSection_hi.png",
    "addPanelData/sections/columnsServicesSection_it/columnsServicesSection_it.png": "/addPanelData/sections/columnsServicesSection_it/columnsServicesSection_it.png",
    "addPanelData/sections/columnsServicesSection_jp/columnsServicesSection_jp.png": "/addPanelData/sections/columnsServicesSection_jp/columnsServicesSection_jp.png",
    "addPanelData/sections/columnsServicesSection_nl/columnsServicesSection_nl.png": "/addPanelData/sections/columnsServicesSection_nl/columnsServicesSection_nl.png",
    "addPanelData/sections/columnsServicesSection_no/columnsServicesSection_no.png": "/addPanelData/sections/columnsServicesSection_no/columnsServicesSection_no.png",
    "addPanelData/sections/columnsServicesSection_pl/columnsServicesSection_pl.png": "/addPanelData/sections/columnsServicesSection_pl/columnsServicesSection_pl.png",
    "addPanelData/sections/columnsServicesSection_pt/columnsServicesSection_pt.png": "/addPanelData/sections/columnsServicesSection_pt/columnsServicesSection_pt.png",
    "addPanelData/sections/columnsServicesSection_ru/columnsServicesSection_ru.png": "/addPanelData/sections/columnsServicesSection_ru/columnsServicesSection_ru.png",
    "addPanelData/sections/columnsServicesSection_sv/columnsServicesSection_sv.png": "/addPanelData/sections/columnsServicesSection_sv/columnsServicesSection_sv.png",
    "addPanelData/sections/columnsServicesSection_tr/columnsServicesSection_tr.png": "/addPanelData/sections/columnsServicesSection_tr/columnsServicesSection_tr.png",
    "addPanelData/sections/columnsTeamSection_cz/columnsTeamSection_cz.png": "/addPanelData/sections/columnsTeamSection_cz/columnsTeamSection_cz.png",
    "addPanelData/sections/columnsTeamSection_da/columnsTeamSection_da.png": "/addPanelData/sections/columnsTeamSection_da/columnsTeamSection_da.png",
    "addPanelData/sections/columnsTeamSection_de/columnsTeamSection_de.png": "/addPanelData/sections/columnsTeamSection_de/columnsTeamSection_de.png",
    "addPanelData/sections/columnsTeamSection_en/columnsTeamSection_en.png": "/addPanelData/sections/columnsTeamSection_en/columnsTeamSection_en.png",
    "addPanelData/sections/columnsTeamSection_es/columnsTeamSection_es.png": "/addPanelData/sections/columnsTeamSection_es/columnsTeamSection_es.png",
    "addPanelData/sections/columnsTeamSection_fr/columnsTeamSection_fr.png": "/addPanelData/sections/columnsTeamSection_fr/columnsTeamSection_fr.png",
    "addPanelData/sections/columnsTeamSection_hi/columnsTeamSection_hi.png": "/addPanelData/sections/columnsTeamSection_hi/columnsTeamSection_hi.png",
    "addPanelData/sections/columnsTeamSection_it/columnsTeamSection_it.png": "/addPanelData/sections/columnsTeamSection_it/columnsTeamSection_it.png",
    "addPanelData/sections/columnsTeamSection_jp/columnsTeamSection_jp.png": "/addPanelData/sections/columnsTeamSection_jp/columnsTeamSection_jp.png",
    "addPanelData/sections/columnsTeamSection_nl/columnsTeamSection_nl.png": "/addPanelData/sections/columnsTeamSection_nl/columnsTeamSection_nl.png",
    "addPanelData/sections/columnsTeamSection_no/columnsTeamSection_no.png": "/addPanelData/sections/columnsTeamSection_no/columnsTeamSection_no.png",
    "addPanelData/sections/columnsTeamSection_pl/columnsTeamSection_pl.png": "/addPanelData/sections/columnsTeamSection_pl/columnsTeamSection_pl.png",
    "addPanelData/sections/columnsTeamSection_pt/columnsTeamSection_pt.png": "/addPanelData/sections/columnsTeamSection_pt/columnsTeamSection_pt.png",
    "addPanelData/sections/columnsTeamSection_ru/columnsTeamSection_ru.png": "/addPanelData/sections/columnsTeamSection_ru/columnsTeamSection_ru.png",
    "addPanelData/sections/columnsTeamSection_sv/columnsTeamSection_sv.png": "/addPanelData/sections/columnsTeamSection_sv/columnsTeamSection_sv.png",
    "addPanelData/sections/columnsTeamSection_tr/columnsTeamSection_tr.png": "/addPanelData/sections/columnsTeamSection_tr/columnsTeamSection_tr.png",
    "addPanelData/sections/columnsTestimonialsSection_cz/columnsTestimonialsSection_cz.png": "/addPanelData/sections/columnsTestimonialsSection_cz/columnsTestimonialsSection_cz.png",
    "addPanelData/sections/columnsTestimonialsSection_da/columnsTestimonialsSection_da.png": "/addPanelData/sections/columnsTestimonialsSection_da/columnsTestimonialsSection_da.png",
    "addPanelData/sections/columnsTestimonialsSection_en/columnsTestimonialsSection_en.png": "/addPanelData/sections/columnsTestimonialsSection_en/columnsTestimonialsSection_en.v2.png",
    "addPanelData/sections/columnsTestimonialsSection_es/columnsTestimonialsSection_es.png": "/addPanelData/sections/columnsTestimonialsSection_es/columnsTestimonialsSection_es.png",
    "addPanelData/sections/columnsTestimonialsSection_fr/columnsTestimonialsSection_fr.png": "/addPanelData/sections/columnsTestimonialsSection_fr/columnsTestimonialsSection_fr.png",
    "addPanelData/sections/columnsTestimonialsSection_hi/columnsTestimonialsSection_hi.png": "/addPanelData/sections/columnsTestimonialsSection_hi/columnsTestimonialsSection_hi.png",
    "addPanelData/sections/columnsTestimonialsSection_it/columnsTestimonialsSection_it.png": "/addPanelData/sections/columnsTestimonialsSection_it/columnsTestimonialsSection_it.png",
    "addPanelData/sections/columnsTestimonialsSection_jp/columnsTestimonialsSection_jp.png": "/addPanelData/sections/columnsTestimonialsSection_jp/columnsTestimonialsSection_jp.png",
    "addPanelData/sections/columnsTestimonialsSection_no/columnsTestimonialsSection_no.png": "/addPanelData/sections/columnsTestimonialsSection_no/columnsTestimonialsSection_no.png",
    "addPanelData/sections/columnsTestimonialsSection_pl/columnsTestimonialsSection_pl.png": "/addPanelData/sections/columnsTestimonialsSection_pl/columnsTestimonialsSection_pl.png",
    "addPanelData/sections/columnsTestimonialsSection_pt/columnsTestimonialsSection_pt.png": "/addPanelData/sections/columnsTestimonialsSection_pt/columnsTestimonialsSection_pt.png",
    "addPanelData/sections/columnsTestimonialsSection_ru/columnsTestimonialsSection_ru.png": "/addPanelData/sections/columnsTestimonialsSection_ru/columnsTestimonialsSection_ru.png",
    "addPanelData/sections/columnsTestimonialsSection_sv/columnsTestimonialsSection_sv.png": "/addPanelData/sections/columnsTestimonialsSection_sv/columnsTestimonialsSection_sv.png",
    "addPanelData/sections/columnsTestimonialsSection_tr/columnsTestimonialsSection_tr.png": "/addPanelData/sections/columnsTestimonialsSection_tr/columnsTestimonialsSection_tr.png",
    "addPanelData/sections/columnsWelcomeSection_cz/columnsWelcomeSection_cz.png": "/addPanelData/sections/columnsWelcomeSection_cz/columnsWelcomeSection_cz.png",
    "addPanelData/sections/columnsWelcomeSection_de/columnsWelcomeSection_de.png": "/addPanelData/sections/columnsWelcomeSection_de/columnsWelcomeSection_de.png",
    "addPanelData/sections/columnsWelcomeSection_en/columnsWelcomeSection_en.png": "/addPanelData/sections/columnsWelcomeSection_en/columnsWelcomeSection_en.v2.png",
    "addPanelData/sections/columnsWelcomeSection_es/columnsWelcomeSection_es.png": "/addPanelData/sections/columnsWelcomeSection_es/columnsWelcomeSection_es.png",
    "addPanelData/sections/columnsWelcomeSection_fr/columnsWelcomeSection_fr.png": "/addPanelData/sections/columnsWelcomeSection_fr/columnsWelcomeSection_fr.png",
    "addPanelData/sections/columnsWelcomeSection_hi/columnsWelcomeSection_hi.png": "/addPanelData/sections/columnsWelcomeSection_hi/columnsWelcomeSection_hi.png",
    "addPanelData/sections/columnsWelcomeSection_it/columnsWelcomeSection_it.png": "/addPanelData/sections/columnsWelcomeSection_it/columnsWelcomeSection_it.png",
    "addPanelData/sections/columnsWelcomeSection_jp/columnsWelcomeSection_jp.png": "/addPanelData/sections/columnsWelcomeSection_jp/columnsWelcomeSection_jp.png",
    "addPanelData/sections/columnsWelcomeSection_nl/columnsWelcomeSection_nl.png": "/addPanelData/sections/columnsWelcomeSection_nl/columnsWelcomeSection_nl.png",
    "addPanelData/sections/columnsWelcomeSection_no/columnsWelcomeSection_no.png": "/addPanelData/sections/columnsWelcomeSection_no/columnsWelcomeSection_no.png",
    "addPanelData/sections/columnsWelcomeSection_pl/columnsWelcomeSection_pl.png": "/addPanelData/sections/columnsWelcomeSection_pl/columnsWelcomeSection_pl.png",
    "addPanelData/sections/columnsWelcomeSection_pt/columnsWelcomeSection_pt.png": "/addPanelData/sections/columnsWelcomeSection_pt/columnsWelcomeSection_pt.png",
    "addPanelData/sections/columnsWelcomeSection_ru/columnsWelcomeSection_ru.png": "/addPanelData/sections/columnsWelcomeSection_ru/columnsWelcomeSection_ru.png",
    "addPanelData/sections/columnsWelcomeSection_sv/columnsWelcomeSection_sv.png": "/addPanelData/sections/columnsWelcomeSection_sv/columnsWelcomeSection_sv.png",
    "addPanelData/sections/columnsWelcomeSection_tr/columnsWelcomeSection_tr.png": "/addPanelData/sections/columnsWelcomeSection_tr/columnsWelcomeSection_tr.png",
    "addPanelData/sections/compositesSection_en/compositesSection_en.png": "/addPanelData/sections/compositesSection_en/compositesSection_en.v3.png",
    "addPanelData/sections/contactFormsSection_da/contactFormsSection_da.png": "/addPanelData/sections/contactFormsSection_da/contactFormsSection_da.v1.png",
    "addPanelData/sections/contactFormsSection_de/contactFormsSection_de.png": "/addPanelData/sections/contactFormsSection_de/contactFormsSection_de.v3.png",
    "addPanelData/sections/contactFormsSection_en/contactFormsSection_en.png": "/addPanelData/sections/contactFormsSection_en/contactFormsSection_en.v8.png",
    "addPanelData/sections/contactFormsSection_es/contactFormsSection_es.png": "/addPanelData/sections/contactFormsSection_es/contactFormsSection_es.v1.png",
    "addPanelData/sections/contactFormsSection_es/popularSocialToolsSection_es.png": "/addPanelData/sections/contactFormsSection_es/popularSocialToolsSection_es.v3.png",
    "addPanelData/sections/contactFormsSection_fr/contactFormsSection_fr.png": "/addPanelData/sections/contactFormsSection_fr/contactFormsSection_fr.v1.png",
    "addPanelData/sections/contactFormsSection_it/contactFormsSection_it.png": "/addPanelData/sections/contactFormsSection_it/contactFormsSection_it.v1.png",
    "addPanelData/sections/contactFormsSection_ja/contactFormsSection_ja.png": "/addPanelData/sections/contactFormsSection_ja/contactFormsSection_ja.v5.png",
    "addPanelData/sections/contactFormsSection_ko/contactFormsSection_ko.png": "/addPanelData/sections/contactFormsSection_ko/contactFormsSection_ko.v3.png",
    "addPanelData/sections/contactFormsSection_nl/contactFormsSection_nl.png": "/addPanelData/sections/contactFormsSection_nl/contactFormsSection_nl.v1.png",
    "addPanelData/sections/contactFormsSection_no/contactFormsSection_no.png": "/addPanelData/sections/contactFormsSection_no/contactFormsSection_no.v1.png",
    "addPanelData/sections/contactFormsSection_pl/contactFormsSection_pl.png": "/addPanelData/sections/contactFormsSection_pl/contactFormsSection_pl.v1.png",
    "addPanelData/sections/contactFormsSection_pt/contactFormsSection_pt.png": "/addPanelData/sections/contactFormsSection_pt/contactFormsSection_pt.v1.png",
    "addPanelData/sections/contactFormsSection_ru/contactFormsSection_ru.png": "/addPanelData/sections/contactFormsSection_ru/contactFormsSection_ru.v1.png",
    "addPanelData/sections/contactFormsSection_sv/contactFormsSection_sv.png": "/addPanelData/sections/contactFormsSection_sv/contactFormsSection_sv.v1.png",
    "addPanelData/sections/contactFormsSection_tr/contactFormsSection_tr.png": "/addPanelData/sections/contactFormsSection_tr/contactFormsSection_tr.v1.png",
    "addPanelData/sections/containerBoxesSection2_en/containerBoxesSection2_en.png": "/addPanelData/sections/containerBoxesSection2_en/containerBoxesSection2_en.v2.png",
    "addPanelData/sections/containerBoxesSection3_en/containerBoxesSection3_en.png": "/addPanelData/sections/containerBoxesSection3_en/containerBoxesSection3_en.png",
    "addPanelData/sections/containerBoxesSection_en/containerBoxesSection_en.png": "/addPanelData/sections/containerBoxesSection_en/containerBoxesSection_en.v6.png",
    "addPanelData/sections/containerBoxesSection_en/containerBoxesSection_redesign_en.png": "/addPanelData/sections/containerBoxesSection_en/containerBoxesSection_redesign_en.png",
    "addPanelData/sections/containerBoxesSection_en/mobileContainerBoxesSection_en.png": "/addPanelData/sections/containerBoxesSection_en/mobileContainerBoxesSection_en.png",
    "addPanelData/sections/corvidDevModeSection/corvidDevMode.png": "/addPanelData/sections/corvidDevModeSection/corvidDevMode.v2.png",
    "addPanelData/sections/customEmbeds/customElement.png": "/addPanelData/sections/customEmbeds/customElement.v2.png",
    "addPanelData/sections/customEmbeds/embedaSite.png": "/addPanelData/sections/customEmbeds/embedaSite.v2.png",
    "addPanelData/sections/customEmbeds/embedaWidget.png": "/addPanelData/sections/customEmbeds/embedaWidget.v2.png",
    "addPanelData/sections/customEmbeds/embedFlash.png": "/addPanelData/sections/customEmbeds/embedFlash.v1.png",
    "addPanelData/sections/dailyAgenda/dailyAgenda.png": "/addPanelData/sections/dailyAgenda/dailyAgenda.v2.png",
    "addPanelData/sections/dataBinding/addCollection.png": "/addPanelData/sections/dataBinding/addCollection.v1.png",
    "addPanelData/sections/dataBinding/addCollection.svg": "/addPanelData/sections/dataBinding/addCollection.v2.svg",
    "addPanelData/sections/dataBinding/addDastaset.png": "/addPanelData/sections/dataBinding/addDastaset.v1.png",
    "addPanelData/sections/dataBinding/addDastasetDisabled.png": "/addPanelData/sections/dataBinding/addDastasetDisabled.v1.png",
    "addPanelData/sections/dataBinding/addDataset.png": "/addPanelData/sections/dataBinding/addDataset.v3.png",
    "addPanelData/sections/dataBinding/addDataset.svg": "/addPanelData/sections/dataBinding/addDataset.v4.svg",
    "addPanelData/sections/dataBinding/addDatasetDisabled.png": "/addPanelData/sections/dataBinding/addDatasetDisabled.v3.png",
    "addPanelData/sections/dataBinding/addDatasetDisabled.svg": "/addPanelData/sections/dataBinding/addDatasetDisabled.v4.svg",
    "addPanelData/sections/dataBinding/addDynamicPage.png": "/addPanelData/sections/dataBinding/addDynamicPage.v1.png",
    "addPanelData/sections/dataBinding/addDynamicPage.svg": "/addPanelData/sections/dataBinding/addDynamicPage.svg",
    "addPanelData/sections/dataBinding/addDynamicPageDisabled.png": "/addPanelData/sections/dataBinding/addDynamicPageDisabled.v1.png",
    "addPanelData/sections/dataBinding/addDynamicPageDisabled.svg": "/addPanelData/sections/dataBinding/addDynamicPageDisabled.svg",
    "addPanelData/sections/dataBinding/addPreset.png": "/addPanelData/sections/dataBinding/addPreset.v1.png",
    "addPanelData/sections/dataBinding/addPreset.svg": "/addPanelData/sections/dataBinding/addPreset.v2.svg",
    "addPanelData/sections/dataBinding/addRichContent.png": "/addPanelData/sections/dataBinding/addRichContent.png",
    "addPanelData/sections/dataBinding/addRichContent.svg": "/addPanelData/sections/dataBinding/addRichContent.v1.svg",
    "addPanelData/sections/dataBinding/addRichContent@2x.png": "/addPanelData/sections/dataBinding/addRichContent@2x.png",
    "addPanelData/sections/dataBinding/collectionVideo.png": "/addPanelData/sections/dataBinding/collectionVideo.png",
    "addPanelData/sections/dataBinding/contentManager.png": "/addPanelData/sections/dataBinding/contentManager.v2.png",
    "addPanelData/sections/dataBinding/contentManagerNew.png": "/addPanelData/sections/dataBinding/contentManagerNew.png",
    "addPanelData/sections/dataBinding/contentManagerVideo.png": "/addPanelData/sections/dataBinding/contentManagerVideo.png",
    "addPanelData/sections/dataBinding/contentManagerVideoThumbnail.png": "/addPanelData/sections/dataBinding/contentManagerVideoThumbnail.png",
    "addPanelData/sections/dataBinding/controller.png": "/addPanelData/sections/dataBinding/controller.v1.png",
    "addPanelData/sections/dataBinding/databaseWelcomeSection.png": "/addPanelData/sections/dataBinding/databaseWelcomeSection.v5.png",
    "addPanelData/sections/dataBinding/databaseWelcomeSection@x2.png": "/addPanelData/sections/dataBinding/databaseWelcomeSection@x2.v4.png",
    "addPanelData/sections/dataBinding/manageContentVideo.png": "/addPanelData/sections/dataBinding/manageContentVideo.png",
    "addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection.png": "/addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection.v2.png",
    "addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection@x2.png": "/addPanelData/sections/dataBinding/newWorkspace_databaseWelcomeSection@x2.v2.png",
    "addPanelData/sections/datePickerSection_en/datePickerSection_en.png": "/addPanelData/sections/datePickerSection_en/datePickerSection_en.v3.png",
    "addPanelData/sections/decoratedLineSection_en/decoratedLineSection_en.png": "/addPanelData/sections/decoratedLineSection_en/decoratedLineSection_en.v4.png",
    "addPanelData/sections/decorativeShapesSection_en/decorativeShapesSection_en.png": "/addPanelData/sections/decorativeShapesSection_en/decorativeShapesSection_en.v4.png",
    "addPanelData/sections/developer_en/addressInputSection_en.png": "/addPanelData/sections/developer_en/addressInputSection_en.v2.png",
    "addPanelData/sections/developer_en/appointmentFieldSection_en.png": "/addPanelData/sections/developer_en/appointmentFieldSection_en.v1.png",
    "addPanelData/sections/developer_en/checkboxSection_en.png": "/addPanelData/sections/developer_en/checkboxSection_en.v3.png",
    "addPanelData/sections/developer_en/dataSet.svg": "/addPanelData/sections/developer_en/dataSet.svg",
    "addPanelData/sections/developer_en/datePickerSection_en.png": "/addPanelData/sections/developer_en/datePickerSection_en.v1.png",
    "addPanelData/sections/developer_en/fileUploaderSection_en.png": "/addPanelData/sections/developer_en/fileUploaderSection_en.v1.png",
    "addPanelData/sections/developer_en/gridSection_en.png": "/addPanelData/sections/developer_en/gridSection_en.v5.png",
    "addPanelData/sections/developer_en/gridSection_tmp_en.png": "/addPanelData/sections/developer_en/gridSection_tmp_en.v1.png",
    "addPanelData/sections/developer_en/paginationSection_en.png": "/addPanelData/sections/developer_en/paginationSection_en.v2.png",
    "addPanelData/sections/developer_en/radioGroup_en.jpg": "/addPanelData/sections/developer_en/radioGroup_en.jpg",
    "addPanelData/sections/developer_en/radioGroupSection_en.png": "/addPanelData/sections/developer_en/radioGroupSection_en.v1.png",
    "addPanelData/sections/developer_en/ratingsDisplaySection_en.png": "/addPanelData/sections/developer_en/ratingsDisplaySection_en.v2.png",
    "addPanelData/sections/developer_en/ratingsInputSection_en.png": "/addPanelData/sections/developer_en/ratingsInputSection_en.v2.png",
    "addPanelData/sections/developer_en/ratingsInputSection_hover_en.png": "/addPanelData/sections/developer_en/ratingsInputSection_hover_en.v2.png",
    "addPanelData/sections/developer_en/richTextBoxSection_en.png": "/addPanelData/sections/developer_en/richTextBoxSection_en.v3.png",
    "addPanelData/sections/developer_en/signatureInputDesignPanelSection_en.png": "/addPanelData/sections/developer_en/signatureInputDesignPanelSection_en.v2.png",
    "addPanelData/sections/developer_en/signatureInputSection_en.png": "/addPanelData/sections/developer_en/signatureInputSection_en.v5.png",
    "addPanelData/sections/developer_en/sliderSection_en.png": "/addPanelData/sections/developer_en/sliderSection_en.v5.png",
    "addPanelData/sections/developer_en/textAreaSection_en.png": "/addPanelData/sections/developer_en/textAreaSection_en.v1.png",
    "addPanelData/sections/developer_en/textInputSection_en.png": "/addPanelData/sections/developer_en/textInputSection_en.v1.png",
    "addPanelData/sections/developer_en/timePickerSection_en.png": "/addPanelData/sections/developer_en/timePickerSection_en.v1.png",
    "addPanelData/sections/developer_en/toggleSwitchSection.png": "/addPanelData/sections/developer_en/toggleSwitchSection.v1.png",
    "addPanelData/sections/developer_en/toggleSwitchSection_en.png": "/addPanelData/sections/developer_en/toggleSwitchSection_en.v4.png",
    "addPanelData/sections/developer_en/transparencyPattern.png": "/addPanelData/sections/developer_en/transparencyPattern.png",
    "addPanelData/sections/disqusSection_en/disqusSection_en.png": "/addPanelData/sections/disqusSection_en/disqusSection_en.v3.png",
    "addPanelData/sections/donationCampaignsDonationFormSection_en/donationCampaignsDonationFormSection_en.png": "/addPanelData/sections/donationCampaignsDonationFormSection_en/donationCampaignsDonationFormSection_en.png",
    "addPanelData/sections/donationCampaignsWelcomeSection_en/donationCampaignsWelcomeSection.png": "/addPanelData/sections/donationCampaignsWelcomeSection_en/donationCampaignsWelcomeSection.png",
    "addPanelData/sections/dropDownSection_en/dropDownSection_en.png": "/addPanelData/sections/dropDownSection_en/dropDownSection_en.v4.png",
    "addPanelData/sections/dynamicContactFormsSection_en/dynamicContactFormsSection_en.png": "/addPanelData/sections/dynamicContactFormsSection_en/dynamicContactFormsSection_en.v4.png",
    "addPanelData/sections/eventsSection_en/eventsSection_en.png": "/addPanelData/sections/eventsSection_en/eventsSection_en.v5.png",
    "addPanelData/sections/eventsSection_en/eventsSectionCalendar.png": "/addPanelData/sections/eventsSection_en/eventsSectionCalendar.png",
    "addPanelData/sections/eventsSection_en/eventsSectionCalendar_en.png": "/addPanelData/sections/eventsSection_en/eventsSectionCalendar_en.png",
    "addPanelData/sections/eventsSection_en/eventsSectionResponsive_en.png": "/addPanelData/sections/eventsSection_en/eventsSectionResponsive_en.v4.png",
    "addPanelData/sections/eventsSection_en/eventsSectionResponsiveWixStudio_en.png": "/addPanelData/sections/eventsSection_en/eventsSectionResponsiveWixStudio_en.v2.png",
    "addPanelData/sections/eventsSection_en/eventsSectionStudioCalendar_en.png": "/addPanelData/sections/eventsSection_en/eventsSectionStudioCalendar_en.png",
    "addPanelData/sections/eventsSection_en/eventsSectionStudioList_en.png": "/addPanelData/sections/eventsSection_en/eventsSectionStudioList_en.png",
    "addPanelData/sections/eventsSection_en/hover_en/eventsSection_hover_en.png": "/addPanelData/sections/eventsSection_en/hover_en/eventsSection_hover_en.v5.png",
    "addPanelData/sections/eventsSection_en/hover_en/eventsSectionResponsive_hover_en.png": "/addPanelData/sections/eventsSection_en/hover_en/eventsSectionResponsive_hover_en.v4.png",
    "addPanelData/sections/eventsWelcomeSection_en/eventsWelcomeSection_en.png": "/addPanelData/sections/eventsWelcomeSection_en/eventsWelcomeSection_en.v3.png",
    "addPanelData/sections/eventsWelcomeSection_en/eventsWelcomeSection_en@x2.png": "/addPanelData/sections/eventsWelcomeSection_en/eventsWelcomeSection_en@x2.v2.png",
    "addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en.png": "/addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en.v2.png",
    "addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en@x2.png": "/addPanelData/sections/eventsWelcomeSection_en/newWorkspace_eventsWelcomeSection_en@x2.v2.png",
    "addPanelData/sections/facebookCommentsSection_da/facebookCommentsSection_da.png": "/addPanelData/sections/facebookCommentsSection_da/facebookCommentsSection_da.v1.png",
    "addPanelData/sections/facebookCommentsSection_de/facebookCommentsSection_de.png": "/addPanelData/sections/facebookCommentsSection_de/facebookCommentsSection_de.v1.png",
    "addPanelData/sections/facebookCommentsSection_en/facebookCommentsSection_en.png": "/addPanelData/sections/facebookCommentsSection_en/facebookCommentsSection_en.v1.png",
    "addPanelData/sections/facebookCommentsSection_es/facebookCommentsSection_es.png": "/addPanelData/sections/facebookCommentsSection_es/facebookCommentsSection_es.v1.png",
    "addPanelData/sections/facebookCommentsSection_fr/facebookCommentsSection_fr.png": "/addPanelData/sections/facebookCommentsSection_fr/facebookCommentsSection_fr.v1.png",
    "addPanelData/sections/facebookCommentsSection_it/facebookCommentsSection_it.png": "/addPanelData/sections/facebookCommentsSection_it/facebookCommentsSection_it.v1.png",
    "addPanelData/sections/facebookCommentsSection_ja/facebookCommentsSection_ja.png": "/addPanelData/sections/facebookCommentsSection_ja/facebookCommentsSection_ja.v1.png",
    "addPanelData/sections/facebookCommentsSection_ko/facebookCommentsSection_ko.png": "/addPanelData/sections/facebookCommentsSection_ko/facebookCommentsSection_ko.v1.png",
    "addPanelData/sections/facebookCommentsSection_nl/facebookCommentsSection_nl.png": "/addPanelData/sections/facebookCommentsSection_nl/facebookCommentsSection_nl.v1.png",
    "addPanelData/sections/facebookCommentsSection_no/facebookCommentsSection_no.png": "/addPanelData/sections/facebookCommentsSection_no/facebookCommentsSection_no.v1.png",
    "addPanelData/sections/facebookCommentsSection_pl/facebookCommentsSection_pl.png": "/addPanelData/sections/facebookCommentsSection_pl/facebookCommentsSection_pl.v1.png",
    "addPanelData/sections/facebookCommentsSection_pt/facebookCommentsSection_pt.png": "/addPanelData/sections/facebookCommentsSection_pt/facebookCommentsSection_pt.v1.png",
    "addPanelData/sections/facebookCommentsSection_ru/facebookCommentsSection_ru.png": "/addPanelData/sections/facebookCommentsSection_ru/facebookCommentsSection_ru.v1.png",
    "addPanelData/sections/facebookCommentsSection_sv/facebookCommentsSection_sv.png": "/addPanelData/sections/facebookCommentsSection_sv/facebookCommentsSection_sv.v1.png",
    "addPanelData/sections/facebookCommentsSection_tr/facebookCommentsSection_tr.png": "/addPanelData/sections/facebookCommentsSection_tr/facebookCommentsSection_tr.v1.png",
    "addPanelData/sections/facebookSection_da/facebookSection_da.png": "/addPanelData/sections/facebookSection_da/facebookSection_da.png",
    "addPanelData/sections/facebookSection_de/facebookSection_de.png": "/addPanelData/sections/facebookSection_de/facebookSection_de.v3.png",
    "addPanelData/sections/facebookSection_en/facebookSection_en.png": "/addPanelData/sections/facebookSection_en/facebookSection_en.v4.png",
    "addPanelData/sections/facebookSection_es/facebookSection_es.png": "/addPanelData/sections/facebookSection_es/facebookSection_es.v2.png",
    "addPanelData/sections/facebookSection_fr/facebookSection_fr.png": "/addPanelData/sections/facebookSection_fr/facebookSection_fr.v2.png",
    "addPanelData/sections/facebookSection_it/facebookSection_it.png": "/addPanelData/sections/facebookSection_it/facebookSection_it.png",
    "addPanelData/sections/facebookSection_ja/facebookSection_ja.png": "/addPanelData/sections/facebookSection_ja/facebookSection_ja.png",
    "addPanelData/sections/facebookSection_ko/facebookSection_ko.png": "/addPanelData/sections/facebookSection_ko/facebookSection_ko.png",
    "addPanelData/sections/facebookSection_nl/facebookSection_nl.png": "/addPanelData/sections/facebookSection_nl/facebookSection_nl.png",
    "addPanelData/sections/facebookSection_no/facebookSection_no.png": "/addPanelData/sections/facebookSection_no/facebookSection_no.png",
    "addPanelData/sections/facebookSection_pl/facebookSection_pl.png": "/addPanelData/sections/facebookSection_pl/facebookSection_pl.png",
    "addPanelData/sections/facebookSection_pt/facebookSection_pt.png": "/addPanelData/sections/facebookSection_pt/facebookSection_pt.v3.png",
    "addPanelData/sections/facebookSection_ru/facebookSection_ru.png": "/addPanelData/sections/facebookSection_ru/facebookSection_ru.png",
    "addPanelData/sections/facebookSection_sv/facebookSection_sv.png": "/addPanelData/sections/facebookSection_sv/facebookSection_sv.png",
    "addPanelData/sections/facebookSection_tr/facebookSection_tr.png": "/addPanelData/sections/facebookSection_tr/facebookSection_tr.png",
    "addPanelData/sections/fileDownloadButtonsSection_en/fileDownloadButtonsSection_en.png": "/addPanelData/sections/fileDownloadButtonsSection_en/fileDownloadButtonsSection_en.v3.png",
    "addPanelData/sections/fileShareSharedFolderSection/fileShareSharedFolderWidget.jpg": "/addPanelData/sections/fileShareSharedFolderSection/fileShareSharedFolderWidget.jpg",
    "addPanelData/sections/fileShareWelcomeSection/fileShareWelcomeSection.jpg": "/addPanelData/sections/fileShareWelcomeSection/fileShareWelcomeSection.jpg",
    "addPanelData/sections/fileUploaderSection_en/fileUploaderSection_en.png": "/addPanelData/sections/fileUploaderSection_en/fileUploaderSection_en.v3.png",
    "addPanelData/sections/footerStyleSection_en/footerStyleSection_en.png": "/addPanelData/sections/footerStyleSection_en/footerStyleSection_en.v5.png",
    "addPanelData/sections/formBuilderPlatformApp_en/formBuilderPlatformApp_en.png": "/addPanelData/sections/formBuilderPlatformApp_en/formBuilderPlatformApp_en.v4.png",
    "addPanelData/sections/formContestsSection_en/formsContestsSection_en.png": "/addPanelData/sections/formContestsSection_en/formsContestsSection_en.v9.png",
    "addPanelData/sections/formContestsSection_en/formsContestsSection_en.v5@1x.png": "/addPanelData/sections/formContestsSection_en/formsContestsSection_en.v5@1x.png",
    "addPanelData/sections/formContestsSection_en/formsContestsSection_en.v9@1x.png": "/addPanelData/sections/formContestsSection_en/formsContestsSection_en.v9@1x.png",
    "addPanelData/sections/formContestsSection_hover_en/formsContestsSection_hover_en.png": "/addPanelData/sections/formContestsSection_hover_en/formsContestsSection_hover_en.v10.png",
    "addPanelData/sections/formsApplicationSectionNewForms_en/formsApplicationSectionNewForms_en.png": "/addPanelData/sections/formsApplicationSectionNewForms_en/formsApplicationSectionNewForms_en.png",
    "addPanelData/sections/formsCateringSectionNewForms_en/formsCateringSectionNewForms_en.png": "/addPanelData/sections/formsCateringSectionNewForms_en/formsCateringSectionNewForms_en.png",
    "addPanelData/sections/formsContactSection_en/formsContactSection_en.png": "/addPanelData/sections/formsContactSection_en/formsContactSection_en.v8.png",
    "addPanelData/sections/formsContactSection_en/formsContactSection_en.v5.1.png": "/addPanelData/sections/formsContactSection_en/formsContactSection_en.v5.1.v8.png",
    "addPanelData/sections/formsContactSection_en/formsContactSection_en.v5.1.v8@1x.png": "/addPanelData/sections/formsContactSection_en/formsContactSection_en.v5.1.v8@1x.png",
    "addPanelData/sections/formsContactSection_en/formsContactSection_en.v8@1x.png": "/addPanelData/sections/formsContactSection_en/formsContactSection_en.v8@1x.png",
    "addPanelData/sections/formsContactSection_hover_en/formsContactSection_hover_en.png": "/addPanelData/sections/formsContactSection_hover_en/formsContactSection_hover_en.v9.png",
    "addPanelData/sections/formsContactSectionNewForms_en/formsContactSectionNewForms_en.png": "/addPanelData/sections/formsContactSectionNewForms_en/formsContactSectionNewForms_en.png",
    "addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.png": "/addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.v2.png",
    "addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.v1@1x.png": "/addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.v1@1x.png",
    "addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.v2@1x.png": "/addPanelData/sections/formsDonationsSection_en/formsDonationsSection_en.v2@1x.png",
    "addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.png": "/addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v8.png",
    "addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v5@1x.png": "/addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v5@1x.png",
    "addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v8@1x.png": "/addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v8@1x.png",
    "addPanelData/sections/formsFeedbackSection_hover_en/formsFeedbackSection_hover_en.png": "/addPanelData/sections/formsFeedbackSection_hover_en/formsFeedbackSection_hover_en.v8.png",
    "addPanelData/sections/formsHealthDeclarationSection_en/formsHealthDeclarationSection_en.png": "/addPanelData/sections/formsHealthDeclarationSection_en/formsHealthDeclarationSection_en.v1.png",
    "addPanelData/sections/formsHealthDeclarationSection_en/formsHealthDeclarationSection_en.v1@1x.png": "/addPanelData/sections/formsHealthDeclarationSection_en/formsHealthDeclarationSection_en.v1@1x.png",
    "addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.png": "/addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v11.png",
    "addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v11@1x.png": "/addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v11@1x.png",
    "addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v4@1x.png": "/addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v4@1x.png",
    "addPanelData/sections/formsJobApplicationSection_hover_en/formsJobApplicationSection_hover_en.png": "/addPanelData/sections/formsJobApplicationSection_hover_en/formsJobApplicationSection_hover_en.v10.png",
    "addPanelData/sections/formsMultiStepSection_en/formsMultiStepSection_en.png": "/addPanelData/sections/formsMultiStepSection_en/formsMultiStepSection_en.v2.png",
    "addPanelData/sections/formsMultiStepSection_hover_en/formsMultiStepSection_en_hover.png": "/addPanelData/sections/formsMultiStepSection_hover_en/formsMultiStepSection_en_hover.v3.png",
    "addPanelData/sections/formsMultiStepSection_hover_en/formsMultiStepSection_hover_en.png": "/addPanelData/sections/formsMultiStepSection_hover_en/formsMultiStepSection_hover_en.v3.png",
    "addPanelData/sections/formsOrderSectionNewForms_en/formsOrderSectionNewForms_en.png": "/addPanelData/sections/formsOrderSectionNewForms_en/formsOrderSectionNewForms_en.png",
    "addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.png": "/addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v2.png",
    "addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v1@1x.png": "/addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v1@1x.png",
    "addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v2@1x.png": "/addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v2@1x.png",
    "addPanelData/sections/formsQuizSection_en/formsQuizSection_en.png": "/addPanelData/sections/formsQuizSection_en/formsQuizSection_en.v6.png",
    "addPanelData/sections/formsQuizSection_en/formsQuizSection_en.v5@1x.png": "/addPanelData/sections/formsQuizSection_en/formsQuizSection_en.v5@1x.png",
    "addPanelData/sections/formsQuoteSection_en/formsquoteSection_en.png": "/addPanelData/sections/formsQuoteSection_en/formsquoteSection_en.v6.png",
    "addPanelData/sections/formsQuoteSection_en/formsquoteSection_en.v5@1x.png": "/addPanelData/sections/formsQuoteSection_en/formsquoteSection_en.v5@1x.png",
    "addPanelData/sections/formsRegistrationSectionNewForms_en/formsRegistrationSectionNewForms_en.png": "/addPanelData/sections/formsRegistrationSectionNewForms_en/formsRegistrationSectionNewForms_en.png",
    "addPanelData/sections/formsRequestSection_en/formsRequestSection_en.png": "/addPanelData/sections/formsRequestSection_en/formsRequestSection_en.v1.png",
    "addPanelData/sections/formsRequestSection_en/formsRequestSection_en.v1@1x.png": "/addPanelData/sections/formsRequestSection_en/formsRequestSection_en.v1@1x.png",
    "addPanelData/sections/formsRequestSectionNewForms_en/formsRequestSectionNewForms_en.png": "/addPanelData/sections/formsRequestSectionNewForms_en/formsRequestSectionNewForms_en.png",
    "addPanelData/sections/formsSalesLeadSection_en/formsSalesLeadSection_en.png": "/addPanelData/sections/formsSalesLeadSection_en/formsSalesLeadSection_en.v6.png",
    "addPanelData/sections/formsSalesLeadSection_en/formsSalesLeadSection_en.v5@1x.png": "/addPanelData/sections/formsSalesLeadSection_en/formsSalesLeadSection_en.v5@1x.png",
    "addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.png": "/addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v7.png",
    "addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v5@1x.png": "/addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v5@1x.png",
    "addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v7@1x.png": "/addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v7@1x.png",
    "addPanelData/sections/formsSubscribeSectionNewForms_en/formsSubscribeSectionNewForms_en.png": "/addPanelData/sections/formsSubscribeSectionNewForms_en/formsSubscribeSectionNewForms_en.png",
    "addPanelData/sections/formsSupportSection_en/formsSupportSection_en.png": "/addPanelData/sections/formsSupportSection_en/formsSupportSection_en.v6.png",
    "addPanelData/sections/formsSupportSection_en/formsSupportSection_en.v5@1x.png": "/addPanelData/sections/formsSupportSection_en/formsSupportSection_en.v5@1x.png",
    "addPanelData/sections/formsTestimonialsSection_en/formsTestimonialsSection_en.png": "/addPanelData/sections/formsTestimonialsSection_en/formsTestimonialsSection_en.v6.png",
    "addPanelData/sections/formsTestimonialsSection_en/formsTestimonialsSection_en.v5@1x.png": "/addPanelData/sections/formsTestimonialsSection_en/formsTestimonialsSection_en.v5@1x.png",
    "addPanelData/sections/formsWaiverSection_en/formsWaiverSection_en.png": "/addPanelData/sections/formsWaiverSection_en/formsWaiverSection_en.v3.png",
    "addPanelData/sections/formsWaiverSection_en/formsWaiverSection_en.v3@1x.png": "/addPanelData/sections/formsWaiverSection_en/formsWaiverSection_en.v3@1x.png",
    "addPanelData/sections/formsWaiverSectionNewForms_en/formsWaiverSectionNewForms_en.png": "/addPanelData/sections/formsWaiverSectionNewForms_en/formsWaiverSectionNewForms_en.png",
    "addPanelData/sections/forumRecentPostsSection_en/forumRecentPostsSection_en.png": "/addPanelData/sections/forumRecentPostsSection_en/forumRecentPostsSection_en.v1.png",
    "addPanelData/sections/forumRecentPostsSection_en/recentPostsWidgets.png": "/addPanelData/sections/forumRecentPostsSection_en/recentPostsWidgets.png",
    "addPanelData/sections/forumRecentPostsSection_en/recentPostsWidgets@2x.png": "/addPanelData/sections/forumRecentPostsSection_en/recentPostsWidgets@2x.v2.png",
    "addPanelData/sections/forumResentPostsSection_en/resentPostsWidgets.png": "/addPanelData/sections/forumResentPostsSection_en/resentPostsWidgets.png",
    "addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_Community_en.png": "/addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_Community_en.png",
    "addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_Community_en@2x.png": "/addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_Community_en@2x.png",
    "addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_en.png": "/addPanelData/sections/forumWelcomeSection_en/forumWelcomeSection_en.v1.png",
    "addPanelData/sections/frameSection_en/frameSection_en.png": "/addPanelData/sections/frameSection_en/frameSection_en.v1.png",
    "addPanelData/sections/fullWidthGalleriesSection_en/fullWidthGalleriesSection_en.png": "/addPanelData/sections/fullWidthGalleriesSection_en/fullWidthGalleriesSection_en.v3.png",
    "addPanelData/sections/fullWidthLinesSection_en/fullWidthLinesSection_en.png": "/addPanelData/sections/fullWidthLinesSection_en/fullWidthLinesSection_en.v3.png",
    "addPanelData/sections/getMoreFollowersSection_en/getMoreFollowersSection_en.png": "/addPanelData/sections/getMoreFollowersSection_en/getMoreFollowersSection_en.v7.png",
    "addPanelData/sections/getMoreSharesSection_en/getMoreSharesSection_en.png": "/addPanelData/sections/getMoreSharesSection_en/getMoreSharesSection_en.v4.png",
    "addPanelData/sections/getSubscribersSection_da/getSubscribersSection_da.png": "/addPanelData/sections/getSubscribersSection_da/getSubscribersSection_da.v3.png",
    "addPanelData/sections/getSubscribersSection_de/getSubscribersSection_de.png": "/addPanelData/sections/getSubscribersSection_de/getSubscribersSection_de.v3.png",
    "addPanelData/sections/getSubscribersSection_en/getSubscribersSection_en.jpg": "/addPanelData/sections/getSubscribersSection_en/getSubscribersSection_en.v2.jpg",
    "addPanelData/sections/getSubscribersSection_en/getSubscribersSection_en.png": "/addPanelData/sections/getSubscribersSection_en/getSubscribersSection_en.v8.png",
    "addPanelData/sections/getSubscribersSection_es/getSubscribersSection_es.png": "/addPanelData/sections/getSubscribersSection_es/getSubscribersSection_es.v3.png",
    "addPanelData/sections/getSubscribersSection_fr/getSubscribersSection_fr.png": "/addPanelData/sections/getSubscribersSection_fr/getSubscribersSection_fr.v3.png",
    "addPanelData/sections/getSubscribersSection_it/getSubscribersSection_it.png": "/addPanelData/sections/getSubscribersSection_it/getSubscribersSection_it.v3.png",
    "addPanelData/sections/getSubscribersSection_ja/getSubscribersSection_ja.png": "/addPanelData/sections/getSubscribersSection_ja/getSubscribersSection_ja.v4.png",
    "addPanelData/sections/getSubscribersSection_ko/getSubscribersSection_ko.png": "/addPanelData/sections/getSubscribersSection_ko/getSubscribersSection_ko.v4.png",
    "addPanelData/sections/getSubscribersSection_nl/getSubscribersSection_nl.png": "/addPanelData/sections/getSubscribersSection_nl/getSubscribersSection_nl.v4.png",
    "addPanelData/sections/getSubscribersSection_no/getSubscribersSection_no.png": "/addPanelData/sections/getSubscribersSection_no/getSubscribersSection_no.v3.png",
    "addPanelData/sections/getSubscribersSection_pl/getSubscribersSection_pl.png": "/addPanelData/sections/getSubscribersSection_pl/getSubscribersSection_pl.v3.png",
    "addPanelData/sections/getSubscribersSection_pt/getSubscribersSection_pt.png": "/addPanelData/sections/getSubscribersSection_pt/getSubscribersSection_pt.v3.png",
    "addPanelData/sections/getSubscribersSection_ru/getSubscribersSection_ru.png": "/addPanelData/sections/getSubscribersSection_ru/getSubscribersSection_ru.v4.png",
    "addPanelData/sections/getSubscribersSection_sv/getSubscribersSection_sv.png": "/addPanelData/sections/getSubscribersSection_sv/getSubscribersSection_sv.v3.png",
    "addPanelData/sections/getSubscribersSection_tr/getSubscribersSection_tr.png": "/addPanelData/sections/getSubscribersSection_tr/getSubscribersSection_tr.v3.png",
    "addPanelData/sections/googleMapsDesignPanelSection_en/googleMapsDesignPanelSection_en.png": "/addPanelData/sections/googleMapsDesignPanelSection_en/googleMapsDesignPanelSection_en.png",
    "addPanelData/sections/googleMapsGenericSection_en/googleMapsGenericSection_en.png": "/addPanelData/sections/googleMapsGenericSection_en/googleMapsGenericSection_en.v2.png",
    "addPanelData/sections/googleMapsGenericSection_en/googlMapsGenericSection_en.png": "/addPanelData/sections/googleMapsGenericSection_en/googlMapsGenericSection_en.png",
    "addPanelData/sections/googleMapsSection_en/googleMapsSection_en.png": "/addPanelData/sections/googleMapsSection_en/googleMapsSection_en.v4.png",
    "addPanelData/sections/googleMapsSquareSection_en/googleMapsSquareSection_en.png": "/addPanelData/sections/googleMapsSquareSection_en/googleMapsSquareSection_en.v3.png",
    "addPanelData/sections/googleMapsStripSection_en/googleMapsStripSection_en.png": "/addPanelData/sections/googleMapsStripSection_en/googleMapsStripSection_en.v3.png",
    "addPanelData/sections/googlePlusSection_da/googlePlusSection_da.png": "/addPanelData/sections/googlePlusSection_da/googlePlusSection_da.v3.png",
    "addPanelData/sections/googlePlusSection_de/googlePlusSection_de.png": "/addPanelData/sections/googlePlusSection_de/googlePlusSection_de.v3.png",
    "addPanelData/sections/googlePlusSection_en/googlePlusSection_en.png": "/addPanelData/sections/googlePlusSection_en/googlePlusSection_en.v4.png",
    "addPanelData/sections/googlePlusSection_es/googlePlusSection_es.png": "/addPanelData/sections/googlePlusSection_es/googlePlusSection_es.v3.png",
    "addPanelData/sections/googlePlusSection_fr/googlePlusSection_fr.png": "/addPanelData/sections/googlePlusSection_fr/googlePlusSection_fr.v3.png",
    "addPanelData/sections/googlePlusSection_it/googlePlusSection_it.png": "/addPanelData/sections/googlePlusSection_it/googlePlusSection_it.v3.png",
    "addPanelData/sections/googlePlusSection_ja/googlePlusSection_ja.png": "/addPanelData/sections/googlePlusSection_ja/googlePlusSection_ja.v3.png",
    "addPanelData/sections/googlePlusSection_ko/googlePlusSection_ko.png": "/addPanelData/sections/googlePlusSection_ko/googlePlusSection_ko.v3.png",
    "addPanelData/sections/googlePlusSection_nl/googlePlusSection_nl.png": "/addPanelData/sections/googlePlusSection_nl/googlePlusSection_nl.v3.png",
    "addPanelData/sections/googlePlusSection_no/googlePlusSection_no.png": "/addPanelData/sections/googlePlusSection_no/googlePlusSection_no.v3.png",
    "addPanelData/sections/googlePlusSection_pl/googlePlusSection_pl.png": "/addPanelData/sections/googlePlusSection_pl/googlePlusSection_pl.v3.png",
    "addPanelData/sections/googlePlusSection_pt/googlePlusSection_pt.png": "/addPanelData/sections/googlePlusSection_pt/googlePlusSection_pt.v3.png",
    "addPanelData/sections/googlePlusSection_ru/googlePlusSection_ru.png": "/addPanelData/sections/googlePlusSection_ru/googlePlusSection_ru.v3.png",
    "addPanelData/sections/googlePlusSection_sv/googlePlusSection_sv.png": "/addPanelData/sections/googlePlusSection_sv/googlePlusSection_sv.v3.png",
    "addPanelData/sections/googlePlusSection_tr/googlePlusSection_tr.png": "/addPanelData/sections/googlePlusSection_tr/googlePlusSection_tr.v3.png",
    "addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en-@2x.png": "/addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en-@2x.v1.png",
    "addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en.png": "/addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en.v8.png",
    "addPanelData/sections/gridGalleriesSection_en/gridGalleriesSectionNew_en.png": "/addPanelData/sections/gridGalleriesSection_en/gridGalleriesSectionNew_en.png",
    "addPanelData/sections/groupsGroupListSection_en/groupList.png": "/addPanelData/sections/groupsGroupListSection_en/groupList.png",
    "addPanelData/sections/groupsGroupListSection_en/groupListWidgets.png": "/addPanelData/sections/groupsGroupListSection_en/groupListWidgets.png",
    "addPanelData/sections/groupsGroupListSection_en/groupListWidgets@2x.png": "/addPanelData/sections/groupsGroupListSection_en/groupListWidgets@2x.png",
    "addPanelData/sections/groupsWelcomeSection_en/groupsWelcomeSection_en.png": "/addPanelData/sections/groupsWelcomeSection_en/groupsWelcomeSection_en.png",
    "addPanelData/sections/groupsWelcomeSection_en/groupsWelcomeSection_en@2x.png": "/addPanelData/sections/groupsWelcomeSection_en/groupsWelcomeSection_en@2x.png",
    "addPanelData/sections/headerStyleSection_en/headerStyleSection_en.png": "/addPanelData/sections/headerStyleSection_en/headerStyleSection_en.v5.png",
    "addPanelData/sections/horizontalLinesSection_en/horizontalLinesSection_en.png": "/addPanelData/sections/horizontalLinesSection_en/horizontalLinesSection_en.v4.png",
    "addPanelData/sections/horizontalLinesSection_en/horizontalLinesSection_redesign_en.png": "/addPanelData/sections/horizontalLinesSection_en/horizontalLinesSection_redesign_en.v2.png",
    "addPanelData/sections/horizontalMenusSection_da/horizontalMenusSection_da.png": "/addPanelData/sections/horizontalMenusSection_da/horizontalMenusSection_da.v1.png",
    "addPanelData/sections/horizontalMenusSection_de/horizontalMenusSection_de.png": "/addPanelData/sections/horizontalMenusSection_de/horizontalMenusSection_de.v3.png",
    "addPanelData/sections/horizontalMenusSection_en/horizontalMenusSection_en.png": "/addPanelData/sections/horizontalMenusSection_en/horizontalMenusSection_en.v10.png",
    "addPanelData/sections/horizontalMenusSection_en/HorizontalMenusSection_en.png": "/addPanelData/sections/horizontalMenusSection_en/HorizontalMenusSection_en.v6.png",
    "addPanelData/sections/horizontalMenusSection_es/horizontalMenusSection_es.png": "/addPanelData/sections/horizontalMenusSection_es/horizontalMenusSection_es.v4.png",
    "addPanelData/sections/horizontalMenusSection_fr/horizontalMenusSection_fr.png": "/addPanelData/sections/horizontalMenusSection_fr/horizontalMenusSection_fr.v3.png",
    "addPanelData/sections/horizontalMenusSection_it/horizontalMenusSection_it.png": "/addPanelData/sections/horizontalMenusSection_it/horizontalMenusSection_it.v1.png",
    "addPanelData/sections/horizontalMenusSection_ja/horizontalMenusSection_ja.png": "/addPanelData/sections/horizontalMenusSection_ja/horizontalMenusSection_ja.v1.png",
    "addPanelData/sections/horizontalMenusSection_ko/horizontalMenusSection_ko.png": "/addPanelData/sections/horizontalMenusSection_ko/horizontalMenusSection_ko.v1.png",
    "addPanelData/sections/horizontalMenusSection_nl/horizontalMenusSection_nl.png": "/addPanelData/sections/horizontalMenusSection_nl/horizontalMenusSection_nl.v1.png",
    "addPanelData/sections/horizontalMenusSection_no/horizontalMenusSection_no.png": "/addPanelData/sections/horizontalMenusSection_no/horizontalMenusSection_no.v1.png",
    "addPanelData/sections/horizontalMenusSection_pl/horizontalMenusSection_pl.png": "/addPanelData/sections/horizontalMenusSection_pl/horizontalMenusSection_pl.v1.png",
    "addPanelData/sections/horizontalMenusSection_pt/horizontalMenusSection_pt.png": "/addPanelData/sections/horizontalMenusSection_pt/horizontalMenusSection_pt.v3.png",
    "addPanelData/sections/horizontalMenusSection_ru/horizontalMenusSection_ru.png": "/addPanelData/sections/horizontalMenusSection_ru/horizontalMenusSection_ru.v3.png",
    "addPanelData/sections/horizontalMenusSection_sv/horizontalMenusSection_sv.png": "/addPanelData/sections/horizontalMenusSection_sv/horizontalMenusSection_sv.v1.png",
    "addPanelData/sections/horizontalMenusSection_tr/horizontalMenusSection_tr.png": "/addPanelData/sections/horizontalMenusSection_tr/horizontalMenusSection_tr.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_da.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_da.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_de.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_de.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_en.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_en.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_es.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_es.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_fr.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_fr.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_it.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_it.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ja.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ja.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ko.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ko.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_nl.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_nl.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_no.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_no.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_pl.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_pl.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_pt.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_pt.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ru.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_ru.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_sv.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_sv.v1.png",
    "addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_tr.png": "/addPanelData/sections/horizontalMenusSectionLocalization/horizontalMenusSection_tr.v1.png",
    "addPanelData/sections/hotels/hotelsAvailabilityWidget.png": "/addPanelData/sections/hotels/hotelsAvailabilityWidget.png",
    "addPanelData/sections/hotels/hotelsSearchBar.png": "/addPanelData/sections/hotels/hotelsSearchBar.png",
    "addPanelData/sections/hoverBoxesSection_en/hover_en/hoverBoxesSection_hover_en.jpg": "/addPanelData/sections/hoverBoxesSection_en/hover_en/hoverBoxesSection_hover_en.v5.jpg",
    "addPanelData/sections/hoverBoxesSection_en/hover_en/hoverBoxesSection_hover_en.png": "/addPanelData/sections/hoverBoxesSection_en/hover_en/hoverBoxesSection_hover_en.v3.png",
    "addPanelData/sections/hoverBoxesSection_en/hoverBoxesSection_en.jpg": "/addPanelData/sections/hoverBoxesSection_en/hoverBoxesSection_en.v5.jpg",
    "addPanelData/sections/hoverBoxesSection_en/hoverBoxesSection_en.png": "/addPanelData/sections/hoverBoxesSection_en/hoverBoxesSection_en.v3.png",
    "addPanelData/sections/htmlFlashSection_en/htmlFlashSection_en.png": "/addPanelData/sections/htmlFlashSection_en/htmlFlashSection_en.v6.png",
    "addPanelData/sections/iconButtonsSection_en.png/hover_en/iconButtonsSection_hover_en.png": "/addPanelData/sections/iconButtonsSection_en.png/hover_en/iconButtonsSection_hover_en.v5.png",
    "addPanelData/sections/iconButtonsSection_en.png/iconButtonsSection_en.png": "/addPanelData/sections/iconButtonsSection_en.png/iconButtonsSection_en.v3.png",
    "addPanelData/sections/iconButtonsSection_en/hover_en/iconButtonsSection_hover_en.png": "/addPanelData/sections/iconButtonsSection_en/hover_en/iconButtonsSection_hover_en.v15.png",
    "addPanelData/sections/iconButtonsSection_en/hover_en/iconButtonsSection_hover_redesign_en.png": "/addPanelData/sections/iconButtonsSection_en/hover_en/iconButtonsSection_hover_redesign_en.v2.png",
    "addPanelData/sections/iconButtonsSection_en/iconButtonsSection_en.png": "/addPanelData/sections/iconButtonsSection_en/iconButtonsSection_en.v18.png",
    "addPanelData/sections/iconButtonsSection_en/iconButtonsSection_redesign_en.png": "/addPanelData/sections/iconButtonsSection_en/iconButtonsSection_redesign_en.v2.png",
    "addPanelData/sections/iconsSection_en/iconsSection_en.png": "/addPanelData/sections/iconsSection_en/iconsSection_en.v6.png",
    "addPanelData/sections/imageAiCreationSection/imageAiCreation.svg": "/addPanelData/sections/imageAiCreationSection/imageAiCreation.svg",
    "addPanelData/sections/imageButtonsSection/hover/imageButtonSection.png": "/addPanelData/sections/imageButtonsSection/hover/imageButtonSection.png",
    "addPanelData/sections/imageButtonsSection/imageButtonSection.png": "/addPanelData/sections/imageButtonsSection/imageButtonSection.png",
    "addPanelData/sections/imageDynamicSection/importImages.png": "/addPanelData/sections/imageDynamicSection/importImages.v1.png",
    "addPanelData/sections/imageDynamicSection/uploadImages.png": "/addPanelData/sections/imageDynamicSection/uploadImages.v1.png",
    "addPanelData/sections/imageSection/bigStockImages.png": "/addPanelData/sections/imageSection/bigStockImages.v3.png",
    "addPanelData/sections/imageSection/freeWixClipArt.png": "/addPanelData/sections/imageSection/freeWixClipArt.v2.png",
    "addPanelData/sections/imageSection/freeWixIllustrations.png": "/addPanelData/sections/imageSection/freeWixIllustrations.v2.png",
    "addPanelData/sections/imageSection/imageUploader.png": "/addPanelData/sections/imageSection/imageUploader.v3.png",
    "addPanelData/sections/imageSection/logomakerImage.png": "/addPanelData/sections/imageSection/logomakerImage.png",
    "addPanelData/sections/imageSection/myFacebook.png": "/addPanelData/sections/imageSection/myFacebook.v3.png",
    "addPanelData/sections/imageSection/myFlickr.png": "/addPanelData/sections/imageSection/myFlickr.v3.png",
    "addPanelData/sections/imageSection/myInstagram.png": "/addPanelData/sections/imageSection/myInstagram.v4.png",
    "addPanelData/sections/imageSection/myPicasa.png": "/addPanelData/sections/imageSection/myPicasa.v3.png",
    "addPanelData/sections/imageSection/wixFreeImages.png": "/addPanelData/sections/imageSection/wixFreeImages.v3.png",
    "addPanelData/sections/imageStripsSection_en/imageStripsSection_en.png": "/addPanelData/sections/imageStripsSection_en/imageStripsSection_en.v3.png",
    "addPanelData/sections/imageStyleSection_en/imageStyleSection_en.png": "/addPanelData/sections/imageStyleSection_en/imageStyleSection_en.v16.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresets_redesign_en.png": "/addPanelData/sections/interactions/container/interactionsContainerPresets_redesign_en.v2.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresetsHover.png": "/addPanelData/sections/interactions/container/interactionsContainerPresetsHover.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresetsHover_en.png": "/addPanelData/sections/interactions/container/interactionsContainerPresetsHover_en.v3.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresetsHover_redesign_en.png": "/addPanelData/sections/interactions/container/interactionsContainerPresetsHover_redesign_en.v2.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresetsRegular.png": "/addPanelData/sections/interactions/container/interactionsContainerPresetsRegular.png",
    "addPanelData/sections/interactions/container/interactionsContainerPresetsRegular_en.png": "/addPanelData/sections/interactions/container/interactionsContainerPresetsRegular_en.v3.png",
    "addPanelData/sections/interactions/interactionsPresetsHover_en.png": "/addPanelData/sections/interactions/interactionsPresetsHover_en.v5.png",
    "addPanelData/sections/interactions/interactionsPresetsRegular_en.png": "/addPanelData/sections/interactions/interactionsPresetsRegular_en.v5.png",
    "addPanelData/sections/iTunesDownloadButtonSection_da/iTunesDownloadButtonSection_da.png": "/addPanelData/sections/iTunesDownloadButtonSection_da/iTunesDownloadButtonSection_da.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_de/iTunesDownloadButtonSection_de.png": "/addPanelData/sections/iTunesDownloadButtonSection_de/iTunesDownloadButtonSection_de.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_en/iTunesDownloadButtonSection_en.png": "/addPanelData/sections/iTunesDownloadButtonSection_en/iTunesDownloadButtonSection_en.v5.png",
    "addPanelData/sections/iTunesDownloadButtonSection_es/iTunesDownloadButtonSection_es.png": "/addPanelData/sections/iTunesDownloadButtonSection_es/iTunesDownloadButtonSection_es.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_fr/iTunesDownloadButtonSection_fr.png": "/addPanelData/sections/iTunesDownloadButtonSection_fr/iTunesDownloadButtonSection_fr.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_it/iTunesDownloadButtonSection_it.png": "/addPanelData/sections/iTunesDownloadButtonSection_it/iTunesDownloadButtonSection_it.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_ja/iTunesDownloadButtonSection_ja.png": "/addPanelData/sections/iTunesDownloadButtonSection_ja/iTunesDownloadButtonSection_ja.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_ko/iTunesDownloadButtonSection_ko.png": "/addPanelData/sections/iTunesDownloadButtonSection_ko/iTunesDownloadButtonSection_ko.v1.png",
    "addPanelData/sections/iTunesDownloadButtonSection_nl/iTunesDownloadButtonSection_nl.png": "/addPanelData/sections/iTunesDownloadButtonSection_nl/iTunesDownloadButtonSection_nl.v1.png",
    "addPanelData/sections/iTunesDownloadButtonSection_no/iTunesDownloadButtonSection_no.png": "/addPanelData/sections/iTunesDownloadButtonSection_no/iTunesDownloadButtonSection_no.v1.png",
    "addPanelData/sections/iTunesDownloadButtonSection_pl/iTunesDownloadButtonSection_pl.png": "/addPanelData/sections/iTunesDownloadButtonSection_pl/iTunesDownloadButtonSection_pl.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_pt/iTunesDownloadButtonSection_pt.png": "/addPanelData/sections/iTunesDownloadButtonSection_pt/iTunesDownloadButtonSection_pt.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_ru/iTunesDownloadButtonSection_ru.png": "/addPanelData/sections/iTunesDownloadButtonSection_ru/iTunesDownloadButtonSection_ru.v2.png",
    "addPanelData/sections/iTunesDownloadButtonSection_sv/iTunesDownloadButtonSection_sv.png": "/addPanelData/sections/iTunesDownloadButtonSection_sv/iTunesDownloadButtonSection_sv.v1.png",
    "addPanelData/sections/iTunesDownloadButtonSection_tr/iTunesDownloadButtonSection_tr.png": "/addPanelData/sections/iTunesDownloadButtonSection_tr/iTunesDownloadButtonSection_tr.v1.png",
    "addPanelData/sections/languageMenusSection_en/languageMenusSection_en.png": "/addPanelData/sections/languageMenusSection_en/languageMenusSection_en.v4.png",
    "addPanelData/sections/leanGalleriesSection/leanGalleriesSection.png": "/addPanelData/sections/leanGalleriesSection/leanGalleriesSection.png",
    "addPanelData/sections/leanGalleriesSection/leanGalleriesSection.retina.png": "/addPanelData/sections/leanGalleriesSection/leanGalleriesSection.retina.png",
    "addPanelData/sections/listAddPresets_en/listAddPresets_en.png": "/addPanelData/sections/listAddPresets_en/listAddPresets_en.v1.png",
    "addPanelData/sections/listEventsSection_da/listEventsSection_da.png": "/addPanelData/sections/listEventsSection_da/listEventsSection_da.v1.png",
    "addPanelData/sections/listEventsSection_de/listEventsSection_de.png": "/addPanelData/sections/listEventsSection_de/listEventsSection_de.v1.png",
    "addPanelData/sections/listEventsSection_en/listEventsSection_en.png": "/addPanelData/sections/listEventsSection_en/listEventsSection_en.v1.png",
    "addPanelData/sections/listEventsSection_es/listEventsSection_es.png": "/addPanelData/sections/listEventsSection_es/listEventsSection_es.v1.png",
    "addPanelData/sections/listEventsSection_fr/listEventsSection_fr.png": "/addPanelData/sections/listEventsSection_fr/listEventsSection_fr.v1.png",
    "addPanelData/sections/listEventsSection_it/listEventsSection_it.png": "/addPanelData/sections/listEventsSection_it/listEventsSection_it.v1.png",
    "addPanelData/sections/listEventsSection_ja/listEventsSection_ja.png": "/addPanelData/sections/listEventsSection_ja/listEventsSection_ja.v4.png",
    "addPanelData/sections/listEventsSection_ko/listEventsSection_ko.png": "/addPanelData/sections/listEventsSection_ko/listEventsSection_ko.v4.png",
    "addPanelData/sections/listEventsSection_nl/listEventsSection_nl.png": "/addPanelData/sections/listEventsSection_nl/listEventsSection_nl.v1.png",
    "addPanelData/sections/listEventsSection_no/listEventsSection_no.png": "/addPanelData/sections/listEventsSection_no/listEventsSection_no.v1.png",
    "addPanelData/sections/listEventsSection_pl/listEventsSection_pl.png": "/addPanelData/sections/listEventsSection_pl/listEventsSection_pl.v1.png",
    "addPanelData/sections/listEventsSection_pt/listEventsSection_pt.png": "/addPanelData/sections/listEventsSection_pt/listEventsSection_pt.v1.png",
    "addPanelData/sections/listEventsSection_ru/listEventsSection_ru.png": "/addPanelData/sections/listEventsSection_ru/listEventsSection_ru.v1.png",
    "addPanelData/sections/listEventsSection_sv/listEventsSection_sv.png": "/addPanelData/sections/listEventsSection_sv/listEventsSection_sv.v1.png",
    "addPanelData/sections/listEventsSection_tr/listEventsSection_tr.png": "/addPanelData/sections/listEventsSection_tr/listEventsSection_tr.v1.png",
    "addPanelData/sections/listGeneralSection_en/listGeneralSection_en.png": "/addPanelData/sections/listGeneralSection_en/listGeneralSection_en.v3.png",
    "addPanelData/sections/listNewsAndEventsSection_en/listNewsAndEventsSection_en.png": "/addPanelData/sections/listNewsAndEventsSection_en/listNewsAndEventsSection_en.v3.png",
    "addPanelData/sections/listNewsPostsSection_da/listNewsPostsSection_da.png": "/addPanelData/sections/listNewsPostsSection_da/listNewsPostsSection_da.png",
    "addPanelData/sections/listNewsPostsSection_de/listNewsPostsSection_de.png": "/addPanelData/sections/listNewsPostsSection_de/listNewsPostsSection_de.png",
    "addPanelData/sections/listNewsPostsSection_en/listNewsPostsSection_en.png": "/addPanelData/sections/listNewsPostsSection_en/listNewsPostsSection_en.png",
    "addPanelData/sections/listNewsPostsSection_es/listNewsPostsSection_es.png": "/addPanelData/sections/listNewsPostsSection_es/listNewsPostsSection_es.png",
    "addPanelData/sections/listNewsPostsSection_fr/listNewsPostsSection_fr.png": "/addPanelData/sections/listNewsPostsSection_fr/listNewsPostsSection_fr.png",
    "addPanelData/sections/listNewsPostsSection_it/listNewsPostsSection_it.png": "/addPanelData/sections/listNewsPostsSection_it/listNewsPostsSection_it.png",
    "addPanelData/sections/listNewsPostsSection_ja/listNewsPostsSection_ja.png": "/addPanelData/sections/listNewsPostsSection_ja/listNewsPostsSection_ja.png",
    "addPanelData/sections/listNewsPostsSection_ko/listNewsPostsSection_ko.png": "/addPanelData/sections/listNewsPostsSection_ko/listNewsPostsSection_ko.v3.png",
    "addPanelData/sections/listNewsPostsSection_nl/listNewsPostsSection_nl.png": "/addPanelData/sections/listNewsPostsSection_nl/listNewsPostsSection_nl.png",
    "addPanelData/sections/listNewsPostsSection_no/listNewsPostsSection_no.png": "/addPanelData/sections/listNewsPostsSection_no/listNewsPostsSection_no.png",
    "addPanelData/sections/listNewsPostsSection_po/listNewsPostsSection_po.png": "/addPanelData/sections/listNewsPostsSection_po/listNewsPostsSection_po.png",
    "addPanelData/sections/listNewsPostsSection_pt/listNewsPostsSection_pt.png": "/addPanelData/sections/listNewsPostsSection_pt/listNewsPostsSection_pt.png",
    "addPanelData/sections/listNewsPostsSection_ru/listNewsPostsSection_ru.png": "/addPanelData/sections/listNewsPostsSection_ru/listNewsPostsSection_ru.png",
    "addPanelData/sections/listNewsPostsSection_sv/listNewsPostsSection_sv.png": "/addPanelData/sections/listNewsPostsSection_sv/listNewsPostsSection_sv.png",
    "addPanelData/sections/listNewsPostsSection_tr/listNewsPostsSection_tr.png": "/addPanelData/sections/listNewsPostsSection_tr/listNewsPostsSection_tr.png",
    "addPanelData/sections/listOtherSection_da/listOtherSection_da.png": "/addPanelData/sections/listOtherSection_da/listOtherSection_da.png",
    "addPanelData/sections/listOtherSection_de/listOtherSection_de.png": "/addPanelData/sections/listOtherSection_de/listOtherSection_de.png",
    "addPanelData/sections/listOtherSection_en/listOtherSection_en.png": "/addPanelData/sections/listOtherSection_en/listOtherSection_en.png",
    "addPanelData/sections/listOtherSection_es/listOtherSection_es.png": "/addPanelData/sections/listOtherSection_es/listOtherSection_es.png",
    "addPanelData/sections/listOtherSection_fr/listOtherSection_fr.png": "/addPanelData/sections/listOtherSection_fr/listOtherSection_fr.png",
    "addPanelData/sections/listOtherSection_it/listOtherSection_it.png": "/addPanelData/sections/listOtherSection_it/listOtherSection_it.png",
    "addPanelData/sections/listOtherSection_ja/listOtherSection_ja.png": "/addPanelData/sections/listOtherSection_ja/listOtherSection_ja.png",
    "addPanelData/sections/listOtherSection_ko/listOtherSection_ko.png": "/addPanelData/sections/listOtherSection_ko/listOtherSection_ko.png",
    "addPanelData/sections/listOtherSection_nl/listOtherSection_nl.png": "/addPanelData/sections/listOtherSection_nl/listOtherSection_nl.png",
    "addPanelData/sections/listOtherSection_no/listOtherSection_no.png": "/addPanelData/sections/listOtherSection_no/listOtherSection_no.png",
    "addPanelData/sections/listOtherSection_pl/listOtherSection_pl.png": "/addPanelData/sections/listOtherSection_pl/listOtherSection_pl.png",
    "addPanelData/sections/listOtherSection_pt/listOtherSection_pt.png": "/addPanelData/sections/listOtherSection_pt/listOtherSection_pt.png",
    "addPanelData/sections/listOtherSection_ru/listOtherSection_ru.png": "/addPanelData/sections/listOtherSection_ru/listOtherSection_ru.png",
    "addPanelData/sections/listOtherSection_sv/listOtherSection_sv.png": "/addPanelData/sections/listOtherSection_sv/listOtherSection_sv.png",
    "addPanelData/sections/listOtherSection_tr/listOtherSection_tr.png": "/addPanelData/sections/listOtherSection_tr/listOtherSection_tr.png",
    "addPanelData/sections/listProductsAndServicesSection_da/listProductsAndServicesSection_da.png": "/addPanelData/sections/listProductsAndServicesSection_da/listProductsAndServicesSection_da.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_de/listProductsAndServicesSection_de.png": "/addPanelData/sections/listProductsAndServicesSection_de/listProductsAndServicesSection_de.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_en/listProductsAndServicesSection_en.png": "/addPanelData/sections/listProductsAndServicesSection_en/listProductsAndServicesSection_en.v4.png",
    "addPanelData/sections/listProductsAndServicesSection_es/listProductsAndServicesSection_es.png": "/addPanelData/sections/listProductsAndServicesSection_es/listProductsAndServicesSection_es.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_fr/listProductsAndServicesSection_fr.png": "/addPanelData/sections/listProductsAndServicesSection_fr/listProductsAndServicesSection_fr.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_it/listProductsAndServicesSection_it.png": "/addPanelData/sections/listProductsAndServicesSection_it/listProductsAndServicesSection_it.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_ja/listProductsAndServicesSection_ja.png": "/addPanelData/sections/listProductsAndServicesSection_ja/listProductsAndServicesSection_ja.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_ko/listProductsAndServicesSection_ko.png": "/addPanelData/sections/listProductsAndServicesSection_ko/listProductsAndServicesSection_ko.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_nl/listProductsAndServicesSection_nl.png": "/addPanelData/sections/listProductsAndServicesSection_nl/listProductsAndServicesSection_nl.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_no/listProductsAndServicesSection_no.png": "/addPanelData/sections/listProductsAndServicesSection_no/listProductsAndServicesSection_no.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_pl/listProductsAndServicesSection_pl.png": "/addPanelData/sections/listProductsAndServicesSection_pl/listProductsAndServicesSection_pl.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_pt/listProductsAndServicesSection_pt.png": "/addPanelData/sections/listProductsAndServicesSection_pt/listProductsAndServicesSection_pt.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_ru/listProductsAndServicesSection_ru.png": "/addPanelData/sections/listProductsAndServicesSection_ru/listProductsAndServicesSection_ru.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_sv/listProductsAndServicesSection_sv.png": "/addPanelData/sections/listProductsAndServicesSection_sv/listProductsAndServicesSection_sv.v1.png",
    "addPanelData/sections/listProductsAndServicesSection_tr/listProductsAndServicesSection_tr.png": "/addPanelData/sections/listProductsAndServicesSection_tr/listProductsAndServicesSection_tr.v1.png",
    "addPanelData/sections/listTeamMembersSection_da/listTeamMembersSection_da.png": "/addPanelData/sections/listTeamMembersSection_da/listTeamMembersSection_da.png",
    "addPanelData/sections/listTeamMembersSection_de/listTeamMembersSection_de.png": "/addPanelData/sections/listTeamMembersSection_de/listTeamMembersSection_de.png",
    "addPanelData/sections/listTeamMembersSection_en/listTeamMembersSection_en.png": "/addPanelData/sections/listTeamMembersSection_en/listTeamMembersSection_en.png",
    "addPanelData/sections/listTeamMembersSection_es/listTeamMembersSection_es.png": "/addPanelData/sections/listTeamMembersSection_es/listTeamMembersSection_es.png",
    "addPanelData/sections/listTeamMembersSection_fr/listTeamMembersSection_fr.png": "/addPanelData/sections/listTeamMembersSection_fr/listTeamMembersSection_fr.png",
    "addPanelData/sections/listTeamMembersSection_it/listTeamMembersSection_it.png": "/addPanelData/sections/listTeamMembersSection_it/listTeamMembersSection_it.png",
    "addPanelData/sections/listTeamMembersSection_ja/listTeamMembersSection_ja.png": "/addPanelData/sections/listTeamMembersSection_ja/listTeamMembersSection_ja.png",
    "addPanelData/sections/listTeamMembersSection_ko/listTeamMembersSection_ko.png": "/addPanelData/sections/listTeamMembersSection_ko/listTeamMembersSection_ko.png",
    "addPanelData/sections/listTeamMembersSection_nl/listTeamMembersSection_nl.png": "/addPanelData/sections/listTeamMembersSection_nl/listTeamMembersSection_nl.png",
    "addPanelData/sections/listTeamMembersSection_no/listTeamMembersSection_no.png": "/addPanelData/sections/listTeamMembersSection_no/listTeamMembersSection_no.png",
    "addPanelData/sections/listTeamMembersSection_pl/listTeamMembersSection_pl.png": "/addPanelData/sections/listTeamMembersSection_pl/listTeamMembersSection_pl.png",
    "addPanelData/sections/listTeamMembersSection_pt/listTeamMembersSection_pt.png": "/addPanelData/sections/listTeamMembersSection_pt/listTeamMembersSection_pt.png",
    "addPanelData/sections/listTeamMembersSection_ru/listTeamMembersSection_ru.png": "/addPanelData/sections/listTeamMembersSection_ru/listTeamMembersSection_ru.png",
    "addPanelData/sections/listTeamMembersSection_sv/listTeamMembersSection_sv.png": "/addPanelData/sections/listTeamMembersSection_sv/listTeamMembersSection_sv.png",
    "addPanelData/sections/listTeamMembersSection_tr/listTeamMembersSection_tr.png": "/addPanelData/sections/listTeamMembersSection_tr/listTeamMembersSection_tr.png",
    "addPanelData/sections/listTestimonialsSection_da/listTestimonialsSection_da.png": "/addPanelData/sections/listTestimonialsSection_da/listTestimonialsSection_da.png",
    "addPanelData/sections/listTestimonialsSection_de/listTestimonialsSection_de.png": "/addPanelData/sections/listTestimonialsSection_de/listTestimonialsSection_de.png",
    "addPanelData/sections/listTestimonialsSection_en/listTestimonialsSection_en.png": "/addPanelData/sections/listTestimonialsSection_en/listTestimonialsSection_en.png",
    "addPanelData/sections/listTestimonialsSection_es/listTestimonialsSection_es.png": "/addPanelData/sections/listTestimonialsSection_es/listTestimonialsSection_es.png",
    "addPanelData/sections/listTestimonialsSection_fr/listTestimonialsSection_fr.png": "/addPanelData/sections/listTestimonialsSection_fr/listTestimonialsSection_fr.png",
    "addPanelData/sections/listTestimonialsSection_it/listTestimonialsSection_it.png": "/addPanelData/sections/listTestimonialsSection_it/listTestimonialsSection_it.png",
    "addPanelData/sections/listTestimonialsSection_ja/listTestimonialsSection_ja.png": "/addPanelData/sections/listTestimonialsSection_ja/listTestimonialsSection_ja.png",
    "addPanelData/sections/listTestimonialsSection_ko/listTestimonialsSection_ko.png": "/addPanelData/sections/listTestimonialsSection_ko/listTestimonialsSection_ko.png",
    "addPanelData/sections/listTestimonialsSection_nl/listTestimonialsSection_nl.png": "/addPanelData/sections/listTestimonialsSection_nl/listTestimonialsSection_nl.png",
    "addPanelData/sections/listTestimonialsSection_no/listTestimonialsSection_no.png": "/addPanelData/sections/listTestimonialsSection_no/listTestimonialsSection_no.png",
    "addPanelData/sections/listTestimonialsSection_pl/listTestimonialsSection_pl.png": "/addPanelData/sections/listTestimonialsSection_pl/listTestimonialsSection_pl.png",
    "addPanelData/sections/listTestimonialsSection_pt/listTestimonialsSection_pt.png": "/addPanelData/sections/listTestimonialsSection_pt/listTestimonialsSection_pt.png",
    "addPanelData/sections/listTestimonialsSection_ru/listTestimonialsSection_ru.png": "/addPanelData/sections/listTestimonialsSection_ru/listTestimonialsSection_ru.png",
    "addPanelData/sections/listTestimonialsSection_sv/listTestimonialsSection_sv.png": "/addPanelData/sections/listTestimonialsSection_sv/listTestimonialsSection_sv.png",
    "addPanelData/sections/listTestimonialsSection_tr/listTestimonialsSection_tr.png": "/addPanelData/sections/listTestimonialsSection_tr/listTestimonialsSection_tr.png",
    "addPanelData/sections/liveChatSection_en/liveChatSection_en.png": "/addPanelData/sections/liveChatSection_en/liveChatSection_en.v7.png",
    "addPanelData/sections/liveChatSection_en/liveChatSection_en.v2@1x.png": "/addPanelData/sections/liveChatSection_en/liveChatSection_en.v2@1x.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_cs.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_cs.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_da.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_da.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_de.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_de.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_en.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_en.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_es.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_es.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_fr.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_fr.v3.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_it.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_it.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_nl.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_nl.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_no.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_no.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_pl.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_pl.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_pt.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_pt.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_redesign_en.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_redesign_en.v2.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_ru.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_ru.v1.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_sv.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_sv.v3.png",
    "addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_tr.png": "/addPanelData/sections/LocalizationAddPanelParagraphs/paragraphsSection_tr.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_cs.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_cs.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_da.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_da.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_de.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_de.v4.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_en.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_en.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_es.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_es.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_fr.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_fr.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_it.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_it.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_nl.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_nl.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_no.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_no.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_pl.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_pl.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_pt.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_pt.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_redesign_en.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_redesign_en.v2.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_ru.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_ru.v3.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_sv.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_sv.v1.png",
    "addPanelData/sections/LocalizationAddPanelTitles/titlesSection_tr.png": "/addPanelData/sections/LocalizationAddPanelTitles/titlesSection_tr.v3.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_cs.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_cs.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_da.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_da.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_de.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_de.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_en.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_en.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_es.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_es.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_fr.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_fr.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_hi.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_hi.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_it.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_it.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ja.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ja.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ko.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ko.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_nl.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_nl.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_no.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_no.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_pl.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_pl.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_pt.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_pt.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ru.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_ru.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_sv.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_sv.v1.png",
    "addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_tr.png": "/addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_tr.v1.png",
    "addPanelData/sections/localizationRepeaters/repeaters_cs.png": "/addPanelData/sections/localizationRepeaters/repeaters_cs.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_da.jpg": "/addPanelData/sections/localizationRepeaters/repeaters_da.jpg",
    "addPanelData/sections/localizationRepeaters/repeaters_da.png": "/addPanelData/sections/localizationRepeaters/repeaters_da.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_de.jpg": "/addPanelData/sections/localizationRepeaters/repeaters_de.v2.jpg",
    "addPanelData/sections/localizationRepeaters/repeaters_de.png": "/addPanelData/sections/localizationRepeaters/repeaters_de.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_en.png": "/addPanelData/sections/localizationRepeaters/repeaters_en.v1.png",
    "addPanelData/sections/localizationRepeaters/repeaters_es.png": "/addPanelData/sections/localizationRepeaters/repeaters_es.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_fr.png": "/addPanelData/sections/localizationRepeaters/repeaters_fr.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_hi.png": "/addPanelData/sections/localizationRepeaters/repeaters_hi.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_it.png": "/addPanelData/sections/localizationRepeaters/repeaters_it.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_ja.png": "/addPanelData/sections/localizationRepeaters/repeaters_ja.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_ko.png": "/addPanelData/sections/localizationRepeaters/repeaters_ko.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_nl.png": "/addPanelData/sections/localizationRepeaters/repeaters_nl.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_no.png": "/addPanelData/sections/localizationRepeaters/repeaters_no.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_pl.png": "/addPanelData/sections/localizationRepeaters/repeaters_pl.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_pt.png": "/addPanelData/sections/localizationRepeaters/repeaters_pt.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_ru.png": "/addPanelData/sections/localizationRepeaters/repeaters_ru.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_sv.png": "/addPanelData/sections/localizationRepeaters/repeaters_sv.v4.png",
    "addPanelData/sections/localizationRepeaters/repeaters_tr.png": "/addPanelData/sections/localizationRepeaters/repeaters_tr.v4.png",
    "addPanelData/sections/logo/logoDefault.png": "/addPanelData/sections/logo/logoDefault.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/customCode.png": "/addPanelData/sections/marketingIntegrationsSection/customCode.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/facebookPixel.png": "/addPanelData/sections/marketingIntegrationsSection/facebookPixel.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/googleAds.png": "/addPanelData/sections/marketingIntegrationsSection/googleAds.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/googleAnalytics.png": "/addPanelData/sections/marketingIntegrationsSection/googleAnalytics.v3.png",
    "addPanelData/sections/marketingIntegrationsSection/googleTagManager.png": "/addPanelData/sections/marketingIntegrationsSection/googleTagManager.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/verificationCode.png": "/addPanelData/sections/marketingIntegrationsSection/verificationCode.v2.png",
    "addPanelData/sections/marketingIntegrationsSection/yandex.png": "/addPanelData/sections/marketingIntegrationsSection/yandex.v2.png",
    "addPanelData/sections/membersLoginBarSection_en/membersLoginBarSection_en.png": "/addPanelData/sections/membersLoginBarSection_en/membersLoginBarSection_en.v7.png",
    "addPanelData/sections/membersMenuSection_en/membersMenuSection_en.png": "/addPanelData/sections/membersMenuSection_en/membersMenuSection_en.v4.png",
    "addPanelData/sections/membersMenuSection_en/membersMenuSectionResponsive_en.png": "/addPanelData/sections/membersMenuSection_en/membersMenuSectionResponsive_en.v5.png",
    "addPanelData/sections/membersProfileSection_en/membersProfileSection_en.png": "/addPanelData/sections/membersProfileSection_en/membersProfileSection_en.v4.png",
    "addPanelData/sections/membersProfileSection_en/membersProfileSectionResponsive_en.png": "/addPanelData/sections/membersProfileSection_en/membersProfileSectionResponsive_en.v2.png",
    "addPanelData/sections/membersStaffWidgetSection_en/membersStaffWidgetSection_en.png": "/addPanelData/sections/membersStaffWidgetSection_en/membersStaffWidgetSection_en.v1.png",
    "addPanelData/sections/membersWelcomeSection_en/membersWelcomeSection_en.png": "/addPanelData/sections/membersWelcomeSection_en/membersWelcomeSection_en.v3.png",
    "addPanelData/sections/membersWelcomeSection_en/membersWelcomeSection_en@x2.png": "/addPanelData/sections/membersWelcomeSection_en/membersWelcomeSection_en@x2.v2.png",
    "addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en.png": "/addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en.v2.png",
    "addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en@x2.png": "/addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en@x2.v2.png",
    "addPanelData/sections/membersWidgetSection_en/membersWidgetSection_en.png": "/addPanelData/sections/membersWidgetSection_en/membersWidgetSection_en.v8.png",
    "addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en-@2x.png": "/addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en-@2x.v1.png",
    "addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en.png": "/addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en.v1.png",
    "addPanelData/sections/multiStateBoxSection_en/hover_en/multiStateBoxSection_hover_en.png": "/addPanelData/sections/multiStateBoxSection_en/hover_en/multiStateBoxSection_hover_en.v6.png",
    "addPanelData/sections/multiStateBoxSection_en/multiStateBoxSection_en.png": "/addPanelData/sections/multiStateBoxSection_en/multiStateBoxSection_en.v6.png",
    "addPanelData/sections/myButtonsSection_en/myButtonsSection_en.png": "/addPanelData/sections/myButtonsSection_en/myButtonsSection_en.v1.png",
    "addPanelData/sections/oneItemRepeatersSection_en/oneItemRepeatersSection_en.jpg": "/addPanelData/sections/oneItemRepeatersSection_en/oneItemRepeatersSection_en.jpg",
    "addPanelData/sections/onlineProgramsProgramListSection_en/programListWidgets.png": "/addPanelData/sections/onlineProgramsProgramListSection_en/programListWidgets.png",
    "addPanelData/sections/onlineProgramsProgramListSection_en/programListWidgets@2x.png": "/addPanelData/sections/onlineProgramsProgramListSection_en/programListWidgets@2x.png",
    "addPanelData/sections/onlineProgramsWelcomeSection_en/onlineProgramsWelcomeSection_en.png": "/addPanelData/sections/onlineProgramsWelcomeSection_en/onlineProgramsWelcomeSection_en.png",
    "addPanelData/sections/onlineProgramsWelcomeSection_en/onlineProgramsWelcomeSection_en@2x.png": "/addPanelData/sections/onlineProgramsWelcomeSection_en/onlineProgramsWelcomeSection_en@2x.png",
    "addPanelData/sections/pageStyleSection_en/pageStyleSection_en.png": "/addPanelData/sections/pageStyleSection_en/pageStyleSection_en.v7.png",
    "addPanelData/sections/paragraphsSection_da/paragraphsSection_da.png": "/addPanelData/sections/paragraphsSection_da/paragraphsSection_da.v3.png",
    "addPanelData/sections/paragraphsSection_de/paragraphsSection_de.png": "/addPanelData/sections/paragraphsSection_de/paragraphsSection_de.v4.png",
    "addPanelData/sections/paragraphsSection_en/paragraphsSection_en.png": "/addPanelData/sections/paragraphsSection_en/paragraphsSection_en.v8.png",
    "addPanelData/sections/paragraphsSection_es/paragraphsSection_es.png": "/addPanelData/sections/paragraphsSection_es/paragraphsSection_es.v3.png",
    "addPanelData/sections/paragraphsSection_fr/paragraphsSection_fr.png": "/addPanelData/sections/paragraphsSection_fr/paragraphsSection_fr.v3.png",
    "addPanelData/sections/paragraphsSection_it/paragraphsSection_it.png": "/addPanelData/sections/paragraphsSection_it/paragraphsSection_it.v3.png",
    "addPanelData/sections/paragraphsSection_ja/paragraphsSection_ja.png": "/addPanelData/sections/paragraphsSection_ja/paragraphsSection_ja.v1.png",
    "addPanelData/sections/paragraphsSection_ko/paragraphsSection_ko.png": "/addPanelData/sections/paragraphsSection_ko/paragraphsSection_ko.v1.png",
    "addPanelData/sections/paragraphsSection_nl/paragraphsSection_nl.png": "/addPanelData/sections/paragraphsSection_nl/paragraphsSection_nl.v3.png",
    "addPanelData/sections/paragraphsSection_no/paragraphsSection_no.png": "/addPanelData/sections/paragraphsSection_no/paragraphsSection_no.v1.png",
    "addPanelData/sections/paragraphsSection_pl/paragraphsSection_pl.png": "/addPanelData/sections/paragraphsSection_pl/paragraphsSection_pl.v1.png",
    "addPanelData/sections/paragraphsSection_pt/paragraphsSection_pt.png": "/addPanelData/sections/paragraphsSection_pt/paragraphsSection_pt.v3.png",
    "addPanelData/sections/paragraphsSection_ru/paragraphsSection_ru.png": "/addPanelData/sections/paragraphsSection_ru/paragraphsSection_ru.v3.png",
    "addPanelData/sections/paragraphsSection_sv/paragraphsSection_sv.png": "/addPanelData/sections/paragraphsSection_sv/paragraphsSection_sv.v1.png",
    "addPanelData/sections/paragraphsSection_tr/paragraphsSection_tr.png": "/addPanelData/sections/paragraphsSection_tr/paragraphsSection_tr.v4.png",
    "addPanelData/sections/payButton_cs/hover_cs/payButton_hover_cs.png": "/addPanelData/sections/payButton_cs/hover_cs/payButton_hover_cs.v2.png",
    "addPanelData/sections/payButton_cs/payButton_cs.png": "/addPanelData/sections/payButton_cs/payButton_cs.v2.png",
    "addPanelData/sections/payButton_da/hover_da/payButton_hover_da.png": "/addPanelData/sections/payButton_da/hover_da/payButton_hover_da.v2.png",
    "addPanelData/sections/payButton_da/payButton_da.png": "/addPanelData/sections/payButton_da/payButton_da.v2.png",
    "addPanelData/sections/payButton_de/hover_de/payButton_hover_de.png": "/addPanelData/sections/payButton_de/hover_de/payButton_hover_de.v2.png",
    "addPanelData/sections/payButton_de/payButton_de.png": "/addPanelData/sections/payButton_de/payButton_de.v2.png",
    "addPanelData/sections/payButton_en/hover_en/payButton_hover_en.png": "/addPanelData/sections/payButton_en/hover_en/payButton_hover_en.v2.png",
    "addPanelData/sections/payButton_en/hover_en/payButton_hover_redesign_en.png": "/addPanelData/sections/payButton_en/hover_en/payButton_hover_redesign_en.png",
    "addPanelData/sections/payButton_en/pay_buttons.png": "/addPanelData/sections/payButton_en/pay_buttons.png",
    "addPanelData/sections/payButton_en/payButton_en.png": "/addPanelData/sections/payButton_en/payButton_en.v2.png",
    "addPanelData/sections/payButton_es/hover_es/payButton_hover_es.png": "/addPanelData/sections/payButton_es/hover_es/payButton_hover_es.v2.png",
    "addPanelData/sections/payButton_es/payButton_es.png": "/addPanelData/sections/payButton_es/payButton_es.v2.png",
    "addPanelData/sections/payButton_fr/hover_fr/payButton_hover_fr.png": "/addPanelData/sections/payButton_fr/hover_fr/payButton_hover_fr.v2.png",
    "addPanelData/sections/payButton_fr/payButton_fr.png": "/addPanelData/sections/payButton_fr/payButton_fr.v2.png",
    "addPanelData/sections/payButton_hi/hover_hi/payButton_hover_hi.png": "/addPanelData/sections/payButton_hi/hover_hi/payButton_hover_hi.v2.png",
    "addPanelData/sections/payButton_hi/payButton_hi.png": "/addPanelData/sections/payButton_hi/payButton_hi.v2.png",
    "addPanelData/sections/payButton_it/hover_it/payButton_hover_it.png": "/addPanelData/sections/payButton_it/hover_it/payButton_hover_it.v2.png",
    "addPanelData/sections/payButton_it/payButton_it.png": "/addPanelData/sections/payButton_it/payButton_it.v2.png",
    "addPanelData/sections/payButton_ja/hover_ja/payButton_hover_ja.png": "/addPanelData/sections/payButton_ja/hover_ja/payButton_hover_ja.v2.png",
    "addPanelData/sections/payButton_ja/payButton_ja.png": "/addPanelData/sections/payButton_ja/payButton_ja.v2.png",
    "addPanelData/sections/payButton_ko/hover_ko/payButton_hover_ko.png": "/addPanelData/sections/payButton_ko/hover_ko/payButton_hover_ko.v2.png",
    "addPanelData/sections/payButton_ko/payButton_ko.png": "/addPanelData/sections/payButton_ko/payButton_ko.v2.png",
    "addPanelData/sections/payButton_nl/hover_nl/payButton_hover_nl.png": "/addPanelData/sections/payButton_nl/hover_nl/payButton_hover_nl.v2.png",
    "addPanelData/sections/payButton_nl/payButton_nl.png": "/addPanelData/sections/payButton_nl/payButton_nl.v2.png",
    "addPanelData/sections/payButton_no/hover_no/payButton_hover_no.png": "/addPanelData/sections/payButton_no/hover_no/payButton_hover_no.v2.png",
    "addPanelData/sections/payButton_no/payButton_no.png": "/addPanelData/sections/payButton_no/payButton_no.v2.png",
    "addPanelData/sections/payButton_pl/hover_pl/payButton_hover_pl.png": "/addPanelData/sections/payButton_pl/hover_pl/payButton_hover_pl.v2.png",
    "addPanelData/sections/payButton_pl/payButton_pl.png": "/addPanelData/sections/payButton_pl/payButton_pl.v2.png",
    "addPanelData/sections/payButton_pt/hover_pt/payButton_hover_pt.png": "/addPanelData/sections/payButton_pt/hover_pt/payButton_hover_pt.v2.png",
    "addPanelData/sections/payButton_pt/payButton_pt.png": "/addPanelData/sections/payButton_pt/payButton_pt.v2.png",
    "addPanelData/sections/payButton_ru/hover_ru/payButton_hover_ru.png": "/addPanelData/sections/payButton_ru/hover_ru/payButton_hover_ru.v2.png",
    "addPanelData/sections/payButton_ru/payButton_ru.png": "/addPanelData/sections/payButton_ru/payButton_ru.v2.png",
    "addPanelData/sections/payButton_sv/hover_sv/payButton_hover_sv.png": "/addPanelData/sections/payButton_sv/hover_sv/payButton_hover_sv.v2.png",
    "addPanelData/sections/payButton_sv/payButton_sv.png": "/addPanelData/sections/payButton_sv/payButton_sv.v2.png",
    "addPanelData/sections/payButton_th/hover_th/payButton_hover_th.png": "/addPanelData/sections/payButton_th/hover_th/payButton_hover_th.v2.png",
    "addPanelData/sections/payButton_th/payButton_th.png": "/addPanelData/sections/payButton_th/payButton_th.v2.png",
    "addPanelData/sections/payButton_tr/hover_tr/payButton_hover_tr.png": "/addPanelData/sections/payButton_tr/hover_tr/payButton_hover_tr.v2.png",
    "addPanelData/sections/payButton_tr/payButton_tr.png": "/addPanelData/sections/payButton_tr/payButton_tr.v2.png",
    "addPanelData/sections/payButton_uk/hover_uk/payButton_hover_uk.png": "/addPanelData/sections/payButton_uk/hover_uk/payButton_hover_uk.v2.png",
    "addPanelData/sections/payButton_uk/payButton_uk.png": "/addPanelData/sections/payButton_uk/payButton_uk.v2.png",
    "addPanelData/sections/payButton_zh/hover_zh/payButton_hover_zh.png": "/addPanelData/sections/payButton_zh/hover_zh/payButton_hover_zh.v2.png",
    "addPanelData/sections/payButton_zh/payButton_zh.png": "/addPanelData/sections/payButton_zh/payButton_zh.v2.png",
    "addPanelData/sections/paypalButtonsSection_da/paypalButtonsSection_da.png": "/addPanelData/sections/paypalButtonsSection_da/paypalButtonsSection_da.png",
    "addPanelData/sections/paypalButtonsSection_de/paypalButtonsSection_de.png": "/addPanelData/sections/paypalButtonsSection_de/paypalButtonsSection_de.png",
    "addPanelData/sections/paypalButtonsSection_en/paypalButtonsSection_en.png": "/addPanelData/sections/paypalButtonsSection_en/paypalButtonsSection_en.png",
    "addPanelData/sections/paypalButtonsSection_en/paypalButtonsSection_redesign_en.png": "/addPanelData/sections/paypalButtonsSection_en/paypalButtonsSection_redesign_en.png",
    "addPanelData/sections/paypalButtonsSection_es/paypalButtonsSection_es.png": "/addPanelData/sections/paypalButtonsSection_es/paypalButtonsSection_es.png",
    "addPanelData/sections/paypalButtonsSection_fr/paypalButtonsSection_fr.png": "/addPanelData/sections/paypalButtonsSection_fr/paypalButtonsSection_fr.png",
    "addPanelData/sections/paypalButtonsSection_it/paypalButtonsSection_it.png": "/addPanelData/sections/paypalButtonsSection_it/paypalButtonsSection_it.v2.png",
    "addPanelData/sections/paypalButtonsSection_ja/paypalButtonsSection_ja.png": "/addPanelData/sections/paypalButtonsSection_ja/paypalButtonsSection_ja.v2.png",
    "addPanelData/sections/paypalButtonsSection_nl/paypalButtonsSection_nl.png": "/addPanelData/sections/paypalButtonsSection_nl/paypalButtonsSection_nl.png",
    "addPanelData/sections/paypalButtonsSection_no/paypalButtonsSection_no.png": "/addPanelData/sections/paypalButtonsSection_no/paypalButtonsSection_no.png",
    "addPanelData/sections/paypalButtonsSection_pl/paypalButtonsSection_pl.png": "/addPanelData/sections/paypalButtonsSection_pl/paypalButtonsSection_pl.v1.png",
    "addPanelData/sections/paypalButtonsSection_pt/paypalButtonsSection_pt.png": "/addPanelData/sections/paypalButtonsSection_pt/paypalButtonsSection_pt.v2.png",
    "addPanelData/sections/paypalButtonsSection_ru/paypalButtonsSection_ru.png": "/addPanelData/sections/paypalButtonsSection_ru/paypalButtonsSection_ru.png",
    "addPanelData/sections/paypalButtonsSection_sv/paypalButtonsSection_sv.png": "/addPanelData/sections/paypalButtonsSection_sv/paypalButtonsSection_sv.png",
    "addPanelData/sections/paypalButtonsSection_tr/paypalButtonsSection_tr.png": "/addPanelData/sections/paypalButtonsSection_tr/paypalButtonsSection_tr.png",
    "addPanelData/sections/photoAlbumsSections_en/add-panel-photo-albums-section.png": "/addPanelData/sections/photoAlbumsSections_en/add-panel-photo-albums-section.png",
    "addPanelData/sections/photoAlbumsSections_en/add-panel-photo-albums-section@2x.png": "/addPanelData/sections/photoAlbumsSections_en/add-panel-photo-albums-section@2x.png",
    "addPanelData/sections/pinterestSection_en/pinterestSection_en.png": "/addPanelData/sections/pinterestSection_en/pinterestSection_en.v3.png",
    "addPanelData/sections/popularSocialToolsSection_da/popularSocialToolsSection_da.png": "/addPanelData/sections/popularSocialToolsSection_da/popularSocialToolsSection_da.v3.png",
    "addPanelData/sections/popularSocialToolsSection_de/popularSocialToolsSection_de.png": "/addPanelData/sections/popularSocialToolsSection_de/popularSocialToolsSection_de.v4.png",
    "addPanelData/sections/popularSocialToolsSection_en/popularSocialToolsSection_en.png": "/addPanelData/sections/popularSocialToolsSection_en/popularSocialToolsSection_en.v7.png",
    "addPanelData/sections/popularSocialToolsSection_es/popularSocialToolsSection_es.png": "/addPanelData/sections/popularSocialToolsSection_es/popularSocialToolsSection_es.v3.png",
    "addPanelData/sections/popularSocialToolsSection_fr/popularSocialToolsSection_fr.png": "/addPanelData/sections/popularSocialToolsSection_fr/popularSocialToolsSection_fr.v4.png",
    "addPanelData/sections/popularSocialToolsSection_it/popularSocialToolsSection_it.png": "/addPanelData/sections/popularSocialToolsSection_it/popularSocialToolsSection_it.v4.png",
    "addPanelData/sections/popularSocialToolsSection_ja/popularSocialToolsSection_ja.png": "/addPanelData/sections/popularSocialToolsSection_ja/popularSocialToolsSection_ja.v4.png",
    "addPanelData/sections/popularSocialToolsSection_ko/popularSocialToolsSection_ko.png": "/addPanelData/sections/popularSocialToolsSection_ko/popularSocialToolsSection_ko.v4.png",
    "addPanelData/sections/popularSocialToolsSection_nl/popularSocialToolsSection_nl.png": "/addPanelData/sections/popularSocialToolsSection_nl/popularSocialToolsSection_nl.v4.png",
    "addPanelData/sections/popularSocialToolsSection_no/popularSocialToolsSection_no.png": "/addPanelData/sections/popularSocialToolsSection_no/popularSocialToolsSection_no.v4.png",
    "addPanelData/sections/popularSocialToolsSection_pl/popularSocialToolsSection_pl.png": "/addPanelData/sections/popularSocialToolsSection_pl/popularSocialToolsSection_pl.v4.png",
    "addPanelData/sections/popularSocialToolsSection_pt/popularSocialToolsSection_pt.png": "/addPanelData/sections/popularSocialToolsSection_pt/popularSocialToolsSection_pt.v4.png",
    "addPanelData/sections/popularSocialToolsSection_ru/popularSocialToolsSection_ru.png": "/addPanelData/sections/popularSocialToolsSection_ru/popularSocialToolsSection_ru.v4.png",
    "addPanelData/sections/popularSocialToolsSection_sv/popularSocialToolsSection_sv.png": "/addPanelData/sections/popularSocialToolsSection_sv/popularSocialToolsSection_sv.v4.png",
    "addPanelData/sections/popularSocialToolsSection_tr/popularSocialToolsSection_tr.png": "/addPanelData/sections/popularSocialToolsSection_tr/popularSocialToolsSection_tr.v4.png",
    "addPanelData/sections/popups_cz/popups_contact.png": "/addPanelData/sections/popups_cz/popups_contact.png",
    "addPanelData/sections/popups_cz/popups_sale.png": "/addPanelData/sections/popups_cz/popups_sale.png",
    "addPanelData/sections/popups_cz/popups_subscribe.png": "/addPanelData/sections/popups_cz/popups_subscribe.png",
    "addPanelData/sections/popups_cz/popups_welcome.png": "/addPanelData/sections/popups_cz/popups_welcome.png",
    "addPanelData/sections/popups_da/popups_contact.png": "/addPanelData/sections/popups_da/popups_contact.png",
    "addPanelData/sections/popups_da/popups_sale.png": "/addPanelData/sections/popups_da/popups_sale.png",
    "addPanelData/sections/popups_da/popups_subscribe.png": "/addPanelData/sections/popups_da/popups_subscribe.png",
    "addPanelData/sections/popups_da/popups_welcome.png": "/addPanelData/sections/popups_da/popups_welcome.png",
    "addPanelData/sections/popups_de/popups_contact.png": "/addPanelData/sections/popups_de/popups_contact.png",
    "addPanelData/sections/popups_de/popups_sale.png": "/addPanelData/sections/popups_de/popups_sale.png",
    "addPanelData/sections/popups_de/popups_subscribe.png": "/addPanelData/sections/popups_de/popups_subscribe.png",
    "addPanelData/sections/popups_de/popups_welcome.png": "/addPanelData/sections/popups_de/popups_welcome.png",
    "addPanelData/sections/popups_en/popups_contact.png": "/addPanelData/sections/popups_en/popups_contact.v3.png",
    "addPanelData/sections/popups_en/popups_contact_redesign.png": "/addPanelData/sections/popups_en/popups_contact_redesign.v1.png",
    "addPanelData/sections/popups_en/popups_emailMarketing.png": "/addPanelData/sections/popups_en/popups_emailMarketing.png",
    "addPanelData/sections/popups_en/popups_en.png": "/addPanelData/sections/popups_en/popups_en.v1.png",
    "addPanelData/sections/popups_en/popups_generalUpdates.png": "/addPanelData/sections/popups_en/popups_generalUpdates.v2.png",
    "addPanelData/sections/popups_en/popups_promotion.png": "/addPanelData/sections/popups_en/popups_promotion.v2.png",
    "addPanelData/sections/popups_en/popups_sale.png": "/addPanelData/sections/popups_en/popups_sale.v4.png",
    "addPanelData/sections/popups_en/popups_sale_redesign.png": "/addPanelData/sections/popups_en/popups_sale_redesign.v1.png",
    "addPanelData/sections/popups_en/popups_subscribe.png": "/addPanelData/sections/popups_en/popups_subscribe.v3.png",
    "addPanelData/sections/popups_en/popups_subscribe_redesign.png": "/addPanelData/sections/popups_en/popups_subscribe_redesign.v1.png",
    "addPanelData/sections/popups_en/popups_welcome.png": "/addPanelData/sections/popups_en/popups_welcome.v3.png",
    "addPanelData/sections/popups_en/popups_welcome_redesign.png": "/addPanelData/sections/popups_en/popups_welcome_redesign.v1.png",
    "addPanelData/sections/popups_es/popups_contact.png": "/addPanelData/sections/popups_es/popups_contact.png",
    "addPanelData/sections/popups_es/popups_sale.png": "/addPanelData/sections/popups_es/popups_sale.png",
    "addPanelData/sections/popups_es/popups_subscribe.png": "/addPanelData/sections/popups_es/popups_subscribe.png",
    "addPanelData/sections/popups_es/popups_welcome.png": "/addPanelData/sections/popups_es/popups_welcome.png",
    "addPanelData/sections/popups_fr/popups_contact.png": "/addPanelData/sections/popups_fr/popups_contact.png",
    "addPanelData/sections/popups_fr/popups_sale.png": "/addPanelData/sections/popups_fr/popups_sale.png",
    "addPanelData/sections/popups_fr/popups_subscribe.png": "/addPanelData/sections/popups_fr/popups_subscribe.png",
    "addPanelData/sections/popups_fr/popups_welcome.png": "/addPanelData/sections/popups_fr/popups_welcome.png",
    "addPanelData/sections/popups_hi/popups_contact.png": "/addPanelData/sections/popups_hi/popups_contact.png",
    "addPanelData/sections/popups_hi/popups_sale.png": "/addPanelData/sections/popups_hi/popups_sale.png",
    "addPanelData/sections/popups_hi/popups_subscribe.png": "/addPanelData/sections/popups_hi/popups_subscribe.png",
    "addPanelData/sections/popups_hi/popups_welcome.png": "/addPanelData/sections/popups_hi/popups_welcome.png",
    "addPanelData/sections/popups_it/popups_contact.png": "/addPanelData/sections/popups_it/popups_contact.png",
    "addPanelData/sections/popups_it/popups_sale.png": "/addPanelData/sections/popups_it/popups_sale.png",
    "addPanelData/sections/popups_it/popups_subscribe.png": "/addPanelData/sections/popups_it/popups_subscribe.png",
    "addPanelData/sections/popups_it/popups_welcome.png": "/addPanelData/sections/popups_it/popups_welcome.png",
    "addPanelData/sections/popups_jp/popups_contact.png": "/addPanelData/sections/popups_jp/popups_contact.png",
    "addPanelData/sections/popups_jp/popups_sale.png": "/addPanelData/sections/popups_jp/popups_sale.png",
    "addPanelData/sections/popups_jp/popups_subscribe.png": "/addPanelData/sections/popups_jp/popups_subscribe.png",
    "addPanelData/sections/popups_jp/popups_welcome.png": "/addPanelData/sections/popups_jp/popups_welcome.png",
    "addPanelData/sections/popups_nl/popups_contact.png": "/addPanelData/sections/popups_nl/popups_contact.png",
    "addPanelData/sections/popups_nl/popups_sale.png": "/addPanelData/sections/popups_nl/popups_sale.png",
    "addPanelData/sections/popups_nl/popups_subscribe.png": "/addPanelData/sections/popups_nl/popups_subscribe.png",
    "addPanelData/sections/popups_nl/popups_welcome.png": "/addPanelData/sections/popups_nl/popups_welcome.png",
    "addPanelData/sections/popups_no/popups_contact.png": "/addPanelData/sections/popups_no/popups_contact.png",
    "addPanelData/sections/popups_no/popups_sale.png": "/addPanelData/sections/popups_no/popups_sale.png",
    "addPanelData/sections/popups_no/popups_subscribe.png": "/addPanelData/sections/popups_no/popups_subscribe.png",
    "addPanelData/sections/popups_no/popups_welcome.png": "/addPanelData/sections/popups_no/popups_welcome.png",
    "addPanelData/sections/popups_pl/popups_contact.png": "/addPanelData/sections/popups_pl/popups_contact.png",
    "addPanelData/sections/popups_pl/popups_sale.png": "/addPanelData/sections/popups_pl/popups_sale.png",
    "addPanelData/sections/popups_pl/popups_subscribe.png": "/addPanelData/sections/popups_pl/popups_subscribe.png",
    "addPanelData/sections/popups_pl/popups_welcome.png": "/addPanelData/sections/popups_pl/popups_welcome.png",
    "addPanelData/sections/popups_pt/popups_contact.png": "/addPanelData/sections/popups_pt/popups_contact.png",
    "addPanelData/sections/popups_pt/popups_sale.png": "/addPanelData/sections/popups_pt/popups_sale.png",
    "addPanelData/sections/popups_pt/popups_subscribe.png": "/addPanelData/sections/popups_pt/popups_subscribe.png",
    "addPanelData/sections/popups_pt/popups_welcome.png": "/addPanelData/sections/popups_pt/popups_welcome.png",
    "addPanelData/sections/popups_ru/popups_contact.png": "/addPanelData/sections/popups_ru/popups_contact.png",
    "addPanelData/sections/popups_ru/popups_sale.png": "/addPanelData/sections/popups_ru/popups_sale.png",
    "addPanelData/sections/popups_ru/popups_subscribe.png": "/addPanelData/sections/popups_ru/popups_subscribe.png",
    "addPanelData/sections/popups_ru/popups_welcome.png": "/addPanelData/sections/popups_ru/popups_welcome.png",
    "addPanelData/sections/popups_sv/popups_contact.png": "/addPanelData/sections/popups_sv/popups_contact.png",
    "addPanelData/sections/popups_sv/popups_sale.png": "/addPanelData/sections/popups_sv/popups_sale.png",
    "addPanelData/sections/popups_sv/popups_subscribe.png": "/addPanelData/sections/popups_sv/popups_subscribe.png",
    "addPanelData/sections/popups_sv/popups_welcome.png": "/addPanelData/sections/popups_sv/popups_welcome.png",
    "addPanelData/sections/popups_tr/popups_contact.png": "/addPanelData/sections/popups_tr/popups_contact.png",
    "addPanelData/sections/popups_tr/popups_sale.png": "/addPanelData/sections/popups_tr/popups_sale.png",
    "addPanelData/sections/popups_tr/popups_subscribe.png": "/addPanelData/sections/popups_tr/popups_subscribe.png",
    "addPanelData/sections/popups_tr/popups_welcome.png": "/addPanelData/sections/popups_tr/popups_welcome.png",
    "addPanelData/sections/popUpsButtonsSection_en/popUpsButtonsSection.png": "/addPanelData/sections/popUpsButtonsSection_en/popUpsButtonsSection.v1.png",
    "addPanelData/sections/popUpsCloseButtonsSection_en/popUpsCloseButtonsSection.png": "/addPanelData/sections/popUpsCloseButtonsSection_en/popUpsCloseButtonsSection.v3.png",
    "addPanelData/sections/pricingPlans/pricingPlansSection.png": "/addPanelData/sections/pricingPlans/pricingPlansSection.v2.png",
    "addPanelData/sections/pricingPlans/pricingPlansSingleSection.png": "/addPanelData/sections/pricingPlans/pricingPlansSingleSection.v2.png",
    "addPanelData/sections/pricingPlans/pricingPlansSingleSectionBlocks.png": "/addPanelData/sections/pricingPlans/pricingPlansSingleSectionBlocks.png",
    "addPanelData/sections/pricingPlans/pricingPlansWelcomeSection.jpg": "/addPanelData/sections/pricingPlans/pricingPlansWelcomeSection.jpg",
    "addPanelData/sections/pricingPlans/pricingPlansWelcomeSection.png": "/addPanelData/sections/pricingPlans/pricingPlansWelcomeSection.png",
    "addPanelData/sections/pricingPlans/pricingPlansWidgetSection.png": "/addPanelData/sections/pricingPlans/pricingPlansWidgetSection.png",
    "addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new-@2x.png": "/addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new-@2x.v1.png",
    "addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new.png": "/addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new.v1.png",
    "addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section.png": "/addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section.png",
    "addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section-@2x.png": "/addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section-@2x.v2.png",
    "addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section-@3x.png": "/addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section-@3x.v2.png",
    "addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section.png": "/addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section.v2.png",
    "addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section@x3.png": "/addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section@x3.png",
    "addPanelData/sections/proGalleriesSection_en/featured.png": "/addPanelData/sections/proGalleriesSection_en/featured.v1.png",
    "addPanelData/sections/proGalleriesSection_en/horizontal.png": "/addPanelData/sections/proGalleriesSection_en/horizontal.v1.png",
    "addPanelData/sections/proGalleriesSection_en/popular.@2x.png": "/addPanelData/sections/proGalleriesSection_en/popular.@2x.v1.png",
    "addPanelData/sections/proGalleriesSection_en/popular.png": "/addPanelData/sections/proGalleriesSection_en/popular.v2.png",
    "addPanelData/sections/proGalleriesSection_en/vertical.png": "/addPanelData/sections/proGalleriesSection_en/vertical.v1.png",
    "addPanelData/sections/progressBarSection_en/add-panel-progressbar-sction_en.png": "/addPanelData/sections/progressBarSection_en/add-panel-progressbar-sction_en.v2.png",
    "addPanelData/sections/promos/promosAnnouncementWidget.png": "/addPanelData/sections/promos/promosAnnouncementWidget.v2.png",
    "addPanelData/sections/promos/promosWelcomeSection.png": "/addPanelData/sections/promos/promosWelcomeSection.png",
    "addPanelData/sections/quickActionBar_en/quickActionBarSection_en.jpg": "/addPanelData/sections/quickActionBar_en/quickActionBarSection_en.v3.jpg",
    "addPanelData/sections/quickActionBar_en/quickActionBarSection_en.png": "/addPanelData/sections/quickActionBar_en/quickActionBarSection_en.v3.png",
    "addPanelData/sections/radioGroupSection_en/radioGroupSection_en.png": "/addPanelData/sections/radioGroupSection_en/radioGroupSection_en.v4.png",
    "addPanelData/sections/rangeSliderSection_en/rangeSliderSection_en.png": "/addPanelData/sections/rangeSliderSection_en/rangeSliderSection_en.v3.png",
    "addPanelData/sections/rangeSliderSection_en/rangeSliderSection_en.v1.2x.png": "/addPanelData/sections/rangeSliderSection_en/rangeSliderSection_en.v1.2x.png",
    "addPanelData/sections/repeatersSection_en/repeatersBlankSection_en.png": "/addPanelData/sections/repeatersSection_en/repeatersBlankSection_en.png",
    "addPanelData/sections/repeatersSection_en/repeatersInteractiveSection_en.png": "/addPanelData/sections/repeatersSection_en/repeatersInteractiveSection_en.png",
    "addPanelData/sections/repeatersSection_en/repeatersInteractiveSection_hover_en.png": "/addPanelData/sections/repeatersSection_en/repeatersInteractiveSection_hover_en.png",
    "addPanelData/sections/repeatersSection_en/repeatersSection_en.png": "/addPanelData/sections/repeatersSection_en/repeatersSection_en.v4.png",
    "addPanelData/sections/repeatersSection_en/repeatersSection_redesign_en.png": "/addPanelData/sections/repeatersSection_en/repeatersSection_redesign_en.png",
    "addPanelData/sections/responsiveMultiStateBoxSection_en/responsiveMultiStateBoxSection_en.png": "/addPanelData/sections/responsiveMultiStateBoxSection_en/responsiveMultiStateBoxSection_en.v2.png",
    "addPanelData/sections/restaurantMenusSection_en/restaurantMenusWidget1.png": "/addPanelData/sections/restaurantMenusSection_en/restaurantMenusWidget1.png",
    "addPanelData/sections/restaurantMenusWelcomeSection_en/restaurantMenusWelcomeSection.png": "/addPanelData/sections/restaurantMenusWelcomeSection_en/restaurantMenusWelcomeSection.png",
    "addPanelData/sections/restaurantOrdersWelcomeSection_en/restaurantOrdersWelcomeSection.png": "/addPanelData/sections/restaurantOrdersWelcomeSection_en/restaurantOrdersWelcomeSection.png",
    "addPanelData/sections/saleRibbonSection_en/saleRibbonSection_en.png": "/addPanelData/sections/saleRibbonSection_en/saleRibbonSection_en.v1.png",
    "addPanelData/sections/savedComponentsWelcomeSection_cs/savedComponentsWelcomeSection_cs.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_cs/savedComponentsWelcomeSection_cs.v2.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_da/savedComponentsWelcomeSection_da.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_da/savedComponentsWelcomeSection_da.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_de/savedComponentsWelcomeSection_de.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_de/savedComponentsWelcomeSection_de.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en.png": "/addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en.v2.png",
    "addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en@x2.png": "/addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en@x2.v2.png",
    "addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en.png": "/addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en.v1.png",
    "addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en@x2.png": "/addPanelData/sections/savedComponentsWelcomeSection_en/savedComponentsWelcomeSection_en@x2.v1.png",
    "addPanelData/sections/savedComponentsWelcomeSection_es/savedComponentsWelcomeSection_es.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_es/savedComponentsWelcomeSection_es.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_fr/savedComponentsWelcomeSection_fr.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_fr/savedComponentsWelcomeSection_fr.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_hi/savedComponentsWelcomeSection_hi.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_hi/savedComponentsWelcomeSection_hi.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_it/savedComponentsWelcomeSection_it.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_it/savedComponentsWelcomeSection_it.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_ja/savedComponentsWelcomeSection_ja.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_ja/savedComponentsWelcomeSection_ja.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_ko/savedComponentsWelcomeSection_ko.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_ko/savedComponentsWelcomeSection_ko.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_nl/savedComponentsWelcomeSection_nl.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_nl/savedComponentsWelcomeSection_nl.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_no/savedComponentsWelcomeSection_no.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_no/savedComponentsWelcomeSection_no.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_po/savedComponentsWelcomeSection_po.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_po/savedComponentsWelcomeSection_po.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_pt/savedComponentsWelcomeSection_pt.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_pt/savedComponentsWelcomeSection_pt.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_ru/savedComponentsWelcomeSection_ru.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_ru/savedComponentsWelcomeSection_ru.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_sv/savedComponentsWelcomeSection_sv.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_sv/savedComponentsWelcomeSection_sv.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_th/savedComponentsWelcomeSection_th.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_th/savedComponentsWelcomeSection_th.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_tr/savedComponentsWelcomeSection_tr.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_tr/savedComponentsWelcomeSection_tr.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_uk/savedComponentsWelcomeSection_uk.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_uk/savedComponentsWelcomeSection_uk.jpg",
    "addPanelData/sections/savedComponentsWelcomeSection_zh/savedComponentsWelcomeSection_zh.jpg": "/addPanelData/sections/savedComponentsWelcomeSection_zh/savedComponentsWelcomeSection_zh.jpg",
    "addPanelData/sections/searchSection_en/searchSection_en.png": "/addPanelData/sections/searchSection_en/searchSection_en.v1.png",
    "addPanelData/sections/selectionTagsListSection_en/selectionTagsList_en.png": "/addPanelData/sections/selectionTagsListSection_en/selectionTagsList_en.v4.png",
    "addPanelData/sections/shareButtons_en/shareButtons_en.png": "/addPanelData/sections/shareButtons_en/shareButtons_en.png",
    "addPanelData/sections/signatureInputSection_en/add-panel-signature-input-section_en.png": "/addPanelData/sections/signatureInputSection_en/add-panel-signature-input-section_en.v2.png",
    "addPanelData/sections/singleTrackPlayerSection2_da/singleTrackPlayerSection2_da.png": "/addPanelData/sections/singleTrackPlayerSection2_da/singleTrackPlayerSection2_da.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_de/singleTrackPlayerSection2_de.png": "/addPanelData/sections/singleTrackPlayerSection2_de/singleTrackPlayerSection2_de.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_en/singleTrackPlayerSection2_en.png": "/addPanelData/sections/singleTrackPlayerSection2_en/singleTrackPlayerSection2_en.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_es/singleTrackPlayerSection2_es.png": "/addPanelData/sections/singleTrackPlayerSection2_es/singleTrackPlayerSection2_es.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_fr/singleTrackPlayerSection2_fr.png": "/addPanelData/sections/singleTrackPlayerSection2_fr/singleTrackPlayerSection2_fr.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_it/singleTrackPlayerSection2_it.png": "/addPanelData/sections/singleTrackPlayerSection2_it/singleTrackPlayerSection2_it.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_ja/singleTrackPlayerSection2_ja.png": "/addPanelData/sections/singleTrackPlayerSection2_ja/singleTrackPlayerSection2_ja.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_nl/singleTrackPlayerSection2_nl.png": "/addPanelData/sections/singleTrackPlayerSection2_nl/singleTrackPlayerSection2_nl.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_no/singleTrackPlayerSection2_no.png": "/addPanelData/sections/singleTrackPlayerSection2_no/singleTrackPlayerSection2_no.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_pl/singleTrackPlayerSection2_pl.png": "/addPanelData/sections/singleTrackPlayerSection2_pl/singleTrackPlayerSection2_pl.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_pt/singleTrackPlayerSection2_pt.png": "/addPanelData/sections/singleTrackPlayerSection2_pt/singleTrackPlayerSection2_pt.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_ru/singleTrackPlayerSection2_ru.png": "/addPanelData/sections/singleTrackPlayerSection2_ru/singleTrackPlayerSection2_ru.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_sv/singleTrackPlayerSection2_sv.png": "/addPanelData/sections/singleTrackPlayerSection2_sv/singleTrackPlayerSection2_sv.v1.png",
    "addPanelData/sections/singleTrackPlayerSection2_tr/singleTrackPlayerSection2_tr.png": "/addPanelData/sections/singleTrackPlayerSection2_tr/singleTrackPlayerSection2_tr.v1.png",
    "addPanelData/sections/singleTrackPlayerSection_en/singleTrackPlayerSection_en.png": "/addPanelData/sections/singleTrackPlayerSection_en/singleTrackPlayerSection_en.v1.png",
    "addPanelData/sections/siteLoginButtonsSection_en/siteLoginButtonsSection_en.png": "/addPanelData/sections/siteLoginButtonsSection_en/siteLoginButtonsSection_en.v1.png",
    "addPanelData/sections/skypeCallButtonSection_en/skypeCallButtonSection_en.png": "/addPanelData/sections/skypeCallButtonSection_en/skypeCallButtonSection_en.v4.png",
    "addPanelData/sections/sliderGalleriesSection_en/hover_en/sliderGalleriesSection_hover_en.png": "/addPanelData/sections/sliderGalleriesSection_en/hover_en/sliderGalleriesSection_hover_en.v1.png",
    "addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSection_en-@2x.png": "/addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSection_en-@2x.png",
    "addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSection_en.png": "/addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSection_en.v2.png",
    "addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSectionNew_en.png": "/addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSectionNew_en.png",
    "addPanelData/sections/socialBarSection_en/socialBarSection_en.png": "/addPanelData/sections/socialBarSection_en/socialBarSection_en.v10.png",
    "addPanelData/sections/socialBarSection_en/socialBarSectionWithoutGooglePlus_en.png": "/addPanelData/sections/socialBarSection_en/socialBarSectionWithoutGooglePlus_en.v1.png",
    "addPanelData/sections/socialBarSection_en/socialBarSectionWithTikTok_en.png": "/addPanelData/sections/socialBarSection_en/socialBarSectionWithTikTok_en.v3.png",
    "addPanelData/sections/socialLineSection_en/line_share_with_hit_area.png": "/addPanelData/sections/socialLineSection_en/line_share_with_hit_area.v1.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_da/soundCloudAudioPlayerSection_da.png": "/addPanelData/sections/soundCloudAudioPlayerSection_da/soundCloudAudioPlayerSection_da.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_de/soundCloudAudioPlayerSection_de.png": "/addPanelData/sections/soundCloudAudioPlayerSection_de/soundCloudAudioPlayerSection_de.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_en/soundCloudAudioPlayerSection_en.png": "/addPanelData/sections/soundCloudAudioPlayerSection_en/soundCloudAudioPlayerSection_en.v3.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_es/soundCloudAudioPlayerSection_es.png": "/addPanelData/sections/soundCloudAudioPlayerSection_es/soundCloudAudioPlayerSection_es.v2.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_fr/soundCloudAudioPlayerSection_fr.png": "/addPanelData/sections/soundCloudAudioPlayerSection_fr/soundCloudAudioPlayerSection_fr.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_it/soundCloudAudioPlayerSection_it.png": "/addPanelData/sections/soundCloudAudioPlayerSection_it/soundCloudAudioPlayerSection_it.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_ja/soundCloudAudioPlayerSection_ja.png": "/addPanelData/sections/soundCloudAudioPlayerSection_ja/soundCloudAudioPlayerSection_ja.v3.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_ko/soundCloudAudioPlayerSection_ko.png": "/addPanelData/sections/soundCloudAudioPlayerSection_ko/soundCloudAudioPlayerSection_ko.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_nl/soundCloudAudioPlayerSection_nl.png": "/addPanelData/sections/soundCloudAudioPlayerSection_nl/soundCloudAudioPlayerSection_nl.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_no/soundCloudAudioPlayerSection_no.png": "/addPanelData/sections/soundCloudAudioPlayerSection_no/soundCloudAudioPlayerSection_no.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_pl/soundCloudAudioPlayerSection_pl.png": "/addPanelData/sections/soundCloudAudioPlayerSection_pl/soundCloudAudioPlayerSection_pl.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_pt/soundCloudAudioPlayerSection_pt.png": "/addPanelData/sections/soundCloudAudioPlayerSection_pt/soundCloudAudioPlayerSection_pt.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_ru/soundCloudAudioPlayerSection_ru.png": "/addPanelData/sections/soundCloudAudioPlayerSection_ru/soundCloudAudioPlayerSection_ru.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_sv/soundCloudAudioPlayerSection_sv.png": "/addPanelData/sections/soundCloudAudioPlayerSection_sv/soundCloudAudioPlayerSection_sv.png",
    "addPanelData/sections/soundCloudAudioPlayerSection_tr/soundCloudAudioPlayerSection_tr.png": "/addPanelData/sections/soundCloudAudioPlayerSection_tr/soundCloudAudioPlayerSection_tr.png",
    "addPanelData/sections/spotifyApp/spotifyApp_en.png": "/addPanelData/sections/spotifyApp/spotifyApp_en.png",
    "addPanelData/sections/spotifyPlayerSection_da/spotifyPlayerSection_da.png": "/addPanelData/sections/spotifyPlayerSection_da/spotifyPlayerSection_da.png",
    "addPanelData/sections/spotifyPlayerSection_de/spotifyPlayerSection_de.png": "/addPanelData/sections/spotifyPlayerSection_de/spotifyPlayerSection_de.png",
    "addPanelData/sections/spotifyPlayerSection_en/spotifyPlayerSection_en.png": "/addPanelData/sections/spotifyPlayerSection_en/spotifyPlayerSection_en.v3.png",
    "addPanelData/sections/spotifyPlayerSection_es/spotifyPlayerSection_es.png": "/addPanelData/sections/spotifyPlayerSection_es/spotifyPlayerSection_es.png",
    "addPanelData/sections/spotifyPlayerSection_es/spotifyPlayerSection_es.v2png.png": "/addPanelData/sections/spotifyPlayerSection_es/spotifyPlayerSection_es.v2png.png",
    "addPanelData/sections/spotifyPlayerSection_fr/spotifyPlayerSection_fr.png": "/addPanelData/sections/spotifyPlayerSection_fr/spotifyPlayerSection_fr.png",
    "addPanelData/sections/spotifyPlayerSection_it/spotifyPlayerSection_it.png": "/addPanelData/sections/spotifyPlayerSection_it/spotifyPlayerSection_it.png",
    "addPanelData/sections/spotifyPlayerSection_ja/spotifyPlayerSection_ja.png": "/addPanelData/sections/spotifyPlayerSection_ja/spotifyPlayerSection_ja.v3.png",
    "addPanelData/sections/spotifyPlayerSection_ko/spotifyPlayerSection_ko.png": "/addPanelData/sections/spotifyPlayerSection_ko/spotifyPlayerSection_ko.png",
    "addPanelData/sections/spotifyPlayerSection_nl/spotifyPlayerSection_nl.png": "/addPanelData/sections/spotifyPlayerSection_nl/spotifyPlayerSection_nl.png",
    "addPanelData/sections/spotifyPlayerSection_no/spotifyPlayerSection_no.png": "/addPanelData/sections/spotifyPlayerSection_no/spotifyPlayerSection_no.png",
    "addPanelData/sections/spotifyPlayerSection_pl/spotifyPlayerSection_pl.png": "/addPanelData/sections/spotifyPlayerSection_pl/spotifyPlayerSection_pl.png",
    "addPanelData/sections/spotifyPlayerSection_pt/spotifyPlayerSection_pt.png": "/addPanelData/sections/spotifyPlayerSection_pt/spotifyPlayerSection_pt.png",
    "addPanelData/sections/spotifyPlayerSection_ru/spotifyPlayerSection_ru.png": "/addPanelData/sections/spotifyPlayerSection_ru/spotifyPlayerSection_ru.png",
    "addPanelData/sections/spotifyPlayerSection_sv/spotifyPlayerSection_sv.png": "/addPanelData/sections/spotifyPlayerSection_sv/spotifyPlayerSection_sv.png",
    "addPanelData/sections/spotifyPlayerSection_tr/spotifyPlayerSection_tr.png": "/addPanelData/sections/spotifyPlayerSection_tr/spotifyPlayerSection_tr.png",
    "addPanelData/sections/spotifySocialSection_da/spotifySocialSection_da.png": "/addPanelData/sections/spotifySocialSection_da/spotifySocialSection_da.png",
    "addPanelData/sections/spotifySocialSection_de/spotifySocialSection_de.png": "/addPanelData/sections/spotifySocialSection_de/spotifySocialSection_de.png",
    "addPanelData/sections/spotifySocialSection_en/spotifySocialSection_en.png": "/addPanelData/sections/spotifySocialSection_en/spotifySocialSection_en.v3.png",
    "addPanelData/sections/spotifySocialSection_es/spotifySocialSection_es.png": "/addPanelData/sections/spotifySocialSection_es/spotifySocialSection_es.png",
    "addPanelData/sections/spotifySocialSection_fr/spotifySocialSection_fr.png": "/addPanelData/sections/spotifySocialSection_fr/spotifySocialSection_fr.png",
    "addPanelData/sections/spotifySocialSection_it/spotifySocialSection_it.png": "/addPanelData/sections/spotifySocialSection_it/spotifySocialSection_it.png",
    "addPanelData/sections/spotifySocialSection_ja/spotifySocialSection_ja.png": "/addPanelData/sections/spotifySocialSection_ja/spotifySocialSection_ja.png",
    "addPanelData/sections/spotifySocialSection_ko/spotifySocialSection_ko.png": "/addPanelData/sections/spotifySocialSection_ko/spotifySocialSection_ko.png",
    "addPanelData/sections/spotifySocialSection_nl/spotifySocialSection_nl.png": "/addPanelData/sections/spotifySocialSection_nl/spotifySocialSection_nl.png",
    "addPanelData/sections/spotifySocialSection_no/spotifySocialSection_no.png": "/addPanelData/sections/spotifySocialSection_no/spotifySocialSection_no.png",
    "addPanelData/sections/spotifySocialSection_pl/spotifySocialSection_pl.png": "/addPanelData/sections/spotifySocialSection_pl/spotifySocialSection_pl.png",
    "addPanelData/sections/spotifySocialSection_pt/spotifySocialSection_pt.png": "/addPanelData/sections/spotifySocialSection_pt/spotifySocialSection_pt.png",
    "addPanelData/sections/spotifySocialSection_ru/spotifySocialSection_ru.png": "/addPanelData/sections/spotifySocialSection_ru/spotifySocialSection_ru.png",
    "addPanelData/sections/spotifySocialSection_sv/spotifySocialSection_sv.png": "/addPanelData/sections/spotifySocialSection_sv/spotifySocialSection_sv.png",
    "addPanelData/sections/spotifySocialSection_tr/spotifySocialSection_tr.png": "/addPanelData/sections/spotifySocialSection_tr/spotifySocialSection_tr.png",
    "addPanelData/sections/stripBoxSlideShowSection_en/stripBoxSlideShowSection_en.png": "/addPanelData/sections/stripBoxSlideShowSection_en/stripBoxSlideShowSection_en.v6.png",
    "addPanelData/sections/stripBoxSlideShowSection_en/stripBoxSlideShowSection_redesign_en.png": "/addPanelData/sections/stripBoxSlideShowSection_en/stripBoxSlideShowSection_redesign_en.png",
    "addPanelData/sections/stripColumnsContainerSection_en/stripColumnsContainerSection_en.png": "/addPanelData/sections/stripColumnsContainerSection_en/stripColumnsContainerSection_en.v1.png",
    "addPanelData/sections/StripColumnsContainerWelcomeSection_en/stripColumnsContainerWelcomeSection_en.png": "/addPanelData/sections/StripColumnsContainerWelcomeSection_en/stripColumnsContainerWelcomeSection_en.v1.png",
    "addPanelData/sections/stripContainerAboutSection_da/stripContainerAboutSection_da.png": "/addPanelData/sections/stripContainerAboutSection_da/stripContainerAboutSection_da.png",
    "addPanelData/sections/stripContainerAboutSection_de/stripContainerAboutSection_de.png": "/addPanelData/sections/stripContainerAboutSection_de/stripContainerAboutSection_de.png",
    "addPanelData/sections/stripContainerAboutSection_en/stripContainerAboutSection_en.png": "/addPanelData/sections/stripContainerAboutSection_en/stripContainerAboutSection_en.v5.png",
    "addPanelData/sections/stripContainerAboutSection_es/stripContainerAboutSection_es.png": "/addPanelData/sections/stripContainerAboutSection_es/stripContainerAboutSection_es.png",
    "addPanelData/sections/stripContainerAboutSection_fr/stripContainerAboutSection_fr.png": "/addPanelData/sections/stripContainerAboutSection_fr/stripContainerAboutSection_fr.png",
    "addPanelData/sections/stripContainerAboutSection_it/stripContainerAboutSection_it.png": "/addPanelData/sections/stripContainerAboutSection_it/stripContainerAboutSection_it.png",
    "addPanelData/sections/stripContainerAboutSection_ja/stripContainerAboutSection_ja.png": "/addPanelData/sections/stripContainerAboutSection_ja/stripContainerAboutSection_ja.png",
    "addPanelData/sections/stripContainerAboutSection_ko/stripContainerAboutSection_ko.png": "/addPanelData/sections/stripContainerAboutSection_ko/stripContainerAboutSection_ko.png",
    "addPanelData/sections/stripContainerAboutSection_nl/stripContainerAboutSection_nl.png": "/addPanelData/sections/stripContainerAboutSection_nl/stripContainerAboutSection_nl.png",
    "addPanelData/sections/stripContainerAboutSection_no/stripContainerAboutSection_no.png": "/addPanelData/sections/stripContainerAboutSection_no/stripContainerAboutSection_no.png",
    "addPanelData/sections/stripContainerAboutSection_pl/stripContainerAboutSection_pl.png": "/addPanelData/sections/stripContainerAboutSection_pl/stripContainerAboutSection_pl.png",
    "addPanelData/sections/stripContainerAboutSection_pt/stripContainerAboutSection_pt.png": "/addPanelData/sections/stripContainerAboutSection_pt/stripContainerAboutSection_pt.png",
    "addPanelData/sections/stripContainerAboutSection_ru/stripContainerAboutSection_ru.png": "/addPanelData/sections/stripContainerAboutSection_ru/stripContainerAboutSection_ru.png",
    "addPanelData/sections/stripContainerAboutSection_sv/stripContainerAboutSection_sv.png": "/addPanelData/sections/stripContainerAboutSection_sv/stripContainerAboutSection_sv.png",
    "addPanelData/sections/stripContainerAboutSection_tr/stripContainerAboutSection_tr.png": "/addPanelData/sections/stripContainerAboutSection_tr/stripContainerAboutSection_tr.png",
    "addPanelData/sections/stripContainerClassicSection_da/stripContainerClassicSection_da.png": "/addPanelData/sections/stripContainerClassicSection_da/stripContainerClassicSection_da.png",
    "addPanelData/sections/stripContainerClassicSection_de/stripContainerClassicSection_de.png": "/addPanelData/sections/stripContainerClassicSection_de/stripContainerClassicSection_de.png",
    "addPanelData/sections/stripContainerClassicSection_en/stripContainerClassicSection_en.png": "/addPanelData/sections/stripContainerClassicSection_en/stripContainerClassicSection_en.v3.png",
    "addPanelData/sections/stripContainerClassicSection_es/stripContainerClassicSection_es.png": "/addPanelData/sections/stripContainerClassicSection_es/stripContainerClassicSection_es.png",
    "addPanelData/sections/stripContainerClassicSection_fr/stripContainerClassicSection_fr.png": "/addPanelData/sections/stripContainerClassicSection_fr/stripContainerClassicSection_fr.png",
    "addPanelData/sections/stripContainerClassicSection_it/stripContainerClassicSection_it.png": "/addPanelData/sections/stripContainerClassicSection_it/stripContainerClassicSection_it.png",
    "addPanelData/sections/stripContainerClassicSection_ja/stripContainerClassicSection_ja.png": "/addPanelData/sections/stripContainerClassicSection_ja/stripContainerClassicSection_ja.png",
    "addPanelData/sections/stripContainerClassicSection_ko/stripContainerClassicSection_ko.png": "/addPanelData/sections/stripContainerClassicSection_ko/stripContainerClassicSection_ko.png",
    "addPanelData/sections/stripContainerClassicSection_nl/stripContainerClassicSection_nl.png": "/addPanelData/sections/stripContainerClassicSection_nl/stripContainerClassicSection_nl.png",
    "addPanelData/sections/stripContainerClassicSection_no/stripContainerClassicSection_no.png": "/addPanelData/sections/stripContainerClassicSection_no/stripContainerClassicSection_no.png",
    "addPanelData/sections/stripContainerClassicSection_pl/stripContainerClassicSection_pl.png": "/addPanelData/sections/stripContainerClassicSection_pl/stripContainerClassicSection_pl.png",
    "addPanelData/sections/stripContainerClassicSection_pt/stripContainerClassicSection_pt.png": "/addPanelData/sections/stripContainerClassicSection_pt/stripContainerClassicSection_pt.png",
    "addPanelData/sections/stripContainerClassicSection_ru/stripContainerClassicSection_ru.png": "/addPanelData/sections/stripContainerClassicSection_ru/stripContainerClassicSection_ru.png",
    "addPanelData/sections/stripContainerClassicSection_sv/stripContainerClassicSection_sv.png": "/addPanelData/sections/stripContainerClassicSection_sv/stripContainerClassicSection_sv.png",
    "addPanelData/sections/stripContainerClassicSection_tr/stripContainerClassicSection_tr.png": "/addPanelData/sections/stripContainerClassicSection_tr/stripContainerClassicSection_tr.png",
    "addPanelData/sections/stripContainerClientsSection_da/stripContainerClientsSection_da.png": "/addPanelData/sections/stripContainerClientsSection_da/stripContainerClientsSection_da.v1.png",
    "addPanelData/sections/stripContainerClientsSection_de/stripContainerClientsSection_de.png": "/addPanelData/sections/stripContainerClientsSection_de/stripContainerClientsSection_de.v1.png",
    "addPanelData/sections/stripContainerClientsSection_en/stripContainerClientsSection_en.png": "/addPanelData/sections/stripContainerClientsSection_en/stripContainerClientsSection_en.v4.png",
    "addPanelData/sections/stripContainerClientsSection_es/stripContainerClientsSection_es.png": "/addPanelData/sections/stripContainerClientsSection_es/stripContainerClientsSection_es.v1.png",
    "addPanelData/sections/stripContainerClientsSection_fr/stripContainerClientsSection_fr.png": "/addPanelData/sections/stripContainerClientsSection_fr/stripContainerClientsSection_fr.v1.png",
    "addPanelData/sections/stripContainerClientsSection_it/stripContainerClientsSection_it.png": "/addPanelData/sections/stripContainerClientsSection_it/stripContainerClientsSection_it.v1.png",
    "addPanelData/sections/stripContainerClientsSection_ja/stripContainerClientsSection_ja.png": "/addPanelData/sections/stripContainerClientsSection_ja/stripContainerClientsSection_ja.v1.png",
    "addPanelData/sections/stripContainerClientsSection_ko/stripContainerClientsSection_ko.png": "/addPanelData/sections/stripContainerClientsSection_ko/stripContainerClientsSection_ko.v1.png",
    "addPanelData/sections/stripContainerClientsSection_nl/stripContainerClientsSection_nl.png": "/addPanelData/sections/stripContainerClientsSection_nl/stripContainerClientsSection_nl.v1.png",
    "addPanelData/sections/stripContainerClientsSection_no/stripContainerClientsSection_no.png": "/addPanelData/sections/stripContainerClientsSection_no/stripContainerClientsSection_no.v1.png",
    "addPanelData/sections/stripContainerClientsSection_pl/stripContainerClientsSection_pl.png": "/addPanelData/sections/stripContainerClientsSection_pl/stripContainerClientsSection_pl.v1.png",
    "addPanelData/sections/stripContainerClientsSection_pt/stripContainerClientsSection_pt.png": "/addPanelData/sections/stripContainerClientsSection_pt/stripContainerClientsSection_pt.v1.png",
    "addPanelData/sections/stripContainerClientsSection_ru/stripContainerClientsSection_ru.png": "/addPanelData/sections/stripContainerClientsSection_ru/stripContainerClientsSection_ru.v1.png",
    "addPanelData/sections/stripContainerClientsSection_sv/stripContainerClientsSection_sv.png": "/addPanelData/sections/stripContainerClientsSection_sv/stripContainerClientsSection_sv.v1.png",
    "addPanelData/sections/stripContainerClientsSection_tr/stripContainerClientsSection_tr.png": "/addPanelData/sections/stripContainerClientsSection_tr/stripContainerClientsSection_tr.v1.png",
    "addPanelData/sections/stripContainerContactSection_da/stripContainerContactSection_da.png": "/addPanelData/sections/stripContainerContactSection_da/stripContainerContactSection_da.png",
    "addPanelData/sections/stripContainerContactSection_de/stripContainerContactSection_de.png": "/addPanelData/sections/stripContainerContactSection_de/stripContainerContactSection_de.v2.png",
    "addPanelData/sections/stripContainerContactSection_en/stripContainerContactSection_en.png": "/addPanelData/sections/stripContainerContactSection_en/stripContainerContactSection_en.v5.png",
    "addPanelData/sections/stripContainerContactSection_es/stripContainerContactSection_es.png": "/addPanelData/sections/stripContainerContactSection_es/stripContainerContactSection_es.png",
    "addPanelData/sections/stripContainerContactSection_fr/stripContainerContactSection_fr.png": "/addPanelData/sections/stripContainerContactSection_fr/stripContainerContactSection_fr.v2.png",
    "addPanelData/sections/stripContainerContactSection_it/stripContainerContactSection_it.png": "/addPanelData/sections/stripContainerContactSection_it/stripContainerContactSection_it.png",
    "addPanelData/sections/stripContainerContactSection_ja/stripContainerContactSection_ja.png": "/addPanelData/sections/stripContainerContactSection_ja/stripContainerContactSection_ja.png",
    "addPanelData/sections/stripContainerContactSection_ko/stripContainerContactSection_ko.png": "/addPanelData/sections/stripContainerContactSection_ko/stripContainerContactSection_ko.png",
    "addPanelData/sections/stripContainerContactSection_nl/stripContainerContactSection_nl.png": "/addPanelData/sections/stripContainerContactSection_nl/stripContainerContactSection_nl.png",
    "addPanelData/sections/stripContainerContactSection_no/stripContainerContactSection_no.png": "/addPanelData/sections/stripContainerContactSection_no/stripContainerContactSection_no.png",
    "addPanelData/sections/stripContainerContactSection_pl/stripContainerContactSection_pl.png": "/addPanelData/sections/stripContainerContactSection_pl/stripContainerContactSection_pl.png",
    "addPanelData/sections/stripContainerContactSection_pt/stripContainerContactSection_pt.png": "/addPanelData/sections/stripContainerContactSection_pt/stripContainerContactSection_pt.v2.png",
    "addPanelData/sections/stripContainerContactSection_ru/stripContainerContactSection_ru.png": "/addPanelData/sections/stripContainerContactSection_ru/stripContainerContactSection_ru.v2.png",
    "addPanelData/sections/stripContainerContactSection_sv/stripContainerContactSection_sv.png": "/addPanelData/sections/stripContainerContactSection_sv/stripContainerContactSection_sv.png",
    "addPanelData/sections/stripContainerContactSection_tr/stripContainerContactSection_tr.png": "/addPanelData/sections/stripContainerContactSection_tr/stripContainerContactSection_tr.png",
    "addPanelData/sections/stripContainerParallaxSection_da/stripContainerParallaxSection_da.png": "/addPanelData/sections/stripContainerParallaxSection_da/stripContainerParallaxSection_da.v2.png",
    "addPanelData/sections/stripContainerParallaxSection_de/stripContainerParallaxSection_de.png": "/addPanelData/sections/stripContainerParallaxSection_de/stripContainerParallaxSection_de.v2.png",
    "addPanelData/sections/stripContainerParallaxSection_en/stripContainerParallaxSection_en.png": "/addPanelData/sections/stripContainerParallaxSection_en/stripContainerParallaxSection_en.v9.png",
    "addPanelData/sections/stripContainerParallaxSection_es/stripContainerParallaxSection_es.png": "/addPanelData/sections/stripContainerParallaxSection_es/stripContainerParallaxSection_es.png",
    "addPanelData/sections/stripContainerParallaxSection_fr/stripContainerParallaxSection_fr.png": "/addPanelData/sections/stripContainerParallaxSection_fr/stripContainerParallaxSection_fr.png",
    "addPanelData/sections/stripContainerParallaxSection_it/stripContainerParallaxSection_it.png": "/addPanelData/sections/stripContainerParallaxSection_it/stripContainerParallaxSection_it.png",
    "addPanelData/sections/stripContainerParallaxSection_ja/stripContainerParallaxSection_ja.png": "/addPanelData/sections/stripContainerParallaxSection_ja/stripContainerParallaxSection_ja.png",
    "addPanelData/sections/stripContainerParallaxSection_ko/stripContainerParallaxSection_ko.png": "/addPanelData/sections/stripContainerParallaxSection_ko/stripContainerParallaxSection_ko.png",
    "addPanelData/sections/stripContainerParallaxSection_nl/stripContainerParallaxSection_nl.png": "/addPanelData/sections/stripContainerParallaxSection_nl/stripContainerParallaxSection_nl.png",
    "addPanelData/sections/stripContainerParallaxSection_no/stripContainerParallaxSection_no.png": "/addPanelData/sections/stripContainerParallaxSection_no/stripContainerParallaxSection_no.v2.png",
    "addPanelData/sections/stripContainerParallaxSection_pl/stripContainerParallaxSection_pl.png": "/addPanelData/sections/stripContainerParallaxSection_pl/stripContainerParallaxSection_pl.png",
    "addPanelData/sections/stripContainerParallaxSection_pt/stripContainerParallaxSection_pt.png": "/addPanelData/sections/stripContainerParallaxSection_pt/stripContainerParallaxSection_pt.v2.png",
    "addPanelData/sections/stripContainerParallaxSection_ru/stripContainerParallaxSection_ru.png": "/addPanelData/sections/stripContainerParallaxSection_ru/stripContainerParallaxSection_ru.v2.png",
    "addPanelData/sections/stripContainerParallaxSection_sv/stripContainerParallaxSection_sv.png": "/addPanelData/sections/stripContainerParallaxSection_sv/stripContainerParallaxSection_sv.png",
    "addPanelData/sections/stripContainerParallaxSection_tr/stripContainerParallaxSection_tr.png": "/addPanelData/sections/stripContainerParallaxSection_tr/stripContainerParallaxSection_tr.png",
    "addPanelData/sections/stripContainerSection2_en/stripContainerSection2_en.png": "/addPanelData/sections/stripContainerSection2_en/stripContainerSection2_en.v3.png",
    "addPanelData/sections/stripContainerSection3_en/stripContainerSection3_en.png": "/addPanelData/sections/stripContainerSection3_en/stripContainerSection3_en.v1.png",
    "addPanelData/sections/stripContainerSection4_en/stripContainerSection4_en.png": "/addPanelData/sections/stripContainerSection4_en/stripContainerSection4_en.v1.png",
    "addPanelData/sections/stripContainerSection5_en/stripContainerSection5_en.png": "/addPanelData/sections/stripContainerSection5_en/stripContainerSection5_en.v1.png",
    "addPanelData/sections/stripContainerSection6_en/stripContainerSection6_en.png": "/addPanelData/sections/stripContainerSection6_en/stripContainerSection6_en.v1.png",
    "addPanelData/sections/stripContainerSection7_en/stripContainerSection7_en.png": "/addPanelData/sections/stripContainerSection7_en/stripContainerSection7_en.v1.png",
    "addPanelData/sections/stripContainerSection_en/stripContainerSection_en.png": "/addPanelData/sections/stripContainerSection_en/stripContainerSection_en.v1.png",
    "addPanelData/sections/stripContainerServicesSection_da/stripContainerServicesSection_da.png": "/addPanelData/sections/stripContainerServicesSection_da/stripContainerServicesSection_da.png",
    "addPanelData/sections/stripContainerServicesSection_de/stripContainerServicesSection_de.png": "/addPanelData/sections/stripContainerServicesSection_de/stripContainerServicesSection_de.v2.png",
    "addPanelData/sections/stripContainerServicesSection_en/stripContainerServicesSection_en.png": "/addPanelData/sections/stripContainerServicesSection_en/stripContainerServicesSection_en.v7.png",
    "addPanelData/sections/stripContainerServicesSection_es/stripContainerServicesSection_es.png": "/addPanelData/sections/stripContainerServicesSection_es/stripContainerServicesSection_es.png",
    "addPanelData/sections/stripContainerServicesSection_fr/stripContainerServicesSection_fr.png": "/addPanelData/sections/stripContainerServicesSection_fr/stripContainerServicesSection_fr.png",
    "addPanelData/sections/stripContainerServicesSection_it/stripContainerServicesSection_it.png": "/addPanelData/sections/stripContainerServicesSection_it/stripContainerServicesSection_it.png",
    "addPanelData/sections/stripContainerServicesSection_ja/stripContainerServicesSection_ja.png": "/addPanelData/sections/stripContainerServicesSection_ja/stripContainerServicesSection_ja.png",
    "addPanelData/sections/stripContainerServicesSection_ko/stripContainerServicesSection_ko.png": "/addPanelData/sections/stripContainerServicesSection_ko/stripContainerServicesSection_ko.png",
    "addPanelData/sections/stripContainerServicesSection_nl/stripContainerServicesSection_nl.png": "/addPanelData/sections/stripContainerServicesSection_nl/stripContainerServicesSection_nl.png",
    "addPanelData/sections/stripContainerServicesSection_no/stripContainerServicesSection_no.png": "/addPanelData/sections/stripContainerServicesSection_no/stripContainerServicesSection_no.png",
    "addPanelData/sections/stripContainerServicesSection_pl/stripContainerServicesSection_pl.png": "/addPanelData/sections/stripContainerServicesSection_pl/stripContainerServicesSection_pl.png",
    "addPanelData/sections/stripContainerServicesSection_pt/stripContainerServicesSection_pt.png": "/addPanelData/sections/stripContainerServicesSection_pt/stripContainerServicesSection_pt.png",
    "addPanelData/sections/stripContainerServicesSection_ru/stripContainerServicesSection_ru.png": "/addPanelData/sections/stripContainerServicesSection_ru/stripContainerServicesSection_ru.png",
    "addPanelData/sections/stripContainerServicesSection_sv/stripContainerServicesSection_sv.png": "/addPanelData/sections/stripContainerServicesSection_sv/stripContainerServicesSection_sv.png",
    "addPanelData/sections/stripContainerServicesSection_tr/stripContainerServicesSection_tr.png": "/addPanelData/sections/stripContainerServicesSection_tr/stripContainerServicesSection_tr.png",
    "addPanelData/sections/stripContainerSubscribeSection_da/stripContainerSubscribeSection_da.png": "/addPanelData/sections/stripContainerSubscribeSection_da/stripContainerSubscribeSection_da.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_de/stripContainerSubscribeSection_de.png": "/addPanelData/sections/stripContainerSubscribeSection_de/stripContainerSubscribeSection_de.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_en/stripContainerSubscribeSection_en.png": "/addPanelData/sections/stripContainerSubscribeSection_en/stripContainerSubscribeSection_en.v4.png",
    "addPanelData/sections/stripContainerSubscribeSection_es/stripContainerSubscribeSection_es.png": "/addPanelData/sections/stripContainerSubscribeSection_es/stripContainerSubscribeSection_es.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_fr/stripContainerSubscribeSection_fr.png": "/addPanelData/sections/stripContainerSubscribeSection_fr/stripContainerSubscribeSection_fr.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_it/stripContainerSubscribeSection_it.png": "/addPanelData/sections/stripContainerSubscribeSection_it/stripContainerSubscribeSection_it.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_ja/stripContainerSubscribeSection_ja.png": "/addPanelData/sections/stripContainerSubscribeSection_ja/stripContainerSubscribeSection_ja.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_ko/stripContainerSubscribeSection_ko.png": "/addPanelData/sections/stripContainerSubscribeSection_ko/stripContainerSubscribeSection_ko.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_nl/stripContainerSubscribeSection_nl.png": "/addPanelData/sections/stripContainerSubscribeSection_nl/stripContainerSubscribeSection_nl.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_no/stripContainerSubscribeSection_no.png": "/addPanelData/sections/stripContainerSubscribeSection_no/stripContainerSubscribeSection_no.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_pl/stripContainerSubscribeSection_pl.png": "/addPanelData/sections/stripContainerSubscribeSection_pl/stripContainerSubscribeSection_pl.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_pt/stripContainerSubscribeSection_pt.png": "/addPanelData/sections/stripContainerSubscribeSection_pt/stripContainerSubscribeSection_pt.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_ru/stripContainerSubscribeSection_ru.png": "/addPanelData/sections/stripContainerSubscribeSection_ru/stripContainerSubscribeSection_ru.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_sv/stripContainerSubscribeSection_sv.png": "/addPanelData/sections/stripContainerSubscribeSection_sv/stripContainerSubscribeSection_sv.v1.png",
    "addPanelData/sections/stripContainerSubscribeSection_tr/stripContainerSubscribeSection_tr.png": "/addPanelData/sections/stripContainerSubscribeSection_tr/stripContainerSubscribeSection_tr.v1.png",
    "addPanelData/sections/stripContainerTeamSection_da/stripContainerTeamSection_da.png": "/addPanelData/sections/stripContainerTeamSection_da/stripContainerTeamSection_da.png",
    "addPanelData/sections/stripContainerTeamSection_de/stripContainerTeamSection_de.png": "/addPanelData/sections/stripContainerTeamSection_de/stripContainerTeamSection_de.png",
    "addPanelData/sections/stripContainerTeamSection_en/stripContainerTeamSection_en.png": "/addPanelData/sections/stripContainerTeamSection_en/stripContainerTeamSection_en.v3.png",
    "addPanelData/sections/stripContainerTeamSection_es/stripContainerTeamSection_es.png": "/addPanelData/sections/stripContainerTeamSection_es/stripContainerTeamSection_es.png",
    "addPanelData/sections/stripContainerTeamSection_fr/stripContainerTeamSection_fr.png": "/addPanelData/sections/stripContainerTeamSection_fr/stripContainerTeamSection_fr.png",
    "addPanelData/sections/stripContainerTeamSection_it/stripContainerTeamSection_it.png": "/addPanelData/sections/stripContainerTeamSection_it/stripContainerTeamSection_it.png",
    "addPanelData/sections/stripContainerTeamSection_ja/stripContainerTeamSection_ja.png": "/addPanelData/sections/stripContainerTeamSection_ja/stripContainerTeamSection_ja.png",
    "addPanelData/sections/stripContainerTeamSection_ko/stripContainerTeamSection_ko.png": "/addPanelData/sections/stripContainerTeamSection_ko/stripContainerTeamSection_ko.png",
    "addPanelData/sections/stripContainerTeamSection_nl/stripContainerTeamSection_nl.png": "/addPanelData/sections/stripContainerTeamSection_nl/stripContainerTeamSection_nl.png",
    "addPanelData/sections/stripContainerTeamSection_no/stripContainerTeamSection_no.png": "/addPanelData/sections/stripContainerTeamSection_no/stripContainerTeamSection_no.png",
    "addPanelData/sections/stripContainerTeamSection_pl/stripContainerTeamSection_pl.png": "/addPanelData/sections/stripContainerTeamSection_pl/stripContainerTeamSection_pl.png",
    "addPanelData/sections/stripContainerTeamSection_pt/stripContainerTeamSection_pt.png": "/addPanelData/sections/stripContainerTeamSection_pt/stripContainerTeamSection_pt.v2.png",
    "addPanelData/sections/stripContainerTeamSection_ru/stripContainerTeamSection_ru.png": "/addPanelData/sections/stripContainerTeamSection_ru/stripContainerTeamSection_ru.png",
    "addPanelData/sections/stripContainerTeamSection_sv/stripContainerTeamSection_sv.png": "/addPanelData/sections/stripContainerTeamSection_sv/stripContainerTeamSection_sv.png",
    "addPanelData/sections/stripContainerTeamSection_tr/stripContainerTeamSection_tr.png": "/addPanelData/sections/stripContainerTeamSection_tr/stripContainerTeamSection_tr.png",
    "addPanelData/sections/stripContainerTestimonialsSection_da/stripContainerTestimonialsSection_da.png": "/addPanelData/sections/stripContainerTestimonialsSection_da/stripContainerTestimonialsSection_da.v2.png",
    "addPanelData/sections/stripContainerTestimonialsSection_de/stripContainerTestimonialsSection_de.png": "/addPanelData/sections/stripContainerTestimonialsSection_de/stripContainerTestimonialsSection_de.png",
    "addPanelData/sections/stripContainerTestimonialsSection_en/stripContainerTestimonialsSection_en.png": "/addPanelData/sections/stripContainerTestimonialsSection_en/stripContainerTestimonialsSection_en.v2.png",
    "addPanelData/sections/stripContainerTestimonialsSection_es/stripContainerTestimonialsSection_es.png": "/addPanelData/sections/stripContainerTestimonialsSection_es/stripContainerTestimonialsSection_es.png",
    "addPanelData/sections/stripContainerTestimonialsSection_fr/stripContainerTestimonialsSection_fr.png": "/addPanelData/sections/stripContainerTestimonialsSection_fr/stripContainerTestimonialsSection_fr.png",
    "addPanelData/sections/stripContainerTestimonialsSection_it/stripContainerTestimonialsSection_it.png": "/addPanelData/sections/stripContainerTestimonialsSection_it/stripContainerTestimonialsSection_it.png",
    "addPanelData/sections/stripContainerTestimonialsSection_ja/stripContainerTestimonialsSection_ja.png": "/addPanelData/sections/stripContainerTestimonialsSection_ja/stripContainerTestimonialsSection_ja.png",
    "addPanelData/sections/stripContainerTestimonialsSection_ko/stripContainerTestimonialsSection_ko.png": "/addPanelData/sections/stripContainerTestimonialsSection_ko/stripContainerTestimonialsSection_ko.png",
    "addPanelData/sections/stripContainerTestimonialsSection_nl/stripContainerTestimonialsSection_nl.png": "/addPanelData/sections/stripContainerTestimonialsSection_nl/stripContainerTestimonialsSection_nl.png",
    "addPanelData/sections/stripContainerTestimonialsSection_no/stripContainerTestimonialsSection_no.png": "/addPanelData/sections/stripContainerTestimonialsSection_no/stripContainerTestimonialsSection_no.png",
    "addPanelData/sections/stripContainerTestimonialsSection_pl/stripContainerTestimonialsSection_pl.png": "/addPanelData/sections/stripContainerTestimonialsSection_pl/stripContainerTestimonialsSection_pl.png",
    "addPanelData/sections/stripContainerTestimonialsSection_pt/stripContainerTestimonialsSection_pt.png": "/addPanelData/sections/stripContainerTestimonialsSection_pt/stripContainerTestimonialsSection_pt.png",
    "addPanelData/sections/stripContainerTestimonialsSection_ru/stripContainerTestimonialsSection_ru.png": "/addPanelData/sections/stripContainerTestimonialsSection_ru/stripContainerTestimonialsSection_ru.png",
    "addPanelData/sections/stripContainerTestimonialsSection_sv/stripContainerTestimonialsSection_sv.png": "/addPanelData/sections/stripContainerTestimonialsSection_sv/stripContainerTestimonialsSection_sv.png",
    "addPanelData/sections/stripContainerTestimonialsSection_tr/stripContainerTestimonialsSection_tr.png": "/addPanelData/sections/stripContainerTestimonialsSection_tr/stripContainerTestimonialsSection_tr.png",
    "addPanelData/sections/stripContainerWelcomeSection_da/stripContainerWelcomeSection_da.png": "/addPanelData/sections/stripContainerWelcomeSection_da/stripContainerWelcomeSection_da.v2.png",
    "addPanelData/sections/stripContainerWelcomeSection_de/stripContainerWelcomeSection_de.png": "/addPanelData/sections/stripContainerWelcomeSection_de/stripContainerWelcomeSection_de.v2.png",
    "addPanelData/sections/stripContainerWelcomeSection_en/stripContainerWelcomeSection_en.png": "/addPanelData/sections/stripContainerWelcomeSection_en/stripContainerWelcomeSection_en.v7.png",
    "addPanelData/sections/stripContainerWelcomeSection_es/stripContainerWelcomeSection_es.png": "/addPanelData/sections/stripContainerWelcomeSection_es/stripContainerWelcomeSection_es.png",
    "addPanelData/sections/stripContainerWelcomeSection_fr/stripContainerWelcomeSection_fr.png": "/addPanelData/sections/stripContainerWelcomeSection_fr/stripContainerWelcomeSection_fr.png",
    "addPanelData/sections/stripContainerWelcomeSection_it/stripContainerWelcomeSection_it.png": "/addPanelData/sections/stripContainerWelcomeSection_it/stripContainerWelcomeSection_it.png",
    "addPanelData/sections/stripContainerWelcomeSection_ja/stripContainerWelcomeSection_ja.png": "/addPanelData/sections/stripContainerWelcomeSection_ja/stripContainerWelcomeSection_ja.png",
    "addPanelData/sections/stripContainerWelcomeSection_ko/stripContainerWelcomeSection_ko.png": "/addPanelData/sections/stripContainerWelcomeSection_ko/stripContainerWelcomeSection_ko.png",
    "addPanelData/sections/stripContainerWelcomeSection_nl/stripContainerWelcomeSection_nl.png": "/addPanelData/sections/stripContainerWelcomeSection_nl/stripContainerWelcomeSection_nl.png",
    "addPanelData/sections/stripContainerWelcomeSection_no/stripContainerWelcomeSection_no.png": "/addPanelData/sections/stripContainerWelcomeSection_no/stripContainerWelcomeSection_no.v2.png",
    "addPanelData/sections/stripContainerWelcomeSection_pl/stripContainerWelcomeSection_pl.png": "/addPanelData/sections/stripContainerWelcomeSection_pl/stripContainerWelcomeSection_pl.png",
    "addPanelData/sections/stripContainerWelcomeSection_pt/stripContainerWelcomeSection_pt.png": "/addPanelData/sections/stripContainerWelcomeSection_pt/stripContainerWelcomeSection_pt.v2.png",
    "addPanelData/sections/stripContainerWelcomeSection_ru/stripContainerWelcomeSection_ru.png": "/addPanelData/sections/stripContainerWelcomeSection_ru/stripContainerWelcomeSection_ru.v2.png",
    "addPanelData/sections/stripContainerWelcomeSection_sv/stripContainerWelcomeSection_sv.png": "/addPanelData/sections/stripContainerWelcomeSection_sv/stripContainerWelcomeSection_sv.png",
    "addPanelData/sections/stripContainerWelcomeSection_tr/stripContainerWelcomeSection_tr.png": "/addPanelData/sections/stripContainerWelcomeSection_tr/stripContainerWelcomeSection_tr.png",
    "addPanelData/sections/stripSlideShowSection_en/stripSlideShowSection_en.png": "/addPanelData/sections/stripSlideShowSection_en/stripSlideShowSection_en.v1.png",
    "addPanelData/sections/stripsSection_en/imageStripsSection_en.png": "/addPanelData/sections/stripsSection_en/imageStripsSection_en.png",
    "addPanelData/sections/stripsSection_en/stripsSection_en.png": "/addPanelData/sections/stripsSection_en/stripsSection_en.v5.png",
    "addPanelData/sections/stylableButtonSection_en/hover_en/stylableButtonSection_hover_en.png": "/addPanelData/sections/stylableButtonSection_en/hover_en/stylableButtonSection_hover_en.v6.png",
    "addPanelData/sections/stylableButtonSection_en/stylableButtonSection_en (1).png": "/addPanelData/sections/stylableButtonSection_en/stylableButtonSection_en (1).png",
    "addPanelData/sections/stylableButtonSection_en/stylableButtonSection_en.png": "/addPanelData/sections/stylableButtonSection_en/stylableButtonSection_en.v7.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_cs/hover/stylableButtonSection_hover_cs.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_cs/hover/stylableButtonSection_hover_cs.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_cs/stylableButtonSection_cs.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_cs/stylableButtonSection_cs.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_da/hover/stylableButtonSection_hover_da.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_da/hover/stylableButtonSection_hover_da.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_da/stylableButtonSection_da.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_da/stylableButtonSection_da.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_de/hover/stylableButtonSection_hover_de.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_de/hover/stylableButtonSection_hover_de.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_de/stylableButtonSection_de.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_de/stylableButtonSection_de.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_en/hover/stylableButtonSection_hover_en.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_en/hover/stylableButtonSection_hover_en.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_en/stylableButtonSection_en.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_en/stylableButtonSection_en.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_es/hover/stylableButtonSection_hover_es.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_es/hover/stylableButtonSection_hover_es.v3.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_es/stylableButtonSection_es.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_es/stylableButtonSection_es.v3.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_fr/hover/stylableButtonSection_hover_fr.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_fr/hover/stylableButtonSection_hover_fr.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_fr/stylableButtonSection_fr.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_fr/stylableButtonSection_fr.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_hi/hover/stylableButtonSection_hover_hi.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_hi/hover/stylableButtonSection_hover_hi.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_hi/stylableButtonSection_hi.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_hi/stylableButtonSection_hi.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_it/hover/stylableButtonSection_hover_it.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_it/hover/stylableButtonSection_hover_it.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_it/stylableButtonSection_it.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_it/stylableButtonSection_it.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ja/hover/stylableButtonSection_hover_ja.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ja/hover/stylableButtonSection_hover_ja.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ja/stylableButtonSection_ja.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ja/stylableButtonSection_ja.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ko/hover/stylableButtonSection_hover_ko.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ko/hover/stylableButtonSection_hover_ko.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ko/stylableButtonSection_ko.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ko/stylableButtonSection_ko.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_nl/hover/stylableButtonSection_hover_nl.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_nl/hover/stylableButtonSection_hover_nl.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_nl/stylableButtonSection_nl.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_nl/stylableButtonSection_nl.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_no/hover/stylableButtonSection_hover_no.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_no/hover/stylableButtonSection_hover_no.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_no/stylableButtonSection_no.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_no/stylableButtonSection_no.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_pl/hover/stylableButtonSection_hover_pl.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_pl/hover/stylableButtonSection_hover_pl.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_pl/stylableButtonSection_pl.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_pl/stylableButtonSection_pl.v2.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_pt/hover/stylableButtonSection_hover_pt.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_pt/hover/stylableButtonSection_hover_pt.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_pt/stylableButtonSection_pt.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_pt/stylableButtonSection_pt.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ru/hover/stylableButtonSection_hover_ru.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ru/hover/stylableButtonSection_hover_ru.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_ru/stylableButtonSection_ru.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_ru/stylableButtonSection_ru.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_sv/hover/stylableButtonSection_hover_sv.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_sv/hover/stylableButtonSection_hover_sv.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_sv/stylableButtonSection_sv.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_sv/stylableButtonSection_sv.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_th/hover/stylableButtonSection_hover_th.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_th/hover/stylableButtonSection_hover_th.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_th/stylableButtonSection_th.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_th/stylableButtonSection_th.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_tr/hover/stylableButtonSection_hover_tr.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_tr/hover/stylableButtonSection_hover_tr.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_tr/stylableButtonSection_tr.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_tr/stylableButtonSection_tr.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_uk/hover/stylableButtonSection_hover_uk.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_uk/hover/stylableButtonSection_hover_uk.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_uk/stylableButtonSection_uk.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_uk/stylableButtonSection_uk.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_zh/hover/stylableButtonSection_hover_zh.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_zh/hover/stylableButtonSection_hover_zh.v1.png",
    "addPanelData/sections/stylableButtonSections/stylableButtonSection_zh/stylableButtonSection_zh.png": "/addPanelData/sections/stylableButtonSections/stylableButtonSection_zh/stylableButtonSection_zh.v1.png",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en-@2.jpg": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en-@2.v6.jpg",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.jpg": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.v6.jpg",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.new.jpg": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.new.jpg",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.png": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_en.v4.png",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_redesign_en-@2x.png": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_redesign_en-@2x.v2.png",
    "addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_redesign_en.png": "/addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_en/stylableHorizontalMenuSection_redesign_en.v2.png",
    "addPanelData/sections/subscribeFormsSection_en/subscribeFormsSection_en.png": "/addPanelData/sections/subscribeFormsSection_en/subscribeFormsSection_en.v4.png",
    "addPanelData/sections/tableReservationsHorizontalReservationForm_en/tableReservationsHorizontalReservationForm.png": "/addPanelData/sections/tableReservationsHorizontalReservationForm_en/tableReservationsHorizontalReservationForm.png",
    "addPanelData/sections/tableReservationsVerticalReservationForm_en/tableReservationsVerticalReservationForm.png": "/addPanelData/sections/tableReservationsVerticalReservationForm_en/tableReservationsVerticalReservationForm.png",
    "addPanelData/sections/tableReservationsWelcomeSection_en/tableReservationsWelcomeSection.png": "/addPanelData/sections/tableReservationsWelcomeSection_en/tableReservationsWelcomeSection.png",
    "addPanelData/sections/tabsSections/tabs-@2x.jpg": "/addPanelData/sections/tabsSections/tabs-@2x.v4.jpg",
    "addPanelData/sections/tabsSections/tabs-hover-@2x.jpg": "/addPanelData/sections/tabsSections/tabs-hover-@2x.v4.jpg",
    "addPanelData/sections/tabsSections/tabs-hover.jpg": "/addPanelData/sections/tabsSections/tabs-hover.v4.jpg",
    "addPanelData/sections/tabsSections/tabs.jpg": "/addPanelData/sections/tabsSections/tabs.v4.jpg",
    "addPanelData/sections/teamMemberSection_en/teamMemberSection_en.png": "/addPanelData/sections/teamMemberSection_en/teamMemberSection_en.v3.png",
    "addPanelData/sections/textAreaSection_en/textAreaSection_en.png": "/addPanelData/sections/textAreaSection_en/textAreaSection_en.v4.png",
    "addPanelData/sections/textButtonsSection_da/hover_da/textButtonsSection_hover_da.png": "/addPanelData/sections/textButtonsSection_da/hover_da/textButtonsSection_hover_da.v3.png",
    "addPanelData/sections/textButtonsSection_da/textButtonsSection_da.png": "/addPanelData/sections/textButtonsSection_da/textButtonsSection_da.v1.png",
    "addPanelData/sections/textButtonsSection_de/hover_de/textButtonsSection_hover_de.png": "/addPanelData/sections/textButtonsSection_de/hover_de/textButtonsSection_hover_de.v3.png",
    "addPanelData/sections/textButtonsSection_de/textButtonsSection_de.png": "/addPanelData/sections/textButtonsSection_de/textButtonsSection_de.v3.png",
    "addPanelData/sections/textButtonsSection_en/hover_en/textButtonsSection_hover_en.png": "/addPanelData/sections/textButtonsSection_en/hover_en/textButtonsSection_hover_en.v7.png",
    "addPanelData/sections/textButtonsSection_en/hover_en/textButtonsSection_hover_redesign_en.png": "/addPanelData/sections/textButtonsSection_en/hover_en/textButtonsSection_hover_redesign_en.png",
    "addPanelData/sections/textButtonsSection_en/textButtonsSection_en.png": "/addPanelData/sections/textButtonsSection_en/textButtonsSection_en.v6.png",
    "addPanelData/sections/textButtonsSection_en/textButtonsSection_redesign_en.png": "/addPanelData/sections/textButtonsSection_en/textButtonsSection_redesign_en.png",
    "addPanelData/sections/textButtonsSection_es/hover_es/textButtonsSection_hover_es.png": "/addPanelData/sections/textButtonsSection_es/hover_es/textButtonsSection_hover_es.v6.png",
    "addPanelData/sections/textButtonsSection_es/textButtonsSection_es.png": "/addPanelData/sections/textButtonsSection_es/textButtonsSection_es.v1.png",
    "addPanelData/sections/textButtonsSection_fr/hover_fr/textButtonsSection_hover_fr.png": "/addPanelData/sections/textButtonsSection_fr/hover_fr/textButtonsSection_hover_fr.v5.png",
    "addPanelData/sections/textButtonsSection_fr/textButtonsSection_fr.png": "/addPanelData/sections/textButtonsSection_fr/textButtonsSection_fr.v3.png",
    "addPanelData/sections/textButtonsSection_it/hover_it/textButtonsSection_hover_it.png": "/addPanelData/sections/textButtonsSection_it/hover_it/textButtonsSection_hover_it.v3.png",
    "addPanelData/sections/textButtonsSection_it/textButtonsSection_it.png": "/addPanelData/sections/textButtonsSection_it/textButtonsSection_it.v1.png",
    "addPanelData/sections/textButtonsSection_ja/hover_ja/textButtonsSection_hover_ja.png": "/addPanelData/sections/textButtonsSection_ja/hover_ja/textButtonsSection_hover_ja.v3.png",
    "addPanelData/sections/textButtonsSection_ja/textButtonsSection_ja.png": "/addPanelData/sections/textButtonsSection_ja/textButtonsSection_ja.v1.png",
    "addPanelData/sections/textButtonsSection_ko/hover_ko/textButtonsSection_hover_ko.png": "/addPanelData/sections/textButtonsSection_ko/hover_ko/textButtonsSection_hover_ko.v4.png",
    "addPanelData/sections/textButtonsSection_ko/textButtonsSection_ko.png": "/addPanelData/sections/textButtonsSection_ko/textButtonsSection_ko.v3.png",
    "addPanelData/sections/textButtonsSection_nl/hover_nl/textButtonsSection_hover_nl.png": "/addPanelData/sections/textButtonsSection_nl/hover_nl/textButtonsSection_hover_nl.v4.png",
    "addPanelData/sections/textButtonsSection_nl/textButtonsSection_nl.png": "/addPanelData/sections/textButtonsSection_nl/textButtonsSection_nl.v3.png",
    "addPanelData/sections/textButtonsSection_no/hover_no/textButtonsSection_hover_no.png": "/addPanelData/sections/textButtonsSection_no/hover_no/textButtonsSection_hover_no.v3.png",
    "addPanelData/sections/textButtonsSection_no/textButtonsSection_no.png": "/addPanelData/sections/textButtonsSection_no/textButtonsSection_no.v1.png",
    "addPanelData/sections/textButtonsSection_pl/hover_pl/textButtonsSection_hover_pl.png": "/addPanelData/sections/textButtonsSection_pl/hover_pl/textButtonsSection_hover_pl.v1.png",
    "addPanelData/sections/textButtonsSection_pl/textButtonsSection_pl.png": "/addPanelData/sections/textButtonsSection_pl/textButtonsSection_pl.v1.png",
    "addPanelData/sections/textButtonsSection_pt/hover_pt/textButtonsSection_hover_pt.png": "/addPanelData/sections/textButtonsSection_pt/hover_pt/textButtonsSection_hover_pt.v4.png",
    "addPanelData/sections/textButtonsSection_pt/textButtonsSection_pt.png": "/addPanelData/sections/textButtonsSection_pt/textButtonsSection_pt.v1.png",
    "addPanelData/sections/textButtonsSection_ru/hover_ru/textButtonsSection_hover_ru.png": "/addPanelData/sections/textButtonsSection_ru/hover_ru/textButtonsSection_hover_ru.v4.png",
    "addPanelData/sections/textButtonsSection_ru/textButtonsSection_ru.png": "/addPanelData/sections/textButtonsSection_ru/textButtonsSection_ru.v6.png",
    "addPanelData/sections/textButtonsSection_sv/hover_sv/textButtonsSection_hover_sv.png": "/addPanelData/sections/textButtonsSection_sv/hover_sv/textButtonsSection_hover_sv.v4.png",
    "addPanelData/sections/textButtonsSection_sv/textButtonsSection_sv.png": "/addPanelData/sections/textButtonsSection_sv/textButtonsSection_sv.v3.png",
    "addPanelData/sections/textButtonsSection_tr/hover_tr/textButtonsSection_hover_tr.png": "/addPanelData/sections/textButtonsSection_tr/hover_tr/textButtonsSection_hover_tr.v3.png",
    "addPanelData/sections/textButtonsSection_tr/textButtonsSection_tr.png": "/addPanelData/sections/textButtonsSection_tr/textButtonsSection_tr.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_cs.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_cs.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_da.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_da.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_de.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_de.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_en.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_en.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_es.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_es.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_fr.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_fr.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hi.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hi.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_cs.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_cs.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_da.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_da.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_de.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_de.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_en.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_en.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_es.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_es.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_fr.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_fr.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_hi.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_hi.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_it.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_it.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ja.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ja.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ko.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ko.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_nl.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_nl.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_no.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_no.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_pl.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_pl.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_pt.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_pt.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ru.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_ru.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_sv.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_sv.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_tr.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_tr.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_it.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_it.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ja.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ja.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ko.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ko.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_nl.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_nl.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_no.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_no.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_pl.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_pl.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_pt.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_pt.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ru.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_ru.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_sv.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_sv.v1.png",
    "addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_tr.png": "/addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_tr.v1.png",
    "addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanel-@1x.jpg": "/addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanel-@1x.jpg",
    "addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanel-@2x.jpg": "/addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanel-@2x.jpg",
    "addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanelAnim-@2x.webp": "/addPanelData/sections/TextEffects/TextMarquee/textMarqueeAddPanelAnim-@2x.webp",
    "addPanelData/sections/TextEffects/textMask-@2x.png": "/addPanelData/sections/TextEffects/textMask-@2x.v2.png",
    "addPanelData/sections/TextEffects/textMask.png": "/addPanelData/sections/TextEffects/textMask.v4.png",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-1-@2x.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-1-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-1.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-1.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-11-@2x.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-11-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-11.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-11.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-3-@2x.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-3-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-3.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-3.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-7-@2x.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-7-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-7.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-7.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-9-@2x.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-9-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/textMask-thumb-9.mp4": "/addPanelData/sections/TextEffects/videos/textMask-thumb-9.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-1-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-1-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-1.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-1.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-11-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-11-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-11.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-11.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-3-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-3-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-3.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-3.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-7-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-7-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-7.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-7.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-9-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-9-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v1/textMask-thumb-9.mp4": "/addPanelData/sections/TextEffects/videos/v1/textMask-thumb-9.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-1-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-1-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-1.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-1.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-11-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-11-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-11.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-11.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-3-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-3-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-3.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-3.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-7-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-7-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-7.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-7.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-9-@2x.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-9-@2x.mp4",
    "addPanelData/sections/TextEffects/videos/v2/textMask-thumb-9.mp4": "/addPanelData/sections/TextEffects/videos/v2/textMask-thumb-9.mp4",
    "addPanelData/sections/textInputSection_en/textInputSection_en.png": "/addPanelData/sections/textInputSection_en/textInputSection_en.v4.png",
    "addPanelData/sections/tinyMenuStyleSection_en/tinyMenuStyleSection_agadir_en.png": "/addPanelData/sections/tinyMenuStyleSection_en/tinyMenuStyleSection_agadir_en.v1.png",
    "addPanelData/sections/tinyMenuStyleSection_en/tinyMenuStyleSection_en.png": "/addPanelData/sections/tinyMenuStyleSection_en/tinyMenuStyleSection_en.v6.png",
    "addPanelData/sections/titlesSection_da/titlesSection_da.png": "/addPanelData/sections/titlesSection_da/titlesSection_da.v1.png",
    "addPanelData/sections/titlesSection_de/titlesSection_de.png": "/addPanelData/sections/titlesSection_de/titlesSection_de.v1.png",
    "addPanelData/sections/titlesSection_en/titlesSection_en.png": "/addPanelData/sections/titlesSection_en/titlesSection_en.v8.png",
    "addPanelData/sections/titlesSection_es/titlesSection_es.png": "/addPanelData/sections/titlesSection_es/titlesSection_es.v3.png",
    "addPanelData/sections/titlesSection_fr/titlesSection_fr.png": "/addPanelData/sections/titlesSection_fr/titlesSection_fr.v1.png",
    "addPanelData/sections/titlesSection_it/titlesSection_it.png": "/addPanelData/sections/titlesSection_it/titlesSection_it.v1.png",
    "addPanelData/sections/titlesSection_ja/titlesSection_ja.png": "/addPanelData/sections/titlesSection_ja/titlesSection_ja.v3.png",
    "addPanelData/sections/titlesSection_ko/titlesSection_ko.png": "/addPanelData/sections/titlesSection_ko/titlesSection_ko.v3.png",
    "addPanelData/sections/titlesSection_nl/titlesSection_nl.png": "/addPanelData/sections/titlesSection_nl/titlesSection_nl.v3.png",
    "addPanelData/sections/titlesSection_no/titlesSection_no.png": "/addPanelData/sections/titlesSection_no/titlesSection_no.v1.png",
    "addPanelData/sections/titlesSection_pl/titlesSection_pl.png": "/addPanelData/sections/titlesSection_pl/titlesSection_pl.v1.png",
    "addPanelData/sections/titlesSection_pt/titlesSection_pt.png": "/addPanelData/sections/titlesSection_pt/titlesSection_pt.v1.png",
    "addPanelData/sections/titlesSection_ru/titlesSection_ru.png": "/addPanelData/sections/titlesSection_ru/titlesSection_ru.v4.png",
    "addPanelData/sections/titlesSection_sv/titlesSection_sv.png": "/addPanelData/sections/titlesSection_sv/titlesSection_sv.v1.png",
    "addPanelData/sections/titlesSection_tr/titlesSection_tr.png": "/addPanelData/sections/titlesSection_tr/titlesSection_tr.v1.png",
    "addPanelData/sections/twitterSection_da/twitterSection_da.png": "/addPanelData/sections/twitterSection_da/twitterSection_da.v3.png",
    "addPanelData/sections/twitterSection_de/twitterSection_de.png": "/addPanelData/sections/twitterSection_de/twitterSection_de.v3.png",
    "addPanelData/sections/twitterSection_en/twitterSection_en.png": "/addPanelData/sections/twitterSection_en/twitterSection_en.v6.png",
    "addPanelData/sections/twitterSection_es/twitterSection_es.png": "/addPanelData/sections/twitterSection_es/twitterSection_es.v3.png",
    "addPanelData/sections/twitterSection_fr/twitterSection_fr.png": "/addPanelData/sections/twitterSection_fr/twitterSection_fr.v3.png",
    "addPanelData/sections/twitterSection_it/twitterSection_it.png": "/addPanelData/sections/twitterSection_it/twitterSection_it.v3.png",
    "addPanelData/sections/twitterSection_ja/twitterSection_ja.png": "/addPanelData/sections/twitterSection_ja/twitterSection_ja.v3.png",
    "addPanelData/sections/twitterSection_ko/twitterSection_ko.png": "/addPanelData/sections/twitterSection_ko/twitterSection_ko.v3.png",
    "addPanelData/sections/twitterSection_nl/twitterSection_nl.png": "/addPanelData/sections/twitterSection_nl/twitterSection_nl.v3.png",
    "addPanelData/sections/twitterSection_no/twitterSection_no.png": "/addPanelData/sections/twitterSection_no/twitterSection_no.v3.png",
    "addPanelData/sections/twitterSection_pl/twitterSection_pl.png": "/addPanelData/sections/twitterSection_pl/twitterSection_pl.v3.png",
    "addPanelData/sections/twitterSection_pt/twitterSection_pt.png": "/addPanelData/sections/twitterSection_pt/twitterSection_pt.v3.png",
    "addPanelData/sections/twitterSection_ru/twitterSection_ru.png": "/addPanelData/sections/twitterSection_ru/twitterSection_ru.v3.png",
    "addPanelData/sections/twitterSection_sv/twitterSection_sv.png": "/addPanelData/sections/twitterSection_sv/twitterSection_sv.v3.png",
    "addPanelData/sections/twitterSection_tr/twitterSection_tr.png": "/addPanelData/sections/twitterSection_tr/twitterSection_tr.v3.png",
    "addPanelData/sections/vectorArt/animalsSection-@2x.png": "/addPanelData/sections/vectorArt/animalsSection-@2x.v2.png",
    "addPanelData/sections/vectorArt/animalsSection.png": "/addPanelData/sections/vectorArt/animalsSection.v2.png",
    "addPanelData/sections/vectorArt/charactersSection-@2x.png": "/addPanelData/sections/vectorArt/charactersSection-@2x.v2.png",
    "addPanelData/sections/vectorArt/charactersSection.png": "/addPanelData/sections/vectorArt/charactersSection.v2.png",
    "addPanelData/sections/vectorArt/christmas.png": "/addPanelData/sections/vectorArt/christmas.v1.png",
    "addPanelData/sections/vectorArt/christmasFeatured.png": "/addPanelData/sections/vectorArt/christmasFeatured.v1.png",
    "addPanelData/sections/vectorArt/christmasFeaturedRetina.png": "/addPanelData/sections/vectorArt/christmasFeaturedRetina.v1.png",
    "addPanelData/sections/vectorArt/featuredSection-@2x.png": "/addPanelData/sections/vectorArt/featuredSection-@2x.v2.png",
    "addPanelData/sections/vectorArt/featuredSection-winter-2023-@2x.png": "/addPanelData/sections/vectorArt/featuredSection-winter-2023-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection-winter-2023.png": "/addPanelData/sections/vectorArt/featuredSection-winter-2023.png",
    "addPanelData/sections/vectorArt/featuredSection.png": "/addPanelData/sections/vectorArt/featuredSection.v2.png",
    "addPanelData/sections/vectorArt/featuredSection_19_Q2-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_19_Q2-@2x.v2.png",
    "addPanelData/sections/vectorArt/featuredSection_19_Q2.png": "/addPanelData/sections/vectorArt/featuredSection_19_Q2.v2.png",
    "addPanelData/sections/vectorArt/featuredSection_19_Q4-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_19_Q4-@2x.v1.png",
    "addPanelData/sections/vectorArt/featuredSection_19_Q4.png": "/addPanelData/sections/vectorArt/featuredSection_19_Q4.v1.png",
    "addPanelData/sections/vectorArt/featuredSection_20_Q2-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_20_Q2-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_20_Q2.png": "/addPanelData/sections/vectorArt/featuredSection_20_Q2.png",
    "addPanelData/sections/vectorArt/featuredSection_20_Q4-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_20_Q4-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_20_Q4.png": "/addPanelData/sections/vectorArt/featuredSection_20_Q4.png",
    "addPanelData/sections/vectorArt/featuredSection_21_Q3_summer-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_21_Q3_summer-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_21_Q3_summer.png": "/addPanelData/sections/vectorArt/featuredSection_21_Q3_summer.png",
    "addPanelData/sections/vectorArt/featuredSection_22_Ukraine-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_22_Ukraine-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_22_Ukraine.png": "/addPanelData/sections/vectorArt/featuredSection_22_Ukraine.png",
    "addPanelData/sections/vectorArt/featuredSection_22_winter-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_22_winter-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_22_winter-@2x.png.png": "/addPanelData/sections/vectorArt/featuredSection_22_winter-@2x.png.v1.png",
    "addPanelData/sections/vectorArt/featuredSection_22_winter.png": "/addPanelData/sections/vectorArt/featuredSection_22_winter.v1.png",
    "addPanelData/sections/vectorArt/featuredSection_coronaVirus-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_coronaVirus-@2x.v2.png",
    "addPanelData/sections/vectorArt/featuredSection_coronaVirus.png": "/addPanelData/sections/vectorArt/featuredSection_coronaVirus.v2.png",
    "addPanelData/sections/vectorArt/featuredSection_holiday_21_Q1_redesign.png": "/addPanelData/sections/vectorArt/featuredSection_holiday_21_Q1_redesign.png",
    "addPanelData/sections/vectorArt/featuredSection_holidays_20_Q4-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_holidays_20_Q4-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_holidays_20_Q4.png": "/addPanelData/sections/vectorArt/featuredSection_holidays_20_Q4.png",
    "addPanelData/sections/vectorArt/featuredSection_new-year23-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_new-year23-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_new-year23.png": "/addPanelData/sections/vectorArt/featuredSection_new-year23.png",
    "addPanelData/sections/vectorArt/featuredSection_xmas_20_Q4-@2x.png": "/addPanelData/sections/vectorArt/featuredSection_xmas_20_Q4-@2x.png",
    "addPanelData/sections/vectorArt/featuredSection_xmas_20_Q4.png": "/addPanelData/sections/vectorArt/featuredSection_xmas_20_Q4.png",
    "addPanelData/sections/vectorArt/featuredSectionValentine-@2x.png": "/addPanelData/sections/vectorArt/featuredSectionValentine-@2x.v1.png",
    "addPanelData/sections/vectorArt/featuredSectionValentine.png": "/addPanelData/sections/vectorArt/featuredSectionValentine.v1.png",
    "addPanelData/sections/vectorArt/iconsSection-@2x.png": "/addPanelData/sections/vectorArt/iconsSection-@2x.v3.png",
    "addPanelData/sections/vectorArt/iconsSection.png": "/addPanelData/sections/vectorArt/iconsSection.v2.png",
    "addPanelData/sections/vectorArt/iconsSections.png": "/addPanelData/sections/vectorArt/iconsSections.v1.png",
    "addPanelData/sections/vectorArt/logosSection-@2x.png": "/addPanelData/sections/vectorArt/logosSection-@2x.v3.png",
    "addPanelData/sections/vectorArt/logosSection.png": "/addPanelData/sections/vectorArt/logosSection.v2.png",
    "addPanelData/sections/veloDevModeSection/veloDevMode.png": "/addPanelData/sections/veloDevModeSection/veloDevMode.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_da/verticalAnchorsMenuSection_da.png": "/addPanelData/sections/verticalAnchorsMenuSection_da/verticalAnchorsMenuSection_da.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_de/verticalAnchorsMenuSection_de.png": "/addPanelData/sections/verticalAnchorsMenuSection_de/verticalAnchorsMenuSection_de.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_en/verticalAnchorsMenuSection_en.png": "/addPanelData/sections/verticalAnchorsMenuSection_en/verticalAnchorsMenuSection_en.v8.png",
    "addPanelData/sections/verticalAnchorsMenuSection_es/verticalAnchorsMenuSection_es.png": "/addPanelData/sections/verticalAnchorsMenuSection_es/verticalAnchorsMenuSection_es.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_fr/verticalAnchorsMenuSection_fr.png": "/addPanelData/sections/verticalAnchorsMenuSection_fr/verticalAnchorsMenuSection_fr.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_it/verticalAnchorsMenuSection_it.png": "/addPanelData/sections/verticalAnchorsMenuSection_it/verticalAnchorsMenuSection_it.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_ja/verticalAnchorsMenuSection_ja.png": "/addPanelData/sections/verticalAnchorsMenuSection_ja/verticalAnchorsMenuSection_ja.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_ko/verticalAnchorsMenuSection_ko.png": "/addPanelData/sections/verticalAnchorsMenuSection_ko/verticalAnchorsMenuSection_ko.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_nl/verticalAnchorsMenuSection_nl.png": "/addPanelData/sections/verticalAnchorsMenuSection_nl/verticalAnchorsMenuSection_nl.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_no/verticalAnchorsMenuSection_no.png": "/addPanelData/sections/verticalAnchorsMenuSection_no/verticalAnchorsMenuSection_no.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_pl/verticalAnchorsMenuSection_pl.png": "/addPanelData/sections/verticalAnchorsMenuSection_pl/verticalAnchorsMenuSection_pl.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_pt/verticalAnchorsMenuSection_pt.png": "/addPanelData/sections/verticalAnchorsMenuSection_pt/verticalAnchorsMenuSection_pt.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_ru/verticalAnchorsMenuSection_ru.png": "/addPanelData/sections/verticalAnchorsMenuSection_ru/verticalAnchorsMenuSection_ru.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_sv/verticalAnchorsMenuSection_sv.png": "/addPanelData/sections/verticalAnchorsMenuSection_sv/verticalAnchorsMenuSection_sv.v1.png",
    "addPanelData/sections/verticalAnchorsMenuSection_tr/verticalAnchorsMenuSection_tr.png": "/addPanelData/sections/verticalAnchorsMenuSection_tr/verticalAnchorsMenuSection_tr.v1.png",
    "addPanelData/sections/verticalLinesSection_en/verticalLinesSection_en.png": "/addPanelData/sections/verticalLinesSection_en/verticalLinesSection_en.v4.png",
    "addPanelData/sections/verticalLinesSection_en/verticalLinesSection_redesign_en.png": "/addPanelData/sections/verticalLinesSection_en/verticalLinesSection_redesign_en.png",
    "addPanelData/sections/verticalMenusSection_da/verticalMenusSection_da.png": "/addPanelData/sections/verticalMenusSection_da/verticalMenusSection_da.v1.png",
    "addPanelData/sections/verticalMenusSection_de/verticalMenusSection_de.png": "/addPanelData/sections/verticalMenusSection_de/verticalMenusSection_de.v1.png",
    "addPanelData/sections/verticalMenusSection_en/verticalMenusSection_en.png": "/addPanelData/sections/verticalMenusSection_en/verticalMenusSection_en.v6.png",
    "addPanelData/sections/verticalMenusSection_en/verticalMenusSection_redesign_en.png": "/addPanelData/sections/verticalMenusSection_en/verticalMenusSection_redesign_en.png",
    "addPanelData/sections/verticalMenusSection_es/verticalMenusSection_en.png": "/addPanelData/sections/verticalMenusSection_es/verticalMenusSection_en.v1.png",
    "addPanelData/sections/verticalMenusSection_es/verticalMenusSection_es.png": "/addPanelData/sections/verticalMenusSection_es/verticalMenusSection_es.v3.png",
    "addPanelData/sections/verticalMenusSection_fr/verticalMenusSection_fr.png": "/addPanelData/sections/verticalMenusSection_fr/verticalMenusSection_fr.v1.png",
    "addPanelData/sections/verticalMenusSection_it/verticalMenusSection_it.png": "/addPanelData/sections/verticalMenusSection_it/verticalMenusSection_it.v1.png",
    "addPanelData/sections/verticalMenusSection_ja/verticalMenusSection_ja.png": "/addPanelData/sections/verticalMenusSection_ja/verticalMenusSection_ja.v3.png",
    "addPanelData/sections/verticalMenusSection_ko/verticalMenusSection_ko.png": "/addPanelData/sections/verticalMenusSection_ko/verticalMenusSection_ko.v3.png",
    "addPanelData/sections/verticalMenusSection_nl/verticalMenusSection_nl.png": "/addPanelData/sections/verticalMenusSection_nl/verticalMenusSection_nl.v1.png",
    "addPanelData/sections/verticalMenusSection_no/verticalMenusSection_no.png": "/addPanelData/sections/verticalMenusSection_no/verticalMenusSection_no.v3.png",
    "addPanelData/sections/verticalMenusSection_pl/verticalMenusSection_pl.png": "/addPanelData/sections/verticalMenusSection_pl/verticalMenusSection_pl.v1.png",
    "addPanelData/sections/verticalMenusSection_pt/verticalMenusSection_pt.png": "/addPanelData/sections/verticalMenusSection_pt/verticalMenusSection_pt.v1.png",
    "addPanelData/sections/verticalMenusSection_ru/verticalMenusSection_ru.png": "/addPanelData/sections/verticalMenusSection_ru/verticalMenusSection_ru.v1.png",
    "addPanelData/sections/verticalMenusSection_sv/verticalMenusSection_sv.png": "/addPanelData/sections/verticalMenusSection_sv/verticalMenusSection_sv.v1.png",
    "addPanelData/sections/verticalMenusSection_tr/verticalMenusSection_tr.png": "/addPanelData/sections/verticalMenusSection_tr/verticalMenusSection_tr.v1.png",
    "addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-1-poster.png": "/addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-1-poster.png",
    "addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-2-poster.png": "/addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-2-poster.png",
    "addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-3-poster.png": "/addPanelData/sections/videos_en/3DGalleries/gallery-video-thumb-3d-3-poster.png",
    "addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-1-poster.png": "/addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-1-poster.v1.png",
    "addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-2-poster.png": "/addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-2-poster.v1.png",
    "addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-3-poster.png": "/addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-3-poster.v1.png",
    "addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-4-poster.png": "/addPanelData/sections/videos_en/fullWidthGalleries/gallery-video-thumb-fullwidth-4-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-1-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-1-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-10-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-10-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-11-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-11-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-12-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-12-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-13-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-13-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-14-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-14-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-15-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-15-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-16-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-16-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-17-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-17-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-18-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-18-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-19-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-19-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-2-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-2-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-20-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-20-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-21-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-21-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-22-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-22-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-3-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-3-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-4-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-4-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-5-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-5-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-6-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-6-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-7-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-7-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-8-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-8-poster.v1.png",
    "addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-9-poster.png": "/addPanelData/sections/videos_en/gridGalleries/gallery-video-thumb-grid-9-poster.v1.png",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-1-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-1-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-1.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-1.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-2-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-2-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-2.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-2.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-3-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-3-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-3.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-3.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-4-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-4-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-4.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-4.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-5-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-5-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-5.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-5.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-6-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-6-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-6.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-6.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-7-@2x.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-7-@2x.mp4",
    "addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-7.mp4": "/addPanelData/sections/videos_en/maskedVideobox/videobox-thumb-7.mp4",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-1-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-1-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-10-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-10-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-11-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-11-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-12-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-12-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-13-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-13-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-14-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-14-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-15-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-15-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-16-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-16-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-17-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-17-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-2-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-2-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-3-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-3-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-4-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-4-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-5-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-5-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-6-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-6-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-7-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-7-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-8-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-8-poster.png",
    "addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-9-poster.png": "/addPanelData/sections/videos_en/sliderGalleries/gallery-video-thumb-sliders-9-poster.png",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-1-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-1-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-1.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-1.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-2-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-2-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-2.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-2.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-3-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-3-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-3.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-3.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-4-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-4-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-4.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-4.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-5-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-5-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-5.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-5.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-6-@2x.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-6-@2x.mp4",
    "addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-6.mp4": "/addPanelData/sections/videos_en/transparentVideobox/videobox-thumb-6.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-1-@2x.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-1-@2x.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-1.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-1.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-2-@2x.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-2-@2x.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-2.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-2.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-3-@2x.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-3-@2x.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-3.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-3.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-4-@2x.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-4-@2x.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-4.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-4.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-5-@2x.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-5-@2x.mp4",
    "addPanelData/sections/videos_en/videobox/videobox-thumb-5.mp4": "/addPanelData/sections/videos_en/videobox/videobox-thumb-5.mp4",
    "addPanelData/sections/videoSection_en/bigPlayDesignPanel.png": "/addPanelData/sections/videoSection_en/bigPlayDesignPanel.v1.png",
    "addPanelData/sections/videoSection_en/transparent-video_en-@2.png": "/addPanelData/sections/videoSection_en/transparent-video_en-@2.v5.png",
    "addPanelData/sections/videoSection_en/transparent-video_en-@2x.png": "/addPanelData/sections/videoSection_en/transparent-video_en-@2x.v3.png",
    "addPanelData/sections/videoSection_en/transparent-video_en.png": "/addPanelData/sections/videoSection_en/transparent-video_en.v4.png",
    "addPanelData/sections/videoSection_en/transparent-video_en.v2-@2.png": "/addPanelData/sections/videoSection_en/transparent-video_en.v2-@2.v1.png",
    "addPanelData/sections/videoSection_en/videoBox_en-@2x.png": "/addPanelData/sections/videoSection_en/videoBox_en-@2x.v6.png",
    "addPanelData/sections/videoSection_en/videoBox_en.png": "/addPanelData/sections/videoSection_en/videoBox_en.v6.png",
    "addPanelData/sections/videoSection_en/videoBoxMasked-@2.png": "/addPanelData/sections/videoSection_en/videoBoxMasked-@2.v3.png",
    "addPanelData/sections/videoSection_en/videoBoxMasked.png": "/addPanelData/sections/videoSection_en/videoBoxMasked.v3.png",
    "addPanelData/sections/videoSection_en/videoSection_en.png": "/addPanelData/sections/videoSection_en/videoSection_en.png",
    "addPanelData/sections/videoSection_en/videoSection_mp_en.jpg": "/addPanelData/sections/videoSection_en/videoSection_mp_en.v5.jpg",
    "addPanelData/sections/videoSection_en/videoSection_mp_en.png": "/addPanelData/sections/videoSection_en/videoSection_mp_en.v3.png",
    "addPanelData/sections/videoSection_en/videoSectionSocialPlayers_en.png": "/addPanelData/sections/videoSection_en/videoSectionSocialPlayers_en.v6.png",
    "addPanelData/sections/videoSection_en/videoSectionUnitedPlayer_en.png": "/addPanelData/sections/videoSection_en/videoSectionUnitedPlayer_en.png",
    "addPanelData/sections/videoSection_en/WixVideo_addpanel_hover.png": "/addPanelData/sections/videoSection_en/WixVideo_addpanel_hover.v1.png",
    "addPanelData/sections/videoSection_en/WixVideo_addpanel_regular.png": "/addPanelData/sections/videoSection_en/WixVideo_addpanel_regular.v1.png",
    "addPanelData/sections/videoSection_en/wixVideo_addPanelContent_hover.png": "/addPanelData/sections/videoSection_en/wixVideo_addPanelContent_hover.v1.png",
    "addPanelData/sections/videoSection_en/wixVideo_addPanelContent_regular.png": "/addPanelData/sections/videoSection_en/wixVideo_addPanelContent_regular.v1.png",
    "addPanelData/sections/videoSection_en/WixVideo_hover.png": "/addPanelData/sections/videoSection_en/WixVideo_hover.v1.png",
    "addPanelData/sections/videoSection_en/WixVideo_regular.png": "/addPanelData/sections/videoSection_en/WixVideo_regular.v1.png",
    "addPanelData/sections/videoSection_en/wixVideoSection_en.png": "/addPanelData/sections/videoSection_en/wixVideoSection_en.v1.png",
    "addPanelData/sections/vimeoSection_en/vimeoSection_en.png": "/addPanelData/sections/vimeoSection_en/vimeoSection_en.v2.png",
    "addPanelData/sections/vkSection_da/vkSection_da.png": "/addPanelData/sections/vkSection_da/vkSection_da.v1.png",
    "addPanelData/sections/vkSection_de/vkSection_de.png": "/addPanelData/sections/vkSection_de/vkSection_de.v1.png",
    "addPanelData/sections/vkSection_en/vkSection_en.png": "/addPanelData/sections/vkSection_en/vkSection_en.v5.png",
    "addPanelData/sections/vkSection_es/vkSection_es.png": "/addPanelData/sections/vkSection_es/vkSection_es.v1.png",
    "addPanelData/sections/vkSection_fr/vkSection_fr.png": "/addPanelData/sections/vkSection_fr/vkSection_fr.v1.png",
    "addPanelData/sections/vkSection_it/vkSection_it.png": "/addPanelData/sections/vkSection_it/vkSection_it.v1.png",
    "addPanelData/sections/vkSection_ja/vkSection_ja.png": "/addPanelData/sections/vkSection_ja/vkSection_ja.v1.png",
    "addPanelData/sections/vkSection_ko/vkSection_ko.png": "/addPanelData/sections/vkSection_ko/vkSection_ko.v1.png",
    "addPanelData/sections/vkSection_nl/vkSection_nl.png": "/addPanelData/sections/vkSection_nl/vkSection_nl.v1.png",
    "addPanelData/sections/vkSection_no/vkSection_no.png": "/addPanelData/sections/vkSection_no/vkSection_no.v1.png",
    "addPanelData/sections/vkSection_pl/vkSection_pl.png": "/addPanelData/sections/vkSection_pl/vkSection_pl.v1.png",
    "addPanelData/sections/vkSection_pt/vkSection_pt.png": "/addPanelData/sections/vkSection_pt/vkSection_pt.v1.png",
    "addPanelData/sections/vkSection_ru/vkSection_ru.png": "/addPanelData/sections/vkSection_ru/vkSection_ru.v1.png",
    "addPanelData/sections/vkSection_sv/vkSection_sv.png": "/addPanelData/sections/vkSection_sv/vkSection_sv.v1.png",
    "addPanelData/sections/vkSection_tr/vkSection_tr.png": "/addPanelData/sections/vkSection_tr/vkSection_tr.v1.png",
    "addPanelData/sections/wixDesignSystem_en/button_en.png": "/addPanelData/sections/wixDesignSystem_en/button_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/buttonhover_en.png": "/addPanelData/sections/wixDesignSystem_en/buttonhover_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/heading_en.png": "/addPanelData/sections/wixDesignSystem_en/heading_en.v3.png",
    "addPanelData/sections/wixDesignSystem_en/iconbutton_en.png": "/addPanelData/sections/wixDesignSystem_en/iconbutton_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/iconbuttonhover_en.png": "/addPanelData/sections/wixDesignSystem_en/iconbuttonhover_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/infoIcon_en.png": "/addPanelData/sections/wixDesignSystem_en/infoIcon_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/input_en.png": "/addPanelData/sections/wixDesignSystem_en/input_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/inputhover_en.png": "/addPanelData/sections/wixDesignSystem_en/inputhover_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/numberinput_en.png": "/addPanelData/sections/wixDesignSystem_en/numberinput_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/numberinputhover_en.png": "/addPanelData/sections/wixDesignSystem_en/numberinputhover_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/text_en.png": "/addPanelData/sections/wixDesignSystem_en/text_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/textbutton_en.png": "/addPanelData/sections/wixDesignSystem_en/textbutton_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/textbuttonhover_en.png": "/addPanelData/sections/wixDesignSystem_en/textbuttonhover_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/toggleswitch_en.png": "/addPanelData/sections/wixDesignSystem_en/toggleswitch_en.v2.png",
    "addPanelData/sections/wixDesignSystem_en/toggleswitchhover_en.png": "/addPanelData/sections/wixDesignSystem_en/toggleswitchhover_en.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_da/wixMusicPlayerSection2_da.png": "/addPanelData/sections/wixMusicPlayerSection_da/wixMusicPlayerSection2_da.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_da/wixMusicPlayerSection_da.png": "/addPanelData/sections/wixMusicPlayerSection_da/wixMusicPlayerSection_da.png",
    "addPanelData/sections/wixMusicPlayerSection_de/wixMusicPlayerSection2_de.png": "/addPanelData/sections/wixMusicPlayerSection_de/wixMusicPlayerSection2_de.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_de/wixMusicPlayerSection_de.png": "/addPanelData/sections/wixMusicPlayerSection_de/wixMusicPlayerSection_de.png",
    "addPanelData/sections/wixMusicPlayerSection_en/wixMusicPlayerSection2_en.png": "/addPanelData/sections/wixMusicPlayerSection_en/wixMusicPlayerSection2_en.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_en/wixMusicPlayerSection_en.png": "/addPanelData/sections/wixMusicPlayerSection_en/wixMusicPlayerSection_en.v4.png",
    "addPanelData/sections/wixMusicPlayerSection_es/wixMusicPlayerSection2_es.png": "/addPanelData/sections/wixMusicPlayerSection_es/wixMusicPlayerSection2_es.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_es/wixMusicPlayerSection_es.png": "/addPanelData/sections/wixMusicPlayerSection_es/wixMusicPlayerSection_es.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_fr/wixMusicPlayerSection2_fr.png": "/addPanelData/sections/wixMusicPlayerSection_fr/wixMusicPlayerSection2_fr.v3.png",
    "addPanelData/sections/wixMusicPlayerSection_fr/wixMusicPlayerSection_fr.png": "/addPanelData/sections/wixMusicPlayerSection_fr/wixMusicPlayerSection_fr.png",
    "addPanelData/sections/wixMusicPlayerSection_it/wixMusicPlayerSection2_it.png": "/addPanelData/sections/wixMusicPlayerSection_it/wixMusicPlayerSection2_it.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_it/wixMusicPlayerSection_it.png": "/addPanelData/sections/wixMusicPlayerSection_it/wixMusicPlayerSection_it.png",
    "addPanelData/sections/wixMusicPlayerSection_ja/wixMusicPlayerSection2_ja.png": "/addPanelData/sections/wixMusicPlayerSection_ja/wixMusicPlayerSection2_ja.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_ja/wixMusicPlayerSection_ja.png": "/addPanelData/sections/wixMusicPlayerSection_ja/wixMusicPlayerSection_ja.v3.png",
    "addPanelData/sections/wixMusicPlayerSection_ko/wixMusicPlayerSection2_ko.png": "/addPanelData/sections/wixMusicPlayerSection_ko/wixMusicPlayerSection2_ko.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_ko/wixMusicPlayerSection_ko.png": "/addPanelData/sections/wixMusicPlayerSection_ko/wixMusicPlayerSection_ko.png",
    "addPanelData/sections/wixMusicPlayerSection_nl/wixMusicPlayerSection2_nl.png": "/addPanelData/sections/wixMusicPlayerSection_nl/wixMusicPlayerSection2_nl.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_nl/wixMusicPlayerSection_nl.png": "/addPanelData/sections/wixMusicPlayerSection_nl/wixMusicPlayerSection_nl.png",
    "addPanelData/sections/wixMusicPlayerSection_no/wixMusicPlayerSection2_no.png": "/addPanelData/sections/wixMusicPlayerSection_no/wixMusicPlayerSection2_no.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_no/wixMusicPlayerSection_no.png": "/addPanelData/sections/wixMusicPlayerSection_no/wixMusicPlayerSection_no.png",
    "addPanelData/sections/wixMusicPlayerSection_pl/wixMusicPlayerSection2_pl.png": "/addPanelData/sections/wixMusicPlayerSection_pl/wixMusicPlayerSection2_pl.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_pl/wixMusicPlayerSection_pl.png": "/addPanelData/sections/wixMusicPlayerSection_pl/wixMusicPlayerSection_pl.png",
    "addPanelData/sections/wixMusicPlayerSection_pt/wixMusicPlayerSection2_pt.png": "/addPanelData/sections/wixMusicPlayerSection_pt/wixMusicPlayerSection2_pt.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_pt/wixMusicPlayerSection_pt.png": "/addPanelData/sections/wixMusicPlayerSection_pt/wixMusicPlayerSection_pt.png",
    "addPanelData/sections/wixMusicPlayerSection_ru/wixMusicPlayerSection2_ru.png": "/addPanelData/sections/wixMusicPlayerSection_ru/wixMusicPlayerSection2_ru.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_ru/wixMusicPlayerSection_ru.png": "/addPanelData/sections/wixMusicPlayerSection_ru/wixMusicPlayerSection_ru.png",
    "addPanelData/sections/wixMusicPlayerSection_sv/wixMusicPlayerSection2_sv.png": "/addPanelData/sections/wixMusicPlayerSection_sv/wixMusicPlayerSection2_sv.v2.png",
    "addPanelData/sections/wixMusicPlayerSection_sv/wixMusicPlayerSection_sv.png": "/addPanelData/sections/wixMusicPlayerSection_sv/wixMusicPlayerSection_sv.png",
    "addPanelData/sections/wixMusicPlayerSection_tr/wixMusicPlayerSection2_tr.png": "/addPanelData/sections/wixMusicPlayerSection_tr/wixMusicPlayerSection2_tr.v4.png",
    "addPanelData/sections/wixMusicPlayerSection_tr/wixMusicPlayerSection_tr.png": "/addPanelData/sections/wixMusicPlayerSection_tr/wixMusicPlayerSection_tr.png",
    "addPanelData/sections/wixStaffSection_en/wixStaffSection_en.png": "/addPanelData/sections/wixStaffSection_en/wixStaffSection_en.v3.png",
    "addPanelData/sections/youTubeSection_en/youTubeSection_en.png": "/addPanelData/sections/youTubeSection_en/youTubeSection_en.v2.png",
    "addPanelData/sections/youtubeSocialSection_en/youtubeSocialSection_en.png": "/addPanelData/sections/youtubeSocialSection_en/youtubeSocialSection_en.v4.png",
    "addPanelData/sections/youTubeStyleSection_en/youTubeStyleSection_en.png": "/addPanelData/sections/youTubeStyleSection_en/youTubeStyleSection_en.v2.png",
    "advancedStylePanel/iconsForSkins/contactform.FieldAnimationSkin.png": "/advancedStylePanel/iconsForSkins/contactform.FieldAnimationSkin.png",
    "advancedStylePanel/iconsForSkins/contactform.FullWidthButtonSkin.png": "/advancedStylePanel/iconsForSkins/contactform.FullWidthButtonSkin.png",
    "advancedStylePanel/iconsForSkins/contactform.LineOnlySkin.png": "/advancedStylePanel/iconsForSkins/contactform.LineOnlySkin.png",
    "advancedStylePanel/iconsForSkins/contactform.OverlappingButtonSkin.png": "/advancedStylePanel/iconsForSkins/contactform.OverlappingButtonSkin.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchDoubleHorizontalArea.png": "/advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchDoubleHorizontalArea.v1.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchDoubleVerticalArea.png": "/advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchDoubleVerticalArea.v1.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchTopArea.png": "/advancedStylePanel/iconsForSkins/skins.viewer.area.ScotchTopArea.v1.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.BevelScreenSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.BevelScreenSkin.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.BgImageStripSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.BgImageStripSkin.v1.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.DoubleBorderScreenSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.DoubleBorderScreenSkin.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.IronScreenSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.bgimagestrip.IronScreenSkin.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowCleanAndSimple.png": "/advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowCleanAndSimple.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowIron.png": "/advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowIron.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowScotchTape.png": "/advancedStylePanel/iconsForSkins/skins.viewer.gallery.SlideShowScotchTape.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.gallerymatrix.MatrixGalleryIronSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.gallerymatrix.MatrixGalleryIronSkin.png",
    "advancedStylePanel/iconsForSkins/skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin.png": "/advancedStylePanel/iconsForSkins/skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ArrowRightMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ArrowRightMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.IndentedMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.IndentedMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.RibbonsMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.RibbonsMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateIndentedMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateIndentedMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIIButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIIButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SloppyBorderMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SloppyBorderMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.VerticalRibbonsMenuButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.dropdownmenu.viewer.skins.VerticalRibbonsMenuButtonSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.AppleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.AppleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ArrowRightRibbon.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ArrowRightRibbon.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BorderDashDefaultAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BorderDashDefaultAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleAreaLeft.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleAreaLeft.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleAreaRight.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleAreaRight.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleLeftArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.BubbleLeftArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CenterRibbon.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CenterRibbon.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CircleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CircleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CustomRibbonArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.CustomRibbonArea.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.DBDefaultAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.DBDefaultAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.DefaultAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.DefaultAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ForkedRibbonArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ForkedRibbonArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.InnerShadowAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.InnerShadowAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.IronBox.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.IronBox.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LeftTriangleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LeftTriangleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedBottomAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedBottomAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedShadowArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedShadowArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedTopAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.LiftedTopAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.PhotoArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.PhotoArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RectangleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RectangleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RibbonAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RibbonAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RightTriangleArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.RightTriangleArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.SandClockArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.SandClockArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.SloopyArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.SloopyArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ThreeDeeAreaSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.ThreeDeeAreaSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.VerticalArrowArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.VerticalArrowArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.VerticalRibbonArea.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.area.VerticalRibbonArea.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.Audio3DPlayer.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.Audio3DPlayer.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.BoldPlayer.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.BoldPlayer.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.ShinyPlayer.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.ShinyPlayer.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.SimplePlayer.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.audioplayer.SimplePlayer.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.BasicButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.BasicButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.BrandButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.BrandButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonArrow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonArrow.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonArrowLeft.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonArrowLeft.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonInnerShadow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonInnerShadow.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonLiftedShadow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonLiftedShadow.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonShadowLeft.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonShadowLeft.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonShadowRight.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonShadowRight.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonThreeD.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ButtonThreeD.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.CircleButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.CircleButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.GamingButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.GamingButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.IronButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.IronButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.RibbonButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.RibbonButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ScotchTapeButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ScotchTapeButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonIISkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonIISkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonInverted.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonInverted.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonISkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.ShinyButtonISkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.SloopyButton.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.SloopyButton.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.TextOnlyButtonSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.button.TextOnlyButtonSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.BasicContactFormSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.BasicContactFormSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.DefaultContactForm.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.DefaultContactForm.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.VerticalForm.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.VerticalForm.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.EbayItemsBySellerSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.EbayItemsBySellerSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowPolaroid.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowPolaroid.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextBottom.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextBottom.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextFloating.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextFloating.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextOverlay.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextOverlay.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextRight.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallery.SlideShowTextRight.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.GoogleMapSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.GoogleMapSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ArrowLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ArrowLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ArrowRightLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ArrowRightLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DashedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DashedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DashedLineStudio.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DashedLineStudio.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DottedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DottedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DottedLineStudio.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DottedLineStudio.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DoubleLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.DoubleLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeLine.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeNotchBottomLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeNotchBottomLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeNotchTopLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.FadeNotchTopLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.IronLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.IronLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.NotchDashedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.NotchDashedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.NotchLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.NotchLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ShadowBottomLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ShadowBottomLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ShadowTopLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ShadowTopLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SloppyLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SloppyLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SolidLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SolidLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SolidLineStudio.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.SolidLineStudio.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalArrowLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalArrowLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalArrowLineTop.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalArrowLineTop.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDashedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDashedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDottedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDottedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDoubleLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalDoubleLine.v2.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalFadeNotchLeftLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalFadeNotchLeftLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalFadeNotchRightLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalFadeNotchRightLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalIronLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalIronLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalNotchDashedLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalNotchDashedLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalNotchLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalNotchLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalShadowLeftLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalShadowLeftLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalShadowRightLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalShadowRightLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalSloopyLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalSloopyLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalSolidLine.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.VerticalSolidLine.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ZigzagLineFlipSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ZigzagLineFlipSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ZigzagLineSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.line.ZigzagLineSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapDefault.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapDefault.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapLiftedShadow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapLiftedShadow.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapSloppy.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.map.GoogleMapSloppy.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ArrowRightMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ArrowRightMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.IndentedMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.IndentedMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.LinesMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.LinesMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.OverlineMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.OverlineMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.PointerMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.PointerMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.RibbonsMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.RibbonsMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateBasicMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateBasicMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateIndentedMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateIndentedMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateLinesMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateLinesMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateShinyIIMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateShinyIIMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateShinyIMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SeparateShinyIMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ShinyMenuIISkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ShinyMenuIISkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ShinyMenuISkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.ShinyMenuISkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SloppyBorderMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SloppyBorderMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SolidColorMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.SolidColorMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.TextOnlyMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.TextOnlyMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.TextSeparatorsMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.menu.TextSeparatorsMenuSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin.v3.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin.v3.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin.v3.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin.v3.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.mobile.TinyMenuSkin.v3.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.BasicPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.BasicPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.BorderPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.BorderPageSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.InnerShadowPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.InnerShadowPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedBottomPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedBottomPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedShadowPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedShadowPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedTopPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.LiftedTopPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.NoMarginPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.NoMarginPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.ShinyIPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.ShinyIPageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.SloopyPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.SloopyPageSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.ThreeDeePageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.ThreeDeePageSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.TransparentPageSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.page.TransparentPageSkin.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.CirclePhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.CirclePhoto.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.DoubleBorderCirclePhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.DoubleBorderCirclePhoto.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.DoubleBorderPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.DoubleBorderPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.GlowLinePhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.GlowLinePhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.IronPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.IronPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.LiftedShadowPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.LiftedShadowPhoto.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.LiftedTopPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.LiftedTopPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.MouseOverPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.MouseOverPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.NewPolaroidPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.NewPolaroidPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.NoSkinPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.NoSkinPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.PolaroidPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.PolaroidPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.RoundPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.RoundPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchDoubleHorizontal.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchDoubleHorizontal.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchDoubleVertical.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchDoubleVertical.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchTopPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.ScotchTopPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.SloppyPhoto.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.photo.SloppyPhoto.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.AfterScroll.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.AfterScroll.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.BevelScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.BevelScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.BoxScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.BoxScreen.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.IronScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.IronScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.LiftedBottomScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.LiftedBottomScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.LiftedTopScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.LiftedTopScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowBottomScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowBottomScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowTopScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowTopScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowTopScreen.png.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ShadowTopScreen.png.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ThreeDeeScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.ThreeDeeScreen.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoDefault.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoDefault.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoLiftedShadow.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoLiftedShadow.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoSloppy.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.video.VideoSloppy.v1.png",
    "advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.VideoSkin.png": "/advancedStylePanel/iconsForSkins/wysiwyg.viewer.skins.VideoSkin.v1.png",
    "advancedStylePanel/widgetDesign/presetPlaceholder.svg": "/advancedStylePanel/widgetDesign/presetPlaceholder.svg",
    "ai/panels/welcomePanel/components/questionnairePanel/animationPanel/card.png": "/ai/panels/welcomePanel/components/questionnairePanel/animationPanel/card.v9.png",
    "ai/panels/welcomePanel/components/questionnairePanel/animationPanel/star.png": "/ai/panels/welcomePanel/components/questionnairePanel/animationPanel/star.png",
    "appManager/appCardHeader/blogs.png": "/appManager/appCardHeader/blogs.png",
    "appManager/appCardHeader/blogs@2x.png": "/appManager/appCardHeader/blogs@2x.png",
    "appManager/appCardHeader/bookings.png": "/appManager/appCardHeader/bookings.png",
    "appManager/appCardHeader/bookings@2x.png": "/appManager/appCardHeader/bookings@2x.png",
    "appManager/appCardHeader/stores.png": "/appManager/appCardHeader/stores.png",
    "appManager/appCardHeader/stores@2x.png": "/appManager/appCardHeader/stores@2x.png",
    "background/overlayPatternIcons/v-overlay-pat-0-icon.png": "/background/overlayPatternIcons/v-overlay-pat-0-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-1-icon.png": "/background/overlayPatternIcons/v-overlay-pat-1-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-2-icon.png": "/background/overlayPatternIcons/v-overlay-pat-2-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-3-icon.png": "/background/overlayPatternIcons/v-overlay-pat-3-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-4-icon.png": "/background/overlayPatternIcons/v-overlay-pat-4-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-5-icon.png": "/background/overlayPatternIcons/v-overlay-pat-5-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-6-icon.png": "/background/overlayPatternIcons/v-overlay-pat-6-icon.png",
    "background/overlayPatternIcons/v-overlay-pat-7-icon.png": "/background/overlayPatternIcons/v-overlay-pat-7-icon.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-1.png": "/background/overlayPatternsIconsX/v-overlay-pattern-1.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-10.png": "/background/overlayPatternsIconsX/v-overlay-pattern-10.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-11.png": "/background/overlayPatternsIconsX/v-overlay-pattern-11.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-12.png": "/background/overlayPatternsIconsX/v-overlay-pattern-12.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-13.png": "/background/overlayPatternsIconsX/v-overlay-pattern-13.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-14.png": "/background/overlayPatternsIconsX/v-overlay-pattern-14.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-15.png": "/background/overlayPatternsIconsX/v-overlay-pattern-15.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-16.png": "/background/overlayPatternsIconsX/v-overlay-pattern-16.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-17.png": "/background/overlayPatternsIconsX/v-overlay-pattern-17.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-18.png": "/background/overlayPatternsIconsX/v-overlay-pattern-18.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-19.png": "/background/overlayPatternsIconsX/v-overlay-pattern-19.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-2.png": "/background/overlayPatternsIconsX/v-overlay-pattern-2.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-20.png": "/background/overlayPatternsIconsX/v-overlay-pattern-20.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-21.png": "/background/overlayPatternsIconsX/v-overlay-pattern-21.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-22.png": "/background/overlayPatternsIconsX/v-overlay-pattern-22.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-23.png": "/background/overlayPatternsIconsX/v-overlay-pattern-23.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-24.png": "/background/overlayPatternsIconsX/v-overlay-pattern-24.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-25.png": "/background/overlayPatternsIconsX/v-overlay-pattern-25.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-26.png": "/background/overlayPatternsIconsX/v-overlay-pattern-26.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-27.png": "/background/overlayPatternsIconsX/v-overlay-pattern-27.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-28.png": "/background/overlayPatternsIconsX/v-overlay-pattern-28.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-29.png": "/background/overlayPatternsIconsX/v-overlay-pattern-29.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-3.png": "/background/overlayPatternsIconsX/v-overlay-pattern-3.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-30.png": "/background/overlayPatternsIconsX/v-overlay-pattern-30.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-31.png": "/background/overlayPatternsIconsX/v-overlay-pattern-31.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-32.png": "/background/overlayPatternsIconsX/v-overlay-pattern-32.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-33.png": "/background/overlayPatternsIconsX/v-overlay-pattern-33.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-34.png": "/background/overlayPatternsIconsX/v-overlay-pattern-34.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-35.png": "/background/overlayPatternsIconsX/v-overlay-pattern-35.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-36.png": "/background/overlayPatternsIconsX/v-overlay-pattern-36.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-37.png": "/background/overlayPatternsIconsX/v-overlay-pattern-37.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-38.png": "/background/overlayPatternsIconsX/v-overlay-pattern-38.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-39.png": "/background/overlayPatternsIconsX/v-overlay-pattern-39.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-4.png": "/background/overlayPatternsIconsX/v-overlay-pattern-4.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-40.png": "/background/overlayPatternsIconsX/v-overlay-pattern-40.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-41.png": "/background/overlayPatternsIconsX/v-overlay-pattern-41.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-42.png": "/background/overlayPatternsIconsX/v-overlay-pattern-42.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-43.png": "/background/overlayPatternsIconsX/v-overlay-pattern-43.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-44.png": "/background/overlayPatternsIconsX/v-overlay-pattern-44.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-45.png": "/background/overlayPatternsIconsX/v-overlay-pattern-45.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-46.png": "/background/overlayPatternsIconsX/v-overlay-pattern-46.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-47.png": "/background/overlayPatternsIconsX/v-overlay-pattern-47.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-48.png": "/background/overlayPatternsIconsX/v-overlay-pattern-48.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-5.png": "/background/overlayPatternsIconsX/v-overlay-pattern-5.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-6.png": "/background/overlayPatternsIconsX/v-overlay-pattern-6.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-7.png": "/background/overlayPatternsIconsX/v-overlay-pattern-7.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-8.png": "/background/overlayPatternsIconsX/v-overlay-pattern-8.png",
    "background/overlayPatternsIconsX/v-overlay-pattern-9.png": "/background/overlayPatternsIconsX/v-overlay-pattern-9.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-1.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-1.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-10.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-10.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-11.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-11.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-12.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-12.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-13.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-13.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-14.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-14.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-15.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-15.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-16.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-16.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-17.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-17.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-18.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-18.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-19.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-19.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-2.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-2.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-20.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-20.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-21.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-21.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-22.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-22.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-23.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-23.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-24.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-24.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-25.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-25.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-26.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-26.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-27.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-27.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-28.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-28.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-29.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-29.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-3.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-3.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-30.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-30.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-31.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-31.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-32.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-32.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-33.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-33.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-34.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-34.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-35.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-35.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-36.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-36.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-37.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-37.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-38.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-38.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-39.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-39.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-4.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-4.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-40.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-40.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-41.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-41.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-42.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-42.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-43.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-43.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-44.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-44.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-45.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-45.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-46.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-46.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-47.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-47.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-48.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-48.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-5.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-5.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-6.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-6.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-7.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-7.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-8.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-8.png",
    "background/overlayPatternsIconsX/v1/v-overlay-pattern-9.png": "/background/overlayPatternsIconsX/v1/v-overlay-pattern-9.png",
    "background/scrollEffects/close-up_hover.gif": "/background/scrollEffects/close-up_hover.gif",
    "background/scrollEffects/close-up_regular.gif": "/background/scrollEffects/close-up_regular.gif",
    "background/scrollEffects/close-up_selected.gif": "/background/scrollEffects/close-up_selected.gif",
    "background/scrollEffects/close-up_selected_frame.gif": "/background/scrollEffects/close-up_selected_frame.gif",
    "background/scrollEffects/expand_hover.gif": "/background/scrollEffects/expand_hover.gif",
    "background/scrollEffects/expand_regular.gif": "/background/scrollEffects/expand_regular.gif",
    "background/scrollEffects/expend_selected.gif": "/background/scrollEffects/expend_selected.gif",
    "background/scrollEffects/expend_selected_frame.gif": "/background/scrollEffects/expend_selected_frame.gif",
    "background/scrollEffects/fade-in_hover.gif": "/background/scrollEffects/fade-in_hover.gif",
    "background/scrollEffects/fade-in_regular.gif": "/background/scrollEffects/fade-in_regular.gif",
    "background/scrollEffects/fade-in_selected.gif": "/background/scrollEffects/fade-in_selected.gif",
    "background/scrollEffects/fade-in_selected_frame.gif": "/background/scrollEffects/fade-in_selected_frame.gif",
    "background/scrollEffects/fade-out_hover.gif": "/background/scrollEffects/fade-out_hover.gif",
    "background/scrollEffects/fade-out_regular.gif": "/background/scrollEffects/fade-out_regular.gif",
    "background/scrollEffects/fade-out_selected.gif": "/background/scrollEffects/fade-out_selected.gif",
    "background/scrollEffects/fade-out_selected_frame.gif": "/background/scrollEffects/fade-out_selected_frame.gif",
    "background/scrollEffects/pan-left_hover.gif": "/background/scrollEffects/pan-left_hover.gif",
    "background/scrollEffects/pan-left_regular.gif": "/background/scrollEffects/pan-left_regular.gif",
    "background/scrollEffects/pan-left_selected.gif": "/background/scrollEffects/pan-left_selected.gif",
    "background/scrollEffects/pan-left_selected_frame.gif": "/background/scrollEffects/pan-left_selected_frame.gif",
    "background/scrollEffects/pan-right_hover.gif": "/background/scrollEffects/pan-right_hover.gif",
    "background/scrollEffects/pan-right_regular.gif": "/background/scrollEffects/pan-right_regular.gif",
    "background/scrollEffects/pan-right_selected.gif": "/background/scrollEffects/pan-right_selected.gif",
    "background/scrollEffects/pan-right_selected_frame.gif": "/background/scrollEffects/pan-right_selected_frame.gif",
    "background/scrollEffects/parallax_hover.gif": "/background/scrollEffects/parallax_hover.gif",
    "background/scrollEffects/parallax_regular.gif": "/background/scrollEffects/parallax_regular.gif",
    "background/scrollEffects/parallax_selected.gif": "/background/scrollEffects/parallax_selected.gif",
    "background/scrollEffects/parallax_selected_frame.gif": "/background/scrollEffects/parallax_selected_frame.gif",
    "background/scrollEffects/pull-back_hover.gif": "/background/scrollEffects/pull-back_hover.gif",
    "background/scrollEffects/pull-back_regular.gif": "/background/scrollEffects/pull-back_regular.gif",
    "background/scrollEffects/pull-back_selected.gif": "/background/scrollEffects/pull-back_selected.gif",
    "background/scrollEffects/pull-back_selected_frame.gif": "/background/scrollEffects/pull-back_selected_frame.gif",
    "background/scrollEffects/reveal_hover.gif": "/background/scrollEffects/reveal_hover.gif",
    "background/scrollEffects/reveal_regular.gif": "/background/scrollEffects/reveal_regular.gif",
    "background/scrollEffects/reveal_selected.gif": "/background/scrollEffects/reveal_selected.gif",
    "background/scrollEffects/reveal_selected_frame.gif": "/background/scrollEffects/reveal_selected_frame.gif",
    "background/scrollEffects/rotate_hover.gif": "/background/scrollEffects/rotate_hover.gif",
    "background/scrollEffects/rotate_regular.gif": "/background/scrollEffects/rotate_regular.gif",
    "background/scrollEffects/rotate_selected.gif": "/background/scrollEffects/rotate_selected.gif",
    "background/scrollEffects/rotate_selected_frame.gif": "/background/scrollEffects/rotate_selected_frame.gif",
    "background/scrollEffects/shrink_hover.gif": "/background/scrollEffects/shrink_hover.gif",
    "background/scrollEffects/shrink_regular.gif": "/background/scrollEffects/shrink_regular.gif",
    "background/scrollEffects/shrink_selected.gif": "/background/scrollEffects/shrink_selected.gif",
    "background/scrollEffects/shrink_selected_static.gif": "/background/scrollEffects/shrink_selected_static.gif",
    "background/scrollEffects/tilt_hover.gif": "/background/scrollEffects/tilt_hover.gif",
    "background/scrollEffects/tilt_regular.gif": "/background/scrollEffects/tilt_regular.gif",
    "background/scrollEffects/tilt_selected.gif": "/background/scrollEffects/tilt_selected.gif",
    "background/scrollEffects/tilt_selected_frame.gif": "/background/scrollEffects/tilt_selected_frame.gif",
    "background/scrollEffects/v2.1/3d_hover.gif": "/background/scrollEffects/v2.1/3d_hover.gif",
    "background/scrollEffects/v2.1/3d_regular.gif": "/background/scrollEffects/v2.1/3d_regular.gif",
    "background/scrollEffects/v2.1/3d_selected.gif": "/background/scrollEffects/v2.1/3d_selected.gif",
    "background/scrollEffects/v2.1/3d_selected_frame.gif": "/background/scrollEffects/v2.1/3d_selected_frame.gif",
    "background/scrollEffects/v2.1/close-up_hover.gif": "/background/scrollEffects/v2.1/close-up_hover.gif",
    "background/scrollEffects/v2.1/close-up_regular.gif": "/background/scrollEffects/v2.1/close-up_regular.gif",
    "background/scrollEffects/v2.1/close-up_selected.gif": "/background/scrollEffects/v2.1/close-up_selected.gif",
    "background/scrollEffects/v2.1/close-up_selected_frame.gif": "/background/scrollEffects/v2.1/close-up_selected_frame.gif",
    "background/scrollEffects/v2.1/expand_hover.gif": "/background/scrollEffects/v2.1/expand_hover.gif",
    "background/scrollEffects/v2.1/expand_regular.gif": "/background/scrollEffects/v2.1/expand_regular.gif",
    "background/scrollEffects/v2.1/expand_selected.gif": "/background/scrollEffects/v2.1/expand_selected.gif",
    "background/scrollEffects/v2.1/expand_selected_frame.gif": "/background/scrollEffects/v2.1/expand_selected_frame.gif",
    "background/scrollEffects/v2.1/fade-back_hover.gif": "/background/scrollEffects/v2.1/fade-back_hover.gif",
    "background/scrollEffects/v2.1/fade-back_regular.gif": "/background/scrollEffects/v2.1/fade-back_regular.gif",
    "background/scrollEffects/v2.1/fade-back_selected.gif": "/background/scrollEffects/v2.1/fade-back_selected.gif",
    "background/scrollEffects/v2.1/fade-back_selected_frame.gif": "/background/scrollEffects/v2.1/fade-back_selected_frame.gif",
    "background/scrollEffects/v2.1/fade-in_hover.gif": "/background/scrollEffects/v2.1/fade-in_hover.gif",
    "background/scrollEffects/v2.1/fade-in_regular.gif": "/background/scrollEffects/v2.1/fade-in_regular.gif",
    "background/scrollEffects/v2.1/fade-in_selected.gif": "/background/scrollEffects/v2.1/fade-in_selected.gif",
    "background/scrollEffects/v2.1/fade-in_selected_frame.gif": "/background/scrollEffects/v2.1/fade-in_selected_frame.gif",
    "background/scrollEffects/v2.1/fade-out_hover.gif": "/background/scrollEffects/v2.1/fade-out_hover.gif",
    "background/scrollEffects/v2.1/fade-out_regular.gif": "/background/scrollEffects/v2.1/fade-out_regular.gif",
    "background/scrollEffects/v2.1/fade-out_selected.gif": "/background/scrollEffects/v2.1/fade-out_selected.gif",
    "background/scrollEffects/v2.1/fade-out_selected_frame.gif": "/background/scrollEffects/v2.1/fade-out_selected_frame.gif",
    "background/scrollEffects/v2.1/left-reveal_hover.gif": "/background/scrollEffects/v2.1/left-reveal_hover.gif",
    "background/scrollEffects/v2.1/left-reveal_regular.gif": "/background/scrollEffects/v2.1/left-reveal_regular.gif",
    "background/scrollEffects/v2.1/left-reveal_selected.gif": "/background/scrollEffects/v2.1/left-reveal_selected.gif",
    "background/scrollEffects/v2.1/left-reveal_selected_frame.gif": "/background/scrollEffects/v2.1/left-reveal_selected_frame.gif",
    "background/scrollEffects/v2.1/pan-left_hover.gif": "/background/scrollEffects/v2.1/pan-left_hover.gif",
    "background/scrollEffects/v2.1/pan-left_regular.gif": "/background/scrollEffects/v2.1/pan-left_regular.gif",
    "background/scrollEffects/v2.1/pan-left_selected.gif": "/background/scrollEffects/v2.1/pan-left_selected.gif",
    "background/scrollEffects/v2.1/pan-left_selected_frame.gif": "/background/scrollEffects/v2.1/pan-left_selected_frame.gif",
    "background/scrollEffects/v2.1/pan-right_hover.gif": "/background/scrollEffects/v2.1/pan-right_hover.gif",
    "background/scrollEffects/v2.1/pan-right_regular.gif": "/background/scrollEffects/v2.1/pan-right_regular.gif",
    "background/scrollEffects/v2.1/pan-right_selected.gif": "/background/scrollEffects/v2.1/pan-right_selected.gif",
    "background/scrollEffects/v2.1/pan-right_selected_frame.gif": "/background/scrollEffects/v2.1/pan-right_selected_frame.gif",
    "background/scrollEffects/v2.1/parallax_hover.gif": "/background/scrollEffects/v2.1/parallax_hover.gif",
    "background/scrollEffects/v2.1/parallax_regular.gif": "/background/scrollEffects/v2.1/parallax_regular.gif",
    "background/scrollEffects/v2.1/parallax_selected.gif": "/background/scrollEffects/v2.1/parallax_selected.gif",
    "background/scrollEffects/v2.1/parallax_selected_frame.gif": "/background/scrollEffects/v2.1/parallax_selected_frame.gif",
    "background/scrollEffects/v2.1/pull-back_frame.gif": "/background/scrollEffects/v2.1/pull-back_frame.gif",
    "background/scrollEffects/v2.1/pull-back_hover.gif": "/background/scrollEffects/v2.1/pull-back_hover.gif",
    "background/scrollEffects/v2.1/pull-back_regular.gif": "/background/scrollEffects/v2.1/pull-back_regular.gif",
    "background/scrollEffects/v2.1/pull-back_selected.gif": "/background/scrollEffects/v2.1/pull-back_selected.gif",
    "background/scrollEffects/v2.1/reveal_hover.gif": "/background/scrollEffects/v2.1/reveal_hover.gif",
    "background/scrollEffects/v2.1/reveal_regular.gif": "/background/scrollEffects/v2.1/reveal_regular.gif",
    "background/scrollEffects/v2.1/reveal_selected.gif": "/background/scrollEffects/v2.1/reveal_selected.gif",
    "background/scrollEffects/v2.1/reveal_selected_frame.gif": "/background/scrollEffects/v2.1/reveal_selected_frame.gif",
    "background/scrollEffects/v2.1/right-reveal_hover.gif": "/background/scrollEffects/v2.1/right-reveal_hover.gif",
    "background/scrollEffects/v2.1/right-reveal_regular.gif": "/background/scrollEffects/v2.1/right-reveal_regular.gif",
    "background/scrollEffects/v2.1/right-reveal_selected.gif": "/background/scrollEffects/v2.1/right-reveal_selected.gif",
    "background/scrollEffects/v2.1/right-reveal_selected_frame.gif": "/background/scrollEffects/v2.1/right-reveal_selected_frame.gif",
    "background/scrollEffects/v2.1/rotate_hover.gif": "/background/scrollEffects/v2.1/rotate_hover.gif",
    "background/scrollEffects/v2.1/rotate_regular.gif": "/background/scrollEffects/v2.1/rotate_regular.gif",
    "background/scrollEffects/v2.1/rotate_selected.gif": "/background/scrollEffects/v2.1/rotate_selected.gif",
    "background/scrollEffects/v2.1/rotate_selected_frame.gif": "/background/scrollEffects/v2.1/rotate_selected_frame.gif",
    "background/scrollEffects/v2.1/shrink_hover.gif": "/background/scrollEffects/v2.1/shrink_hover.gif",
    "background/scrollEffects/v2.1/shrink_regular.gif": "/background/scrollEffects/v2.1/shrink_regular.gif",
    "background/scrollEffects/v2.1/shrink_selected.gif": "/background/scrollEffects/v2.1/shrink_selected.gif",
    "background/scrollEffects/v2.1/shrink_selected_frame.gif": "/background/scrollEffects/v2.1/shrink_selected_frame.gif",
    "background/scrollEffects/v2.1/skew_hover.gif": "/background/scrollEffects/v2.1/skew_hover.gif",
    "background/scrollEffects/v2.1/skew_regular.gif": "/background/scrollEffects/v2.1/skew_regular.gif",
    "background/scrollEffects/v2.1/skew_selected.gif": "/background/scrollEffects/v2.1/skew_selected.gif",
    "background/scrollEffects/v2.1/skew_selected_frame.gif": "/background/scrollEffects/v2.1/skew_selected_frame.gif",
    "background/scrollEffects/v2.1/slo-mo_hover.gif": "/background/scrollEffects/v2.1/slo-mo_hover.gif",
    "background/scrollEffects/v2.1/slo-mo_regular.gif": "/background/scrollEffects/v2.1/slo-mo_regular.gif",
    "background/scrollEffects/v2.1/slo-mo_selected.gif": "/background/scrollEffects/v2.1/slo-mo_selected.gif",
    "background/scrollEffects/v2.1/slo-mo_selected_frame.gif": "/background/scrollEffects/v2.1/slo-mo_selected_frame.gif",
    "background/scrollEffects/v2.1/unwind_hover.gif": "/background/scrollEffects/v2.1/unwind_hover.gif",
    "background/scrollEffects/v2.1/unwind_regular.gif": "/background/scrollEffects/v2.1/unwind_regular.gif",
    "background/scrollEffects/v2.1/unwind_selected.gif": "/background/scrollEffects/v2.1/unwind_selected.gif",
    "background/scrollEffects/v2.1/unwind_selected_frame.gif": "/background/scrollEffects/v2.1/unwind_selected_frame.gif",
    "background/scrollEffects/v2.1/zoom-in_hover.gif": "/background/scrollEffects/v2.1/zoom-in_hover.gif",
    "background/scrollEffects/v2.1/zoom-in_regular.gif": "/background/scrollEffects/v2.1/zoom-in_regular.gif",
    "background/scrollEffects/v2.1/zoom-in_selected.gif": "/background/scrollEffects/v2.1/zoom-in_selected.gif",
    "background/scrollEffects/v2.1/zoom-in_selected_frame.gif": "/background/scrollEffects/v2.1/zoom-in_selected_frame.gif",
    "background/scrollEffects/v2.1/zoom-out_hover.gif": "/background/scrollEffects/v2.1/zoom-out_hover.gif",
    "background/scrollEffects/v2.1/zoom-out_regular.gif": "/background/scrollEffects/v2.1/zoom-out_regular.gif",
    "background/scrollEffects/v2.1/zoom-out_selected.gif": "/background/scrollEffects/v2.1/zoom-out_selected.gif",
    "background/scrollEffects/v2.1/zoom-out_selected_frame.gif": "/background/scrollEffects/v2.1/zoom-out_selected_frame.gif",
    "background/scrollEffects/v2/close-up_hover.gif": "/background/scrollEffects/v2/close-up_hover.gif",
    "background/scrollEffects/v2/close-up_regular.gif": "/background/scrollEffects/v2/close-up_regular.gif",
    "background/scrollEffects/v2/close-up_selected.gif": "/background/scrollEffects/v2/close-up_selected.gif",
    "background/scrollEffects/v2/close-up_selected_frame.gif": "/background/scrollEffects/v2/close-up_selected_frame.gif",
    "background/scrollEffects/v2/expand_hover.gif": "/background/scrollEffects/v2/expand_hover.gif",
    "background/scrollEffects/v2/expand_regular.gif": "/background/scrollEffects/v2/expand_regular.gif",
    "background/scrollEffects/v2/expand_selected.gif": "/background/scrollEffects/v2/expand_selected.gif",
    "background/scrollEffects/v2/expand_selected_frame.gif": "/background/scrollEffects/v2/expand_selected_frame.gif",
    "background/scrollEffects/v2/fade-in_hover.gif": "/background/scrollEffects/v2/fade-in_hover.gif",
    "background/scrollEffects/v2/fade-in_regular.gif": "/background/scrollEffects/v2/fade-in_regular.gif",
    "background/scrollEffects/v2/fade-in_selected.gif": "/background/scrollEffects/v2/fade-in_selected.gif",
    "background/scrollEffects/v2/fade-in_selected_frame.gif": "/background/scrollEffects/v2/fade-in_selected_frame.gif",
    "background/scrollEffects/v2/fade-out_hover.gif": "/background/scrollEffects/v2/fade-out_hover.gif",
    "background/scrollEffects/v2/fade-out_regular.gif": "/background/scrollEffects/v2/fade-out_regular.gif",
    "background/scrollEffects/v2/fade-out_selected.gif": "/background/scrollEffects/v2/fade-out_selected.gif",
    "background/scrollEffects/v2/fade-out_selected_frame.gif": "/background/scrollEffects/v2/fade-out_selected_frame.gif",
    "background/scrollEffects/v2/pan-left_hover.gif": "/background/scrollEffects/v2/pan-left_hover.gif",
    "background/scrollEffects/v2/pan-left_regular.gif": "/background/scrollEffects/v2/pan-left_regular.gif",
    "background/scrollEffects/v2/pan-left_selected.gif": "/background/scrollEffects/v2/pan-left_selected.gif",
    "background/scrollEffects/v2/pan-left_selected_frame.gif": "/background/scrollEffects/v2/pan-left_selected_frame.gif",
    "background/scrollEffects/v2/pan-right_hover.gif": "/background/scrollEffects/v2/pan-right_hover.gif",
    "background/scrollEffects/v2/pan-right_regular.gif": "/background/scrollEffects/v2/pan-right_regular.gif",
    "background/scrollEffects/v2/pan-right_selected.gif": "/background/scrollEffects/v2/pan-right_selected.gif",
    "background/scrollEffects/v2/pan-right_selected_frame.gif": "/background/scrollEffects/v2/pan-right_selected_frame.gif",
    "background/scrollEffects/v2/parallax_hover.gif": "/background/scrollEffects/v2/parallax_hover.gif",
    "background/scrollEffects/v2/parallax_regular.gif": "/background/scrollEffects/v2/parallax_regular.gif",
    "background/scrollEffects/v2/parallax_selected.gif": "/background/scrollEffects/v2/parallax_selected.gif",
    "background/scrollEffects/v2/parallax_selected_frame.gif": "/background/scrollEffects/v2/parallax_selected_frame.gif",
    "background/scrollEffects/v2/pull-back_hover.gif": "/background/scrollEffects/v2/pull-back_hover.gif",
    "background/scrollEffects/v2/pull-back_regular.gif": "/background/scrollEffects/v2/pull-back_regular.gif",
    "background/scrollEffects/v2/pull-back_selected.gif": "/background/scrollEffects/v2/pull-back_selected.gif",
    "background/scrollEffects/v2/pull-back_selected_frame.gif": "/background/scrollEffects/v2/pull-back_selected_frame.gif",
    "background/scrollEffects/v2/reveal_hover.gif": "/background/scrollEffects/v2/reveal_hover.gif",
    "background/scrollEffects/v2/reveal_regular.gif": "/background/scrollEffects/v2/reveal_regular.gif",
    "background/scrollEffects/v2/reveal_selected.gif": "/background/scrollEffects/v2/reveal_selected.gif",
    "background/scrollEffects/v2/reveal_selected_frame.gif": "/background/scrollEffects/v2/reveal_selected_frame.gif",
    "background/scrollEffects/v2/rotate_hover.gif": "/background/scrollEffects/v2/rotate_hover.gif",
    "background/scrollEffects/v2/rotate_regular.gif": "/background/scrollEffects/v2/rotate_regular.gif",
    "background/scrollEffects/v2/rotate_selected.gif": "/background/scrollEffects/v2/rotate_selected.gif",
    "background/scrollEffects/v2/rotate_selected_frame.gif": "/background/scrollEffects/v2/rotate_selected_frame.gif",
    "background/scrollEffects/v2/shrink_hover.gif": "/background/scrollEffects/v2/shrink_hover.gif",
    "background/scrollEffects/v2/shrink_regular.gif": "/background/scrollEffects/v2/shrink_regular.gif",
    "background/scrollEffects/v2/shrink_selected.gif": "/background/scrollEffects/v2/shrink_selected.gif",
    "background/scrollEffects/v2/shrink_selected_frame.gif": "/background/scrollEffects/v2/shrink_selected_frame.gif",
    "background/scrollEffects/v2/zoom-in_hover.gif": "/background/scrollEffects/v2/zoom-in_hover.gif",
    "background/scrollEffects/v2/zoom-in_regular.gif": "/background/scrollEffects/v2/zoom-in_regular.gif",
    "background/scrollEffects/v2/zoom-in_selected.gif": "/background/scrollEffects/v2/zoom-in_selected.gif",
    "background/scrollEffects/v2/zoom-in_selected_frame.gif": "/background/scrollEffects/v2/zoom-in_selected_frame.gif",
    "background/scrollEffects/v2/zoom-out_hover.gif": "/background/scrollEffects/v2/zoom-out_hover.gif",
    "background/scrollEffects/v2/zoom-out_regular.gif": "/background/scrollEffects/v2/zoom-out_regular.gif",
    "background/scrollEffects/v2/zoom-out_selected.gif": "/background/scrollEffects/v2/zoom-out_selected.gif",
    "background/scrollEffects/v2/zoom-out_selected_frame.gif": "/background/scrollEffects/v2/zoom-out_selected_frame.gif",
    "background/scrollEffects/v3/3d_hover.gif": "/background/scrollEffects/v3/3d_hover.gif",
    "background/scrollEffects/v3/3d_regular.gif": "/background/scrollEffects/v3/3d_regular.gif",
    "background/scrollEffects/v3/3d_selected.gif": "/background/scrollEffects/v3/3d_selected.gif",
    "background/scrollEffects/v3/3d_selected_frame.gif": "/background/scrollEffects/v3/3d_selected_frame.gif",
    "background/scrollEffects/v3/close-up_hover.gif": "/background/scrollEffects/v3/close-up_hover.gif",
    "background/scrollEffects/v3/close-up_regular.gif": "/background/scrollEffects/v3/close-up_regular.gif",
    "background/scrollEffects/v3/close-up_selected.gif": "/background/scrollEffects/v3/close-up_selected.gif",
    "background/scrollEffects/v3/close-up_selected_frame.gif": "/background/scrollEffects/v3/close-up_selected_frame.gif",
    "background/scrollEffects/v3/fade-back_hover.gif": "/background/scrollEffects/v3/fade-back_hover.gif",
    "background/scrollEffects/v3/fade-back_regular.gif": "/background/scrollEffects/v3/fade-back_regular.gif",
    "background/scrollEffects/v3/fade-back_selected.gif": "/background/scrollEffects/v3/fade-back_selected.gif",
    "background/scrollEffects/v3/fade-back_selected_frame.gif": "/background/scrollEffects/v3/fade-back_selected_frame.gif",
    "background/scrollEffects/v3/fade-in_hover.gif": "/background/scrollEffects/v3/fade-in_hover.gif",
    "background/scrollEffects/v3/fade-in_regular.gif": "/background/scrollEffects/v3/fade-in_regular.gif",
    "background/scrollEffects/v3/fade-in_selected.gif": "/background/scrollEffects/v3/fade-in_selected.gif",
    "background/scrollEffects/v3/fade-in_selected_frame.gif": "/background/scrollEffects/v3/fade-in_selected_frame.gif",
    "background/scrollEffects/v3/fade-out_hover.gif": "/background/scrollEffects/v3/fade-out_hover.gif",
    "background/scrollEffects/v3/fade-out_regular.gif": "/background/scrollEffects/v3/fade-out_regular.gif",
    "background/scrollEffects/v3/fade-out_selected.gif": "/background/scrollEffects/v3/fade-out_selected.gif",
    "background/scrollEffects/v3/fade-out_selected_frame.gif": "/background/scrollEffects/v3/fade-out_selected_frame.gif",
    "background/scrollEffects/v3/pan-left_hover.gif": "/background/scrollEffects/v3/pan-left_hover.gif",
    "background/scrollEffects/v3/pan-left_regular.gif": "/background/scrollEffects/v3/pan-left_regular.gif",
    "background/scrollEffects/v3/pan-left_selected.gif": "/background/scrollEffects/v3/pan-left_selected.gif",
    "background/scrollEffects/v3/pan-left_selected_frame.gif": "/background/scrollEffects/v3/pan-left_selected_frame.gif",
    "background/scrollEffects/v3/pan-right_hover.gif": "/background/scrollEffects/v3/pan-right_hover.gif",
    "background/scrollEffects/v3/pan-right_regular.gif": "/background/scrollEffects/v3/pan-right_regular.gif",
    "background/scrollEffects/v3/pan-right_selected.gif": "/background/scrollEffects/v3/pan-right_selected.gif",
    "background/scrollEffects/v3/pan-right_selected_frame.gif": "/background/scrollEffects/v3/pan-right_selected_frame.gif",
    "background/scrollEffects/v3/parallax_hover.gif": "/background/scrollEffects/v3/parallax_hover.gif",
    "background/scrollEffects/v3/parallax_regular.gif": "/background/scrollEffects/v3/parallax_regular.gif",
    "background/scrollEffects/v3/parallax_selected.gif": "/background/scrollEffects/v3/parallax_selected.gif",
    "background/scrollEffects/v3/parallax_selected_frame.gif": "/background/scrollEffects/v3/parallax_selected_frame.gif",
    "background/scrollEffects/v3/pull-back_frame.gif": "/background/scrollEffects/v3/pull-back_frame.gif",
    "background/scrollEffects/v3/pull-back_hover.gif": "/background/scrollEffects/v3/pull-back_hover.gif",
    "background/scrollEffects/v3/pull-back_regular.gif": "/background/scrollEffects/v3/pull-back_regular.gif",
    "background/scrollEffects/v3/pull-back_selected.gif": "/background/scrollEffects/v3/pull-back_selected.gif",
    "background/scrollEffects/v3/reveal_hover.gif": "/background/scrollEffects/v3/reveal_hover.gif",
    "background/scrollEffects/v3/reveal_regular.gif": "/background/scrollEffects/v3/reveal_regular.gif",
    "background/scrollEffects/v3/reveal_selected.gif": "/background/scrollEffects/v3/reveal_selected.gif",
    "background/scrollEffects/v3/reveal_selected_frame.gif": "/background/scrollEffects/v3/reveal_selected_frame.gif",
    "background/scrollEffects/v3/rotate_hover.gif": "/background/scrollEffects/v3/rotate_hover.gif",
    "background/scrollEffects/v3/rotate_regular.gif": "/background/scrollEffects/v3/rotate_regular.gif",
    "background/scrollEffects/v3/rotate_selected.gif": "/background/scrollEffects/v3/rotate_selected.gif",
    "background/scrollEffects/v3/rotate_selected_frame.gif": "/background/scrollEffects/v3/rotate_selected_frame.gif",
    "background/scrollEffects/v3/skew_hover.gif": "/background/scrollEffects/v3/skew_hover.gif",
    "background/scrollEffects/v3/skew_regular.gif": "/background/scrollEffects/v3/skew_regular.gif",
    "background/scrollEffects/v3/skew_selected.gif": "/background/scrollEffects/v3/skew_selected.gif",
    "background/scrollEffects/v3/skew_selected_frame.gif": "/background/scrollEffects/v3/skew_selected_frame.gif",
    "background/scrollEffects/v3/zoom-in_hover.gif": "/background/scrollEffects/v3/zoom-in_hover.gif",
    "background/scrollEffects/v3/zoom-in_regular.gif": "/background/scrollEffects/v3/zoom-in_regular.gif",
    "background/scrollEffects/v3/zoom-in_selected.gif": "/background/scrollEffects/v3/zoom-in_selected.gif",
    "background/scrollEffects/v3/zoom-in_selected_frame.gif": "/background/scrollEffects/v3/zoom-in_selected_frame.gif",
    "background/scrollEffects/v3/zoom-out_hover.gif": "/background/scrollEffects/v3/zoom-out_hover.gif",
    "background/scrollEffects/v3/zoom-out_regular.gif": "/background/scrollEffects/v3/zoom-out_regular.gif",
    "background/scrollEffects/v3/zoom-out_selected.gif": "/background/scrollEffects/v3/zoom-out_selected.gif",
    "background/scrollEffects/v3/zoom-out_selected_frame.gif": "/background/scrollEffects/v3/zoom-out_selected_frame.gif",
    "background/scrollEffects/zoom-in_hover.gif": "/background/scrollEffects/zoom-in_hover.gif",
    "background/scrollEffects/zoom-in_regular.gif": "/background/scrollEffects/zoom-in_regular.gif",
    "background/scrollEffects/zoom-in_selected.gif": "/background/scrollEffects/zoom-in_selected.gif",
    "background/scrollEffects/zoom-in_selected_frame.gif": "/background/scrollEffects/zoom-in_selected_frame.gif",
    "background/scrollEffects/zoom-out_hover.gif": "/background/scrollEffects/zoom-out_hover.gif",
    "background/scrollEffects/zoom-out_regular.gif": "/background/scrollEffects/zoom-out_regular.gif",
    "background/scrollEffects/zoom-out_selected.gif": "/background/scrollEffects/zoom-out_selected.gif",
    "background/scrollEffects/zoom-out_selected_frame.gif": "/background/scrollEffects/zoom-out_selected_frame.gif",
    "baseUI/panelInputs/dead-areas-indication-pattern.png": "/baseUI/panelInputs/dead-areas-indication-pattern.v4.png",
    "baseUI/panelInputs/graphic-slider-fill-pattern.png": "/baseUI/panelInputs/graphic-slider-fill-pattern.png",
    "blog/appMenuPanel/firstTimePromotionalMainImage.png": "/blog/appMenuPanel/firstTimePromotionalMainImage.png",
    "blog/appMenuPanel/gallery/blogGallery01.png": "/blog/appMenuPanel/gallery/blogGallery01.png",
    "blog/appMenuPanel/gallery/blogGallery02.png": "/blog/appMenuPanel/gallery/blogGallery02.png",
    "blog/appMenuPanel/gallery/blogGallery03.png": "/blog/appMenuPanel/gallery/blogGallery03.png",
    "blog/appMenuPanel/gallery/blogGallery04.png": "/blog/appMenuPanel/gallery/blogGallery04.png",
    "blog/appMenuPanel/managePanelWelcome.png": "/blog/appMenuPanel/managePanelWelcome.png",
    "blog/sections/archiveSection.png": "/blog/sections/archiveSection.v3.png",
    "blog/sections/blogLayoutsSection.png": "/blog/sections/blogLayoutsSection.v2.png",
    "blog/sections/categoriesSection.png": "/blog/sections/categoriesSection.png",
    "blog/sections/customFeedSection.png": "/blog/sections/customFeedSection.v2.png",
    "blog/sections/featuredPostsSection.png": "/blog/sections/featuredPostsSection.v2.png",
    "blog/sections/postGallerySection.png": "/blog/sections/postGallerySection.v2.png",
    "blog/sections/postListSection.png": "/blog/sections/postListSection.v2.png",
    "blog/sections/recentPostsSection.png": "/blog/sections/recentPostsSection.v3.png",
    "blog/sections/relatedPostsSection.png": "/blog/sections/relatedPostsSection.png",
    "blog/sections/rssButtonSection.png": "/blog/sections/rssButtonSection.v2.png",
    "blog/sections/tagCloudSection.png": "/blog/sections/tagCloudSection.v2.png",
    "blog/sections/tickerSection.png": "/blog/sections/tickerSection.v2.png",
    "compPanels/backToTopButton/presets/BTT_01.png": "/compPanels/backToTopButton/presets/BTT_01.png",
    "compPanels/backToTopButton/presets/BTT_02.png": "/compPanels/backToTopButton/presets/BTT_02.png",
    "compPanels/backToTopButton/presets/BTT_03.png": "/compPanels/backToTopButton/presets/BTT_03.png",
    "compPanels/backToTopButton/presets/BTT_04.png": "/compPanels/backToTopButton/presets/BTT_04.png",
    "compPanels/backToTopButton/presets/BTT_05.png": "/compPanels/backToTopButton/presets/BTT_05.png",
    "compPanels/backToTopButton/presets/BTT_06.png": "/compPanels/backToTopButton/presets/BTT_06.png",
    "compPanels/backToTopButton/presets/BTT_07.png": "/compPanels/backToTopButton/presets/BTT_07.png",
    "compPanels/backToTopButton/presets/BTT_08.png": "/compPanels/backToTopButton/presets/BTT_08.png",
    "compPanels/backToTopButton/presets/BTT_09.png": "/compPanels/backToTopButton/presets/BTT_09.png",
    "compPanels/backToTopButton/presets/BTT_10.png": "/compPanels/backToTopButton/presets/BTT_10.png",
    "compPanels/backToTopButton/presets/BTT_11.png": "/compPanels/backToTopButton/presets/BTT_11.png",
    "compPanels/backToTopButton/presets/BTT_12.png": "/compPanels/backToTopButton/presets/BTT_12.png",
    "compPanels/backToTopButton/presets/BTT_13.png": "/compPanels/backToTopButton/presets/BTT_13.png",
    "compPanels/backToTopButton/presets/BTT_14.png": "/compPanels/backToTopButton/presets/BTT_14.png",
    "compPanels/backToTopButton/presets/BTT_15.png": "/compPanels/backToTopButton/presets/BTT_15.png",
    "compPanels/backToTopButton/presets/BTT_16.png": "/compPanels/backToTopButton/presets/BTT_16.png",
    "compPanels/backToTopButton/presets/BTT_17.png": "/compPanels/backToTopButton/presets/BTT_17.png",
    "compPanels/backToTopButton/presets/BTT_18.png": "/compPanels/backToTopButton/presets/BTT_18.png",
    "compPanels/backToTopButton/presets/BTT_19.png": "/compPanels/backToTopButton/presets/BTT_19.png",
    "compPanels/backToTopButton/presets/BTT_20.png": "/compPanels/backToTopButton/presets/BTT_20.png",
    "compPanels/backToTopButton/presets/BTT_21.png": "/compPanels/backToTopButton/presets/BTT_21.png",
    "compPanels/backToTopButton/presets/BTT_22.png": "/compPanels/backToTopButton/presets/BTT_22.png",
    "compPanels/backToTopButton/presets/BTT_23.png": "/compPanels/backToTopButton/presets/BTT_23.png",
    "compPanels/backToTopButton/presets/BTT_24.png": "/compPanels/backToTopButton/presets/BTT_24.png",
    "compPanels/backToTopButton/presets/BTT_25.png": "/compPanels/backToTopButton/presets/BTT_25.png",
    "compPanels/backToTopButton/presets/BTT_26.png": "/compPanels/backToTopButton/presets/BTT_26.png",
    "compPanels/backToTopButton/presets/BTT_27.png": "/compPanels/backToTopButton/presets/BTT_27.png",
    "compPanels/backToTopButton/presets/BTT_28.png": "/compPanels/backToTopButton/presets/BTT_28.png",
    "compPanels/backToTopButton/presets/BTT_29.png": "/compPanels/backToTopButton/presets/BTT_29.png",
    "compPanels/backToTopButton/presets/BTT_30.png": "/compPanels/backToTopButton/presets/BTT_30.png",
    "compPanels/backToTopButton/presets/BTT_31.png": "/compPanels/backToTopButton/presets/BTT_31.png",
    "compPanels/backToTopButton/presets/BTT_32.png": "/compPanels/backToTopButton/presets/BTT_32.png",
    "compPanels/backToTopButton/presets/BTT_33.png": "/compPanels/backToTopButton/presets/BTT_33.png",
    "compPanels/backToTopButton/presets/BTT_34.png": "/compPanels/backToTopButton/presets/BTT_34.png",
    "compPanels/backToTopButton/presets/BTT_35.png": "/compPanels/backToTopButton/presets/BTT_35.png",
    "compPanels/backToTopButton/presets/BTT_36.png": "/compPanels/backToTopButton/presets/BTT_36.png",
    "compPanels/backToTopButton/presets/BTT_37.png": "/compPanels/backToTopButton/presets/BTT_37.png",
    "compPanels/backToTopButton/presets/BTT_38.png": "/compPanels/backToTopButton/presets/BTT_38.png",
    "compPanels/backToTopButton/presets/BTT_39.png": "/compPanels/backToTopButton/presets/BTT_39.png",
    "compPanels/collapsibleText/CollapsibleText_Layout_Expand.svg": "/compPanels/collapsibleText/CollapsibleText_Layout_Expand.svg",
    "compPanels/collapsibleText/CollapsibleText_Layout_Link.svg": "/compPanels/collapsibleText/CollapsibleText_Layout_Link.svg",
    "compPanels/collapsibleText/CollapsibleText_Layout_NoButton.svg": "/compPanels/collapsibleText/CollapsibleText_Layout_NoButton.svg",
    "compPanels/deprecationPanel/deprecationIllustration.svg": "/compPanels/deprecationPanel/deprecationIllustration.svg",
    "compPanels/dividersPanel/thumbs/thumbnail_1.png": "/compPanels/dividersPanel/thumbs/thumbnail_1.png",
    "compPanels/dividersPanel/thumbs/thumbnail_10.png": "/compPanels/dividersPanel/thumbs/thumbnail_10.png",
    "compPanels/dividersPanel/thumbs/thumbnail_11.png": "/compPanels/dividersPanel/thumbs/thumbnail_11.png",
    "compPanels/dividersPanel/thumbs/thumbnail_12.png": "/compPanels/dividersPanel/thumbs/thumbnail_12.png",
    "compPanels/dividersPanel/thumbs/thumbnail_13.png": "/compPanels/dividersPanel/thumbs/thumbnail_13.png",
    "compPanels/dividersPanel/thumbs/thumbnail_14.png": "/compPanels/dividersPanel/thumbs/thumbnail_14.png",
    "compPanels/dividersPanel/thumbs/thumbnail_15.png": "/compPanels/dividersPanel/thumbs/thumbnail_15.png",
    "compPanels/dividersPanel/thumbs/thumbnail_16.png": "/compPanels/dividersPanel/thumbs/thumbnail_16.png",
    "compPanels/dividersPanel/thumbs/thumbnail_17.png": "/compPanels/dividersPanel/thumbs/thumbnail_17.png",
    "compPanels/dividersPanel/thumbs/thumbnail_18.png": "/compPanels/dividersPanel/thumbs/thumbnail_18.png",
    "compPanels/dividersPanel/thumbs/thumbnail_19.png": "/compPanels/dividersPanel/thumbs/thumbnail_19.png",
    "compPanels/dividersPanel/thumbs/thumbnail_2.png": "/compPanels/dividersPanel/thumbs/thumbnail_2.png",
    "compPanels/dividersPanel/thumbs/thumbnail_20.png": "/compPanels/dividersPanel/thumbs/thumbnail_20.png",
    "compPanels/dividersPanel/thumbs/thumbnail_21.png": "/compPanels/dividersPanel/thumbs/thumbnail_21.png",
    "compPanels/dividersPanel/thumbs/thumbnail_22.png": "/compPanels/dividersPanel/thumbs/thumbnail_22.png",
    "compPanels/dividersPanel/thumbs/thumbnail_23.png": "/compPanels/dividersPanel/thumbs/thumbnail_23.png",
    "compPanels/dividersPanel/thumbs/thumbnail_24.png": "/compPanels/dividersPanel/thumbs/thumbnail_24.png",
    "compPanels/dividersPanel/thumbs/thumbnail_3.png": "/compPanels/dividersPanel/thumbs/thumbnail_3.png",
    "compPanels/dividersPanel/thumbs/thumbnail_4.png": "/compPanels/dividersPanel/thumbs/thumbnail_4.png",
    "compPanels/dividersPanel/thumbs/thumbnail_5.png": "/compPanels/dividersPanel/thumbs/thumbnail_5.png",
    "compPanels/dividersPanel/thumbs/thumbnail_6.png": "/compPanels/dividersPanel/thumbs/thumbnail_6.png",
    "compPanels/dividersPanel/thumbs/thumbnail_7.png": "/compPanels/dividersPanel/thumbs/thumbnail_7.png",
    "compPanels/dividersPanel/thumbs/thumbnail_8.png": "/compPanels/dividersPanel/thumbs/thumbnail_8.png",
    "compPanels/dividersPanel/thumbs/thumbnail_9.png": "/compPanels/dividersPanel/thumbs/thumbnail_9.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_1.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_1.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_10.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_10.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_11.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_11.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_12.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_12.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_13.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_13.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_14.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_14.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_15.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_15.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_16.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_16.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_17.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_17.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_18.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_18.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_19.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_19.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_2.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_2.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_20.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_20.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_21.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_21.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_22.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_22.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_23.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_23.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_24.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_24.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_3.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_3.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_4.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_4.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_5.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_5.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_6.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_6.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_7.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_7.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_8.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_8.png",
    "compPanels/dividersPanel/thumbs/v1/thumbnail_9.png": "/compPanels/dividersPanel/thumbs/v1/thumbnail_9.png",
    "compPanels/dynamicPanels/smartWriterPanel/SmartWriter_load_new_x2.gif": "/compPanels/dynamicPanels/smartWriterPanel/SmartWriter_load_new_x2.gif",
    "compPanels/dynamicPanels/smartWriterPanel/SmartWriter_load_x2.gif": "/compPanels/dynamicPanels/smartWriterPanel/SmartWriter_load_x2.gif",
    "compPanels/facebookLike/facebook_like_box_layout.png": "/compPanels/facebookLike/facebook_like_box_layout.v3.png",
    "compPanels/facebookLike/facebook_like_counter_layout.png": "/compPanels/facebookLike/facebook_like_counter_layout.v3.png",
    "compPanels/facebookLike/facebook_like_layout.png": "/compPanels/facebookLike/facebook_like_layout.v3.png",
    "compPanels/facebookLike/facebook_like_layout_with_pics.png": "/compPanels/facebookLike/facebook_like_layout_with_pics.v3.png",
    "compPanels/googleMap/hybrid.png": "/compPanels/googleMap/hybrid.png",
    "compPanels/googleMap/normal.png": "/compPanels/googleMap/normal.png",
    "compPanels/googleMap/satellite.png": "/compPanels/googleMap/satellite.png",
    "compPanels/googleMap/terrian.png": "/compPanels/googleMap/terrian.png",
    "compPanels/googlePlus/google-plus-basic.png": "/compPanels/googlePlus/google-plus-basic.v2.png",
    "compPanels/googlePlus/google-plus-minimal.png": "/compPanels/googlePlus/google-plus-minimal.v2.png",
    "compPanels/googlePlus/google-plus-withcounter.png": "/compPanels/googlePlus/google-plus-withcounter.v2.png",
    "compPanels/headerContainer/desktop-header-fade-on-scroll-paused.gif": "/compPanels/headerContainer/desktop-header-fade-on-scroll-paused.gif",
    "compPanels/headerContainer/desktop-header-fade-on-scroll-selected-paused.gif": "/compPanels/headerContainer/desktop-header-fade-on-scroll-selected-paused.gif",
    "compPanels/headerContainer/desktop-header-fade-on-scroll-selected.gif": "/compPanels/headerContainer/desktop-header-fade-on-scroll-selected.gif",
    "compPanels/headerContainer/desktop-header-fade-on-scroll.gif": "/compPanels/headerContainer/desktop-header-fade-on-scroll.gif",
    "compPanels/headerContainer/desktop-header-fixed-paused.gif": "/compPanels/headerContainer/desktop-header-fixed-paused.gif",
    "compPanels/headerContainer/desktop-header-fixed-selected-paused.gif": "/compPanels/headerContainer/desktop-header-fixed-selected-paused.gif",
    "compPanels/headerContainer/desktop-header-fixed-selected.gif": "/compPanels/headerContainer/desktop-header-fixed-selected.gif",
    "compPanels/headerContainer/desktop-header-fixed.gif": "/compPanels/headerContainer/desktop-header-fixed.gif",
    "compPanels/headerContainer/desktop-header-hide-on-scroll-paused.gif": "/compPanels/headerContainer/desktop-header-hide-on-scroll-paused.gif",
    "compPanels/headerContainer/desktop-header-hide-on-scroll-selected-paused.gif": "/compPanels/headerContainer/desktop-header-hide-on-scroll-selected-paused.gif",
    "compPanels/headerContainer/desktop-header-hide-on-scroll-selected.gif": "/compPanels/headerContainer/desktop-header-hide-on-scroll-selected.gif",
    "compPanels/headerContainer/desktop-header-hide-on-scroll.gif": "/compPanels/headerContainer/desktop-header-hide-on-scroll.gif",
    "compPanels/headerContainer/desktop-header-scroll-paused.gif": "/compPanels/headerContainer/desktop-header-scroll-paused.gif",
    "compPanels/headerContainer/desktop-header-scroll-selected-paused.gif": "/compPanels/headerContainer/desktop-header-scroll-selected-paused.gif",
    "compPanels/headerContainer/desktop-header-scroll-selected.gif": "/compPanels/headerContainer/desktop-header-scroll-selected.gif",
    "compPanels/headerContainer/desktop-header-scroll.gif": "/compPanels/headerContainer/desktop-header-scroll.gif",
    "compPanels/headerContainer/mobile-header-fade-on-scroll-paused.gif": "/compPanels/headerContainer/mobile-header-fade-on-scroll-paused.gif",
    "compPanels/headerContainer/mobile-header-fade-on-scroll-selected-paused.gif": "/compPanels/headerContainer/mobile-header-fade-on-scroll-selected-paused.gif",
    "compPanels/headerContainer/mobile-header-fade-on-scroll-selected.gif": "/compPanels/headerContainer/mobile-header-fade-on-scroll-selected.gif",
    "compPanels/headerContainer/mobile-header-fade-on-scroll.gif": "/compPanels/headerContainer/mobile-header-fade-on-scroll.gif",
    "compPanels/headerContainer/mobile-header-fixed-paused.gif": "/compPanels/headerContainer/mobile-header-fixed-paused.gif",
    "compPanels/headerContainer/mobile-header-fixed-selected-paused.gif": "/compPanels/headerContainer/mobile-header-fixed-selected-paused.gif",
    "compPanels/headerContainer/mobile-header-fixed-selected.gif": "/compPanels/headerContainer/mobile-header-fixed-selected.gif",
    "compPanels/headerContainer/mobile-header-fixed.gif": "/compPanels/headerContainer/mobile-header-fixed.gif",
    "compPanels/headerContainer/mobile-header-hide-on-scroll-paused.gif": "/compPanels/headerContainer/mobile-header-hide-on-scroll-paused.gif",
    "compPanels/headerContainer/mobile-header-hide-on-scroll-selected-paused.gif": "/compPanels/headerContainer/mobile-header-hide-on-scroll-selected-paused.gif",
    "compPanels/headerContainer/mobile-header-hide-on-scroll-selected.gif": "/compPanels/headerContainer/mobile-header-hide-on-scroll-selected.gif",
    "compPanels/headerContainer/mobile-header-hide-on-scroll.gif": "/compPanels/headerContainer/mobile-header-hide-on-scroll.gif",
    "compPanels/headerContainer/mobile-header-scroll-paused.gif": "/compPanels/headerContainer/mobile-header-scroll-paused.gif",
    "compPanels/headerContainer/mobile-header-scroll-selected-paused.gif": "/compPanels/headerContainer/mobile-header-scroll-selected-paused.gif",
    "compPanels/headerContainer/mobile-header-scroll-selected.gif": "/compPanels/headerContainer/mobile-header-scroll-selected.gif",
    "compPanels/headerContainer/mobile-header-scroll.gif": "/compPanels/headerContainer/mobile-header-scroll.gif",
    "compPanels/inlinePopupToggle/presets/hamburger-panel-@2x.png": "/compPanels/inlinePopupToggle/presets/hamburger-panel-@2x.png",
    "compPanels/inlinePopupToggle/presets/hamburger-panel.png": "/compPanels/inlinePopupToggle/presets/hamburger-panel.png",
    "compPanels/inlinePopupToggle/presets/hamburgerTogglePresets.png": "/compPanels/inlinePopupToggle/presets/hamburgerTogglePresets.png",
    "compPanels/lineShareButton/share-a.png": "/compPanels/lineShareButton/share-a.png",
    "compPanels/lineShareButton/share-b.png": "/compPanels/lineShareButton/share-b.png",
    "compPanels/lineShareButton/share-c.png": "/compPanels/lineShareButton/share-c.png",
    "compPanels/lineShareButton/share-d.png": "/compPanels/lineShareButton/share-d.png",
    "compPanels/lineShareButton/share-e.png": "/compPanels/lineShareButton/share-e.png",
    "compPanels/lineShareButton/share-f.png": "/compPanels/lineShareButton/share-f.png",
    "compPanels/lineShareButton/share-g.png": "/compPanels/lineShareButton/share-g.png",
    "compPanels/lineShareButton/share-h.png": "/compPanels/lineShareButton/share-h.png",
    "compPanels/mediaPlayer/controls/bigPlay/1.png": "/compPanels/mediaPlayer/controls/bigPlay/1.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/10.png": "/compPanels/mediaPlayer/controls/bigPlay/10.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/11.png": "/compPanels/mediaPlayer/controls/bigPlay/11.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/12.png": "/compPanels/mediaPlayer/controls/bigPlay/12.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/13.png": "/compPanels/mediaPlayer/controls/bigPlay/13.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/14.png": "/compPanels/mediaPlayer/controls/bigPlay/14.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/15.png": "/compPanels/mediaPlayer/controls/bigPlay/15.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/15.V1.png": "/compPanels/mediaPlayer/controls/bigPlay/15.V1.png",
    "compPanels/mediaPlayer/controls/bigPlay/16.png": "/compPanels/mediaPlayer/controls/bigPlay/16.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/17.png": "/compPanels/mediaPlayer/controls/bigPlay/17.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/18.png": "/compPanels/mediaPlayer/controls/bigPlay/18.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/19.png": "/compPanels/mediaPlayer/controls/bigPlay/19.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/2.png": "/compPanels/mediaPlayer/controls/bigPlay/2.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/20.png": "/compPanels/mediaPlayer/controls/bigPlay/20.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/21.png": "/compPanels/mediaPlayer/controls/bigPlay/21.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/22.png": "/compPanels/mediaPlayer/controls/bigPlay/22.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/23.png": "/compPanels/mediaPlayer/controls/bigPlay/23.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/24.png": "/compPanels/mediaPlayer/controls/bigPlay/24.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/25.png": "/compPanels/mediaPlayer/controls/bigPlay/25.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/26.png": "/compPanels/mediaPlayer/controls/bigPlay/26.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/27.png": "/compPanels/mediaPlayer/controls/bigPlay/27.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/3.png": "/compPanels/mediaPlayer/controls/bigPlay/3.v3.png",
    "compPanels/mediaPlayer/controls/bigPlay/4.png": "/compPanels/mediaPlayer/controls/bigPlay/4.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/5.png": "/compPanels/mediaPlayer/controls/bigPlay/5.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/6.png": "/compPanels/mediaPlayer/controls/bigPlay/6.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/7.png": "/compPanels/mediaPlayer/controls/bigPlay/7.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/8.png": "/compPanels/mediaPlayer/controls/bigPlay/8.v2.png",
    "compPanels/mediaPlayer/controls/bigPlay/9.png": "/compPanels/mediaPlayer/controls/bigPlay/9.v2.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-01.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-01.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-02.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-02.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-03.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-03.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-04.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-04.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-05.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-05.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-06.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-06.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-07.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-07.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-08.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-08.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-09.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-09.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-10.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-10.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-11.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-11.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-12.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-12.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-13.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-13.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-14.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-14.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-15.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-15.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-16.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-16.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-17.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-17.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-18.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-18.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-19.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-19.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-20.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-20.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-21.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-21.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-22.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-22.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-23.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-23.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-24.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-24.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-25.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-25.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-26.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-26.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-27.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-27.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-28.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-28.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-29.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-29.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-30.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-30.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-31.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-31.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-32.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-32.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-33.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-33.v1.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-34.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-34.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-35.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-35.png",
    "compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-36.png": "/compPanels/mediaPlayer/controls/bigPlayColor/BigPlay_72X72-36.png",
    "compPanels/mediaPlayer/patternOverlay/1.png": "/compPanels/mediaPlayer/patternOverlay/1.v2.png",
    "compPanels/mediaPlayer/patternOverlay/10.gif": "/compPanels/mediaPlayer/patternOverlay/10.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/10.png": "/compPanels/mediaPlayer/patternOverlay/10.v5.png",
    "compPanels/mediaPlayer/patternOverlay/11.gif": "/compPanels/mediaPlayer/patternOverlay/11.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/11.png": "/compPanels/mediaPlayer/patternOverlay/11.v3.png",
    "compPanels/mediaPlayer/patternOverlay/12.gif": "/compPanels/mediaPlayer/patternOverlay/12.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/12.png": "/compPanels/mediaPlayer/patternOverlay/12.v4.png",
    "compPanels/mediaPlayer/patternOverlay/13.gif": "/compPanels/mediaPlayer/patternOverlay/13.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/13.png": "/compPanels/mediaPlayer/patternOverlay/13.v4.png",
    "compPanels/mediaPlayer/patternOverlay/14.gif": "/compPanels/mediaPlayer/patternOverlay/14.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/14.png": "/compPanels/mediaPlayer/patternOverlay/14.v3.png",
    "compPanels/mediaPlayer/patternOverlay/15.gif": "/compPanels/mediaPlayer/patternOverlay/15.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/15.png": "/compPanels/mediaPlayer/patternOverlay/15.v3.png",
    "compPanels/mediaPlayer/patternOverlay/16.gif": "/compPanels/mediaPlayer/patternOverlay/16.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/16.png": "/compPanels/mediaPlayer/patternOverlay/16.v2.png",
    "compPanels/mediaPlayer/patternOverlay/17.gif": "/compPanels/mediaPlayer/patternOverlay/17.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/17.png": "/compPanels/mediaPlayer/patternOverlay/17.v2.png",
    "compPanels/mediaPlayer/patternOverlay/18.gif": "/compPanels/mediaPlayer/patternOverlay/18.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/18.png": "/compPanels/mediaPlayer/patternOverlay/18.v2.png",
    "compPanels/mediaPlayer/patternOverlay/19.gif": "/compPanels/mediaPlayer/patternOverlay/19.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/19.png": "/compPanels/mediaPlayer/patternOverlay/19.v5.png",
    "compPanels/mediaPlayer/patternOverlay/2.png": "/compPanels/mediaPlayer/patternOverlay/2.v5.png",
    "compPanels/mediaPlayer/patternOverlay/20.gif": "/compPanels/mediaPlayer/patternOverlay/20.v1.gif",
    "compPanels/mediaPlayer/patternOverlay/20.png": "/compPanels/mediaPlayer/patternOverlay/20.v5.png",
    "compPanels/mediaPlayer/patternOverlay/21.gif": "/compPanels/mediaPlayer/patternOverlay/21.v3.gif",
    "compPanels/mediaPlayer/patternOverlay/21.png": "/compPanels/mediaPlayer/patternOverlay/21.v5.png",
    "compPanels/mediaPlayer/patternOverlay/22.gif": "/compPanels/mediaPlayer/patternOverlay/22.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/22.png": "/compPanels/mediaPlayer/patternOverlay/22.v5.png",
    "compPanels/mediaPlayer/patternOverlay/23.gif": "/compPanels/mediaPlayer/patternOverlay/23.gif",
    "compPanels/mediaPlayer/patternOverlay/23.png": "/compPanels/mediaPlayer/patternOverlay/23.v5.png",
    "compPanels/mediaPlayer/patternOverlay/24.gif": "/compPanels/mediaPlayer/patternOverlay/24.v1.gif",
    "compPanels/mediaPlayer/patternOverlay/24.png": "/compPanels/mediaPlayer/patternOverlay/24.v5.png",
    "compPanels/mediaPlayer/patternOverlay/25.gif": "/compPanels/mediaPlayer/patternOverlay/25.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/25.png": "/compPanels/mediaPlayer/patternOverlay/25.v3.png",
    "compPanels/mediaPlayer/patternOverlay/26.gif": "/compPanels/mediaPlayer/patternOverlay/26.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/26.png": "/compPanels/mediaPlayer/patternOverlay/26.v2.png",
    "compPanels/mediaPlayer/patternOverlay/27.gif": "/compPanels/mediaPlayer/patternOverlay/27.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/27.png": "/compPanels/mediaPlayer/patternOverlay/27.v2.png",
    "compPanels/mediaPlayer/patternOverlay/28.png": "/compPanels/mediaPlayer/patternOverlay/28.v5.png",
    "compPanels/mediaPlayer/patternOverlay/29.gif": "/compPanels/mediaPlayer/patternOverlay/29.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/29.png": "/compPanels/mediaPlayer/patternOverlay/29.v2.png",
    "compPanels/mediaPlayer/patternOverlay/3.png": "/compPanels/mediaPlayer/patternOverlay/3.v5.png",
    "compPanels/mediaPlayer/patternOverlay/30.gif": "/compPanels/mediaPlayer/patternOverlay/30.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/30.png": "/compPanels/mediaPlayer/patternOverlay/30.v2.png",
    "compPanels/mediaPlayer/patternOverlay/31.gif": "/compPanels/mediaPlayer/patternOverlay/31.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/31.png": "/compPanels/mediaPlayer/patternOverlay/31.v5.png",
    "compPanels/mediaPlayer/patternOverlay/32.gif": "/compPanels/mediaPlayer/patternOverlay/32.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/32.png": "/compPanels/mediaPlayer/patternOverlay/32.v5.png",
    "compPanels/mediaPlayer/patternOverlay/33.gif": "/compPanels/mediaPlayer/patternOverlay/33.v2.gif",
    "compPanels/mediaPlayer/patternOverlay/33.png": "/compPanels/mediaPlayer/patternOverlay/33.v5.png",
    "compPanels/mediaPlayer/patternOverlay/34.gif": "/compPanels/mediaPlayer/patternOverlay/34.v3.gif",
    "compPanels/mediaPlayer/patternOverlay/34.png": "/compPanels/mediaPlayer/patternOverlay/34.v5.png",
    "compPanels/mediaPlayer/patternOverlay/35.gif": "/compPanels/mediaPlayer/patternOverlay/35.v3.gif",
    "compPanels/mediaPlayer/patternOverlay/35.png": "/compPanels/mediaPlayer/patternOverlay/35.v5.png",
    "compPanels/mediaPlayer/patternOverlay/36.gif": "/compPanels/mediaPlayer/patternOverlay/36.v1.gif",
    "compPanels/mediaPlayer/patternOverlay/36.png": "/compPanels/mediaPlayer/patternOverlay/36.v5.png",
    "compPanels/mediaPlayer/patternOverlay/37.gif": "/compPanels/mediaPlayer/patternOverlay/37.gif",
    "compPanels/mediaPlayer/patternOverlay/37.png": "/compPanels/mediaPlayer/patternOverlay/37.v5.png",
    "compPanels/mediaPlayer/patternOverlay/38.gif": "/compPanels/mediaPlayer/patternOverlay/38.gif",
    "compPanels/mediaPlayer/patternOverlay/38.png": "/compPanels/mediaPlayer/patternOverlay/38.v5.png",
    "compPanels/mediaPlayer/patternOverlay/39.gif": "/compPanels/mediaPlayer/patternOverlay/39.gif",
    "compPanels/mediaPlayer/patternOverlay/39.png": "/compPanels/mediaPlayer/patternOverlay/39.v5.png",
    "compPanels/mediaPlayer/patternOverlay/4.gif": "/compPanels/mediaPlayer/patternOverlay/4.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/4.png": "/compPanels/mediaPlayer/patternOverlay/4.v3.png",
    "compPanels/mediaPlayer/patternOverlay/40.png": "/compPanels/mediaPlayer/patternOverlay/40.v5.png",
    "compPanels/mediaPlayer/patternOverlay/41.gif": "/compPanels/mediaPlayer/patternOverlay/41.gif",
    "compPanels/mediaPlayer/patternOverlay/41.png": "/compPanels/mediaPlayer/patternOverlay/41.v5.png",
    "compPanels/mediaPlayer/patternOverlay/42.gif": "/compPanels/mediaPlayer/patternOverlay/42.gif",
    "compPanels/mediaPlayer/patternOverlay/42.png": "/compPanels/mediaPlayer/patternOverlay/42.v5.png",
    "compPanels/mediaPlayer/patternOverlay/43.gif": "/compPanels/mediaPlayer/patternOverlay/43.gif",
    "compPanels/mediaPlayer/patternOverlay/43.png": "/compPanels/mediaPlayer/patternOverlay/43.v5.png",
    "compPanels/mediaPlayer/patternOverlay/44.gif": "/compPanels/mediaPlayer/patternOverlay/44.gif",
    "compPanels/mediaPlayer/patternOverlay/44.png": "/compPanels/mediaPlayer/patternOverlay/44.v5.png",
    "compPanels/mediaPlayer/patternOverlay/45.gif": "/compPanels/mediaPlayer/patternOverlay/45.gif",
    "compPanels/mediaPlayer/patternOverlay/45.png": "/compPanels/mediaPlayer/patternOverlay/45.v5.png",
    "compPanels/mediaPlayer/patternOverlay/46.png": "/compPanels/mediaPlayer/patternOverlay/46.v5.png",
    "compPanels/mediaPlayer/patternOverlay/47.png": "/compPanels/mediaPlayer/patternOverlay/47.v5.png",
    "compPanels/mediaPlayer/patternOverlay/48.png": "/compPanels/mediaPlayer/patternOverlay/48.v5.png",
    "compPanels/mediaPlayer/patternOverlay/49.png": "/compPanels/mediaPlayer/patternOverlay/49.v5.png",
    "compPanels/mediaPlayer/patternOverlay/5.gif": "/compPanels/mediaPlayer/patternOverlay/5.gif",
    "compPanels/mediaPlayer/patternOverlay/5.png": "/compPanels/mediaPlayer/patternOverlay/5.v5.png",
    "compPanels/mediaPlayer/patternOverlay/50.png": "/compPanels/mediaPlayer/patternOverlay/50.v5.png",
    "compPanels/mediaPlayer/patternOverlay/51.png": "/compPanels/mediaPlayer/patternOverlay/51.v5.png",
    "compPanels/mediaPlayer/patternOverlay/52.png": "/compPanels/mediaPlayer/patternOverlay/52.v5.png",
    "compPanels/mediaPlayer/patternOverlay/53.png": "/compPanels/mediaPlayer/patternOverlay/53.v5.png",
    "compPanels/mediaPlayer/patternOverlay/54.png": "/compPanels/mediaPlayer/patternOverlay/54.v5.png",
    "compPanels/mediaPlayer/patternOverlay/55.gif": "/compPanels/mediaPlayer/patternOverlay/55.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/56.png": "/compPanels/mediaPlayer/patternOverlay/56.v5.png",
    "compPanels/mediaPlayer/patternOverlay/57.png": "/compPanels/mediaPlayer/patternOverlay/57.v5.png",
    "compPanels/mediaPlayer/patternOverlay/58.png": "/compPanels/mediaPlayer/patternOverlay/58.v5.png",
    "compPanels/mediaPlayer/patternOverlay/59.png": "/compPanels/mediaPlayer/patternOverlay/59.v5.png",
    "compPanels/mediaPlayer/patternOverlay/6.png": "/compPanels/mediaPlayer/patternOverlay/6.v5.png",
    "compPanels/mediaPlayer/patternOverlay/60.gif": "/compPanels/mediaPlayer/patternOverlay/60.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/7.gif": "/compPanels/mediaPlayer/patternOverlay/7.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/7.png": "/compPanels/mediaPlayer/patternOverlay/7.v2.png",
    "compPanels/mediaPlayer/patternOverlay/8.gif": "/compPanels/mediaPlayer/patternOverlay/8.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/8.png": "/compPanels/mediaPlayer/patternOverlay/8.v3.png",
    "compPanels/mediaPlayer/patternOverlay/9.gif": "/compPanels/mediaPlayer/patternOverlay/9.v5.gif",
    "compPanels/mediaPlayer/patternOverlay/9.png": "/compPanels/mediaPlayer/patternOverlay/9.v3.png",
    "compPanels/pagination/Pagination_Alignment_Spread.svg": "/compPanels/pagination/Pagination_Alignment_Spread.svg",
    "compPanels/pagination/Pagination_Mode_Arrows.svg": "/compPanels/pagination/Pagination_Mode_Arrows.svg",
    "compPanels/pagination/Pagination_Mode_Click.svg": "/compPanels/pagination/Pagination_Mode_Click.svg",
    "compPanels/pagination/Pagination_Mode_Input.svg": "/compPanels/pagination/Pagination_Mode_Input.svg",
    "compPanels/paypalButton/paypalButtonBuyLarge.png": "/compPanels/paypalButton/paypalButtonBuyLarge.png",
    "compPanels/paypalButton/paypalButtonBuySmall.png": "/compPanels/paypalButton/paypalButtonBuySmall.png",
    "compPanels/paypalButton/paypalButtonBuyWithCreditCards.png": "/compPanels/paypalButton/paypalButtonBuyWithCreditCards.v2.png",
    "compPanels/paypalButton/paypalButtonDonateLarge.png": "/compPanels/paypalButton/paypalButtonDonateLarge.v2.png",
    "compPanels/paypalButton/paypalButtonDonateSmall.png": "/compPanels/paypalButton/paypalButtonDonateSmall.v2.png",
    "compPanels/paypalButton/paypalButtonDonateWithCreditCards.png": "/compPanels/paypalButton/paypalButtonDonateWithCreditCards.v2.png",
    "compPanels/pinItButton/pin-it-gray-small.png": "/compPanels/pinItButton/pin-it-gray-small.png",
    "compPanels/pinItButton/pin-it-gray-with-counter-hor-small.png": "/compPanels/pinItButton/pin-it-gray-with-counter-hor-small.png",
    "compPanels/pinItButton/pin-it-gray-with-counter-ver-small.png": "/compPanels/pinItButton/pin-it-gray-with-counter-ver-small.png",
    "compPanels/pinItButton/pin-it-red-small.png": "/compPanels/pinItButton/pin-it-red-small.v3.png",
    "compPanels/pinItButton/pin-it-red-with-counter-hor-small.png": "/compPanels/pinItButton/pin-it-red-with-counter-hor-small.png",
    "compPanels/pinItButton/pin-it-red-with-counter-ver-small.png": "/compPanels/pinItButton/pin-it-red-with-counter-ver-small.v2.png",
    "compPanels/pinItButton/pin-it-white-small.png": "/compPanels/pinItButton/pin-it-white-small.png",
    "compPanels/pinItButton/pin-it-white-with-counter-hor-small.png": "/compPanels/pinItButton/pin-it-white-with-counter-hor-small.png",
    "compPanels/pinItButton/pin-it-white-with-counter-ver-small.png": "/compPanels/pinItButton/pin-it-white-with-counter-ver-small.png",
    "compPanels/pinItButton/report.pdf": "/compPanels/pinItButton/report.pdf",
    "compPanels/popupGridLayoutPanel/popupOverlayBlue.png": "/compPanels/popupGridLayoutPanel/popupOverlayBlue.png",
    "compPanels/popupGridLayoutPanel/popupOverlayWhite.png": "/compPanels/popupGridLayoutPanel/popupOverlayWhite.png",
    "compPanels/ratings/ratingIcons.png": "/compPanels/ratings/ratingIcons.png",
    "compPanels/repeater/layout-align-icon-center-selected-static.gif": "/compPanels/repeater/layout-align-icon-center-selected-static.gif",
    "compPanels/repeater/layout-align-icon-center-selected.gif": "/compPanels/repeater/layout-align-icon-center-selected.gif",
    "compPanels/repeater/layout-align-icon-center-static.gif": "/compPanels/repeater/layout-align-icon-center-static.gif",
    "compPanels/repeater/layout-align-icon-center.gif": "/compPanels/repeater/layout-align-icon-center.gif",
    "compPanels/repeater/layout-align-icon-justify-selected-static.gif": "/compPanels/repeater/layout-align-icon-justify-selected-static.gif",
    "compPanels/repeater/layout-align-icon-justify-selected.gif": "/compPanels/repeater/layout-align-icon-justify-selected.gif",
    "compPanels/repeater/layout-align-icon-justify-static.gif": "/compPanels/repeater/layout-align-icon-justify-static.gif",
    "compPanels/repeater/layout-align-icon-justify.gif": "/compPanels/repeater/layout-align-icon-justify.gif",
    "compPanels/repeater/layout-align-icon-left-selected-static.gif": "/compPanels/repeater/layout-align-icon-left-selected-static.gif",
    "compPanels/repeater/layout-align-icon-left-selected.gif": "/compPanels/repeater/layout-align-icon-left-selected.gif",
    "compPanels/repeater/layout-align-icon-left-static.gif": "/compPanels/repeater/layout-align-icon-left-static.gif",
    "compPanels/repeater/layout-align-icon-left.gif": "/compPanels/repeater/layout-align-icon-left.gif",
    "compPanels/repeater/layout-align-icon-right-selected-static.gif": "/compPanels/repeater/layout-align-icon-right-selected-static.gif",
    "compPanels/repeater/layout-align-icon-right-selected.gif": "/compPanels/repeater/layout-align-icon-right-selected.gif",
    "compPanels/repeater/layout-align-icon-right-static.gif": "/compPanels/repeater/layout-align-icon-right-static.gif",
    "compPanels/repeater/layout-align-icon-right.gif": "/compPanels/repeater/layout-align-icon-right.gif",
    "compPanels/skypeCallButton/skypeButtonBlue.png": "/compPanels/skypeCallButton/skypeButtonBlue.png",
    "compPanels/skypeCallButton/skypeButtonWhite.png": "/compPanels/skypeCallButton/skypeButtonWhite.png",
    "compPanels/skypeCallButton/skypeCallButtonBlue.png": "/compPanels/skypeCallButton/skypeCallButtonBlue.png",
    "compPanels/skypeCallButton/SkypeCallButtonBlue.png": "/compPanels/skypeCallButton/SkypeCallButtonBlue.v2.png",
    "compPanels/skypeCallButton/skypeCallButtonWhite.png": "/compPanels/skypeCallButton/skypeCallButtonWhite.png",
    "compPanels/skypeCallButton/SkypeCallButtonWhite.png": "/compPanels/skypeCallButton/SkypeCallButtonWhite.v2.png",
    "compPanels/soundCloud/SoundCloud_NoPhoto.png": "/compPanels/soundCloud/SoundCloud_NoPhoto.v1.png",
    "compPanels/soundCloud/SoundCloud_WithPhoto.png": "/compPanels/soundCloud/SoundCloud_WithPhoto.png",
    "compPanels/spotifyApp/spotifyApp.png": "/compPanels/spotifyApp/spotifyApp.png",
    "compPanels/spotifyFollow/spotifyFollow.png": "/compPanels/spotifyFollow/spotifyFollow.v3.png",
    "compPanels/spotifyFollow/spotifyFollowArtist.png": "/compPanels/spotifyFollow/spotifyFollowArtist.v5.png",
    "compPanels/spotifyFollow/spotifyFollowCount.png": "/compPanels/spotifyFollow/spotifyFollowCount.v3.png",
    "compPanels/spotifyFollow/spotifyFollowCountArtist.png": "/compPanels/spotifyFollow/spotifyFollowCountArtist.v5.png",
    "compPanels/spotifyPlayer/spotify_compact.png": "/compPanels/spotifyPlayer/spotify_compact.png",
    "compPanels/spotifyPlayer/spotify_dark_cover_stage.png": "/compPanels/spotifyPlayer/spotify_dark_cover_stage.png",
    "compPanels/spotifyPlayer/spotify_dark_list_on_stage.png": "/compPanels/spotifyPlayer/spotify_dark_list_on_stage.png",
    "compPanels/spotifyPlayer/spotify_light_cover_stage.png": "/compPanels/spotifyPlayer/spotify_light_cover_stage.png",
    "compPanels/spotifyPlayer/spotify_list__light_on_stage.png": "/compPanels/spotifyPlayer/spotify_list__light_on_stage.png",
    "compPanels/twitterFollow/twitter_follow_counter_layout.png": "/compPanels/twitterFollow/twitter_follow_counter_layout.v3.png",
    "compPanels/twitterFollow/twitter_follow_layout.png": "/compPanels/twitterFollow/twitter_follow_layout.v3.png",
    "compPanels/twitterFollow/twitter_follow_name_counter_layout.png": "/compPanels/twitterFollow/twitter_follow_name_counter_layout.v3.png",
    "compPanels/twitterFollow/twitter_follow_name_layout.png": "/compPanels/twitterFollow/twitter_follow_name_layout.v3.png",
    "compPanels/twitterTweet/twitter_tweet_hor_layout.png": "/compPanels/twitterTweet/twitter_tweet_hor_layout.png",
    "compPanels/twitterTweet/twitter_tweet_layout.png": "/compPanels/twitterTweet/twitter_tweet_layout.png",
    "compPanels/twitterTweet/twitter_tweet_ver_layout.png": "/compPanels/twitterTweet/twitter_tweet_ver_layout.png",
    "compPanels/vkShareButton/vkShareButton.png": "/compPanels/vkShareButton/vkShareButton.v4.png",
    "compPanels/vkShareButton/vkShareButtonWithoutCounter.png": "/compPanels/vkShareButton/vkShareButtonWithoutCounter.v4.png",
    "compPanels/vkShareButton/vkShareIcon.png": "/compPanels/vkShareButton/vkShareIcon.v4.png",
    "compPanels/vkShareButton/vkShareLink.png": "/compPanels/vkShareButton/vkShareLink.v4.png",
    "compPanels/vkShareButton/vkShareLinkWithoutIcon.png": "/compPanels/vkShareButton/vkShareLinkWithoutIcon.v4.png",
    "compPanels/widgetPresetsPanel/presetFallbackImage.svg": "/compPanels/widgetPresetsPanel/presetFallbackImage.svg",
    "compPanels/widgetPresetsPanel/selectedPresetIcon.png": "/compPanels/widgetPresetsPanel/selectedPresetIcon.png",
    "compPanels/youtubeSubscribe/youtubeSubscriberDefault.png": "/compPanels/youtubeSubscribe/youtubeSubscriberDefault.v2.png",
    "compPanels/youtubeSubscribe/youtubeSubscriberFull.png": "/compPanels/youtubeSubscribe/youtubeSubscriberFull.v2.png",
    "designPanel/panelTypes/default_pin_icon.png": "/designPanel/panelTypes/default_pin_icon.v2.png",
    "designPanel/panelTypes/google_maps_pin_icon.png": "/designPanel/panelTypes/google_maps_pin_icon.png",
    "editorTours/adiToEditorTour/add.mp4": "/editorTours/adiToEditorTour/add.mp4",
    "editorTours/adiToEditorTour/dragRotate.mp4": "/editorTours/adiToEditorTour/dragRotate.mp4",
    "editorTours/adiToEditorTour/gfpp.mp4": "/editorTours/adiToEditorTour/gfpp.mp4",
    "editorTours/adiToEditorTour/intro.mp4": "/editorTours/adiToEditorTour/intro.mp4",
    "editorTours/adiToEditorTour/zoomOut.mp4": "/editorTours/adiToEditorTour/zoomOut.mp4",
    "editorTours/editorNewUsersTour/mobile.mp4": "/editorTours/editorNewUsersTour/mobile.v2.mp4",
    "editorTours/editorToEditorTour/addSec.mp4": "/editorTours/editorToEditorTour/addSec.mp4",
    "editorTours/editorToEditorTour/changeSec.mp4": "/editorTours/editorToEditorTour/changeSec.mp4",
    "editorTours/editorToEditorTour/intro.mp4": "/editorTours/editorToEditorTour/intro.mp4",
    "editorTours/editorToEditorTour/quickEdit.mp4": "/editorTours/editorToEditorTour/quickEdit.mp4",
    "editorTours/welcomeTour/addPanel.mp4": "/editorTours/welcomeTour/addPanel.mp4",
    "editorTours/welcomeTour/addPanelRetina.mp4": "/editorTours/welcomeTour/addPanelRetina.mp4",
    "editorTours/welcomeTour/mobile.mp4": "/editorTours/welcomeTour/mobile.mp4",
    "editorTours/welcomeTour/mobileRetina.mp4": "/editorTours/welcomeTour/mobileRetina.mp4",
    "editorTours/welcomeTour/pages.mp4": "/editorTours/welcomeTour/pages.mp4",
    "editorTours/welcomeTour/pagesRetina.mp4": "/editorTours/welcomeTour/pagesRetina.mp4",
    "editorTours/welcomeTour/smartWriter.mp4": "/editorTours/welcomeTour/smartWriter.mp4",
    "editorTours/welcomeTour/smartWriterRetina.mp4": "/editorTours/welcomeTour/smartWriterRetina.mp4",
    "editorTours/welcomeTour/textOnStage.mp4": "/editorTours/welcomeTour/textOnStage.mp4",
    "editorTours/welcomeTour/textOnStageRetina.mp4": "/editorTours/welcomeTour/textOnStageRetina.mp4",
    "editorTours/welcomeTour/themeManager.mp4": "/editorTours/welcomeTour/themeManager.mp4",
    "editorTours/welcomeTour/themeManagerRetina.mp4": "/editorTours/welcomeTour/themeManagerRetina.mp4",
    "editorTours/welcomeTour/viewAndPublish.mp4": "/editorTours/welcomeTour/viewAndPublish.mp4",
    "editorTours/welcomeTour/viewAndPublishRetina.mp4": "/editorTours/welcomeTour/viewAndPublishRetina.mp4",
    "editorTours/welcomeTour/welcomeTourModal.jpg": "/editorTours/welcomeTour/welcomeTourModal.jpg",
    "editorTours/welcomeTour/welcomeTourModalRetina.jpg": "/editorTours/welcomeTour/welcomeTourModalRetina.jpg",
    "firstTimeExperience/pagesPanel/actionBar.gif": "/firstTimeExperience/pagesPanel/actionBar.gif",
    "firstTimeExperience/pagesPanel/actionBarRetina.gif": "/firstTimeExperience/pagesPanel/actionBarRetina.gif",
    "firstTimeExperience/pagesPanel/reorder.gif": "/firstTimeExperience/pagesPanel/reorder.gif",
    "firstTimeExperience/pagesPanel/reorderRetina.gif": "/firstTimeExperience/pagesPanel/reorderRetina.gif",
    "firstTimeExperience/pagesPanel/selectPage.gif": "/firstTimeExperience/pagesPanel/selectPage.gif",
    "firstTimeExperience/pagesPanel/selectPageRetina.gif": "/firstTimeExperience/pagesPanel/selectPageRetina.gif",
    "imageUploadToStage/loader_150x150.gif": "/imageUploadToStage/loader_150x150.gif",
    "imageUploadToStage/loader_300x300.gif": "/imageUploadToStage/loader_300x300.gif",
    "imageUploadToStage/loader_600x600.gif": "/imageUploadToStage/loader_600x600.gif",
    "mobileEditor/mobileIcons/backToTop.png": "/mobileEditor/mobileIcons/backToTop.png",
    "mobileEditor/mobileIcons/mobileView.png": "/mobileEditor/mobileIcons/mobileView.png",
    "mobileEditor/mobileSettings/backToTop.gif": "/mobileEditor/mobileSettings/backToTop.v3.gif",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_address.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_address.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_address_DS.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_address_DS.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_email.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_email.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_email_DS.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_email_DS.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_navigation_menu.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_navigation_menu.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_navigation_menu_DS.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_navigation_menu_DS.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_Phone_number.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_Phone_number.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_Phone_number_DS.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_Phone_number_DS.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_social.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_social.svg",
    "mobileEditor/mobileSettings/mobileActionBarIcons/mba_social_DS.svg": "/mobileEditor/mobileSettings/mobileActionBarIcons/mba_social_DS.svg",
    "mobileEditor/mobileSettings/mobilePreviewActive.png": "/mobileEditor/mobileSettings/mobilePreviewActive.v2.png",
    "mobileEditor/mobileSettings/mobilePreviewDisable.png": "/mobileEditor/mobileSettings/mobilePreviewDisable.v2.png",
    "mobileEditor/mobileSettings/mobilePreviewQuickActionButtons.png": "/mobileEditor/mobileSettings/mobilePreviewQuickActionButtons.png",
    "mobileEditor/mobileSettings/MobileWelcomePopup.gif": "/mobileEditor/mobileSettings/MobileWelcomePopup.gif",
    "mobileEditor/mobileSettings/nonOptimized.png": "/mobileEditor/mobileSettings/nonOptimized.png",
    "mobileEditor/mobileSettings/optimized.gif": "/mobileEditor/mobileSettings/optimized.gif",
    "mobileEditor/mobileSettings/optimized.png": "/mobileEditor/mobileSettings/optimized.png",
    "mobileEditor/mobileWizard/back2TopNo.jpg": "/mobileEditor/mobileWizard/back2TopNo.v2.jpg",
    "mobileEditor/mobileWizard/back2TopYes.jpg": "/mobileEditor/mobileWizard/back2TopYes.v2.jpg",
    "mobileEditor/mobileWizard/headerEffectsDisappears.jpg": "/mobileEditor/mobileWizard/headerEffectsDisappears.v2.jpg",
    "mobileEditor/mobileWizard/headerEffectsFadesOut.jpg": "/mobileEditor/mobileWizard/headerEffectsFadesOut.v2.jpg",
    "mobileEditor/mobileWizard/headerEffectsFixed.jpg": "/mobileEditor/mobileWizard/headerEffectsFixed.v2.jpg",
    "mobileEditor/mobileWizard/headerEffectsScroll.jpg": "/mobileEditor/mobileWizard/headerEffectsScroll.v2.jpg",
    "mobileEditor/mobileWizard/mobile-wizard-last-screen.png": "/mobileEditor/mobileWizard/mobile-wizard-last-screen.png",
    "mobileEditor/mobileWizard/mobile-wizard-welcome.png": "/mobileEditor/mobileWizard/mobile-wizard-welcome.v2.png",
    "mobileEditor/mobileWizard/qabFab.jpg": "/mobileEditor/mobileWizard/qabFab.v2.jpg",
    "mobileEditor/mobileWizard/qabMab.jpg": "/mobileEditor/mobileWizard/qabMab.v2.jpg",
    "mobileEditor/mobileWizard/qabMab1.jpg": "/mobileEditor/mobileWizard/qabMab1.jpg",
    "mobileEditor/mobileWizard/qabNone.jpg": "/mobileEditor/mobileWizard/qabNone.v2.jpg",
    "mobileEditor/mobileWizard/tinyMenuCenter.jpg": "/mobileEditor/mobileWizard/tinyMenuCenter.v2.jpg",
    "mobileEditor/mobileWizard/tinyMenuLeft.jpg": "/mobileEditor/mobileWizard/tinyMenuLeft.v2.jpg",
    "mobileEditor/mobileWizard/tinyMenuRight.jpg": "/mobileEditor/mobileWizard/tinyMenuRight.v2.jpg",
    "notifications/sounds/Note06.mp3": "/notifications/sounds/Note06.mp3",
    "notifications/sounds/NoteB03.mp3": "/notifications/sounds/NoteB03.mp3",
    "notifications/sounds/NoteB04.mp3": "/notifications/sounds/NoteB04.mp3",
    "notifications/sounds/NoteC03.mp3": "/notifications/sounds/NoteC03.mp3",
    "notifications/sounds/notificationSoundSample.mp3": "/notifications/sounds/notificationSoundSample.mp3",
    "panels/firstTimePanels/anchorFirstTime.gif": "/panels/firstTimePanels/anchorFirstTime.gif",
    "panels/firstTimePanels/anchorFirstTime.png": "/panels/firstTimePanels/anchorFirstTime.png",
    "panels/firstTimePanels/anchorFirstTime.retina.gif": "/panels/firstTimePanels/anchorFirstTime.retina.gif",
    "panels/firstTimePanels/columnFirstTime.gif": "/panels/firstTimePanels/columnFirstTime.gif",
    "panels/firstTimePanels/columnFirstTime.png": "/panels/firstTimePanels/columnFirstTime.png",
    "panels/firstTimePanels/columnFirstTime.retina.gif": "/panels/firstTimePanels/columnFirstTime.retina.gif",
    "panels/firstTimePanels/hoverBoxFirstTime.gif": "/panels/firstTimePanels/hoverBoxFirstTime.gif",
    "panels/firstTimePanels/hoverBoxFirstTime.retina.gif": "/panels/firstTimePanels/hoverBoxFirstTime.retina.gif",
    "panels/firstTimePanels/hoverBoxFirstTimeStatic.png": "/panels/firstTimePanels/hoverBoxFirstTimeStatic.png",
    "panels/firstTimePanels/hoverInteractionFirstTime.gif": "/panels/firstTimePanels/hoverInteractionFirstTime.gif",
    "panels/firstTimePanels/hoverInteractionFirstTime.retina.gif": "/panels/firstTimePanels/hoverInteractionFirstTime.retina.gif",
    "panels/firstTimePanels/menuContainerFirstTime.gif": "/panels/firstTimePanels/menuContainerFirstTime.gif",
    "panels/firstTimePanels/menuContainerFirstTime.retina.gif": "/panels/firstTimePanels/menuContainerFirstTime.retina.gif",
    "panels/firstTimePanels/menuFirstTime.gif": "/panels/firstTimePanels/menuFirstTime.gif",
    "panels/firstTimePanels/menuFirstTime.retina.gif": "/panels/firstTimePanels/menuFirstTime.retina.gif",
    "panels/firstTimePanels/menuFirstTime.svg": "/panels/firstTimePanels/menuFirstTime.svg",
    "panels/firstTimePanels/popUpCloseButtonFirstTime.gif": "/panels/firstTimePanels/popUpCloseButtonFirstTime.v2.gif",
    "panels/firstTimePanels/popUpCloseButtonFirstTime.png": "/panels/firstTimePanels/popUpCloseButtonFirstTime.png",
    "panels/firstTimePanels/popUpCloseButtonFirstTime.retina.gif": "/panels/firstTimePanels/popUpCloseButtonFirstTime.retina.gif",
    "panels/firstTimePanels/popUpFirstTime.gif": "/panels/firstTimePanels/popUpFirstTime.v2.gif",
    "panels/firstTimePanels/popUpFirstTime.png": "/panels/firstTimePanels/popUpFirstTime.png",
    "panels/firstTimePanels/popUpFirstTime.retina.gif": "/panels/firstTimePanels/popUpFirstTime.retina.gif",
    "panels/firstTimePanels/slideShowFirstTime.gif": "/panels/firstTimePanels/slideShowFirstTime.v3.gif",
    "panels/firstTimePanels/slideShowFirstTime.retina.gif": "/panels/firstTimePanels/slideShowFirstTime.retina.gif",
    "panels/firstTimePanels/stateBoxFirstTime.gif": "/panels/firstTimePanels/stateBoxFirstTime.gif",
    "panels/firstTimePanels/stateBoxFirstTime.retina.gif": "/panels/firstTimePanels/stateBoxFirstTime.retina.gif",
    "panels/firstTimePanels/verticalAnchorMenuFirstTime.gif": "/panels/firstTimePanels/verticalAnchorMenuFirstTime.gif",
    "panels/firstTimePanels/verticalAnchorMenuFirstTime.png": "/panels/firstTimePanels/verticalAnchorMenuFirstTime.png",
    "panels/firstTimePanels/verticalAnchorMenuFirstTime.retina.gif": "/panels/firstTimePanels/verticalAnchorMenuFirstTime.retina.gif",
    "panels/focusPanels/addRemoveFromBar.gif": "/panels/focusPanels/addRemoveFromBar.v2.gif",
    "panels/focusPanels/addRemoveFromBarRetina.gif": "/panels/focusPanels/addRemoveFromBarRetina.v2.gif",
    "panels/focusPanels/app-installer-progress-panel.gif": "/panels/focusPanels/app-installer-progress-panel.gif",
    "panels/focusPanels/app-installer-progress-panel_x1.gif": "/panels/focusPanels/app-installer-progress-panel_x1.gif",
    "panels/focusPanels/app-installer-progress-panel_x2.gif": "/panels/focusPanels/app-installer-progress-panel_x2.gif",
    "panels/focusPanels/app-uninstaller-progress-retina.gif": "/panels/focusPanels/app-uninstaller-progress-retina.gif",
    "panels/focusPanels/app-unisntaller-progress.gif": "/panels/focusPanels/app-unisntaller-progress.gif",
    "panels/focusPanels/appLoader.gif": "/panels/focusPanels/appLoader.v2.gif",
    "panels/focusPanels/appLoaderRetina.gif": "/panels/focusPanels/appLoaderRetina.v2.gif",
    "panels/focusPanels/appLoaderSuccess.gif": "/panels/focusPanels/appLoaderSuccess.v2.gif",
    "panels/focusPanels/appLoaderSuccessRetina.gif": "/panels/focusPanels/appLoaderSuccessRetina.v2.gif",
    "panels/focusPanels/checkYourMobileSite.gif": "/panels/focusPanels/checkYourMobileSite.v2.gif",
    "panels/focusPanels/columnsOutOfGrid.gif": "/panels/focusPanels/columnsOutOfGrid.v4.gif",
    "panels/focusPanels/columnsOutOfGrid.retina.gif": "/panels/focusPanels/columnsOutOfGrid.retina.v2.gif",
    "panels/focusPanels/memberAreaAdded.gif": "/panels/focusPanels/memberAreaAdded.v2.gif",
    "panels/focusPanels/memberAreaAddedRetina.gif": "/panels/focusPanels/memberAreaAddedRetina.v2.gif",
    "panels/focusPanels/menuContainerUpgrade.gif": "/panels/focusPanels/menuContainerUpgrade.gif",
    "panels/focusPanels/menuContainerUpgradeRetina.gif": "/panels/focusPanels/menuContainerUpgradeRetina.gif",
    "panels/focusPanels/musicPromotionPopup.png": "/panels/focusPanels/musicPromotionPopup.v2.png",
    "panels/focusPanels/musicPromotionPopupRetina.png": "/panels/focusPanels/musicPromotionPopupRetina.v2.png",
    "panels/focusPanels/new-app-installer-progress-panel_x1.gif": "/panels/focusPanels/new-app-installer-progress-panel_x1.v2.gif",
    "panels/focusPanels/new-app-installer-progress-panel_x2.gif": "/panels/focusPanels/new-app-installer-progress-panel_x2.v2.gif",
    "panels/focusPanels/outOfGridDesktop.gif": "/panels/focusPanels/outOfGridDesktop.v2.gif",
    "panels/focusPanels/pageOutOfGrid.gif": "/panels/focusPanels/pageOutOfGrid.v2.gif",
    "panels/focusPanels/pageOutOfGrid.retina.gif": "/panels/focusPanels/pageOutOfGrid.retina.gif",
    "panels/focusPanels/photoPromotionPopup.png": "/panels/focusPanels/photoPromotionPopup.v3.png",
    "panels/focusPanels/photoPromotionPopupRetina.png": "/panels/focusPanels/photoPromotionPopupRetina.v2.png",
    "panels/interactionTransitions/easeInDefault.png": "/panels/interactionTransitions/easeInDefault.png",
    "panels/interactionTransitions/easeInHover.gif": "/panels/interactionTransitions/easeInHover.gif",
    "panels/interactionTransitions/easeInOutDefault.png": "/panels/interactionTransitions/easeInOutDefault.png",
    "panels/interactionTransitions/easeInOutHover.gif": "/panels/interactionTransitions/easeInOutHover.gif",
    "panels/interactionTransitions/easeInOutSelected.png": "/panels/interactionTransitions/easeInOutSelected.png",
    "panels/interactionTransitions/easeInOutSelectedHover.gif": "/panels/interactionTransitions/easeInOutSelectedHover.gif",
    "panels/interactionTransitions/easeInSelected.png": "/panels/interactionTransitions/easeInSelected.png",
    "panels/interactionTransitions/easeInSelectedHover.gif": "/panels/interactionTransitions/easeInSelectedHover.gif",
    "panels/interactionTransitions/easeOutDefault.png": "/panels/interactionTransitions/easeOutDefault.png",
    "panels/interactionTransitions/easeOutHover.gif": "/panels/interactionTransitions/easeOutHover.gif",
    "panels/interactionTransitions/easeOutSelected.png": "/panels/interactionTransitions/easeOutSelected.png",
    "panels/interactionTransitions/easeOutSelectedHover.gif": "/panels/interactionTransitions/easeOutSelectedHover.gif",
    "panels/interactionTransitions/elasticDefault.png": "/panels/interactionTransitions/elasticDefault.png",
    "panels/interactionTransitions/elasticHover.gif": "/panels/interactionTransitions/elasticHover.gif",
    "panels/interactionTransitions/elasticSelected.png": "/panels/interactionTransitions/elasticSelected.png",
    "panels/interactionTransitions/elasticSelectedHover.gif": "/panels/interactionTransitions/elasticSelectedHover.gif",
    "panels/interactionTransitions/linearDefault.png": "/panels/interactionTransitions/linearDefault.png",
    "panels/interactionTransitions/linearHover.gif": "/panels/interactionTransitions/linearHover.gif",
    "panels/interactionTransitions/linearSelected.png": "/panels/interactionTransitions/linearSelected.png",
    "panels/interactionTransitions/linearSelectedHover.gif": "/panels/interactionTransitions/linearSelectedHover.gif",
    "panels/mobileDiscoverability/Preview.gif": "/panels/mobileDiscoverability/Preview.gif",
    "panels/pages/add_dynamic_pages_intro.png": "/panels/pages/add_dynamic_pages_intro.v3.png",
    "panels/pages/add_dynamic_pages_intro@x2.png": "/panels/pages/add_dynamic_pages_intro@x2.v1.png",
    "panels/pages/custom_menus_intro.svg": "/panels/pages/custom_menus_intro.svg",
    "panels/pages/htmlPreviewThumbnail.gif": "/panels/pages/htmlPreviewThumbnail.gif",
    "panels/pages/newWorkspace_add_dynamic_pages_intro.png": "/panels/pages/newWorkspace_add_dynamic_pages_intro.v2.png",
    "panels/pages/newWorkspace_add_dynamic_pages_intro@x2.png": "/panels/pages/newWorkspace_add_dynamic_pages_intro@x2.v2.png",
    "platform/widgetSection/add-panel-robot.png": "/platform/widgetSection/add-panel-robot.png",
    "platform/widgetSection/widget-color-1.png": "/platform/widgetSection/widget-color-1.v2.png",
    "platform/widgetSection/widget-color-1.square.png": "/platform/widgetSection/widget-color-1.square.v1.png",
    "platform/widgetSection/widget-color-2.png": "/platform/widgetSection/widget-color-2.v2.png",
    "platform/widgetSection/widget-color-2.square.png": "/platform/widgetSection/widget-color-2.square.v1.png",
    "platform/widgetSection/widget-color-3.png": "/platform/widgetSection/widget-color-3.v2.png",
    "platform/widgetSection/widget-color-3.square.png": "/platform/widgetSection/widget-color-3.square.v1.png",
    "platform/widgetSection/widget-color-4.png": "/platform/widgetSection/widget-color-4.v2.png",
    "platform/widgetSection/widget-color-4.square.png": "/platform/widgetSection/widget-color-4.square.v1.png",
    "platform/widgetSection/widget-color-5.png": "/platform/widgetSection/widget-color-5.v2.png",
    "platform/widgetSection/widget-color-5.square.png": "/platform/widgetSection/widget-color-5.square.v1.png",
    "quickEdit/googleMap.jpg": "/quickEdit/googleMap.jpg",
    "quickEdit/googleMaps.jpg": "/quickEdit/googleMaps.jpg",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-0-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-0-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-1-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-1-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-2-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-2-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-3-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-3-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-4-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-4-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-5-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-5-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-6-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-6-icon.png",
    "rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-7-icon.png": "/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-7-icon.png",
    "rEditor/mediaManagerPanel/create-image-action.png": "/rEditor/mediaManagerPanel/create-image-action.png",
    "rEditor/mediaManagerPanel/create-video-action.png": "/rEditor/mediaManagerPanel/create-video-action.png",
    "rEditor/mediaManagerPanel/createImageAction.png": "/rEditor/mediaManagerPanel/createImageAction.png",
    "rEditor/mediaManagerPanel/createVideoAction.png": "/rEditor/mediaManagerPanel/createVideoAction.png",
    "rEditor/mobileFrame/background-pattern.jpg": "/rEditor/mobileFrame/background-pattern.jpg",
    "rEditor/mobileFrame/phone-bg.png": "/rEditor/mobileFrame/phone-bg.v4.png",
    "rEditor/mobileFrame/phone-bg@x2.png": "/rEditor/mobileFrame/phone-bg@x2.v4.png",
    "rEditor/mobileFrame/phone-bottom.png": "/rEditor/mobileFrame/phone-bottom.v4.png",
    "rEditor/mobileFrame/phone-bottom@x2.png": "/rEditor/mobileFrame/phone-bottom@x2.v5.png",
    "rEditor/mobileFrame/phone-top.png": "/rEditor/mobileFrame/phone-top.v4.png",
    "rEditor/mobileFrame/phone-top@x2.png": "/rEditor/mobileFrame/phone-top@x2.v5.png",
    "rEditor/myMediaPanel/createImageAction.png": "/rEditor/myMediaPanel/createImageAction.png",
    "rEditor/myMediaPanel/createVideoAction.png": "/rEditor/myMediaPanel/createVideoAction.png",
    "rEditor/myMediaPanel/document.png": "/rEditor/myMediaPanel/document.png",
    "rEditor/myMediaPanel/image.png": "/rEditor/myMediaPanel/image.png",
    "rEditor/myMediaPanel/music.png": "/rEditor/myMediaPanel/music.png",
    "rEditor/myMediaPanel/music_pro.png": "/rEditor/myMediaPanel/music_pro.png",
    "rEditor/rootComps/gfpp/sparklesBoldAnimationBackground.svg": "/rEditor/rootComps/gfpp/sparklesBoldAnimationBackground.v2.svg",
    "rEditor/rootComps/gfpp/sparklesBoldAnimationBackgroundHover.svg": "/rEditor/rootComps/gfpp/sparklesBoldAnimationBackgroundHover.svg",
    "rEditor/rootComps/rulers/corner.png": "/rEditor/rootComps/rulers/corner.png",
    "rEditor/rootComps/rulers/ruler_ticks.png": "/rEditor/rootComps/rulers/ruler_ticks.png",
    "rEditor/rootComps/rulers/ruler_ticks_vertical.png": "/rEditor/rootComps/rulers/ruler_ticks_vertical.png",
    "rEditor/topBar/menubarSitePanel_30.png": "/rEditor/topBar/menubarSitePanel_30.v2.png",
    "rEditor/topBar/menubarSitePanel_40.png": "/rEditor/topBar/menubarSitePanel_40.v2.png",
    "rEditor/topBar/menubarSitePanel_50.png": "/rEditor/topBar/menubarSitePanel_50.png",
    "rEditor/topBar/menubarUpgradePanel_30.png": "/rEditor/topBar/menubarUpgradePanel_30.png",
    "rEditor/topBar/menubarUpgradePanel_40.png": "/rEditor/topBar/menubarUpgradePanel_40.png",
    "rEditor/topBar/menubarUpgradePanel_50.png": "/rEditor/topBar/menubarUpgradePanel_50.png",
    "rEditor/topBar/mobileDesktopIllustration.png": "/rEditor/topBar/mobileDesktopIllustration.png",
    "rEditor/topBar/mobileDesktopIllustration@x2.png": "/rEditor/topBar/mobileDesktopIllustration@x2.png",
    "rEditor/topBar/popupsExitFirstTime.gif": "/rEditor/topBar/popupsExitFirstTime.gif",
    "rEditor/topBar/popupsExitFirstTimeRetina.gif": "/rEditor/topBar/popupsExitFirstTimeRetina.gif",
    "responsive/addPanelData/animationsEmbed/addPanelResponsiveLottieEmbed.png": "/responsive/addPanelData/animationsEmbed/addPanelResponsiveLottieEmbed.png",
    "responsive/addPanelData/animationsEmbed/addPanelResponsiveLottieEmbedDoubledSize.png": "/responsive/addPanelData/animationsEmbed/addPanelResponsiveLottieEmbedDoubledSize.png",
    "responsive/addPanelData/audioPlayer/audioPlayerSection.png": "/responsive/addPanelData/audioPlayer/audioPlayerSection.png",
    "responsive/addPanelData/button/textButtonsSection.png": "/responsive/addPanelData/button/textButtonsSection.png",
    "responsive/addPanelData/button/textButtonsSection_hover.png": "/responsive/addPanelData/button/textButtonsSection_hover.png",
    "responsive/addPanelData/chat/chat1.png": "/responsive/addPanelData/chat/chat1.png",
    "responsive/addPanelData/chat/chat2.png": "/responsive/addPanelData/chat/chat2.png",
    "responsive/addPanelData/chat/chat3.png": "/responsive/addPanelData/chat/chat3.png",
    "responsive/addPanelData/chat/chat4.png": "/responsive/addPanelData/chat/chat4.png",
    "responsive/addPanelData/chat/chat5.png": "/responsive/addPanelData/chat/chat5.png",
    "responsive/addPanelData/chat/chat6.png": "/responsive/addPanelData/chat/chat6.png",
    "responsive/addPanelData/chat/chat7.png": "/responsive/addPanelData/chat/chat7.png",
    "responsive/addPanelData/comments/commentsWelcomeSectionResponsive.jpg": "/responsive/addPanelData/comments/commentsWelcomeSectionResponsive.v1.jpg",
    "responsive/addPanelData/containerBox/containerBoxSection.png": "/responsive/addPanelData/containerBox/containerBoxSection.v2.png",
    "responsive/addPanelData/dashboard/welcome-dashboard.png": "/responsive/addPanelData/dashboard/welcome-dashboard.png",
    "responsive/addPanelData/dataBinding/databaseWelcomeSectionResponsive.png": "/responsive/addPanelData/dataBinding/databaseWelcomeSectionResponsive.v3.png",
    "responsive/addPanelData/embeds/html-embed-code-v4.png": "/responsive/addPanelData/embeds/html-embed-code-v4.png",
    "responsive/addPanelData/embeds/html-embed-code.png": "/responsive/addPanelData/embeds/html-embed-code.v3.png",
    "responsive/addPanelData/embeds/html-embed-website-v4.png": "/responsive/addPanelData/embeds/html-embed-website-v4.png",
    "responsive/addPanelData/embeds/html-embed-website.png": "/responsive/addPanelData/embeds/html-embed-website.v3.png",
    "responsive/addPanelData/embeds/htmp-embed-core.png": "/responsive/addPanelData/embeds/htmp-embed-core.v1.png",
    "responsive/addPanelData/events/eventsWelcomeSectionResponsive.png": "/responsive/addPanelData/events/eventsWelcomeSectionResponsive.v3.png",
    "responsive/addPanelData/expandableMenu/expandableMenu1.png": "/responsive/addPanelData/expandableMenu/expandableMenu1.v2.png",
    "responsive/addPanelData/expandableMenu/expandableMenu2.png": "/responsive/addPanelData/expandableMenu/expandableMenu2.v2.png",
    "responsive/addPanelData/expandableMenu/expandableMenu3.png": "/responsive/addPanelData/expandableMenu/expandableMenu3.v2.png",
    "responsive/addPanelData/expandableMenu/expandableMenu4.png": "/responsive/addPanelData/expandableMenu/expandableMenu4.v2.png",
    "responsive/addPanelData/fileShare/fileShareWelcomeSectionResponsive.jpg": "/responsive/addPanelData/fileShare/fileShareWelcomeSectionResponsive.v1.jpg",
    "responsive/addPanelData/form/conatctForm1.png": "/responsive/addPanelData/form/conatctForm1.png",
    "responsive/addPanelData/form/conatctForm2.png": "/responsive/addPanelData/form/conatctForm2.png",
    "responsive/addPanelData/form/formAppBlank.png": "/responsive/addPanelData/form/formAppBlank.png",
    "responsive/addPanelData/form/formAppBlank1.png": "/responsive/addPanelData/form/formAppBlank1.png",
    "responsive/addPanelData/form/formAppContact.png": "/responsive/addPanelData/form/formAppContact.png",
    "responsive/addPanelData/form/formAppContact1.png": "/responsive/addPanelData/form/formAppContact1.png",
    "responsive/addPanelData/form/formAppContactDesign.png": "/responsive/addPanelData/form/formAppContactDesign.png",
    "responsive/addPanelData/form/formAppExisting.png": "/responsive/addPanelData/form/formAppExisting.png",
    "responsive/addPanelData/form/formAppExisting1.png": "/responsive/addPanelData/form/formAppExisting1.png",
    "responsive/addPanelData/form/formAppPayment.png": "/responsive/addPanelData/form/formAppPayment.png",
    "responsive/addPanelData/form/formAppPaymentDesign.png": "/responsive/addPanelData/form/formAppPaymentDesign.png",
    "responsive/addPanelData/form/formAppSellProducts.png": "/responsive/addPanelData/form/formAppSellProducts.png",
    "responsive/addPanelData/form/formAppSellProductsDesign.png": "/responsive/addPanelData/form/formAppSellProductsDesign.png",
    "responsive/addPanelData/form/formAppStudioContact.png": "/responsive/addPanelData/form/formAppStudioContact.png",
    "responsive/addPanelData/form/formAppStudioContactDesign.png": "/responsive/addPanelData/form/formAppStudioContactDesign.png",
    "responsive/addPanelData/form/formAppStudioDonate.png": "/responsive/addPanelData/form/formAppStudioDonate.png",
    "responsive/addPanelData/form/formAppStudioPayment.png": "/responsive/addPanelData/form/formAppStudioPayment.png",
    "responsive/addPanelData/form/formAppStudioSubscribe.png": "/responsive/addPanelData/form/formAppStudioSubscribe.png",
    "responsive/addPanelData/form/formAppStudioSubscribeDesign.png": "/responsive/addPanelData/form/formAppStudioSubscribeDesign.png",
    "responsive/addPanelData/form/formAppSubscribe.png": "/responsive/addPanelData/form/formAppSubscribe.png",
    "responsive/addPanelData/form/formAppSubscribe1.png": "/responsive/addPanelData/form/formAppSubscribe1.png",
    "responsive/addPanelData/form/formAppSubscribeDesign.png": "/responsive/addPanelData/form/formAppSubscribeDesign.png",
    "responsive/addPanelData/form/gridContactForm1.png": "/responsive/addPanelData/form/gridContactForm1.png",
    "responsive/addPanelData/form/gridContactForm2.png": "/responsive/addPanelData/form/gridContactForm2.png",
    "responsive/addPanelData/form/gridJobApplication1.png": "/responsive/addPanelData/form/gridJobApplication1.png",
    "responsive/addPanelData/form/gridJobApplication2.png": "/responsive/addPanelData/form/gridJobApplication2.png",
    "responsive/addPanelData/form/gridSalesForm1.png": "/responsive/addPanelData/form/gridSalesForm1.png",
    "responsive/addPanelData/form/gridSalesForm2.png": "/responsive/addPanelData/form/gridSalesForm2.png",
    "responsive/addPanelData/form/gridSubscribeForm1.png": "/responsive/addPanelData/form/gridSubscribeForm1.png",
    "responsive/addPanelData/form/gridSubscribeForm2.png": "/responsive/addPanelData/form/gridSubscribeForm2.png",
    "responsive/addPanelData/form/jobApplication1.png": "/responsive/addPanelData/form/jobApplication1.png",
    "responsive/addPanelData/form/jobApplication2.png": "/responsive/addPanelData/form/jobApplication2.png",
    "responsive/addPanelData/form/salesForm1.png": "/responsive/addPanelData/form/salesForm1.png",
    "responsive/addPanelData/form/salesForm2.png": "/responsive/addPanelData/form/salesForm2.png",
    "responsive/addPanelData/form/subscribeForm1.png": "/responsive/addPanelData/form/subscribeForm1.png",
    "responsive/addPanelData/form/subscribeForm2.png": "/responsive/addPanelData/form/subscribeForm2.png",
    "responsive/addPanelData/gallery/gallerySection.png": "/responsive/addPanelData/gallery/gallerySection.png",
    "responsive/addPanelData/games/games_add_panel.png": "/responsive/addPanelData/games/games_add_panel.v1.png",
    "responsive/addPanelData/googleMaps/googleMapsComponentClassic.jpg": "/responsive/addPanelData/googleMaps/googleMapsComponentClassic.jpg",
    "responsive/addPanelData/googleMaps/googleMapsComponentGrey.jpg": "/responsive/addPanelData/googleMaps/googleMapsComponentGrey.jpg",
    "responsive/addPanelData/googleMaps/googleMapsSectionDark.jpg": "/responsive/addPanelData/googleMaps/googleMapsSectionDark.jpg",
    "responsive/addPanelData/googleMaps/googleMapsSectionLight.jpg": "/responsive/addPanelData/googleMaps/googleMapsSectionLight.jpg",
    "responsive/addPanelData/groups/groupsWelcomeSectionResponsive.jpg": "/responsive/addPanelData/groups/groupsWelcomeSectionResponsive.v1.jpg",
    "responsive/addPanelData/image/imageSection.png": "/responsive/addPanelData/image/imageSection.v2.png",
    "responsive/addPanelData/lightBox/legacy/expandableMenuLegacy.png": "/responsive/addPanelData/lightBox/legacy/expandableMenuLegacy.v2.png",
    "responsive/addPanelData/lightBox/legacy/lightBoxPresetItemLegacy.png": "/responsive/addPanelData/lightBox/legacy/lightBoxPresetItemLegacy.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemCookiesBlack.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemCookiesBlack.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemCookiesWhite.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemCookiesWhite.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemDiscount.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemDiscount.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemProducts.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemProducts.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemSideBar.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemSideBar.v2.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemSubscribe.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemSubscribe.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemSubscribeMail.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemSubscribeMail.png",
    "responsive/addPanelData/lightBox/lightBoxPresetItemTopBar.png": "/responsive/addPanelData/lightBox/lightBoxPresetItemTopBar.png",
    "responsive/addPanelData/lightBox/studio/Banner.png": "/responsive/addPanelData/lightBox/studio/Banner.png",
    "responsive/addPanelData/lightBox/studio/BlankLightboxCenter.png": "/responsive/addPanelData/lightBox/studio/BlankLightboxCenter.v2.png",
    "responsive/addPanelData/lightBox/studio/Donate.png": "/responsive/addPanelData/lightBox/studio/Donate.png",
    "responsive/addPanelData/lightBox/studio/Promo-20.png": "/responsive/addPanelData/lightBox/studio/Promo-20.png",
    "responsive/addPanelData/lightBox/studio/Promo-30.png": "/responsive/addPanelData/lightBox/studio/Promo-30.png",
    "responsive/addPanelData/lightBox/studio/Promo-75.png": "/responsive/addPanelData/lightBox/studio/Promo-75.png",
    "responsive/addPanelData/lines/horizontalLinesSection.png": "/responsive/addPanelData/lines/horizontalLinesSection.png",
    "responsive/addPanelData/lines/verticalLinesSection.png": "/responsive/addPanelData/lines/verticalLinesSection.png",
    "responsive/addPanelData/members/membersWelcomeSectionResponsive.jpg": "/responsive/addPanelData/members/membersWelcomeSectionResponsive.v4.jpg",
    "responsive/addPanelData/members/membersWelcomeSectionResponsive.png": "/responsive/addPanelData/members/membersWelcomeSectionResponsive.v3.png",
    "responsive/addPanelData/menu/expandableMenuSection.png": "/responsive/addPanelData/menu/expandableMenuSection.png",
    "responsive/addPanelData/menu/horizontalMenuSection.png": "/responsive/addPanelData/menu/horizontalMenuSection.png",
    "responsive/addPanelData/menu/verticalMenuSection.png": "/responsive/addPanelData/menu/verticalMenuSection.png",
    "responsive/addPanelData/multilingual/addMultilingualSectionBanner.png": "/responsive/addPanelData/multilingual/addMultilingualSectionBanner.v2.png",
    "responsive/addPanelData/onlinePrograms/onlineProgramsWelcomeSectionResponsive.jpg": "/responsive/addPanelData/onlinePrograms/onlineProgramsWelcomeSectionResponsive.v1.jpg",
    "responsive/addPanelData/organizer/organizerPresetItem1.png": "/responsive/addPanelData/organizer/organizerPresetItem1.v2.png",
    "responsive/addPanelData/organizer/organizerPresetItem2.png": "/responsive/addPanelData/organizer/organizerPresetItem2.v3.png",
    "responsive/addPanelData/organizer/organizerPresetItem2.v2.png.png": "/responsive/addPanelData/organizer/organizerPresetItem2.v2.png.png",
    "responsive/addPanelData/platforms/addContactForm.png": "/responsive/addPanelData/platforms/addContactForm.png",
    "responsive/addPanelData/platforms/contactForm.png": "/responsive/addPanelData/platforms/contactForm.png",
    "responsive/addPanelData/quickAdd/quickAddSection.png": "/responsive/addPanelData/quickAdd/quickAddSection.v7.png",
    "responsive/addPanelData/quickAdd/quickAddSection.svg": "/responsive/addPanelData/quickAdd/quickAddSection.svg",
    "responsive/addPanelData/quickAdd/quickAddSection.v2_hover.png": "/responsive/addPanelData/quickAdd/quickAddSection.v2_hover.png",
    "responsive/addPanelData/quickAdd/quickAddSection.v3_hover.png": "/responsive/addPanelData/quickAdd/quickAddSection.v3_hover.png",
    "responsive/addPanelData/quickAdd/quickAddSection.v4_hover.png": "/responsive/addPanelData/quickAdd/quickAddSection.v4_hover.png",
    "responsive/addPanelData/quickAdd/quickAddSection.v5_hover.png": "/responsive/addPanelData/quickAdd/quickAddSection.v5_hover.png",
    "responsive/addPanelData/quickAdd/quickAddSection.v7_hover.png": "/responsive/addPanelData/quickAdd/quickAddSection.v7_hover.png",
    "responsive/addPanelData/repeaters/repeaterPresetItem1.png": "/responsive/addPanelData/repeaters/repeaterPresetItem1.png",
    "responsive/addPanelData/repeaters/repeaterPresetItem2.png": "/responsive/addPanelData/repeaters/repeaterPresetItem2.png",
    "responsive/addPanelData/restaurants/menus.png": "/responsive/addPanelData/restaurants/menus.png",
    "responsive/addPanelData/restaurants/orders.png": "/responsive/addPanelData/restaurants/orders.png",
    "responsive/addPanelData/restaurants/reservations.png": "/responsive/addPanelData/restaurants/reservations.png",
    "responsive/addPanelData/search/not-found.svg": "/responsive/addPanelData/search/not-found.v2.svg",
    "responsive/addPanelData/search/search2.png": "/responsive/addPanelData/search/search2.png",
    "responsive/addPanelData/searchBox/searchBoxComponentTheme1_en.png": "/responsive/addPanelData/searchBox/searchBoxComponentTheme1_en.png",
    "responsive/addPanelData/searchBox/searchComponentTheme_Dark_en.png": "/responsive/addPanelData/searchBox/searchComponentTheme_Dark_en.png",
    "responsive/addPanelData/searchBox/searchComponentTheme_Light_en.png": "/responsive/addPanelData/searchBox/searchComponentTheme_Light_en.png",
    "responsive/addPanelData/searchBox/searchComponentTheme_RoundedCorners_en.png": "/responsive/addPanelData/searchBox/searchComponentTheme_RoundedCorners_en.png",
    "responsive/addPanelData/text/textSection.png": "/responsive/addPanelData/text/textSection.png",
    "responsive/addPanelData/text/titlesSection.png": "/responsive/addPanelData/text/titlesSection.png",
    "responsive/addPanelData/video/videoBoxSection.png": "/responsive/addPanelData/video/videoBoxSection.png",
    "responsive/addPanelData/video/videoBoxSection/Dune_4_Hover.mov": "/responsive/addPanelData/video/videoBoxSection/Dune_4_Hover.mov",
    "responsive/addPanelData/video/videoBoxSection/Pillars_4_Hover.mov": "/responsive/addPanelData/video/videoBoxSection/Pillars_4_Hover.mov",
    "responsive/addPanelData/video/videoBoxSection/Portrait_4_Hover.mov": "/responsive/addPanelData/video/videoBoxSection/Portrait_4_Hover.mov",
    "responsive/addPanelData/video/videoBoxSection/Pudra_4_Hover.mov": "/responsive/addPanelData/video/videoBoxSection/Pudra_4_Hover.mov",
    "responsive/addPanelData/video/videoBoxSection/Wheel_for_Hover.mov": "/responsive/addPanelData/video/videoBoxSection/Wheel_for_Hover.mov",
    "responsive/addPanelData/video/videoBoxTransparentSection.png": "/responsive/addPanelData/video/videoBoxTransparentSection.png",
    "responsive/addPanelData/video/videoBoxTransparentSection/bubble.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/bubble.mp4",
    "responsive/addPanelData/video/videoBoxTransparentSection/engine.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/engine.mp4",
    "responsive/addPanelData/video/videoBoxTransparentSection/flower.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/flower.mp4",
    "responsive/addPanelData/video/videoBoxTransparentSection/statue.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/statue.mp4",
    "responsive/addPanelData/video/videoBoxTransparentSection/watch.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/watch.mp4",
    "responsive/addPanelData/video/videoBoxTransparentSection/water.mp4": "/responsive/addPanelData/video/videoBoxTransparentSection/water.mp4",
    "responsive/aiExperience/enhance-icon-animated_48.gif": "/responsive/aiExperience/enhance-icon-animated_48.gif",
    "responsive/aiExperience/enhance-icon-animated_48.mp4": "/responsive/aiExperience/enhance-icon-animated_48.mp4",
    "responsive/aiExperience/enhance-icon-animated_48_bold.mp4": "/responsive/aiExperience/enhance-icon-animated_48_bold.mp4",
    "responsive/aiExperience/enhance-icon-animated_48_bold_v2.mp4": "/responsive/aiExperience/enhance-icon-animated_48_bold_v2.mp4",
    "responsive/aiExperience/enhance-icon-animated_48_bold_v3.mp4": "/responsive/aiExperience/enhance-icon-animated_48_bold_v3.mp4",
    "responsive/aiExperience/enhance-icon-animated_48_bold_v4.mp4": "/responsive/aiExperience/enhance-icon-animated_48_bold_v4.mp4",
    "responsive/aiExperience/responsive-ai-dialog-animation.mp4": "/responsive/aiExperience/responsive-ai-dialog-animation.mp4",
    "responsive/aiExperience/responsive-ai-dialog-animation_94.mp4": "/responsive/aiExperience/responsive-ai-dialog-animation_94.mp4",
    "responsive/aiExperience/welcome-dialog-animation.mp4": "/responsive/aiExperience/welcome-dialog-animation.mp4",
    "responsive/animated-studio-logo/hover.gif": "/responsive/animated-studio-logo/hover.v2.gif",
    "responsive/animated-studio-logo/initial.gif": "/responsive/animated-studio-logo/initial.gif",
    "responsive/animated-studio-logo/preview.gif": "/responsive/animated-studio-logo/preview.gif",
    "responsive/animated-studio-logo/selected.gif": "/responsive/animated-studio-logo/selected.v2.gif",
    "responsive/appsPanel/Blog.png": "/responsive/appsPanel/Blog.png",
    "responsive/appsPanel/Bookings.png": "/responsive/appsPanel/Bookings.png",
    "responsive/appsPanel/Chat.png": "/responsive/appsPanel/Chat.png",
    "responsive/appsPanel/Events.png": "/responsive/appsPanel/Events.png",
    "responsive/appsPanel/FAQ.png": "/responsive/appsPanel/FAQ.png",
    "responsive/appsPanel/Form.png": "/responsive/appsPanel/Form.png",
    "responsive/appsPanel/Forum.png": "/responsive/appsPanel/Forum.png",
    "responsive/appsPanel/Groups.png": "/responsive/appsPanel/Groups.png",
    "responsive/appsPanel/Instagram.png": "/responsive/appsPanel/Instagram.png",
    "responsive/appsPanel/Members.png": "/responsive/appsPanel/Members.png",
    "responsive/appsPanel/Paid.png": "/responsive/appsPanel/Paid.png",
    "responsive/appsPanel/popups/BlogBig.png": "/responsive/appsPanel/popups/BlogBig.png",
    "responsive/appsPanel/popups/BookingsBig.png": "/responsive/appsPanel/popups/BookingsBig.png",
    "responsive/appsPanel/popups/ChallengesBig.png": "/responsive/appsPanel/popups/ChallengesBig.png",
    "responsive/appsPanel/popups/ChatBig.png": "/responsive/appsPanel/popups/ChatBig.png",
    "responsive/appsPanel/popups/EventsBig.png": "/responsive/appsPanel/popups/EventsBig.png",
    "responsive/appsPanel/popups/FAQBig.png": "/responsive/appsPanel/popups/FAQBig.png",
    "responsive/appsPanel/popups/FormBig.png": "/responsive/appsPanel/popups/FormBig.png",
    "responsive/appsPanel/popups/ForumBig.png": "/responsive/appsPanel/popups/ForumBig.png",
    "responsive/appsPanel/popups/GroupsBig.png": "/responsive/appsPanel/popups/GroupsBig.png",
    "responsive/appsPanel/popups/InstagramBig.png": "/responsive/appsPanel/popups/InstagramBig.png",
    "responsive/appsPanel/popups/MembersBig.png": "/responsive/appsPanel/popups/MembersBig.png",
    "responsive/appsPanel/popups/StoreBig.png": "/responsive/appsPanel/popups/StoreBig.png",
    "responsive/appsPanel/popups/VideoBig.png": "/responsive/appsPanel/popups/VideoBig.png",
    "responsive/appsPanel/retina/BlogBig.png": "/responsive/appsPanel/retina/BlogBig.png",
    "responsive/appsPanel/retina/BookingsBig.png": "/responsive/appsPanel/retina/BookingsBig.png",
    "responsive/appsPanel/retina/ChallengesBig.png": "/responsive/appsPanel/retina/ChallengesBig.png",
    "responsive/appsPanel/retina/ChatBig.png": "/responsive/appsPanel/retina/ChatBig.png",
    "responsive/appsPanel/retina/EventsBig.png": "/responsive/appsPanel/retina/EventsBig.png",
    "responsive/appsPanel/retina/FAQBig.png": "/responsive/appsPanel/retina/FAQBig.png",
    "responsive/appsPanel/retina/FormBig.png": "/responsive/appsPanel/retina/FormBig.png",
    "responsive/appsPanel/retina/ForumBig.png": "/responsive/appsPanel/retina/ForumBig.png",
    "responsive/appsPanel/retina/GroupsBig.png": "/responsive/appsPanel/retina/GroupsBig.png",
    "responsive/appsPanel/retina/InstagramBig.png": "/responsive/appsPanel/retina/InstagramBig.png",
    "responsive/appsPanel/retina/MembersBig.png": "/responsive/appsPanel/retina/MembersBig.png",
    "responsive/appsPanel/retina/PaidBig.png": "/responsive/appsPanel/retina/PaidBig.png",
    "responsive/appsPanel/retina/StoreBig.png": "/responsive/appsPanel/retina/StoreBig.png",
    "responsive/appsPanel/retina/VideoBig.png": "/responsive/appsPanel/retina/VideoBig.png",
    "responsive/appsPanel/Store.png": "/responsive/appsPanel/Store.png",
    "responsive/appsPanel/Video.png": "/responsive/appsPanel/Video.png",
    "responsive/blocks/editorExperienceIntroductionModal/editor-experience-step.webp": "/responsive/blocks/editorExperienceIntroductionModal/editor-experience-step.webp",
    "responsive/blocks/editorExperienceIntroductionModal/side-panel-step.png": "/responsive/blocks/editorExperienceIntroductionModal/side-panel-step.png",
    "responsive/contentMaxWidth/maxwidth.svg": "/responsive/contentMaxWidth/maxwidth.svg",
    "responsive/contentMaxWidth/maxwidth_darkmode.svg": "/responsive/contentMaxWidth/maxwidth_darkmode.svg",
    "responsive/designLibraries/loader.gif": "/responsive/designLibraries/loader.gif",
    "responsive/editorXLlogo_small.png": "/responsive/editorXLlogo_small.png",
    "responsive/editorXLogo_medium.png": "/responsive/editorXLogo_medium.png",
    "responsive/editorXToStudioMigration/enter-migration-first-step-compressed.mov": "/responsive/editorXToStudioMigration/enter-migration-first-step-compressed.mov",
    "responsive/editorXToStudioMigration/enter-migration-first-step.mp4": "/responsive/editorXToStudioMigration/enter-migration-first-step.mp4",
    "responsive/editorXToStudioMigration/post-migration-compressed.png": "/responsive/editorXToStudioMigration/post-migration-compressed.png",
    "responsive/editorXToStudioMigration/post-migration.png": "/responsive/editorXToStudioMigration/post-migration.png",
    "responsive/education/video-tutorials/thumbnails/breakpoints.png": "/responsive/education/video-tutorials/thumbnails/breakpoints.v2.png",
    "responsive/education/video-tutorials/thumbnails/contact-form.png": "/responsive/education/video-tutorials/thumbnails/contact-form.v2.png",
    "responsive/education/video-tutorials/thumbnails/custom-menus.png": "/responsive/education/video-tutorials/thumbnails/custom-menus.v2.png",
    "responsive/education/video-tutorials/thumbnails/datasets.png": "/responsive/education/video-tutorials/thumbnails/datasets.v2.png",
    "responsive/education/video-tutorials/thumbnails/design-libraries.png": "/responsive/education/video-tutorials/thumbnails/design-libraries.v2.png",
    "responsive/education/video-tutorials/thumbnails/designed-sections.png": "/responsive/education/video-tutorials/thumbnails/designed-sections.v2.png",
    "responsive/education/video-tutorials/thumbnails/grid.png": "/responsive/education/video-tutorials/thumbnails/grid.v2.png",
    "responsive/education/video-tutorials/thumbnails/hover-interactions.png": "/responsive/education/video-tutorials/thumbnails/hover-interactions.v2.png",
    "responsive/education/video-tutorials/thumbnails/images.png": "/responsive/education/video-tutorials/thumbnails/images.v2.png",
    "responsive/education/video-tutorials/thumbnails/layouter.png": "/responsive/education/video-tutorials/thumbnails/layouter.v2.png",
    "responsive/education/video-tutorials/thumbnails/layouting.png": "/responsive/education/video-tutorials/thumbnails/layouting.v2.png",
    "responsive/education/video-tutorials/thumbnails/live-comments.png": "/responsive/education/video-tutorials/thumbnails/live-comments.v2.png",
    "responsive/education/video-tutorials/thumbnails/lottie.png": "/responsive/education/video-tutorials/thumbnails/lottie.v2.png",
    "responsive/education/video-tutorials/thumbnails/masters.png": "/responsive/education/video-tutorials/thumbnails/masters.v2.png",
    "responsive/education/video-tutorials/thumbnails/multi-state-boxes.png": "/responsive/education/video-tutorials/thumbnails/multi-state-boxes.v2.png",
    "responsive/education/video-tutorials/thumbnails/pinned-position.png": "/responsive/education/video-tutorials/thumbnails/pinned-position.v2.png",
    "responsive/education/video-tutorials/thumbnails/position-type.png": "/responsive/education/video-tutorials/thumbnails/position-type.v2.png",
    "responsive/education/video-tutorials/thumbnails/repeater.png": "/responsive/education/video-tutorials/thumbnails/repeater.v2.png",
    "responsive/education/video-tutorials/thumbnails/roles-and-permissions.png": "/responsive/education/video-tutorials/thumbnails/roles-and-permissions.v2.png",
    "responsive/education/video-tutorials/thumbnails/section-background.png": "/responsive/education/video-tutorials/thumbnails/section-background.v2.png",
    "responsive/education/video-tutorials/thumbnails/sections.png": "/responsive/education/video-tutorials/thumbnails/sections.v2.png",
    "responsive/education/video-tutorials/thumbnails/video-and-decorative.png": "/responsive/education/video-tutorials/thumbnails/video-and-decorative.v2.png",
    "responsive/figma2x/assets/wixMadeforBold.ttf": "/responsive/figma2x/assets/wixMadeforBold.ttf",
    "responsive/figma2x/assets/wixMadeforMedium.ttf": "/responsive/figma2x/assets/wixMadeforMedium.ttf",
    "responsive/figma2x/assets/wixMadeforRegular.ttf": "/responsive/figma2x/assets/wixMadeforRegular.ttf",
    "responsive/figma2x/assets/wixMadeForXBold.ttf": "/responsive/figma2x/assets/wixMadeForXBold.ttf",
    "responsive/figma2x/empty.svg": "/responsive/figma2x/empty.svg",
    "responsive/figma2x/icons/back.svg": "/responsive/figma2x/icons/back.svg",
    "responsive/figma2x/icons/help.svg": "/responsive/figma2x/icons/help.svg",
    "responsive/figma2x/loader.gif": "/responsive/figma2x/loader.gif",
    "responsive/figma2x/plugin-splash_img.png": "/responsive/figma2x/plugin-splash_img.png",
    "responsive/figma2x/splash.png": "/responsive/figma2x/splash.png",
    "responsive/figma2x/urlBar.svg": "/responsive/figma2x/urlBar.svg",
    "responsive/headerSettings/disappear.gif": "/responsive/headerSettings/disappear.v2.gif",
    "responsive/headerSettings/fadeOut.gif": "/responsive/headerSettings/fadeOut.v2.gif",
    "responsive/headerSettings/freeze.gif": "/responsive/headerSettings/freeze.v2.gif",
    "responsive/headerSettings/scrollWithSite.gif": "/responsive/headerSettings/scrollWithSite.v2.gif",
    "responsive/interactions/BlowInteraction.gif": "/responsive/interactions/BlowInteraction.v2.gif",
    "responsive/interactions/Cut_animated.gif": "/responsive/interactions/Cut_animated.gif",
    "responsive/interactions/Cut_selected.gif": "/responsive/interactions/Cut_selected.gif",
    "responsive/interactions/Cut_static.gif": "/responsive/interactions/Cut_static.gif",
    "responsive/interactions/CutInteraction.gif": "/responsive/interactions/CutInteraction.v2.gif",
    "responsive/interactions/effects.v2/Arc_animated.gif": "/responsive/interactions/effects.v2/Arc_animated.gif",
    "responsive/interactions/effects.v2/Arch_selected.gif": "/responsive/interactions/effects.v2/Arch_selected.gif",
    "responsive/interactions/effects.v2/Arch_static.gif": "/responsive/interactions/effects.v2/Arch_static.gif",
    "responsive/interactions/effects.v2/Blow_animated.gif": "/responsive/interactions/effects.v2/Blow_animated.gif",
    "responsive/interactions/effects.v2/Blow_selected.gif": "/responsive/interactions/effects.v2/Blow_selected.gif",
    "responsive/interactions/effects.v2/Blow_static.gif": "/responsive/interactions/effects.v2/Blow_static.gif",
    "responsive/interactions/effects.v2/Blur_animated.gif": "/responsive/interactions/effects.v2/Blur_animated.gif",
    "responsive/interactions/effects.v2/Blur_selected.gif": "/responsive/interactions/effects.v2/Blur_selected.gif",
    "responsive/interactions/effects.v2/Blur_static.gif": "/responsive/interactions/effects.v2/Blur_static.gif",
    "responsive/interactions/effects.v2/Bounce_animated.gif": "/responsive/interactions/effects.v2/Bounce_animated.gif",
    "responsive/interactions/effects.v2/Bounce_selected.gif": "/responsive/interactions/effects.v2/Bounce_selected.gif",
    "responsive/interactions/effects.v2/Bounce_static.gif": "/responsive/interactions/effects.v2/Bounce_static.gif",
    "responsive/interactions/effects.v2/Fade_animated.gif": "/responsive/interactions/effects.v2/Fade_animated.gif",
    "responsive/interactions/effects.v2/Fade_selected.gif": "/responsive/interactions/effects.v2/Fade_selected.gif",
    "responsive/interactions/effects.v2/Fade_static.gif": "/responsive/interactions/effects.v2/Fade_static.gif",
    "responsive/interactions/effects.v2/Flip_animated.gif": "/responsive/interactions/effects.v2/Flip_animated.gif",
    "responsive/interactions/effects.v2/Flip_selected.gif": "/responsive/interactions/effects.v2/Flip_selected.gif",
    "responsive/interactions/effects.v2/Flip_static.gif": "/responsive/interactions/effects.v2/Flip_static.gif",
    "responsive/interactions/effects.v2/Float_animated.gif": "/responsive/interactions/effects.v2/Float_animated.gif",
    "responsive/interactions/effects.v2/Float_selected.gif": "/responsive/interactions/effects.v2/Float_selected.gif",
    "responsive/interactions/effects.v2/Float_static.gif": "/responsive/interactions/effects.v2/Float_static.gif",
    "responsive/interactions/effects.v2/Fold_animated.gif": "/responsive/interactions/effects.v2/Fold_animated.gif",
    "responsive/interactions/effects.v2/Fold_selected.gif": "/responsive/interactions/effects.v2/Fold_selected.gif",
    "responsive/interactions/effects.v2/Fold_static.gif": "/responsive/interactions/effects.v2/Fold_static.gif",
    "responsive/interactions/effects.v2/Glide_animated.gif": "/responsive/interactions/effects.v2/Glide_animated.gif",
    "responsive/interactions/effects.v2/Glide_selected.gif": "/responsive/interactions/effects.v2/Glide_selected.gif",
    "responsive/interactions/effects.v2/Glide_static.gif": "/responsive/interactions/effects.v2/Glide_static.gif",
    "responsive/interactions/effects.v2/Grow_animated.gif": "/responsive/interactions/effects.v2/Grow_animated.gif",
    "responsive/interactions/effects.v2/Grow_selected.gif": "/responsive/interactions/effects.v2/Grow_selected.gif",
    "responsive/interactions/effects.v2/Grow_static.gif": "/responsive/interactions/effects.v2/Grow_static.gif",
    "responsive/interactions/effects.v2/legacy/ArcIn_animated.png": "/responsive/interactions/effects.v2/legacy/ArcIn_animated.png",
    "responsive/interactions/effects.v2/legacy/ArcIn_selected.png": "/responsive/interactions/effects.v2/legacy/ArcIn_selected.png",
    "responsive/interactions/effects.v2/legacy/ArcIn_static.png": "/responsive/interactions/effects.v2/legacy/ArcIn_static.png",
    "responsive/interactions/effects.v2/legacy/BounceIn_animated.png": "/responsive/interactions/effects.v2/legacy/BounceIn_animated.png",
    "responsive/interactions/effects.v2/legacy/BounceIn_selected.png": "/responsive/interactions/effects.v2/legacy/BounceIn_selected.png",
    "responsive/interactions/effects.v2/legacy/BounceIn_static.png": "/responsive/interactions/effects.v2/legacy/BounceIn_static.png",
    "responsive/interactions/effects.v2/legacy/TurnIn_animated.png": "/responsive/interactions/effects.v2/legacy/TurnIn_animated.png",
    "responsive/interactions/effects.v2/legacy/TurnIn_selected.png": "/responsive/interactions/effects.v2/legacy/TurnIn_selected.png",
    "responsive/interactions/effects.v2/legacy/TurnIn_static.png": "/responsive/interactions/effects.v2/legacy/TurnIn_static.png",
    "responsive/interactions/effects.v2/mouse/Airy_animated.gif": "/responsive/interactions/effects.v2/mouse/Airy_animated.gif",
    "responsive/interactions/effects.v2/mouse/Airy_selected.gif": "/responsive/interactions/effects.v2/mouse/Airy_selected.gif",
    "responsive/interactions/effects.v2/mouse/Airy_static.gif": "/responsive/interactions/effects.v2/mouse/Airy_static.gif",
    "responsive/interactions/effects.v2/mouse/Blob_animated.gif": "/responsive/interactions/effects.v2/mouse/Blob_animated.gif",
    "responsive/interactions/effects.v2/mouse/Blob_selected.gif": "/responsive/interactions/effects.v2/mouse/Blob_selected.gif",
    "responsive/interactions/effects.v2/mouse/Blob_static.gif": "/responsive/interactions/effects.v2/mouse/Blob_static.gif",
    "responsive/interactions/effects.v2/mouse/Blur_animated.gif": "/responsive/interactions/effects.v2/mouse/Blur_animated.gif",
    "responsive/interactions/effects.v2/mouse/Blur_selected.gif": "/responsive/interactions/effects.v2/mouse/Blur_selected.gif",
    "responsive/interactions/effects.v2/mouse/Blur_static.gif": "/responsive/interactions/effects.v2/mouse/Blur_static.gif",
    "responsive/interactions/effects.v2/mouse/Scale_animated.gif": "/responsive/interactions/effects.v2/mouse/Scale_animated.gif",
    "responsive/interactions/effects.v2/mouse/Scale_selected.gif": "/responsive/interactions/effects.v2/mouse/Scale_selected.gif",
    "responsive/interactions/effects.v2/mouse/Scale_static.gif": "/responsive/interactions/effects.v2/mouse/Scale_static.gif",
    "responsive/interactions/effects.v2/mouse/Skew_animated.gif": "/responsive/interactions/effects.v2/mouse/Skew_animated.gif",
    "responsive/interactions/effects.v2/mouse/Skew_selected.gif": "/responsive/interactions/effects.v2/mouse/Skew_selected.gif",
    "responsive/interactions/effects.v2/mouse/Skew_static.gif": "/responsive/interactions/effects.v2/mouse/Skew_static.gif",
    "responsive/interactions/effects.v2/mouse/Spin_animated.gif": "/responsive/interactions/effects.v2/mouse/Spin_animated.gif",
    "responsive/interactions/effects.v2/mouse/Spin_static.gif": "/responsive/interactions/effects.v2/mouse/Spin_static.gif",
    "responsive/interactions/effects.v2/mouse/Swivel_animated.gif": "/responsive/interactions/effects.v2/mouse/Swivel_animated.gif",
    "responsive/interactions/effects.v2/mouse/Swivel_selected.gif": "/responsive/interactions/effects.v2/mouse/Swivel_selected.gif",
    "responsive/interactions/effects.v2/mouse/Swivel_static.gif": "/responsive/interactions/effects.v2/mouse/Swivel_static.gif",
    "responsive/interactions/effects.v2/mouse/Tilt_animated.gif": "/responsive/interactions/effects.v2/mouse/Tilt_animated.gif",
    "responsive/interactions/effects.v2/mouse/Tilt_selected.gif": "/responsive/interactions/effects.v2/mouse/Tilt_selected.gif",
    "responsive/interactions/effects.v2/mouse/Tilt_static.gif": "/responsive/interactions/effects.v2/mouse/Tilt_static.gif",
    "responsive/interactions/effects.v2/mouse/Track3D_animated.gif": "/responsive/interactions/effects.v2/mouse/Track3D_animated.gif",
    "responsive/interactions/effects.v2/mouse/Track3D_selected.gif": "/responsive/interactions/effects.v2/mouse/Track3D_selected.gif",
    "responsive/interactions/effects.v2/mouse/Track3D_static.gif": "/responsive/interactions/effects.v2/mouse/Track3D_static.gif",
    "responsive/interactions/effects.v2/mouse/Track_animated.gif": "/responsive/interactions/effects.v2/mouse/Track_animated.gif",
    "responsive/interactions/effects.v2/mouse/Track_selected.gif": "/responsive/interactions/effects.v2/mouse/Track_selected.gif",
    "responsive/interactions/effects.v2/mouse/Track_static.gif": "/responsive/interactions/effects.v2/mouse/Track_static.gif",
    "responsive/interactions/effects.v2/ongoing/Bounce_animated.gif": "/responsive/interactions/effects.v2/ongoing/Bounce_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Bounce_selected.gif": "/responsive/interactions/effects.v2/ongoing/Bounce_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Bounce_static.gif": "/responsive/interactions/effects.v2/ongoing/Bounce_static.gif",
    "responsive/interactions/effects.v2/ongoing/Breath_animated.gif": "/responsive/interactions/effects.v2/ongoing/Breath_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Breath_selected.gif": "/responsive/interactions/effects.v2/ongoing/Breath_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Breath_static.gif": "/responsive/interactions/effects.v2/ongoing/Breath_static.gif",
    "responsive/interactions/effects.v2/ongoing/Cross_animated.gif": "/responsive/interactions/effects.v2/ongoing/Cross_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Cross_selected.gif": "/responsive/interactions/effects.v2/ongoing/Cross_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Cross_static.gif": "/responsive/interactions/effects.v2/ongoing/Cross_static.gif",
    "responsive/interactions/effects.v2/ongoing/DVD_Logo_animated.gif": "/responsive/interactions/effects.v2/ongoing/DVD_Logo_animated.gif",
    "responsive/interactions/effects.v2/ongoing/DVD_Logo_selected.gif": "/responsive/interactions/effects.v2/ongoing/DVD_Logo_selected.gif",
    "responsive/interactions/effects.v2/ongoing/DVD_Logo_static.gif": "/responsive/interactions/effects.v2/ongoing/DVD_Logo_static.gif",
    "responsive/interactions/effects.v2/ongoing/Flash_animated.gif": "/responsive/interactions/effects.v2/ongoing/Flash_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Flash_selected.gif": "/responsive/interactions/effects.v2/ongoing/Flash_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Flash_static.gif": "/responsive/interactions/effects.v2/ongoing/Flash_static.gif",
    "responsive/interactions/effects.v2/ongoing/Flip_animated.gif": "/responsive/interactions/effects.v2/ongoing/Flip_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Flip_selected.gif": "/responsive/interactions/effects.v2/ongoing/Flip_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Flip_static.gif": "/responsive/interactions/effects.v2/ongoing/Flip_static.gif",
    "responsive/interactions/effects.v2/ongoing/Fold_animated.gif": "/responsive/interactions/effects.v2/ongoing/Fold_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Fold_selected.gif": "/responsive/interactions/effects.v2/ongoing/Fold_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Fold_static.gif": "/responsive/interactions/effects.v2/ongoing/Fold_static.gif",
    "responsive/interactions/effects.v2/ongoing/Jello_animated.gif": "/responsive/interactions/effects.v2/ongoing/Jello_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Jello_selected.gif": "/responsive/interactions/effects.v2/ongoing/Jello_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Jello_static.gif": "/responsive/interactions/effects.v2/ongoing/Jello_static.gif",
    "responsive/interactions/effects.v2/ongoing/Poke_animated.gif": "/responsive/interactions/effects.v2/ongoing/Poke_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Poke_selected.gif": "/responsive/interactions/effects.v2/ongoing/Poke_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Poke_static.gif": "/responsive/interactions/effects.v2/ongoing/Poke_static.gif",
    "responsive/interactions/effects.v2/ongoing/Pulse_animated.gif": "/responsive/interactions/effects.v2/ongoing/Pulse_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Pulse_selected.gif": "/responsive/interactions/effects.v2/ongoing/Pulse_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Pulse_static.gif": "/responsive/interactions/effects.v2/ongoing/Pulse_static.gif",
    "responsive/interactions/effects.v2/ongoing/Rubber_animated.gif": "/responsive/interactions/effects.v2/ongoing/Rubber_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Rubber_selected.gif": "/responsive/interactions/effects.v2/ongoing/Rubber_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Rubber_static.gif": "/responsive/interactions/effects.v2/ongoing/Rubber_static.gif",
    "responsive/interactions/effects.v2/ongoing/Spin_animated.gif": "/responsive/interactions/effects.v2/ongoing/Spin_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Spin_selected.gif": "/responsive/interactions/effects.v2/ongoing/Spin_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Spin_static.gif": "/responsive/interactions/effects.v2/ongoing/Spin_static.gif",
    "responsive/interactions/effects.v2/ongoing/Swing_animated.gif": "/responsive/interactions/effects.v2/ongoing/Swing_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Swing_selected.gif": "/responsive/interactions/effects.v2/ongoing/Swing_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Swing_static.gif": "/responsive/interactions/effects.v2/ongoing/Swing_static.gif",
    "responsive/interactions/effects.v2/ongoing/Wiggle_animated.gif": "/responsive/interactions/effects.v2/ongoing/Wiggle_animated.gif",
    "responsive/interactions/effects.v2/ongoing/Wiggle_selected.gif": "/responsive/interactions/effects.v2/ongoing/Wiggle_selected.gif",
    "responsive/interactions/effects.v2/ongoing/Wiggle_static.gif": "/responsive/interactions/effects.v2/ongoing/Wiggle_static.gif",
    "responsive/interactions/effects.v2/Puff_animated.gif": "/responsive/interactions/effects.v2/Puff_animated.gif",
    "responsive/interactions/effects.v2/Puff_selected.gif": "/responsive/interactions/effects.v2/Puff_selected.gif",
    "responsive/interactions/effects.v2/Puff_static.gif": "/responsive/interactions/effects.v2/Puff_static.gif",
    "responsive/interactions/effects.v2/Reveal_animated.gif": "/responsive/interactions/effects.v2/Reveal_animated.gif",
    "responsive/interactions/effects.v2/Reveal_selected.gif": "/responsive/interactions/effects.v2/Reveal_selected.gif",
    "responsive/interactions/effects.v2/Reveal_static.gif": "/responsive/interactions/effects.v2/Reveal_static.gif",
    "responsive/interactions/effects.v2/scroll/3D_animated.gif": "/responsive/interactions/effects.v2/scroll/3D_animated.gif",
    "responsive/interactions/effects.v2/scroll/3D_selected.gif": "/responsive/interactions/effects.v2/scroll/3D_selected.gif",
    "responsive/interactions/effects.v2/scroll/3D_static.gif": "/responsive/interactions/effects.v2/scroll/3D_static.gif",
    "responsive/interactions/effects.v2/scroll/Arch_animated.gif": "/responsive/interactions/effects.v2/scroll/Arch_animated.gif",
    "responsive/interactions/effects.v2/scroll/Arch_selected.gif": "/responsive/interactions/effects.v2/scroll/Arch_selected.gif",
    "responsive/interactions/effects.v2/scroll/Arch_static.gif": "/responsive/interactions/effects.v2/scroll/Arch_static.gif",
    "responsive/interactions/effects.v2/scroll/Blur_animated.gif": "/responsive/interactions/effects.v2/scroll/Blur_animated.gif",
    "responsive/interactions/effects.v2/scroll/Blur_selected.gif": "/responsive/interactions/effects.v2/scroll/Blur_selected.gif",
    "responsive/interactions/effects.v2/scroll/Blur_static.gif": "/responsive/interactions/effects.v2/scroll/Blur_static.gif",
    "responsive/interactions/effects.v2/scroll/Fade_animated.gif": "/responsive/interactions/effects.v2/scroll/Fade_animated.gif",
    "responsive/interactions/effects.v2/scroll/Fade_selected.gif": "/responsive/interactions/effects.v2/scroll/Fade_selected.gif",
    "responsive/interactions/effects.v2/scroll/Fade_static.gif": "/responsive/interactions/effects.v2/scroll/Fade_static.gif",
    "responsive/interactions/effects.v2/scroll/Flip_animated.gif": "/responsive/interactions/effects.v2/scroll/Flip_animated.gif",
    "responsive/interactions/effects.v2/scroll/Flip_selected.gif": "/responsive/interactions/effects.v2/scroll/Flip_selected.gif",
    "responsive/interactions/effects.v2/scroll/Flip_static.gif": "/responsive/interactions/effects.v2/scroll/Flip_static.gif",
    "responsive/interactions/effects.v2/scroll/Glide_animated.gif": "/responsive/interactions/effects.v2/scroll/Glide_animated.gif",
    "responsive/interactions/effects.v2/scroll/Glide_selected.gif": "/responsive/interactions/effects.v2/scroll/Glide_selected.gif",
    "responsive/interactions/effects.v2/scroll/Glide_static.gif": "/responsive/interactions/effects.v2/scroll/Glide_static.gif",
    "responsive/interactions/effects.v2/scroll/Grow_animated.gif": "/responsive/interactions/effects.v2/scroll/Grow_animated.gif",
    "responsive/interactions/effects.v2/scroll/Grow_selected.gif": "/responsive/interactions/effects.v2/scroll/Grow_selected.gif",
    "responsive/interactions/effects.v2/scroll/Grow_static.gif": "/responsive/interactions/effects.v2/scroll/Grow_static.v2.gif",
    "responsive/interactions/effects.v2/scroll/Pan_animated.gif": "/responsive/interactions/effects.v2/scroll/Pan_animated.gif",
    "responsive/interactions/effects.v2/scroll/Pan_selected.gif": "/responsive/interactions/effects.v2/scroll/Pan_selected.gif",
    "responsive/interactions/effects.v2/scroll/Pan_static.gif": "/responsive/interactions/effects.v2/scroll/Pan_static.gif",
    "responsive/interactions/effects.v2/scroll/Parallax_animated.gif": "/responsive/interactions/effects.v2/scroll/Parallax_animated.gif",
    "responsive/interactions/effects.v2/scroll/Parallax_selected.gif": "/responsive/interactions/effects.v2/scroll/Parallax_selected.gif",
    "responsive/interactions/effects.v2/scroll/Parallax_static.gif": "/responsive/interactions/effects.v2/scroll/Parallax_static.gif",
    "responsive/interactions/effects.v2/scroll/Puff_animated.gif": "/responsive/interactions/effects.v2/scroll/Puff_animated.gif",
    "responsive/interactions/effects.v2/scroll/Puff_selected.gif": "/responsive/interactions/effects.v2/scroll/Puff_selected.gif",
    "responsive/interactions/effects.v2/scroll/Puff_static.gif": "/responsive/interactions/effects.v2/scroll/Puff_static.gif",
    "responsive/interactions/effects.v2/scroll/Reveal_animated.gif": "/responsive/interactions/effects.v2/scroll/Reveal_animated.gif",
    "responsive/interactions/effects.v2/scroll/Reveal_selected.gif": "/responsive/interactions/effects.v2/scroll/Reveal_selected.gif",
    "responsive/interactions/effects.v2/scroll/Reveal_static.gif": "/responsive/interactions/effects.v2/scroll/Reveal_static.gif",
    "responsive/interactions/effects.v2/scroll/Roll_animated.gif": "/responsive/interactions/effects.v2/scroll/Roll_animated.gif",
    "responsive/interactions/effects.v2/scroll/Roll_selected.gif": "/responsive/interactions/effects.v2/scroll/Roll_selected.gif",
    "responsive/interactions/effects.v2/scroll/Roll_static.gif": "/responsive/interactions/effects.v2/scroll/Roll_static.gif",
    "responsive/interactions/effects.v2/scroll/Shape_animated.gif": "/responsive/interactions/effects.v2/scroll/Shape_animated.gif",
    "responsive/interactions/effects.v2/scroll/Shape_selected.gif": "/responsive/interactions/effects.v2/scroll/Shape_selected.gif",
    "responsive/interactions/effects.v2/scroll/Shape_static.gif": "/responsive/interactions/effects.v2/scroll/Shape_static.gif",
    "responsive/interactions/effects.v2/scroll/Skew_animated.gif": "/responsive/interactions/effects.v2/scroll/Skew_animated.gif",
    "responsive/interactions/effects.v2/scroll/Skew_selected.gif": "/responsive/interactions/effects.v2/scroll/Skew_selected.gif",
    "responsive/interactions/effects.v2/scroll/Skew_static.gif": "/responsive/interactions/effects.v2/scroll/Skew_static.gif",
    "responsive/interactions/effects.v2/scroll/Slide_animated.gif": "/responsive/interactions/effects.v2/scroll/Slide_animated.gif",
    "responsive/interactions/effects.v2/scroll/Slide_selected.gif": "/responsive/interactions/effects.v2/scroll/Slide_selected.gif",
    "responsive/interactions/effects.v2/scroll/Slide_static.gif": "/responsive/interactions/effects.v2/scroll/Slide_static.gif",
    "responsive/interactions/effects.v2/scroll/Spin_animated.gif": "/responsive/interactions/effects.v2/scroll/Spin_animated.gif",
    "responsive/interactions/effects.v2/scroll/Spin_selected.gif": "/responsive/interactions/effects.v2/scroll/Spin_selected.gif",
    "responsive/interactions/effects.v2/scroll/Spin_static.gif": "/responsive/interactions/effects.v2/scroll/Spin_static.gif",
    "responsive/interactions/effects.v2/scroll/Stretch_animated.gif": "/responsive/interactions/effects.v2/scroll/Stretch_animated.gif",
    "responsive/interactions/effects.v2/scroll/Stretch_selected.gif": "/responsive/interactions/effects.v2/scroll/Stretch_selected.gif",
    "responsive/interactions/effects.v2/scroll/Stretch_static.gif": "/responsive/interactions/effects.v2/scroll/Stretch_static.gif",
    "responsive/interactions/effects.v2/scroll/Turn_animated.gif": "/responsive/interactions/effects.v2/scroll/Turn_animated.gif",
    "responsive/interactions/effects.v2/scroll/Turn_selected.gif": "/responsive/interactions/effects.v2/scroll/Turn_selected.gif",
    "responsive/interactions/effects.v2/scroll/Turn_static.gif": "/responsive/interactions/effects.v2/scroll/Turn_static.gif",
    "responsive/interactions/effects.v2/scroll/Twist_animated.gif": "/responsive/interactions/effects.v2/scroll/Twist_animated.gif",
    "responsive/interactions/effects.v2/scroll/Twist_selected.gif": "/responsive/interactions/effects.v2/scroll/Twist_selected.gif",
    "responsive/interactions/effects.v2/scroll/Twist_static.gif": "/responsive/interactions/effects.v2/scroll/Twist_static.gif",
    "responsive/interactions/effects.v2/Shape_animated.gif": "/responsive/interactions/effects.v2/Shape_animated.gif",
    "responsive/interactions/effects.v2/Shape_selected.gif": "/responsive/interactions/effects.v2/Shape_selected.gif",
    "responsive/interactions/effects.v2/Shape_static.gif": "/responsive/interactions/effects.v2/Shape_static.gif",
    "responsive/interactions/effects.v2/Slide_animated.gif": "/responsive/interactions/effects.v2/Slide_animated.gif",
    "responsive/interactions/effects.v2/Slide_selected.gif": "/responsive/interactions/effects.v2/Slide_selected.gif",
    "responsive/interactions/effects.v2/Slide_static.gif": "/responsive/interactions/effects.v2/Slide_static.gif",
    "responsive/interactions/effects.v2/Spin_animated.gif": "/responsive/interactions/effects.v2/Spin_animated.gif",
    "responsive/interactions/effects.v2/Spin_selected.gif": "/responsive/interactions/effects.v2/Spin_selected.gif",
    "responsive/interactions/effects.v2/Spin_static.gif": "/responsive/interactions/effects.v2/Spin_static.gif",
    "responsive/interactions/effects.v2/Tilt_animated.gif": "/responsive/interactions/effects.v2/Tilt_animated.gif",
    "responsive/interactions/effects.v2/Tilt_selected.gif": "/responsive/interactions/effects.v2/Tilt_selected.gif",
    "responsive/interactions/effects.v2/Tilt_static.gif": "/responsive/interactions/effects.v2/Tilt_static.gif",
    "responsive/interactions/effects.v2/Turn_animated.gif": "/responsive/interactions/effects.v2/Turn_animated.gif",
    "responsive/interactions/effects.v2/Turn_selected.gif": "/responsive/interactions/effects.v2/Turn_selected.gif",
    "responsive/interactions/effects.v2/Turn_static.gif": "/responsive/interactions/effects.v2/Turn_static.gif",
    "responsive/interactions/effects.v2/Wink_animated.gif": "/responsive/interactions/effects.v2/Wink_animated.gif",
    "responsive/interactions/effects.v2/Wink_selected.gif": "/responsive/interactions/effects.v2/Wink_selected.gif",
    "responsive/interactions/effects.v2/Wink_static.gif": "/responsive/interactions/effects.v2/Wink_static.gif",
    "responsive/interactions/Fade_animated.gif": "/responsive/interactions/Fade_animated.gif",
    "responsive/interactions/Fade_selected.gif": "/responsive/interactions/Fade_selected.gif",
    "responsive/interactions/Fade_static.gif": "/responsive/interactions/Fade_static.gif",
    "responsive/interactions/FadeInteraction.gif": "/responsive/interactions/FadeInteraction.gif",
    "responsive/interactions/Grow_animated.gif": "/responsive/interactions/Grow_animated.gif",
    "responsive/interactions/Grow_selected.gif": "/responsive/interactions/Grow_selected.gif",
    "responsive/interactions/Grow_static.gif": "/responsive/interactions/Grow_static.gif",
    "responsive/interactions/GrowInteraction.gif": "/responsive/interactions/GrowInteraction.v2.gif",
    "responsive/interactions/Move_animated.gif": "/responsive/interactions/Move_animated.gif",
    "responsive/interactions/Move_selected.gif": "/responsive/interactions/Move_selected.gif",
    "responsive/interactions/Move_static.gif": "/responsive/interactions/Move_static.gif",
    "responsive/interactions/MoveInteraction.gif": "/responsive/interactions/MoveInteraction.v2.gif",
    "responsive/interactions/None_animated.gif": "/responsive/interactions/None_animated.gif",
    "responsive/interactions/None_selected.gif": "/responsive/interactions/None_selected.gif",
    "responsive/interactions/None_static.gif": "/responsive/interactions/None_static.gif",
    "responsive/interactions/Rotate_animated.gif": "/responsive/interactions/Rotate_animated.gif",
    "responsive/interactions/Rotate_selected.gif": "/responsive/interactions/Rotate_selected.gif",
    "responsive/interactions/Rotate_static.gif": "/responsive/interactions/Rotate_static.gif",
    "responsive/interactions/RotateInteraction.gif": "/responsive/interactions/RotateInteraction.gif",
    "responsive/interactions/Shrink_animated.gif": "/responsive/interactions/Shrink_animated.gif",
    "responsive/interactions/Shrink_selected.gif": "/responsive/interactions/Shrink_selected.gif",
    "responsive/interactions/Shrink_static.gif": "/responsive/interactions/Shrink_static.gif",
    "responsive/interactions/ShrinkInteraction.gif": "/responsive/interactions/ShrinkInteraction.v2.gif",
    "responsive/interactions/Skew_animated.gif": "/responsive/interactions/Skew_animated.gif",
    "responsive/interactions/Skew_selected.gif": "/responsive/interactions/Skew_selected.gif",
    "responsive/interactions/Skew_static.gif": "/responsive/interactions/Skew_static.gif",
    "responsive/interactions/SkewInteraction.gif": "/responsive/interactions/SkewInteraction.gif",
    "responsive/interactions/stateAnimations.v2/Cut_animated.gif": "/responsive/interactions/stateAnimations.v2/Cut_animated.gif",
    "responsive/interactions/stateAnimations.v2/Cut_selected.gif": "/responsive/interactions/stateAnimations.v2/Cut_selected.gif",
    "responsive/interactions/stateAnimations.v2/Cut_static.gif": "/responsive/interactions/stateAnimations.v2/Cut_static.gif",
    "responsive/interactions/stateAnimations.v2/Fade_animated.gif": "/responsive/interactions/stateAnimations.v2/Fade_animated.gif",
    "responsive/interactions/stateAnimations.v2/Fade_selected.gif": "/responsive/interactions/stateAnimations.v2/Fade_selected.gif",
    "responsive/interactions/stateAnimations.v2/Fade_static.gif": "/responsive/interactions/stateAnimations.v2/Fade_static.gif",
    "responsive/interactions/stateAnimations.v2/Grow_animated.gif": "/responsive/interactions/stateAnimations.v2/Grow_animated.gif",
    "responsive/interactions/stateAnimations.v2/Grow_selected.gif": "/responsive/interactions/stateAnimations.v2/Grow_selected.gif",
    "responsive/interactions/stateAnimations.v2/Grow_static.gif": "/responsive/interactions/stateAnimations.v2/Grow_static.gif",
    "responsive/interactions/stateAnimations.v2/Move_animated.gif": "/responsive/interactions/stateAnimations.v2/Move_animated.gif",
    "responsive/interactions/stateAnimations.v2/Move_selected.gif": "/responsive/interactions/stateAnimations.v2/Move_selected.gif",
    "responsive/interactions/stateAnimations.v2/Move_static.gif": "/responsive/interactions/stateAnimations.v2/Move_static.gif",
    "responsive/interactions/stateAnimations.v2/Puff_animated.gif": "/responsive/interactions/stateAnimations.v2/Puff_animated.gif",
    "responsive/interactions/stateAnimations.v2/Puff_selected.gif": "/responsive/interactions/stateAnimations.v2/Puff_selected.gif",
    "responsive/interactions/stateAnimations.v2/Puff_static.gif": "/responsive/interactions/stateAnimations.v2/Puff_static.gif",
    "responsive/interactions/stateAnimations.v2/Skew_animated.gif": "/responsive/interactions/stateAnimations.v2/Skew_animated.gif",
    "responsive/interactions/stateAnimations.v2/Skew_selected.gif": "/responsive/interactions/stateAnimations.v2/Skew_selected.gif",
    "responsive/interactions/stateAnimations.v2/Skew_static.gif": "/responsive/interactions/stateAnimations.v2/Skew_static.gif",
    "responsive/interactions/stateAnimations.v2/Spin_animated.gif": "/responsive/interactions/stateAnimations.v2/Spin_animated.gif",
    "responsive/interactions/stateAnimations.v2/Spin_selected.gif": "/responsive/interactions/stateAnimations.v2/Spin_selected.gif",
    "responsive/interactions/stateAnimations.v2/Spin_static.gif": "/responsive/interactions/stateAnimations.v2/Spin_static.gif",
    "responsive/interactions/stateAnimations.v2/Style_animated.gif": "/responsive/interactions/stateAnimations.v2/Style_animated.gif",
    "responsive/interactions/stateAnimations.v2/Style_selected.gif": "/responsive/interactions/stateAnimations.v2/Style_selected.gif",
    "responsive/interactions/stateAnimations.v2/Style_static.gif": "/responsive/interactions/stateAnimations.v2/Style_static.gif",
    "responsive/interactions/stateAnimations.v2/Twist_animated.gif": "/responsive/interactions/stateAnimations.v2/Twist_animated.gif",
    "responsive/interactions/stateAnimations.v2/Twist_selected.gif": "/responsive/interactions/stateAnimations.v2/Twist_selected.gif",
    "responsive/interactions/stateAnimations.v2/Twist_static.gif": "/responsive/interactions/stateAnimations.v2/Twist_static.gif",
    "responsive/interactions/Style_animated.gif": "/responsive/interactions/Style_animated.gif",
    "responsive/interactions/Style_selected.gif": "/responsive/interactions/Style_selected.gif",
    "responsive/interactions/Style_static.gif": "/responsive/interactions/Style_static.gif",
    "responsive/interactions/StyleInteraction.gif": "/responsive/interactions/StyleInteraction.gif",
    "responsive/interactions/Twist_animated.gif": "/responsive/interactions/Twist_animated.gif",
    "responsive/interactions/Twist_selected.gif": "/responsive/interactions/Twist_selected.gif",
    "responsive/interactions/Twist_static.gif": "/responsive/interactions/Twist_static.gif",
    "responsive/loaders/gradient_bg.jpg": "/responsive/loaders/gradient_bg.jpg",
    "responsive/loaders/prodLoader.gif": "/responsive/loaders/prodLoader.gif",
    "responsive/positionType/element-default.gif": "/responsive/positionType/element-default.v3.gif",
    "responsive/positionType/element-pinned.gif": "/responsive/positionType/element-pinned.v3.gif",
    "responsive/positionType/element-sticky.gif": "/responsive/positionType/element-sticky.v3.gif",
    "responsive/positionType/section-default.gif": "/responsive/positionType/section-default.v3.gif",
    "responsive/positionType/section-pinned.gif": "/responsive/positionType/section-pinned.v3.gif",
    "responsive/positionType/section-sticky.gif": "/responsive/positionType/section-sticky.v3.gif",
    "responsive/presence/edix_avatar_36.png": "/responsive/presence/edix_avatar_36.png",
    "responsive/sizingBehavior/element-fixed.gif": "/responsive/sizingBehavior/element-fixed.v3.gif",
    "responsive/sizingBehavior/element-relative-width.gif": "/responsive/sizingBehavior/element-relative-width.v3.gif",
    "responsive/sizingBehavior/element-scale.gif": "/responsive/sizingBehavior/element-scale.v3.gif",
    "responsive/sizingBehavior/element-stretch.gif": "/responsive/sizingBehavior/element-stretch.v3.gif",
    "responsive/sizingBehavior/section-fit-to-screen.gif": "/responsive/sizingBehavior/section-fit-to-screen.v3.gif",
    "responsive/sizingBehavior/section-fixed-height.gif": "/responsive/sizingBehavior/section-fixed-height.v3.gif",
    "responsive/sizingBehavior/section-scale.gif": "/responsive/sizingBehavior/section-scale.v3.gif",
    "responsive/sizingBehavior/text-fixed.gif": "/responsive/sizingBehavior/text-fixed.v3.gif",
    "responsive/sizingBehavior/text-hug.gif": "/responsive/sizingBehavior/text-hug.v3.gif",
    "responsive/sizingBehavior/text-scale.gif": "/responsive/sizingBehavior/text-scale.v3.gif",
    "responsive/sizingBehavior/text-stretch.gif": "/responsive/sizingBehavior/text-stretch.gif",
    "responsive/sizingBehavior/text-wrap.gif": "/responsive/sizingBehavior/text-wrap.v3.gif",
    "responsive/tours/concurrentEditing/avatars.svg": "/responsive/tours/concurrentEditing/avatars.svg",
    "responsive/tours/concurrentEditing/Concurrent01.gif": "/responsive/tours/concurrentEditing/Concurrent01.gif",
    "responsive/tours/concurrentEditing/Concurrent02.gif": "/responsive/tours/concurrentEditing/Concurrent02.gif",
    "responsive/tours/concurrentEditing/Concurrent03.gif": "/responsive/tours/concurrentEditing/Concurrent03.gif",
    "responsive/tours/concurrentEditing/Concurrent1.mp4": "/responsive/tours/concurrentEditing/Concurrent1.mp4",
    "responsive/tours/concurrentEditing/Concurrent2.mp4": "/responsive/tours/concurrentEditing/Concurrent2.mp4",
    "responsive/tours/concurrentEditing/Concurrent3.mp4": "/responsive/tours/concurrentEditing/Concurrent3.mp4",
    "responsive/tours/designLibraries/Assets.mp4": "/responsive/tours/designLibraries/Assets.mp4",
    "responsive/tours/designLibraries/Colors.mp4": "/responsive/tours/designLibraries/Colors.mp4",
    "responsive/tours/designLibraries/DesignLibraries@2x.png": "/responsive/tours/designLibraries/DesignLibraries@2x.png",
    "responsive/tours/designLibraries/Typography.mp4": "/responsive/tours/designLibraries/Typography.mp4",
    "responsive/tours/import/welcome-tour_img.png": "/responsive/tours/import/welcome-tour_img.png",
    "responsive/tours/inspectorContent/InspectorContent.gif": "/responsive/tours/inspectorContent/InspectorContent.gif",
    "responsive/tours/interactions/clickInteractions.gif": "/responsive/tours/interactions/clickInteractions.v2.gif",
    "responsive/tours/interactions/interactions.mp4": "/responsive/tours/interactions/interactions.mp4",
    "responsive/tours/leaveScale/stack.gif": "/responsive/tours/leaveScale/stack.gif",
    "responsive/tours/pinnedPosition/PinnedPosition.mp4": "/responsive/tours/pinnedPosition/PinnedPosition.mp4",
    "responsive/tours/sectionBehaviors/section-bg-inspector.mp4": "/responsive/tours/sectionBehaviors/section-bg-inspector.v3.mp4",
    "responsive/tours/sectionBehaviors/section-scroll-effects.mp4": "/responsive/tours/sectionBehaviors/section-scroll-effects.v3.mp4",
    "responsive/tours/shareSite/permissionsPerPage.mp4": "/responsive/tours/shareSite/permissionsPerPage.v2.mp4",
    "responsive/tours/shareSite/shareButton.mp4": "/responsive/tours/shareSite/shareButton.v2.mp4",
    "responsive/tours/shareSite/sharePanelRoles.mp4": "/responsive/tours/shareSite/sharePanelRoles.v2.mp4",
    "responsive/tours/tourLoader.gif": "/responsive/tours/tourLoader.gif",
    "responsive/tours/tourTooltip.png": "/responsive/tours/tourTooltip.png",
    "responsive/tours/TourTooltipSkeleton.png": "/responsive/tours/TourTooltipSkeleton.png",
    "responsive/tours/welcome/ Layers.mp4": "/responsive/tours/welcome/ Layers.mp4",
    "responsive/tours/welcome/AddComponent.mp4": "/responsive/tours/welcome/AddComponent.v2.mp4",
    "responsive/tours/welcome/AddComponentPoster.png": "/responsive/tours/welcome/AddComponentPoster.v2.png",
    "responsive/tours/welcome/Breakpoints.mp4": "/responsive/tours/welcome/Breakpoints.v2.mp4",
    "responsive/tours/welcome/BreakpointsPoster.png": "/responsive/tours/welcome/BreakpointsPoster.v2.png",
    "responsive/tours/welcome/Inspector.mp4": "/responsive/tours/welcome/Inspector.v2..mp4",
    "responsive/tours/welcome/InspectorPoster.png": "/responsive/tours/welcome/InspectorPoster.v2.png",
    "responsive/tours/welcome/LayersPanel.mp4": "/responsive/tours/welcome/LayersPanel.mp4",
    "responsive/tours/welcome/LayersPoster.png": "/responsive/tours/welcome/LayersPoster.v2.png",
    "responsive/tours/welcome/Masters.mp4": "/responsive/tours/welcome/Masters.v2.mp4",
    "responsive/tours/welcome/MastersPoster.png": "/responsive/tours/welcome/MastersPoster.v2.png",
    "responsive/tours/welcome/Sections.mp4": "/responsive/tours/welcome/Sections.v2.mp4",
    "responsive/tours/welcome/SectionsPoster.png": "/responsive/tours/welcome/SectionsPoster.v2.png",
    "responsive/tours/welcome/WelcomeDialog-New.mp4": "/responsive/tours/welcome/WelcomeDialog-New.mp4",
    "responsive/tours/welcome/WelcomeDialog-New.png": "/responsive/tours/welcome/WelcomeDialog-New.png",
    "responsive/tours/welcome/WelcomeDialog.mp4": "/responsive/tours/welcome/WelcomeDialog.mp4",
    "responsive/tours/welcome/WelcomeDialogPoster.png": "/responsive/tours/welcome/WelcomeDialogPoster.v2.png",
    "responsive/tours/welcome/WelcomeDialogSkeleton.png": "/responsive/tours/welcome/WelcomeDialogSkeleton.png",
    "savePublish/panels/content/connectDomain_30.png": "/savePublish/panels/content/connectDomain_30.png",
    "savePublish/panels/content/connectDomain_40.png": "/savePublish/panels/content/connectDomain_40.png",
    "savePublish/panels/content/connectDomain_50.png": "/savePublish/panels/content/connectDomain_50.png",
    "savePublish/panels/content/musicPromotion.png": "/savePublish/panels/content/musicPromotion.v2.png",
    "savePublish/panels/content/musicPromotionRetina.png": "/savePublish/panels/content/musicPromotionRetina.v2.png",
    "savePublish/panels/content/photoPromotion.gif": "/savePublish/panels/content/photoPromotion.v4.gif",
    "savePublish/panels/content/photoPromotion.png": "/savePublish/panels/content/photoPromotion.png",
    "savePublish/panels/content/photoPromotionRetina.gif": "/savePublish/panels/content/photoPromotionRetina.v4.gif",
    "savePublish/panels/content/photoPromotionRetina.png": "/savePublish/panels/content/photoPromotionRetina.png",
    "superAppMenuInfra/AscendHelpCenter.png": "/superAppMenuInfra/AscendHelpCenter.png",
    "superAppMenuInfra/AscendMarketingTips.png": "/superAppMenuInfra/AscendMarketingTips.png",
    "superAppMenuInfra/AscendTools.png": "/superAppMenuInfra/AscendTools.png",
    "superAppMenuInfra/ContentManager.png": "/superAppMenuInfra/ContentManager.v3.png",
    "superAppMenuInfra/HelpCenter.png": "/superAppMenuInfra/HelpCenter.v3.png",
    "superAppMenuInfra/HireWixDesignPartner.png": "/superAppMenuInfra/HireWixDesignPartner.png",
    "superAppMenuInfra/LearningCenter.png": "/superAppMenuInfra/LearningCenter.v2.png",
    "superAppMenuInfra/OpenTheBlogSupportForum.png": "/superAppMenuInfra/OpenTheBlogSupportForum.v2.png",
    "superAppMenuInfra/TipsFromWixsBloogingPros.png": "/superAppMenuInfra/TipsFromWixsBloogingPros.v2.png",
    "superAppMenuInfra/ViewTheBlogHelpCenter.png": "/superAppMenuInfra/ViewTheBlogHelpCenter.v2.png",
    "textEditor/fonts.png": "/textEditor/fonts.v5.png",
    "textEditor/langs.png": "/textEditor/langs.v2.png",
    "textEditor/user-site-fonts.v1/arabic.css": "/textEditor/user-site-fonts.v1/arabic.css",
    "textEditor/user-site-fonts.v1/cyrillic.css": "/textEditor/user-site-fonts.v1/cyrillic.css",
    "textEditor/user-site-fonts.v1/hebrew.css": "/textEditor/user-site-fonts.v1/hebrew.css",
    "textEditor/user-site-fonts.v1/japanese.css": "/textEditor/user-site-fonts.v1/japanese.css",
    "textEditor/user-site-fonts.v1/korean.css": "/textEditor/user-site-fonts.v1/korean.css",
    "textEditor/user-site-fonts.v1/latin-ext.css": "/textEditor/user-site-fonts.v1/latin-ext.css",
    "textEditor/user-site-fonts.v1/latin.css": "/textEditor/user-site-fonts.v1/latin.css",
    "wixBookings/addPanel/add_panel_booking.png": "/wixBookings/addPanel/add_panel_booking.png",
    "wixBookings/addPanel/add_panel_bookings.png": "/wixBookings/addPanel/add_panel_bookings.v3.png",
    "wixBookings/addPanel/add_panel_bookings@x2.png": "/wixBookings/addPanel/add_panel_bookings@x2.v2.png",
    "wixBookings/addPanel/bookings_offering_list_widget_4_presets.png": "/wixBookings/addPanel/bookings_offering_list_widget_4_presets.v2.png",
    "wixBookings/addPanel/bookings_offering_list_widget_4_presets_final.png": "/wixBookings/addPanel/bookings_offering_list_widget_4_presets_final.png",
    "wixBookings/addPanel/bookings_offering_list_widget_4_presets_new.png": "/wixBookings/addPanel/bookings_offering_list_widget_4_presets_new.png",
    "wixBookings/addPanel/bookings_offering_list_widget_july_2019_presets.png": "/wixBookings/addPanel/bookings_offering_list_widget_july_2019_presets.png",
    "wixBookings/addPanel/bookings_offering_list_widget_Presets_editorX.png": "/wixBookings/addPanel/bookings_offering_list_widget_Presets_editorX.png",
    "wixBookings/addPanel/bookings_offering_list_widget_Presets_wix_studio.png": "/wixBookings/addPanel/bookings_offering_list_widget_Presets_wix_studio.v2.png",
    "wixBookings/addPanel/bookings_single_service_widget_editorX.png": "/wixBookings/addPanel/bookings_single_service_widget_editorX.png",
    "wixBookings/addPanel/bookings_single_service_widget_wix_studio.png": "/wixBookings/addPanel/bookings_single_service_widget_wix_studio.png",
    "wixBookings/addPanel/calendar panel.svg": "/wixBookings/addPanel/calendar panel.svg",
    "wixBookings/addPanel/newWorkspace_add_panel_bookings.png": "/wixBookings/addPanel/newWorkspace_add_panel_bookings.v2.png",
    "wixBookings/addPanel/newWorkspace_add_panel_bookings@x2.png": "/wixBookings/addPanel/newWorkspace_add_panel_bookings@x2.v2.png",
    "wixBookings/addPanel/offering_list_widget.png": "/wixBookings/addPanel/offering_list_widget.png",
    "wixBookings/addPanel/preset_editor_book_button.jpg": "/wixBookings/addPanel/preset_editor_book_button.v1.jpg",
    "wixBookings/addPanel/preset_editor_single_service_widget.png": "/wixBookings/addPanel/preset_editor_single_service_widget.v3.png",
    "wixBookings/addPanel/preset_editor_single_service_widget_V2.png": "/wixBookings/addPanel/preset_editor_single_service_widget_V2.png",
    "wixBookings/addPanel/preset_editor_staff_list_widget.png": "/wixBookings/addPanel/preset_editor_staff_list_widget.v3.png",
    "wixBookings/addPanel/preset_editor_staff_list_widget_V3.png": "/wixBookings/addPanel/preset_editor_staff_list_widget_V3.png",
    "wixBookings/addPanel/preset_editor_timetable.jpg": "/wixBookings/addPanel/preset_editor_timetable.v4.jpg",
    "wixBookings/addPanel/preset_editor_timetable.png": "/wixBookings/addPanel/preset_editor_timetable.v3.png",
    "wixBookings/addPanel/prest_editor_staff_list_widget.png": "/wixBookings/addPanel/prest_editor_staff_list_widget.png",
    "wixBookings/addPanel/service_widget.png": "/wixBookings/addPanel/service_widget.png",
    "wixBookings/addPanel/service_widget_V2.png": "/wixBookings/addPanel/service_widget_V2.png",
    "wixBookings/addPanel/service_widget_V3.png": "/wixBookings/addPanel/service_widget_V3.png",
    "wixBookings/appMenuPanel/bookingsWelcomeSection.png": "/wixBookings/appMenuPanel/bookingsWelcomeSection.v1.png",
    "wixBookings/appMenuPanel/bookingsWelcomeSection@x2.png": "/wixBookings/appMenuPanel/bookingsWelcomeSection@x2.v1.png",
    "wixBookings/appMenuPanel/gallery/bookingsGallery01.png": "/wixBookings/appMenuPanel/gallery/bookingsGallery01.png",
    "wixBookings/appMenuPanel/gallery/bookingsGallery02.png": "/wixBookings/appMenuPanel/gallery/bookingsGallery02.png",
    "wixBookings/appMenuPanel/gallery/bookingsGallery03.png": "/wixBookings/appMenuPanel/gallery/bookingsGallery03.png",
    "wixBookings/appMenuPanel/main/manageBookings/manageBookings_01.png": "/wixBookings/appMenuPanel/main/manageBookings/manageBookings_01.png",
    "wixCode/codeEditor/gutter-error.png": "/wixCode/codeEditor/gutter-error.png",
    "wixCode/codeEditor/gutter-warning.png": "/wixCode/codeEditor/gutter-warning.v2.png",
    "wixCode/codeEditor/range-error.png": "/wixCode/codeEditor/range-error.png",
    "wixCode/codeEditor/range-warning.png": "/wixCode/codeEditor/range-warning.png",
    "wixCode/findReplace/ide_find_case_sensitive.png": "/wixCode/findReplace/ide_find_case_sensitive.png",
    "wixCode/findReplace/ide_find_case_sensitive_hover.png": "/wixCode/findReplace/ide_find_case_sensitive_hover.png",
    "wixCode/findReplace/ide_find_case_sensitive_selected.png": "/wixCode/findReplace/ide_find_case_sensitive_selected.png",
    "wixCode/findReplace/ide_find_next.png": "/wixCode/findReplace/ide_find_next.png",
    "wixCode/findReplace/ide_find_previous.png": "/wixCode/findReplace/ide_find_previous.png",
    "wixCode/findReplace/ide_find_regex.png": "/wixCode/findReplace/ide_find_regex.png",
    "wixCode/findReplace/ide_find_regex_hover.png": "/wixCode/findReplace/ide_find_regex_hover.png",
    "wixCode/findReplace/ide_find_regex_selected.png": "/wixCode/findReplace/ide_find_regex_selected.png",
    "wixCode/findReplace/ide_find_whole_word.png": "/wixCode/findReplace/ide_find_whole_word.png",
    "wixCode/findReplace/ide_find_whole_word_hover.png": "/wixCode/findReplace/ide_find_whole_word_hover.png",
    "wixCode/findReplace/ide_find_whole_word_selected.png": "/wixCode/findReplace/ide_find_whole_word_selected.png",
    "wixStore/appMenuPanel/addPanelTeaser.png": "/wixStore/appMenuPanel/addPanelTeaser.png",
    "wixStore/appMenuPanel/firstTimePromotionalMainImage.png": "/wixStore/appMenuPanel/firstTimePromotionalMainImage.png",
    "wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel.png": "/wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel.v2.png",
    "wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel@x2.png": "/wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel@x2.v3.png",
    "wixStore/appMenuPanel/gallery/storeGallery01.png": "/wixStore/appMenuPanel/gallery/storeGallery01.v2.png",
    "wixStore/appMenuPanel/gallery/storeGallery02.png": "/wixStore/appMenuPanel/gallery/storeGallery02.v2.png",
    "wixStore/appMenuPanel/gallery/storeGallery03.png": "/wixStore/appMenuPanel/gallery/storeGallery03.v2.png",
    "wixStore/appMenuPanel/gallery/storeGallery04.png": "/wixStore/appMenuPanel/gallery/storeGallery04.v2.png",
    "wixStore/appMenuPanel/gallery/storeGallery05.png": "/wixStore/appMenuPanel/gallery/storeGallery05.png",
    "wixStore/appMenuPanel/gallery/storeGallery06.png": "/wixStore/appMenuPanel/gallery/storeGallery06.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel.v3.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel1.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel1.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel2.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel2.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel3.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel3.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel4.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel4.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel5.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel5.png",
    "wixStore/appMenuPanel/gallery/storeGalleryAddPanel@x2.png": "/wixStore/appMenuPanel/gallery/storeGalleryAddPanel@x2.v1.png",
    "wixStore/appMenuPanel/managePanelWelcome.png": "/wixStore/appMenuPanel/managePanelWelcome.png",
    "wixStore/appMenuPanel/wixStoreTooltipUpgrade.png": "/wixStore/appMenuPanel/wixStoreTooltipUpgrade.png",
    "wixStore/sections/addToCartButton.png": "/wixStore/sections/addToCartButton.png",
    "wixStore/sections/bestSellersWidget.png": "/wixStore/sections/bestSellersWidget.png",
    "wixStore/sections/currencyConverter.png": "/wixStore/sections/currencyConverter.v1.png",
    "wixStore/sections/productGrid.png": "/wixStore/sections/productGrid.v5.png",
    "wixStore/sections/productGridGalleryRevamp.png": "/wixStore/sections/productGridGalleryRevamp.png",
    "wixStore/sections/productWidget.png": "/wixStore/sections/productWidget.v4.png",
    "wixStore/sections/productWidgetRevamp.png": "/wixStore/sections/productWidgetRevamp.png",
    "wixStore/sections/relatedProductGalleryFrequentlyBoughtTogether.png": "/wixStore/sections/relatedProductGalleryFrequentlyBoughtTogether.png",
    "wixStore/sections/relatedProductGalleryRevamp.png": "/wixStore/sections/relatedProductGalleryRevamp.png",
    "wixStore/sections/relatedProductsGallery.png": "/wixStore/sections/relatedProductsGallery.v1.png",
    "wixStore/sections/shoppingBag.png": "/wixStore/sections/shoppingBag.png",
    "wixStore/sections/sliderProductGallery.png": "/wixStore/sections/sliderProductGallery.v4.png",
    "wixStore/sections/sliderProductGalleryRevamp.png": "/wixStore/sections/sliderProductGalleryRevamp.png",
    "wixStore/sections/studio/addToCartButton.png": "/wixStore/sections/studio/addToCartButton.png",
    "wixStore/sections/studio/bestSellers.png": "/wixStore/sections/studio/bestSellers.png",
    "wixStore/sections/studio/currencyConverter.png": "/wixStore/sections/studio/currencyConverter.png",
    "wixStore/sections/studio/gridGallery.png": "/wixStore/sections/studio/gridGallery.png",
    "wixStore/sections/studio/relatedProducts.png": "/wixStore/sections/studio/relatedProducts.png",
    "wixStore/sections/studio/shoppingCart.png": "/wixStore/sections/studio/shoppingCart.png",
    "wixStore/sections/studio/sliderProductGallery.png": "/wixStore/sections/studio/sliderProductGallery.png"
}
